import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IKeysListState {
    status: string
    data: null
    errors: any
}

const initialState: IKeysListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}
const keysList = createSlice({
    name: 'localization/keys-list',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const keysListActions = keysList.actions
export default keysList.reducer
