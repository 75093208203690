import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditGroupVisibilityByIDPayload } from '@app-store/slices/eventSettings/editGroupVisibilityByID'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IEditGroupVisibilityByIDPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.editGroupVisibilityById

function* editGroupVisibilityByIdSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(
            `/event/settings/${payload.settingId}/group-visibility/${payload.visibilityId}`,
            payload.data
        )
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.Event?.Visibility_setting_updated_successfully || 'Visibility setting updated successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editGroupVisibilityByIdSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editGroupVisibilityByIdSagaTask)
}

export default editGroupVisibilityByIdSaga
