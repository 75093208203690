import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IPublishContactRequestPayload {
    id: string
}

export interface ICreateState {
    status: string
    data: null
    errors: any
}

const initialState: ICreateState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const publishContactListSlice = createSlice({
    name: 'hub/editList/publishContact',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IPublishContactRequestPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const publishContactListActions = publishContactListSlice.actions
export default publishContactListSlice.reducer
