import listSaga from './listSaga'
import editSaga from './editSaga'
import oneContactSaga from './oneContactSaga'
import assignedListSaga from './assignedListSaga'
import advancedListSaga from './advancedListSaga'
import getPreSignedUploadURLSaga from './getPreSignedUploadURLSaga'
import uploadImageSaga from './uploadImageSaga'
import deleteContactSaga from './deleteSaga'
import moveToCompanySaga from './moveToCompany'
import checkInOutSaga from './checkInOutSaga'
import contactByTokenSaga from './contactByTokenSaga'
import contactsStatusStatisticsSaga from './contactsStatusStatisticsSaga'
import searchSaga from './searchSaga'
import contactVerifySaga from './contactVerifySaga'
import contactListVerifySaga from './contactListVerifySaga'

const companiesSagas = [
    listSaga(),
    oneContactSaga(),
    editSaga(),
    assignedListSaga(),
    advancedListSaga(),
    getPreSignedUploadURLSaga(),
    uploadImageSaga(),
    deleteContactSaga(),
    moveToCompanySaga(),
    checkInOutSaga(),
    contactByTokenSaga(),
    contactsStatusStatisticsSaga(),
    searchSaga(),
    contactVerifySaga(),
    contactListVerifySaga()
]

export default companiesSagas
