import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ICreateEventProfilesPayload extends ISagaCb {
    data: {
        companyProfileItems: any
        industries?: string
    }
}

export interface ICreateEventProfilesState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateEventProfilesState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createEventProfiles = createSlice({
    name: 'events/create-eventprofiles',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateEventProfilesPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createEventProfilesActions = createEventProfiles.actions
export default createEventProfiles.reducer
