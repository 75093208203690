import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IMeetingInfoByTokenPayload } from '@app-store/slices/meetingAgenda/meetingInfoByToken'

interface TaskAction extends Action {
    payload: IMeetingInfoByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingAgenda.meetingInfoByToken

function* meetingInfoByTokenSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/meeting/meeting-info`, {
            params: {
                token: payload.token
            }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload?.sagaCB?.onError()
    }
}

function* meetingInfoByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, meetingInfoByTokenSagaTask)
}

export default meetingInfoByTokenSaga
