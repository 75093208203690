import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IAddToAgendaByTokenPayload extends ISagaCb {
    token?: string
    data: {
        timeslots: {
            startDate: string
            endDate: string
        }[]
    }
}

export interface IAddToAgendaByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: IAddToAgendaByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const addToAgendaByToken = createSlice({
    name: 'meetings/add-to-agenda-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IAddToAgendaByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const addToAgendaByTokenActions = addToAgendaByToken.actions
export default addToAgendaByToken.reducer
