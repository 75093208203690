import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateSurveyPayload } from '@app-store/slices/survey/createSurvey'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICreateSurveyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.survey.createSurvey
const { setAddQuestionModal } = rootActions.ui.settings

function* createSurveySagaTask({ payload }) {
    try {
        const response = yield axios.post(`/meeting/survey`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(setAddQuestionModal(false))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Survey_created_successfully || 'Survey created successfully.'
            })
            if (payload.sagaCB) {
                yield payload.sagaCB.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createSurveySaga(): any {
    yield takeLatest<TaskAction>(onRequest, createSurveySagaTask)
}

export default createSurveySaga
