import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { ISendMessagePayload } from '@app-store/slices/chat/sendMessage'

interface TaskAction extends Action {
    payload: ISendMessagePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.sendMessage

function* sendMessageSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/chat.sendMessage`, payload.data)
        if (response.status === 200) {
            yield put(onSuccess(response.data.message))
            yield payload?.sagaCB?.onSuccess(response.data.message)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* sendMessageSaga(): any {
    yield takeLatest<TaskAction>(onRequest, sendMessageSagaTask)
}

export default sendMessageSaga
