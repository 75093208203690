import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IOneBrandByEventPayload extends ISagaCb {
    eventId?: string
}

export interface IOneBrandByEventState {
    status: string
    data: any
    errors: any
}

const initialState: IOneBrandByEventState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneBrandByEvent = createSlice({
    name: 'onbordingOrganizer/one-brand-by-event',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneBrandByEventPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.result
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneBrandByEventActions = oneBrandByEvent.actions
export default oneBrandByEvent.reducer
