import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IOneEventTopicPayload {
    eventTopicId: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneEventTopic = createSlice({
    name: 'eventTopics/one-event-topic',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneEventTopicPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneEventTopicActions = oneEventTopic.actions
export default oneEventTopic.reducer
