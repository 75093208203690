import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateMeetingPayload } from '@app-store/slices/meetings/createMeeting'
// import { history } from '@components/App'
// import routes from '@utils/constants/routes'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICreateMeetingPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.create

function* createMeetingSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (response?.result?._id) {
                // history.push(routes.meetings.meeting_agenda.profile._.replace(':id', response?.result?._id))
                if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
                const { data: i18n } = yield select((store: RootState) => store.localization.locals)
                notification.success({
                    message: i18n?.Meeting?.Meeting_created_successfully || 'Meeting created successfully.'
                })
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createMeetingSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createMeetingSagaTask)
}

export default createMeetingSaga
