import { combineReducers } from 'redux'
import createEventSettingReducer, { createEventSettingActions } from './create'
import createVisibilityReducer, { createVisibilityActions } from './createVisibility'
import listEventSettingReducer, { listEventSettingActions } from './list'
import oneSettingReducer, { oneSettingActions } from './oneSetting'
import editMatchReducer, { editMatchActions } from './editMatch'
import editNotificationReducer, { editNotificationActions } from './editNotification'
import editUserInvitationReducer, { editUserInvitationActions } from './editUserInvitation'
import editVisibilityReducer, { editVisibilityActions } from './editVisibility'
import editGroupVisibilityByIDReducer, { editGroupVisibilityByIDActions } from './editGroupVisibilityByID'
import editDefaultVisibilityReducer, { editDefaultVisibilityActions } from './editDefaultVisibility'
import editGlobalRenamingReducer, { editGlobalRenamingActions } from './editGlobalRenaming'
import deleteVisibilityReducer, { deleteVisibilityActions } from './deleteVisibility'
import editMenuVisibilityReducer, { editMenuVisibilityActions } from './editMenuVisibility'
import oneSectionSettingReducer, { oneSectionSettingActions } from './oneSectionSetting'
import editMeetingReducer, { editMeetingActions } from './editMeeting'
import activeFeaturesReducer, { activeFeaturesActions } from './activeFeatures'
import eventProfilesReducer, { eventProfilesActions } from './eventProfiles'
import createEventProfilesReducer, { createEventProfilesActions } from './createEventProfiles'
import editEventProfilesReducer, { editEventProfilesActions } from './editEventProfiles'
export const eventSettingReducer = combineReducers({
    create: createEventSettingReducer,
    createVisibility: createVisibilityReducer,
    list: listEventSettingReducer,
    oneSetting: oneSettingReducer,
    editMatch: editMatchReducer,
    editNotification: editNotificationReducer,
    editUserInvitation: editUserInvitationReducer,
    editVisibility: editVisibilityReducer,
    editDefaultVisibility: editDefaultVisibilityReducer,
    editGlobalRenaming: editGlobalRenamingReducer,
    editGroupVisibilityById: editGroupVisibilityByIDReducer,
    deleteVisibility: deleteVisibilityReducer,
    editMenuVisibility: editMenuVisibilityReducer,
    oneSectionSetting: oneSectionSettingReducer,
    editMeeting: editMeetingReducer,
    activeFeatures: activeFeaturesReducer,
    eventProfiles: eventProfilesReducer,
    createEventProfiles: createEventProfilesReducer,
    editEventProfiles: editEventProfilesReducer
})

export const eventSettingActions = {
    create: createEventSettingActions,
    createVisibility: createVisibilityActions,
    list: listEventSettingActions,
    oneSetting: oneSettingActions,
    editMatch: editMatchActions,
    editUserInvitation: editUserInvitationActions,
    editVisibility: editVisibilityActions,
    editDefaultVisibility: editDefaultVisibilityActions,
    editNotification: editNotificationActions,
    editGroupVisibilityById: editGroupVisibilityByIDActions,
    editGlobalRenaming: editGlobalRenamingActions,
    deleteVisibility: deleteVisibilityActions,
    editMenuVisibility: editMenuVisibilityActions,
    oneSectionSetting: oneSectionSettingActions,
    editMeeting: editMeetingActions,
    activeFeatures: activeFeaturesActions,
    eventProfiles: eventProfilesActions,
    createEventProfiles: createEventProfilesActions,
    editEventProfiles: editEventProfilesActions
}
