import { createSlice } from '@reduxjs/toolkit'

export interface StepsPayload {
    index?: number
    step: StepsCampaignInterface
}

export interface MeetingDatePayload {
    index?: number
    date: string
}
export interface StepsCampaignInterface {
    _id?: any
    subject?: string
    content?: string
    sendAt?: string
    meetingRequestDates?: string[]
    duration?: number
    stepType?: string
    place?: string
}
export interface ICommonState {
    selectedStepIndex: number
    startNewCampaignModal: {
        isVisible: boolean
    }
    campaign: {
        name: string
        users: string[]
        companies: string[]
        emailCredential?: string
    }
    steps: StepsCampaignInterface[]
    meetingRequestDates: string[]
}

const initialState: ICommonState = {
    startNewCampaignModal: {
        isVisible: false
    },
    meetingRequestDates: [],
    selectedStepIndex: 0,
    campaign: {
        name: '',
        users: [],
        companies: [],
        emailCredential: ''
    },
    steps: []
}

const wishCampaignSlice = createSlice({
    name: 'ui/wish-campaign',
    initialState,
    reducers: {
        changeStartNewCampaignModal(state, acion) {
            state.startNewCampaignModal.isVisible = acion.payload.isVisible || false
        },
        replaceSteps(state, action) {
            state.steps = action.payload
        },
        replaceStepByIndex(state, action) {
            const index = state.selectedStepIndex
            state.steps[index] = action.payload
        },
        deleteStepById(state, action) {
            const index = state.steps.findIndex(item => item._id === action.payload.stepId)
            if (index > -1) delete state.steps[index]
            if (state.steps.length > 0) state.selectedStepIndex = 0
        },
        setCampaignData(state, action) {
            const validUpdateKeysPattern = Object.keys(action.payload)
            validUpdateKeysPattern.forEach(item => {
                state.campaign[item] = action.payload[item]
            })
        },
        addNewStep(state, action) {
            state.steps.push(action.payload)
            state.selectedStepIndex = state.steps.length - 1
        },
        setSelectedStepIndex(state, action) {
            state.selectedStepIndex = action.payload
        }
    }
})

export const wishCampaignActions = wishCampaignSlice.actions
export default wishCampaignSlice.reducer
