import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOnePropertyPayload } from '@app-store/slices/properties/oneProperty'

interface TaskAction extends Action {
    payload: IOnePropertyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.properties.oneProperty

function* getOnePropertyTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/attachments/properties/${payload.propertyId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getOneProperty(): any {
    yield takeLatest<TaskAction>(onRequest, getOnePropertyTask)
}

export default getOneProperty
