import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IListDirectMessagePayload extends ISagaCb {}

const initialState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const listDirectMessageSlice = createSlice({
    name: 'chat/list-direct-message',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListDirectMessagePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.ims
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onDeleteDirectMessage(state, action) {
            state.data = state.data.filter(item => item._id !== action.payload)
        }
    }
})

export const listDirectMessageActions = listDirectMessageSlice.actions
export default listDirectMessageSlice.reducer
