import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IUpdateCompanyStatsPayload } from '@app-store/slices/reporting/updateCompanyStats'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IUpdateCompanyStatsPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.reporting.updateCompanyStats

function* updateCompanyStatsSagaTask({ payload }) {
    try {
        // const params: any = {}
        const response = yield axios.get('/reporting/agendas/company-stats')
        if (response?.status === 503 || response?.message) {
            notification.success({
                message:
                    "The Report is being prepared, this could take some time. You will receive an email once it's ready with all details."
            })
        } else if (response) {
            yield put(onSuccess(response))
            yield payload?.sagaCB?.onSuccess(response)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* updateCompanyStatsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, updateCompanyStatsSagaTask)
}

export default updateCompanyStatsSaga
