import routes from '@utils/constants/routes'
import { history } from '@components/App'
import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, select, takeLatest } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action, ITask {}
interface ITask {}

const { onRequest, onSuccess, onFailure } = rootActions.dataImport.finalizeImport

function* finalizeImportSagaTask() {
    try {
        const { importId, importType } = yield select((state: RootState) => state.dataImport.initiateImport.data)
        const finalizeData = yield select((state: RootState) => state.dataImport.flow.finalizeData)
        const publishData = yield select((state: RootState) => state.dataImport.flow.publishData)
        const updatePolicy = yield select((state: RootState) => state.dataImport.flow.updatePolicy)
        let url = ''
        const payload: any = {
            excelMap: finalizeData
        }
        switch (importType) {
            case 'host':
            case 'guest':
            case 'upload':
                payload.publishData = publishData
                payload.updatePolicy = updatePolicy
                url = '/companies/imports/excel/finalize'
                break
            case 'category':
                url = '/companies/imports/event/category/excel/finalize'
                break
            case 'itp':
                url = 'companies/imports/itp/excel/finalize'
                break
            default:
                url = '/companies/imports/event/category/excel/finalize'
                break
        }
        const response = yield axios.put(`${url}/${importId}`, payload)
        const { data: i18n } = yield select((store: RootState) => store.localization.locals)
        if (response.success) {
            history.push({
                pathname: routes.database_import.complete._,
                search: `finalCompleteId=${importId}`
            })
            yield put(onSuccess(response.result))
        } else if ([408, 503, 409].includes(response.status)) {
            yield notification.warning({
                message:
                    i18n?.Event?.data_processed_imported_take_some_time ||
                    "The data is being processed and imported, this could take some time. You will receive an email once it's ready with all details."
            })
        } else {
            yield put(onFailure(''))
            yield notification.error({
                message:
                    i18n?.Event?.Something_wrong_review_matched_properties ||
                    'Something went wrong, please review matched properties.'
            })
        }
    } catch (error) {
        yield put(onFailure(error))
        if (error.response?.status === 504) {
            yield put(rootActions.dataImport.flow.toggleImportTimeModal())
        } else {
            if (error?.response?.data?.error) {
                yield notification.error({
                    message: error?.response?.data?.error
                })
            }
        }
    }
}

function* finalizeImportSaga(): any {
    yield takeLatest<TaskAction>(onRequest, finalizeImportSagaTask)
}

export default finalizeImportSaga
