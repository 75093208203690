import { combineReducers } from 'redux'
import createProductReducer, { createProductActions } from '@app-store/slices/products/createProduct'
import editProductReducer, { editProductActions } from '@app-store/slices/products/editProduct'
import deleteProductReducer, { deleteProductActions } from '@app-store/slices/products/deleteProduct'
import oneProductReducer, { oneProductActions } from '@app-store/slices/products/oneProduct'
import listReducer, { listActions } from '@app-store/slices/products/list'
import productViewReducer, { productViewActions } from './view.slice'
import productStaticEnumsReducer, { productStaticEnumsActions } from '@app-store/slices/products/productStaticEnums'

export const productsReducer = combineReducers({
    list: listReducer,
    create: createProductReducer,
    edit: editProductReducer,
    oneProduct: oneProductReducer,
    delete: deleteProductReducer,
    view: productViewReducer,
    productStaticEnums: productStaticEnumsReducer
})

export const productsActions = {
    list: listActions,
    create: createProductActions,
    edit: editProductActions,
    oneProduct: oneProductActions,
    delete: deleteProductActions,
    view: productViewActions,
    productStaticEnums: productStaticEnumsActions
}
