import createSaga from './createSaga'
import editSaga from './editSaga'
import listSaga from './listSaga'
import deleteSaga from './deleteSaga'
import newFeatureByIdSaga from './newFeatureById'
import newFeatureByModuleIdSaga from './newFeatureByModuleId'

const newFeatureSaga = [
    createSaga(),
    editSaga(),
    listSaga(),
    deleteSaga(),
    newFeatureByIdSaga(),
    newFeatureByModuleIdSaga()
]

export default newFeatureSaga
