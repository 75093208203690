import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import store from '@app-store/store'
import axios from '@utils/request'
import { COOKIE_KEYS, setCookie } from '@features/General'

interface TaskAction extends Action {}

const { onRequest, onSuccess, onFailure } = rootActions.localization.languageTypes

function* languageTypesSagaTask({}) {
    const params = {}

    try {
        const response = yield axios.get(`/users/static-enum`, {
            params,
            transformRequest: (data, headers) => {
                delete headers['event-id']
                return data
            }
        })
        if (response.success) {
            setCookie(COOKIE_KEYS.LANG, response.result.language)
            setCookie(COOKIE_KEYS.ALA_DIRECTION, response.result.direction.toLowerCase() || 'ltr')
            store.dispatch(rootActions.ui.common.onToggleMode())
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* languageTypesSaga(): any {
    yield takeLatest<TaskAction>(onRequest, languageTypesSagaTask)
}

export default languageTypesSaga
