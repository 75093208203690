import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ICardListPayload } from '@app-store/slices/subscriptionPayment/cardList'

interface TaskAction extends Action {
    payload: ICardListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.cardList

function* cardListSagaTask({ payload }: TaskAction) {
    try {
        const params = yield getParams(payload || {})

        const response = yield axios.get(`/subscriptions/payments/cards`, {
            params
        })

        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* cardListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, cardListSagaTask)
}

export default cardListSaga
