import { combineReducers } from 'redux'
import oneTableReducer, { oneTableActions } from '@app-store/slices/table/oneTable'
import listTableReducer, { listTableActions } from '@app-store/slices/table/list'
import deleteTableReducer, { deleteTableActions } from '@app-store/slices/table/delete'
import createTableReducer, { createTableActions } from '@app-store/slices/table/create'
import editTableReducer, { editTableActions } from '@app-store/slices/table/edit'
import editRangeTableReducer, { editRangeTableActions } from '@app-store/slices/table/editRangeTable'
import availableTablesReducer, { availableTablesActions } from '@app-store/slices/table/availableTables'
import validateAvailabilityReducer, { validateAvailabilityActions } from '@app-store/slices/table/validateAvailability'
import renameSectionReducer, { renameSectionActions } from '@app-store/slices/table/renameSection'

export const tableReducer = combineReducers({
    list: listTableReducer,
    oneTable: oneTableReducer,
    create: createTableReducer,
    delete: deleteTableReducer,
    edit: editTableReducer,
    editRangeTable: editRangeTableReducer,
    availableTables: availableTablesReducer,
    validateAvailability: validateAvailabilityReducer,
    renameSection: renameSectionReducer
})

export const tableActions = {
    list: listTableActions,
    oneTable: oneTableActions,
    create: createTableActions,
    delete: deleteTableActions,
    edit: editTableActions,
    editRangeTable: editRangeTableActions,
    availableTables: availableTablesActions,
    validateAvailability: validateAvailabilityActions,
    renameSection: renameSectionActions
}
