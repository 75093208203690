import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IOneEmailCredentialPayload {
    id: string
}

export interface IOneEmailState {
    status: string
    data: null
    errors: any
}

const initialState: IOneEmailState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneEmail = createSlice({
    name: 'email-credential/one-email',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneEmailCredentialPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneEmailActions = oneEmail.actions
export default oneEmail.reducer
