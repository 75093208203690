import { createSlice } from '@reduxjs/toolkit'
import { USER_TYPES, USER_ROLES } from '@utils/constants'
import { LOCALSTORAGE_KEYS } from '@features/General'
export interface ICompanyProfileViewState {
    categoryCompanyProfileModal: any
    editCompanyProfileModal: boolean
    createCompanyModal: boolean
    addUserModal: boolean
    resetPasswordModal: boolean
    employeesEditorModal: any
    isUser: boolean
    isAdminUser: boolean
    isAdminType: boolean
    isOrganizerType: boolean
    isAdminRole: boolean
    isAdmin: boolean
    isEmployee: boolean
    isPaySub: boolean
    isNoPaySub: boolean
    isUserCompany: boolean
    isUserRole: boolean
    isOperation: boolean
    menuUserType: string
    permissionApplied: boolean
    isMeetingHubEvent: boolean
    meetingListType: 'meetings' | 'incomingRequests' | 'outgoingRequests'
}
const initialState: ICompanyProfileViewState = {
    categoryCompanyProfileModal: { isOpen: false, type: null },
    editCompanyProfileModal: false,
    createCompanyModal: false,
    addUserModal: false,
    resetPasswordModal: false,
    employeesEditorModal: { isOpen: false, data: {}, view: false },
    isUser: false,
    isAdminUser: false,
    isAdminType: false,
    isOrganizerType: false,
    isAdmin: false,
    isAdminRole: false,
    isEmployee: false,
    isPaySub: false,
    isNoPaySub: true,
    isUserCompany: false,
    isUserRole: false,
    isOperation: false,
    permissionApplied: false,
    menuUserType: 'user',
    isMeetingHubEvent: false,
    meetingListType: 'meetings'
}

const companyProfileViewSlice = createSlice({
    name: 'ui/company_profile',
    initialState,
    reducers: {
        setCompanyProfileRole(state, action) {
            // const userRole = ProfileUserTypes.PAYING_SUBSCRIBER
            /* 1- Aladdin team / Super admin
            This is for: User Type: Admin and Organizer
                This user can edit everything for any company
            2- Company level / Super admin (Company admin to edit his company)
            This is for: User Type: User and Role/Title "Admin", and the user is the Admin of this company
                The user can edit everything for his company.
            3- Company level / Employee
            This is for: User Type: User and Role/Title "User", and the user is part of this company
                The user can see everything in this company without editing
            4- User level / Paying subscriber
            This is for: User Type: User, and the user is not part of this company */
            const userProfile: any = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.USERINFO))
            const userEvent = action.payload?.eventRecord?._id
                ? userProfile?.eventInfos.find(item => item.eventId === action.payload?.eventRecord?._id)
                : null

            const companyId = action.payload?.id || userEvent?.companyId
            const userRole = userEvent?.role?.title
            const userType = userEvent?.role?.type
            const isAdminType = userType === USER_TYPES.ADMIN
            const isOrganizerType = userType === USER_TYPES.ORGANIZER
            const isUserType = userType === USER_TYPES.USER
            const isTeamManagerRole = userRole === USER_ROLES.TEAM_MANAGER
            const isAdminRole = userRole === USER_ROLES.ADMIN
            const isOperation = userRole === USER_ROLES.OPERATION

            const isAdmin = isAdminType || isOrganizerType || (isUserType && isAdminRole)
            const isEmployee = userEvent?.companyId === companyId
            const isPaySub = isUserType && userEvent?.companyId !== companyId
            const isMenuAdmin = isOrganizerType && isAdminRole
            const isMenuManager = (isAdminType && isTeamManagerRole) || (isOrganizerType && isTeamManagerRole)

            state['isAdmin'] = isAdmin
            state['isAdminRole'] = isAdminType || isOrganizerType
            state['isUser'] = isUserType && userRole === USER_ROLES.USER
            state['isAdminUser'] = isUserType && isAdminRole
            state['isEmployee'] = isEmployee
            state['isPaySub'] = isPaySub
            state['isNoPaySub'] = !isAdmin && !isEmployee && !isPaySub
            state['isUserRole'] = isAdminType && isAdminRole
            state['menuUserType'] = isMenuAdmin ? 'admin' : isMenuManager ? 'manager' : 'user'
            state['isMeetingHubEvent'] = action?.payload?.eventRecord?.isMeetingHubEvent || false
            state['isOperation'] = isAdminType || isOrganizerType || isOperation
            state['isAdminType'] = isAdminType
            state['isOrganizerType'] = isOrganizerType
        },
        setPermissionApplied(state, action) {
            state.permissionApplied = action.payload
        },
        setCategoryCompanyProfileModal(state, action) {
            state.categoryCompanyProfileModal = action.payload
        },
        setEditCompanyProfileModal(state, action) {
            state.editCompanyProfileModal = action.payload
        },
        setCreateCompanyModal(state, action) {
            state.createCompanyModal = action.payload
        },
        setAddUserModal(state, action) {
            state.addUserModal = action.payload
        },
        setResetPasswordModal(state, action) {
            state.resetPasswordModal = action.payload
        },
        setEmployeesEditorModal(state, action) {
            state.employeesEditorModal = action.payload
        },
        onChangeItems(state, action) {
            for (const item of Object.keys(action.payload)) {
                state[item] = action.payload[item]
            }
        },
        onChangeEmployeesEditorDetails(state, action) {
            const contactDetailIndex = state.employeesEditorModal.data.contactDetails?.findIndex(
                item => item._id === action.payload._id
            )
            if (contactDetailIndex >= 0)
                state.employeesEditorModal.data.contactDetails[contactDetailIndex] = action.payload
            else if (action.payload.isPrimary) state.employeesEditorModal.data.contactDetails = [{ ...action.payload }]
        }
    }
})

export const companyProfileActions = companyProfileViewSlice.actions
export default companyProfileViewSlice.reducer
