import { createSlice } from '@reduxjs/toolkit'

export interface IDemosState {
    template: string
    type: string
}

const initialState: IDemosState = {
    template: '',
    type: 'All'
}

const demoSlice = createSlice({
    name: 'ui/demo',
    initialState,
    reducers: {
        onChangeItems(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state[item] = action.payload[item]
            }
        },
        reset(state) {
            state.template = ''
            state.type = 'All'
        }
    }
})

export const demoActions = demoSlice.actions
export default demoSlice.reducer
