import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { ICreateSurveyFeedbackByTokenPayload } from '@app-store/slices/survey/createSurveyFeedbackByToken'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: ICreateSurveyFeedbackByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.survey.createSurveyFeedbackByToken

function* createSurveyFeedbackByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/meeting/survey-feedback/by-token?token=${payload.token}`, {
            ...payload.data
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB) payload.sagaCB?.onError()
    }
}

function* createSurveyFeedbackByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createSurveyFeedbackByTokenSagaTask)
}

export default createSurveyFeedbackByTokenSaga
