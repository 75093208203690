import listSaga from './listSaga'
import createSaga from './createSaga'
import deleteSaga from './deleteSaga'
import editSaga from './editSaga'
import oneHallSaga from './oneHallSaga'
import listByCompanyGroupsSaga from './listByCompanyGroups'

const hallSaga = [listSaga(), createSaga(), deleteSaga(), editSaga(), oneHallSaga(), listByCompanyGroupsSaga()]

export default hallSaga
