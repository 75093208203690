import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IEditMatchListPayload extends ISagaCb {
    id?: string
    data: {
        title?: string
        matchedCompanies?: any
        badges?: any
    }
}

export interface IEditMatchListState {
    status: string
    data: any
    errors: any
}

const initialState: IEditMatchListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editMatchList = createSlice({
    name: 'matchList/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditMatchListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editMatchListActions = editMatchList.actions
export default editMatchList.reducer
