import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IIgnoreUserPayload {
    rid: string
    userId: string
    ignore?: boolean
}

const initialState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const ignoreUserSlice = createSlice({
    name: 'chat/ignore-user',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IIgnoreUserPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.messages
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const ignoreUserActions = ignoreUserSlice.actions
export default ignoreUserSlice.reducer
