import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/eventSubscriptions/list'
import oneEventSubscriptionReducer, { oneEventSubscriptionActions } from '@app-store/slices/eventSubscriptions/one'
import addFeatureReducer, { addFeatureActions } from '@app-store/slices/eventSubscriptions/addFeature'
import editFeatureReducer, { editFeatureActions } from '@app-store/slices/eventSubscriptions/editFeature'
import deleteFeatureReducer, { deleteFeatureActions } from '@app-store/slices/eventSubscriptions/deleteFeature'
import getActiveReducer, { getActiveActions } from '@app-store/slices/eventSubscriptions/getActive'

export const eventSubscriptionReducers = combineReducers({
    list: listReducer,
    one: oneEventSubscriptionReducer,
    addFeature: addFeatureReducer,
    editFeature: editFeatureReducer,
    deleteFeature: deleteFeatureReducer,
    getActive: getActiveReducer
})

export const eventSubscriptionActions = {
    list: listActions,
    one: oneEventSubscriptionActions,
    addFeature: addFeatureActions,
    editFeature: editFeatureActions,
    deleteFeature: deleteFeatureActions,
    getActive: getActiveActions
}
