import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListState, ISagaCb } from '@typings/requestPayloads'

export interface IAgentStatisticPayload extends ISagaCb {
    data: {
        email: string[]
        startTime: string
        endTime: string
        metrics?: boolean
    }
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const agentStatisticSlice = createSlice({
    name: 'agent-statistic',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IAgentStatisticPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const agentStatisticActions = agentStatisticSlice.actions
export default agentStatisticSlice.reducer
