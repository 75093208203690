import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IListBySearchQueryPayload {
    query?: any
}

export interface IListBySearchPayload {
    search: string
}

const initialState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const listBySearchSlice = createSlice({
    name: 'hub/categories/list-by-search',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListBySearchQueryPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const listBySearchActions = listBySearchSlice.actions
export default listBySearchSlice.reducer
