import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteItpPayload } from '@app-store/slices/companies/deleteItp'

interface TaskAction extends Action {
    payload: IDeleteItpPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.deleteItp

function* deleteItpSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/companies/itps/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Matching?.Itp_deleted_successfully || 'Itp deleted successfully.'
            })
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteItpSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteItpSagaTask)
}

export default deleteItpSaga
