import { createSlice } from '@reduxjs/toolkit'

export interface ICompanyViewState {
    from: string
}

const initialState: ICompanyViewState = {
    from: ''
}

const companyViewSlice = createSlice({
    name: 'company/view',
    initialState,
    reducers: {
        setFrom(state, action) {
            state.from = action.payload
        }
    }
})

export const companyViewActions = companyViewSlice.actions
export default companyViewSlice.reducer
