import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IPublishRequestPayload } from '@app-store/slices/editList/publishList'

interface TaskAction extends Action {
    payload: IPublishRequestPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.editList.publishList

function* publishListSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/edit-lists/publish/${payload.id}`, { ...payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.List_published_successfully || 'List has been published successfully.'
            })
            yield put(rootActions.ui.directory.onSelectRows([]))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* publishListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, publishListSagaTask)
}

export default publishListSaga
