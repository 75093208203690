import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.editItp
function* editItpSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/itps/${payload.id}`, { ...payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editItpSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editItpSagaTask)
}

export default editItpSaga
