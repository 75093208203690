import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICheckSesAccountStatusPayload } from '@app-store/slices/emailCredential/checkSesAccountStatus'

interface TaskAction extends Action {
    payload: ICheckSesAccountStatusPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.emailCredential.checkSesAccountStatus

function* checkSesAccountStatusTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/notifications/email-credentials/status/${payload.email}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* checkSesAccountStatus(): any {
    yield takeLatest<TaskAction>(onRequest, checkSesAccountStatusTask)
}

export default checkSesAccountStatus
