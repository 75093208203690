import { ISearch } from '@features/General'
export * from './columns'
export * from './filters'

export const listSearch = (placeholder: string): ISearch => ({
    width: 270,
    placeholder,
    fields: ['status'],
    align: 'right'
})
