import { combineReducers } from 'redux'
import sendEmailReducer, { sendEmailActions } from '@app-store/slices/emails/sendEmail'
import sendInvitationReducer, { sendInvitationActions } from '@store/slices/emails/sendInvitation'

export const emailsReducer = combineReducers({
    sendEmail: sendEmailReducer,
    sendInvitation: sendInvitationReducer
})

export const emailsActions = {
    sendEmail: sendEmailActions,
    sendInvitation: sendInvitationActions
}
