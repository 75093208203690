import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IMergeDuplicateListPayload } from '@app-store/slices/companies/mergeDuplicateList'

interface TaskAction extends Action {
    payload: IMergeDuplicateListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.mergeDuplicateList

function* mergeDuplicateListSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/merge`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* mergeDuplicateListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, mergeDuplicateListSagaTask)
}

export default mergeDuplicateListSaga
