import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAddCompaniesPayload } from '@app-store/slices/matchLists/addCompanies'

interface TaskAction extends Action {
    payload: IAddCompaniesPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchLists.addCompanies
const { onAddCompanies } = rootActions.matchLists.oneMatchList

function* addCompaniesSagaTask({ payload }: TaskAction) {
    const { matchedCompanies, _id } = yield select((state: RootState) => state.matchLists.oneMatchList.data)
    const newMatchedCompanies = [
        ...matchedCompanies
            .filter(item => item.company && item.company !== 'null')
            .map(item => ({
                company: item.company?._id || item.company,
                BPARate: item.BPARate || 0,
                ITPRate: item.ITPRate || 0,
                matchRate: item.matchRate || 0
            })),
        ...payload.data.map(item => ({
            ...item,
            company: item?.company?.company?._id || item?.company?._id
        }))
    ]
    try {
        const response = yield axios.put(`/companies/match-lists/${_id}`, {
            matchedCompanies: newMatchedCompanies
        })
        if (response.success) {
            yield payload.sagaCB?.onSuccess(response.result)
            yield put(onSuccess(response.result))
            yield put(onAddCompanies(payload.data))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* addCompaniesSaga(): any {
    yield takeLatest<TaskAction>(onRequest, addCompaniesSagaTask)
}

export default addCompaniesSaga
