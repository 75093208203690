import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateTagByAdminPayload } from '@app-store/slices/tags/createByAdmin'

interface TaskAction extends Action {
    payload: ICreateTagByAdminPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.tags.createByAdmin
const { onAddToList } = rootActions.tags.listByAdmin

function* createTagByAdminSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/companies/tags/standard-tag`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(onAddToList(response.result.data))
            if (typeof payload.sagaCB?.onSuccess === 'function') {
                payload.sagaCB?.onSuccess(response.result.data)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createTagByAdminSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createTagByAdminSagaTask)
}

export default createTagByAdminSaga
