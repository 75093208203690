import { MeetingType } from '@features/Meeting/MeetingAgenda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

type MeetingRequestType = 'CREATE' | 'SUGGEST' | 'EDIT' | 'FOLLOW_UP'
dayjs.extend(utc)
dayjs.extend(timezone)
export interface IMeetingDataItems {
    contactIds?: string[]
    guestCompanyId?: string
    virtualCustomLink?: string
    duration?: number
    requestedTimes?: any
    content?: string
    type?: string
    place?: string
    location?: string
    timeShift?: string | number
    templateId?: string
}

export interface IMeetingRequestFlowMode {
    mode?: MeetingRequestType
    requestId?: string | null
    hostId?: string | null
    status?: string | null
    from?: string | null
}

export interface IMeetingRequestUIState extends IMeetingRequestFlowMode {
    data: IMeetingDataItems
    customReasonModal: {
        isOpen: boolean
    }
}

const initialState: IMeetingRequestUIState = {
    data: {
        contactIds: [],
        guestCompanyId: '',
        duration: 60,
        requestedTimes: [],
        templateId: '',
        content: '',
        type: MeetingType.IN_PERSON,
        place: '',
        location: '',
        timeShift: dayjs.tz.guess(),
        virtualCustomLink: ''
    },
    customReasonModal: {
        isOpen: false
    },
    mode: 'CREATE',
    requestId: null,
    status: null,
    from: null
}
const meetingRequestSlice = createSlice({
    name: 'ui/meetingRequest',
    initialState,
    reducers: {
        addItems(state, action: PayloadAction<IMeetingDataItems>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.data[item] = action.payload[item]
            }
        },
        setMode(state, action: PayloadAction<IMeetingRequestFlowMode>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state[item] = action.payload[item]
            }
        },
        toggleCustomReasonModal(state) {
            state.customReasonModal.isOpen = !state.customReasonModal.isOpen
        }
    }
})

export const meetingRequestActions = meetingRequestSlice.actions
export default meetingRequestSlice.reducer
