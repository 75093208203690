import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOneTablePayload } from '@app-store/slices/table/oneTable'

interface TaskAction extends Action {
    payload: IOneTablePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.table.oneTable

function* getOneTableTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/meeting/table/one/${payload.tableId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getOneTable(): any {
    yield takeLatest<TaskAction>(onRequest, getOneTableTask)
}

export default getOneTable
