import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditModulePayload } from '@app-store/slices/module/edit'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IEditModulePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.module.edit

function* editModuleSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/reporting/modules/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Settings?.Module_edited_successfully || 'Module edited successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editModuleSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editModuleSagaTask)
}

export default editModuleSaga
