import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOneUrlPayload } from '@app-store/slices/properties/oneUrl'

interface TaskAction extends Action {
    payload: IOneUrlPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.properties.oneUrl

function* getOneUrlTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/attachments/urls/one/${payload.urlId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB.onSuccess(response.result)
        } else {
            yield payload.sagaCB.onError(response.error)
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getOneUrl(): any {
    yield takeLatest<TaskAction>(onRequest, getOneUrlTask)
}

export default getOneUrl
