import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IMeetingRequestReportPayload extends ISagaCb {
    status?: string[]
    startTime?: string
    endTime?: string
    type?: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const meetingRequestReportSlice = createSlice({
    name: 'reporting/download-meeting-request-report',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IMeetingRequestReportPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const meetingRequestReportActions = meetingRequestReportSlice.actions
export default meetingRequestReportSlice.reducer
