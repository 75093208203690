import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { ISyncByTokenPayload } from '@app-store/slices/calendar/typings'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: ISyncByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.calendar.syncByToken

function* syncByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.post(
            `/meeting/sync/calendar/sync/by-token/${payload.token}?sourceType=${payload.sourceType}`,
            payload.data
        )
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (typeof payload?.sagaCB?.onError === 'function') {
            payload?.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* syncByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, syncByTokenSagaTask)
}

export default syncByTokenSaga
