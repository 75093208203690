import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ILoginPayload {
    data: {
        user?: string
        password?: string
        resume?: string
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const loginSlice = createSlice({
    name: 'chat/login',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ILoginPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.messages
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const loginActions = loginSlice.actions
export default loginSlice.reducer
