import { combineReducers } from 'redux'
import onePropertyReducer, { onePropertyActions } from '@app-store/slices/properties/oneProperty'
import listPropertyReducer, { listPropertyActions } from '@app-store/slices/properties/list'
import listSearchPropertyReducer, { listSearchPropertyActions } from '@app-store/slices/properties/listSearch'
import deletePropertyReducer, { deletePropertyActions } from '@app-store/slices/properties/delete'
import createPropertyReducer, { createPropertyActions } from '@app-store/slices/properties/create'
import editPropertyReducer, { editPropertyActions } from '@app-store/slices/properties/edit'
import urlPropertyReducer, { urlPropertyActions } from '@app-store/slices/properties/urls'
import oneUrlReducer, { oneUrlActions } from '@app-store/slices/properties/oneUrl'
import listPropertyByTokenReducer, { listPropertyByTokenActions } from '@app-store/slices/properties/listByToken'
import createPropertyByTokenReducer, { createPropertyByTokenActions } from '@app-store/slices/properties/createByToken'
import deletePropertyByTokenReducer, { deletePropertyByTokenActions } from '@app-store/slices/properties/deleteByToken'
import editPropertyByTokenReducer, { editPropertyByTokenActions } from '@app-store/slices/properties/editByToken'
export const propertiesReducer = combineReducers({
    list: listPropertyReducer,
    oneProperty: onePropertyReducer,
    create: createPropertyReducer,
    delete: deletePropertyReducer,
    edit: editPropertyReducer,
    urls: urlPropertyReducer,
    oneUrl: oneUrlReducer,
    listSearch: listSearchPropertyReducer,
    listByToken: listPropertyByTokenReducer,
    createByToken: createPropertyByTokenReducer,
    deleteByToken: deletePropertyByTokenReducer,
    editByToken: editPropertyByTokenReducer
})

export const propertiesActions = {
    list: listPropertyActions,
    oneProperty: onePropertyActions,
    create: createPropertyActions,
    delete: deletePropertyActions,
    edit: editPropertyActions,
    urls: urlPropertyActions,
    oneUrl: oneUrlActions,
    listSearch: listSearchPropertyActions,
    listByToken: listPropertyByTokenActions,
    createByToken: createPropertyByTokenActions,
    deleteByToken: deletePropertyByTokenActions,
    editByToken: editPropertyByTokenActions
}
