import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteSpeakerPayload } from '@app-store/slices/speakers/deleteSpeaker'

interface TaskAction extends Action {
    payload: IDeleteSpeakerPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.speakers.delete

function* deleteSpeakerSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/event/speaker/${payload.SpeakerId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Speaker_deleted_successfully || 'Speaker deleted successfully.'
            })
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteSpeakerSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteSpeakerSagaTask)
}

export default deleteSpeakerSaga
