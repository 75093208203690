import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/chatDetails/list'
import chatLoginReducer, { chatLoginActions } from '@app-store/slices/chatDetails/login'
import getUserIdByContactReducer, { getUserIdByContactActions } from '@store/slices/chatDetails/getUserIdByContact'

export const chatDetailsReducer = combineReducers({
    list: listReducer,
    login: chatLoginReducer,
    getUserIdByContact: getUserIdByContactReducer
})

export const chatDetailsActions = {
    list: listActions,
    login: chatLoginActions,
    getUserIdByContact: getUserIdByContactActions
}
