import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteNewFeaturePayload } from '@app-store/slices/newFeature/delete'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IDeleteNewFeaturePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.newFeature.delete

function* deleteNewFeatureSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete(`/reporting/modules/new-features/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(rootActions.newFeature.list.onRemoveItem(payload.id))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Settings?.New_feature_deleted_successfully || 'New feature deleted successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteNewFeatureSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteNewFeatureSagaTask)
}

export default deleteNewFeatureSaga
