import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditRatingByTokenPayload } from '@app-store/slices/momentum/editRatingByToken'

interface TaskAction extends Action {
    payload: IEditRatingByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.momentum.editByToken

function* momentumEditRateByTokenSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/meeting/edit-rating-by-meeting-token/${payload.rateId}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* momentumEditRateByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, momentumEditRateByTokenSagaTask)
}

export default momentumEditRateByTokenSaga
