import { RootState } from '@app-store/store'
import axios from 'axios'
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import { IFileUploadPayload } from '@app-store/slices/dataImport/fileUpload'
import { END, eventChannel } from 'redux-saga'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: IFileUploadPayload
}

const { onRequest, onSuccess, onFailure, onUploadFileProgress } = rootActions.users.uploadImage

const upload = (payload, cb) => {
    const { file, uploadURL } = payload
    return axios.put(`${uploadURL}`, file.originFileObj, {
        method: 'put',
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        onUploadProgress: cb
    })
}

function createUploader(payload) {
    let emit
    const chan = eventChannel(emitter => {
        emit = emitter
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {}
    })
    const uploadProgressCb = ({ total, loaded }) => {
        const percentage = Math.round((loaded * 100) / total)
        emit(percentage)
        if (percentage === 100) emit(END)
    }
    const uploadPromise = upload(payload, uploadProgressCb)
    return [uploadPromise, chan]
}

function* uploadProgressWatcher(chan) {
    while (true) {
        const progress = yield take(chan)
        yield put(onUploadFileProgress(progress))
    }
}

function* uploadImageSagaTask({ payload }: TaskAction) {
    const { uploadURL, file: fileName } = yield select((store: RootState) => store.users.getPreSignedUpload.data)
    const [uploadPromise, chan] = createUploader({ file: payload, fileName, uploadURL })
    yield fork(uploadProgressWatcher, chan)
    try {
        yield call(() => uploadPromise)
        yield put(onSuccess())
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* uploadImageSaga(): any {
    yield takeLatest<TaskAction>(onRequest, uploadImageSagaTask)
}

export default uploadImageSaga
