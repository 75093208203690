export const validateEmail = email => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

export const validateUrl = url => {
    try {
        new URL(url)
        return true
    } catch (err) {
        return false
    }
}

export const validateFile = (value, extensions: string[], size: number) => {
    let errors = ''
    if (value && (value.type || value.type === '') && value.size && extensions) {
        const typeInfo = value.type.split('/')

        if (!extensions.includes(typeInfo[1])) {
            errors = `Only .${extensions.join(',.')}  are allowed`
        } else if (value.size > size * 1024 * 1024) {
            errors = `The maximum supported file size is ${size} MB`
        }
    }
    return errors
}
