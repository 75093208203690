import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditGroupVisibilityByIDPayload {
    settingId: string
    visibilityId: string
    data: any
}

export interface IEditGroupVisibilityByIDState {
    status: string
    data: any
    errors: any
}

const initialState: IEditGroupVisibilityByIDState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editGroupVisibilityByID = createSlice({
    name: 'event-setting/edit-visibility-by-id',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditGroupVisibilityByIDPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editGroupVisibilityByIDActions = editGroupVisibilityByID.actions
export default editGroupVisibilityByID.reducer
