import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IConnectPayload } from '@app-store/slices/crm/connect'

interface TaskAction extends Action {
    payload: IConnectPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.crm.connect

function* connectServiceSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/integrations/crms`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload.sagaCB.onError(error)
    }
}

function* connectServiceSaga(): any {
    yield takeLatest<TaskAction>(onRequest, connectServiceSagaTask)
}

export default connectServiceSaga
