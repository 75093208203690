import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface ILoginWithOtpPayload extends ISagaCb {
    email: string
    code: string
    cognitoUser: any
    eventId?: string
}

export interface ILoginWithOtpState {
    status: string
    data: {
        token: string
    }
    errors: any
}

const initialState: ILoginWithOtpState = {
    status: STATUS.IDLE,
    data: {
        token: ''
    },
    errors: null
}

const loginWithOtpSlice = createSlice({
    name: 'auth/login-with-otp',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ILoginWithOtpPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data.token = action.payload.token
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const loginWithOtpActions = loginWithOtpSlice.actions
export default loginWithOtpSlice.reducer
