import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IEditCampaignPayload extends ISagaCb {
    id: string
    data: any
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editCampaign = createSlice({
    name: 'campaign/edit-campaign',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IEditCampaignPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editCampaignActions = editCampaign.actions
export default editCampaign.reducer
