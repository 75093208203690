import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditEventProfilesPayload } from '@app-store/slices/eventSettings/editEventProfiles'

interface TaskAction extends Action {
    payload: IEditEventProfilesPayload
}
const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.editEventProfiles
const { onChangeData } = rootActions.eventSetting.eventProfiles
function* editEventProfilesTask({ payload }: TaskAction) {
    try {
        const response = yield axios.patch(`/event/event-profiles/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            yield put(onChangeData(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editEventProfiles(): any {
    yield takeLatest(onRequest, editEventProfilesTask)
}

export default editEventProfiles
