import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IOneEventTopicPayload } from '@app-store/slices/eventTopics/oneEventTopic'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IOneEventTopicPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventTopics.oneEventTopic

function* oneEventTopicSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/event/event-topic/one/${payload.eventTopicId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneEventTopicSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneEventTopicSagaTask)
}

export default oneEventTopicSaga
