import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IAddCompaniesPayload extends ISagaCb {
    data: {
        company: any
        matchRate: number
        ITPRate: number
        BPARate: number
    }[]
}

export interface IAddCompaniesState {
    status: string
    data: any
    errors: any
}

const initialState: IAddCompaniesState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const addCompanies = createSlice({
    name: 'matchList/add-companies',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IAddCompaniesPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const addCompaniesActions = addCompanies.actions
export default addCompanies.reducer
