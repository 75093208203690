import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ItpType = 'CREATE'

export interface IItpDataItems {
    title?: string
    locations?: string[]
    countries?: any
    cities?: string[]
    companySize?: string[]
    type?: string[]
    itpType?: string[]
    positions?: string[]
    jobDepartments?: []
    seniorityLevels?: []
    sectors?: []
    fundingStage?: string[]
    tags?: string[]
    ownerCompany?: string
    isOpenEditTargetedLocation?: boolean
    clientWebsiteExample?: string
    competitorWebsiteExample?: string
}

export interface IItpFlowMode {
    mode?: ItpType
    requestId?: string | null
    hostId?: string | null
    shareItpModalVisible?: {
        isOpen: boolean
        itpId?: string
    }
    matchListModalVisible?: boolean
    isEditItpProfile?: boolean
}

export interface IItpUIState extends IItpFlowMode {
    data: IItpDataItems
}

const initialState: IItpUIState = {
    data: {
        title: '',
        locations: [],
        countries: [],
        cities: [],
        companySize: [],
        type: [],
        itpType: ['Enterprise'],
        fundingStage: [],
        positions: [],
        tags: [],
        ownerCompany: '',
        isOpenEditTargetedLocation: false,
        clientWebsiteExample: '',
        competitorWebsiteExample: ''
    },
    mode: 'CREATE',
    requestId: null,
    shareItpModalVisible: {
        isOpen: false,
        itpId: null
    },
    matchListModalVisible: false,
    isEditItpProfile: false
}
const itpSlice = createSlice({
    name: 'ui/itp',
    initialState,
    reducers: {
        addItems(state, action: PayloadAction<IItpDataItems>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.data[item] = action.payload[item]
            }
        },
        setMode(state, action: PayloadAction<IItpFlowMode>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state[item] = action.payload[item]
            }
        },
        setEditTargetedLocation(state, action: PayloadAction<IItpFlowMode>) {
            state['isOpenEditTargetedLocation'] = action.payload
        },
        setShareModalVisible(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.shareItpModalVisible[item] = action.payload[item]
            }
        },
        setMatchListModalVisible(state, action) {
            state['matchListModalVisible'] = action.payload
        },
        setIsEditItpProfile(state, action) {
            state.isEditItpProfile = action.payload
        }
    }
})

export const itpActions = itpSlice.actions
export default itpSlice.reducer
