import { PropertyRelatedRecordType, PropertyType, ProperyVisibilityType } from '@utils/constants'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'
import { ISagaCb } from '@typings/requestPayloads'

export interface ICreatePropertyByTokenPayload extends ISagaCb {
    data: {
        relatedRecordType: keyof typeof PropertyRelatedRecordType
        visibility: keyof typeof ProperyVisibilityType
        type: keyof typeof PropertyType
        relatedRecordId: string
        subRecordId?: string
        content: string
        ownerCompany?: string
        ownerUser?: string
        fileName?: string
        token?: string
    }
}

export interface ICreatePropertyByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: ICreatePropertyByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createPropertyByToken = createSlice({
    name: 'property/create-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreatePropertyByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createPropertyByTokenActions = createPropertyByToken.actions
export default createPropertyByToken.reducer
