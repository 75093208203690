import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IGenerateQRPayload extends ISagaCb {
    data: {
        content: string
        color?: string
        width?: string
        height?: string
        logo?: string
    }
}

export interface IGenerateQRState {
    status: string
    data: null
    errors: any
}

const initialState: IGenerateQRState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const generateQR = createSlice({
    name: 'qr/generate-QR',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGenerateQRPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const generateQRActions = generateQR.actions
export default generateQR.reducer
