import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ICreateItpPayload extends ISagaCb {
    data: {
        title?: string
        locations?: string[]
        countries?: any
        cities?: string[]
        companySize?: string[]
        type?: string[]
        positions?: string[]
        tags?: string[]
        jobDepartments?: string[]
        seniorityLevels?: string[]
        fundingStage?: string[]
        sectors?: string[]
        ownerCompany?: string
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createItp = createSlice({
    name: 'company/create-itp',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateItpPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createItpActions = createItp.actions
export default createItp.reducer
