import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IInternalPublishCompanyPayload {
    id: string
    condition?: string
}
export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const internalPublishCompanySlice = createSlice({
    name: 'hub/companies/internal-publish-company',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IInternalPublishCompanyPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const internalPublishCompanyActions = internalPublishCompanySlice.actions
export default internalPublishCompanySlice.reducer
