import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteMatchListPayload } from '@app-store/slices/matchLists/delete'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IDeleteMatchListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchLists.delete

function* deleteMatchListSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete('/companies/match-lists/delete', { data: payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Matching?.Match_lists_deleted_successfully || 'Match lists deleted successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteMatchListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteMatchListSagaTask)
}

export default deleteMatchListSaga
