import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IVerificationInitiatePayload extends ISagaCb {
    eventId: string
    data: {
        email: string
    }
}

export interface IVerificationInitiateState {
    status: string
    data: any
    errors: any
}

const initialState: IVerificationInitiateState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const verificationInitiateSlice = createSlice({
    name: '/notifications/verification-initiate',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IVerificationInitiatePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const verificationInitiateActions = verificationInitiateSlice.actions
export default verificationInitiateSlice.reducer
