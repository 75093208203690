import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { TokenListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.meetingRequests.listHallByToken

function* listMeetingRequestSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/meeting/meeting-requests/halls`, {
            params: {
                ...params,
                token: payload.token
            }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result.data)
        } else {
            if (payload?.sagaCB?.onError) payload?.sagaCB?.onError()
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listMeetingRequestSaga(): any {
    yield takeLatest<TokenListTaskAction>(onRequest, listMeetingRequestSagaTask)
}

export default listMeetingRequestSaga
