import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListQueryPaginationTokenPayload } from '@typings/requestPayloads'

const initialState = {
    status: STATUS.IDLE,
    data: [],
    pageList: [],
    pagination: {
        skip: 0,
        hasTotal: true,
        total: 0,
        limit: 10
    },
    errors: null
}

const listPropertyByToken = createSlice({
    name: 'property/list-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onRequestList(state, action: PayloadAction<IListQueryPaginationTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            if (action.payload.keepOldData) {
                state.data = [...state.data, ...action.payload.data]
            } else {
                state.data = action.payload.data
            }

            state.pagination = {
                ...state.pagination,
                skip: action.payload.skip,
                total: action.payload.total
            }
        },
        onSuccessPageList(state, action) {
            state.status = STATUS.READY
            const oldIds = []
            state.pageList.map(item => {
                oldIds.push(item._id)
            })
            action.payload.data.map(item => {
                if (oldIds.indexOf(item._id) == -1) {
                    state.pageList = [...state.pageList, item]
                }
            })
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const listPropertyByTokenActions = listPropertyByToken.actions
export default listPropertyByToken.reducer
