import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/module/list'
import createReducer, { createModuleActions } from '@app-store/slices/module/create'
import editReducer, { editModuleActions } from '@app-store/slices/module/edit'
import deleteReducer, { deleteModuleActions } from '@app-store/slices/module/delete'
import moduleByIdReducer, { moduleByIDActions } from '@app-store/slices/module/moduleById'

export const moduleReducers = combineReducers({
    list: listReducer,
    create: createReducer,
    edit: editReducer,
    delete: deleteReducer,
    moduleById: moduleByIdReducer
})

export const moduleActions = {
    list: listActions,
    create: createModuleActions,
    edit: editModuleActions,
    delete: deleteModuleActions,
    moduleById: moduleByIDActions
}
