import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers } from '../reducers'

const listRating = createSlice({
    name: 'momentum/list-rating',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        replaceItem(state, action) {
            const findIndex = state.data.findIndex(item => item._id === action.payload._id)
            state.data[findIndex] = action.payload
        }
    }
})

export const listRatingActions = listRating.actions
export default listRating.reducer
