import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IGetUsersPayload {
    fields?: any
    query?: any
    pagination?: any
}

const initialState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const getUsersSlice = createSlice({
    name: 'chat/get-users',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetUsersPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.users
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getUsersActions = getUsersSlice.actions
export default getUsersSlice.reducer
