import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ISendNotificationPayload } from '@app-store/slices/notifications/sendNotification'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ISendNotificationPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.notification.sendNotification

function* sendNotificationSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/notifications/send`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Notification_send_successfully || 'Notification send successfully.'
            })
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload.sagaCB?.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* sendNotificationSaga(): any {
    yield takeLatest<TaskAction>(onRequest, sendNotificationSagaTask)
}

export default sendNotificationSaga
