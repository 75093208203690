import listSaga from './listSaga'
import editSaga from './editSaga'
import oneWishListSaga from './oneWishListSaga'
import createSaga from './createSaga'
import addCompanySaga from './addCompanySaga'
import removeCompanySaga from './removeCompanySaga'
import addContactSaga from './addContactSaga'

const wishListsSagas = [
    listSaga(),
    oneWishListSaga(),
    editSaga(),
    createSaga(),
    addCompanySaga(),
    removeCompanySaga(),
    addContactSaga()
]

export default wishListsSagas
