import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateUsernPayload extends ISagaCb {
    data: any
    avatar: any
}

interface ICreateState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const userCreateSlice = createSlice({
    name: 'hub/user/create',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateUsernPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload?.data?.error
        }
    }
})

export const userCreateActions = userCreateSlice.actions
export default userCreateSlice.reducer
