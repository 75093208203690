import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IDeleteUserPayload extends ISagaCb {
    subscriptionId: string
    data: {
        userIds: string[]
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const deleteUserSlice = createSlice({
    name: 'subscription/company-subscription-delete-user',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IDeleteUserPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const deleteUserActions = deleteUserSlice.actions
export default deleteUserSlice.reducer
