import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOneSectionSettingPayload } from '@app-store/slices/eventSettings/oneSectionSetting'
import { LOCALSTORAGE_KEYS } from '@features/General'

interface TaskAction extends Action {
    payload: IOneSectionSettingPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.oneSectionSetting

function* getOneSectionSettingTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/event/settings/one/${payload.settingId}/${payload.sectionName}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sectionName === 'menuVisibility') {
                localStorage.setItem(LOCALSTORAGE_KEYS.MENU_VISIBILITY, JSON.stringify(response.result))
            }
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getOneSectionSetting(): any {
    yield takeLatest<TaskAction>(onRequest, getOneSectionSettingTask)
}

export default getOneSectionSetting
