import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const companyOutgoingRequestsSlice = createSlice({
    name: 'ui/company-outgoing-requests',
    initialState: { ...listUIInitialState, populates: ['location', 'hostId', 'contactIds'] },
    reducers: listUIReducers
})

export const companyOutgoingRequestsActions = companyOutgoingRequestsSlice.actions
export default companyOutgoingRequestsSlice.reducer
