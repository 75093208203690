import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IExhibitorMeetingCostsPayloadState extends ISagaCb {}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const exhibitorMeetingCostsSlice = createSlice({
    name: 'meeting-statistics/exhibitor-meetign-costs',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IExhibitorMeetingCostsPayloadState>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const exhibitorMeetingCostsActions = exhibitorMeetingCostsSlice.actions
export default exhibitorMeetingCostsSlice.reducer
