import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateProductPayload } from '@app-store/slices/products/createProduct'
import store from '@app-store/store'

interface TaskAction extends Action {
    payload: ICreateProductPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.products.create

function* createProductSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/products`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            store.dispatch(
                rootActions.products.list.addProduct({ ...response.result, productImages: payload.productImages })
            )
            store.dispatch(rootActions.ui.products.addTotal())
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createProductSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createProductSagaTask)
}

export default createProductSaga
