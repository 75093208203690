import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditRatingByTokenPayload extends ISagaCb {
    rateId: string
    data: {
        stars: number
        meetingId?: string
        participantUserType?: string
        participantCompanyId?: string
        token?: string
    }
}

export interface IEditRatingByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: IEditRatingByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editRatingByToken = createSlice({
    name: 'momentum/edit-rating-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditRatingByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editRatingByTokenActions = editRatingByToken.actions
export default editRatingByToken.reducer
