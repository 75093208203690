import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/rolesPermissions/list'

export const rolesPermissonsReducer = combineReducers({
    list: listReducer
})

export const rolesPermissonsActions = {
    list: listActions
}
