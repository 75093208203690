import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { rootActions } from '@app-store/slices'
import { IPausePlanPayload } from '@app-store/slices/subscriptionPayment/pausePlan'

interface TaskAction extends Action {
    payload: IPausePlanPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.pausePlan

function* pausePlanSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/subscriptions/payments/pause-plan/${payload.subscriptionId}`, {
            resumeAt: payload.resumeAt
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* pausePlanSaga(): any {
    yield takeLatest<TaskAction>(onRequest, pausePlanSagaTask)
}

export default pausePlanSaga
