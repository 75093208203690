import { put, takeLatest, all } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.cities.list

function* listSagaTask({ payload }: ListTaskAction) {
    const pagination = {
        limit: 1000,
        ...payload.pagination
    }
    try {
        const params = {
            ...pagination,
            query: payload.query
        }

        const response = yield axios.get(`/companies/cities`, {
            params,
            transformRequest: (data, headers) => {
                delete headers['event-id']
                return data
            }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result.data)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

const {
    onRequest: onRequestUpdate,
    onSuccess: onSuccessUpdate,
    onFailure: onFailureUpdate
} = rootActions.cities.listUpdate

function* listUpdateSagaTask({ payload }: ListTaskAction) {
    try {
        const params = {
            ...payload.pagination,
            query: payload.query
        }

        const response = yield axios.get(`/companies/cities`, {
            params
        })
        if (response.success) {
            yield put(onSuccessUpdate(response.result))
        }
    } catch (error) {
        yield put(onFailureUpdate(error))
    }
}

function* listSaga(): any {
    yield all([
        takeLatest<ListTaskAction>(onRequest, listSagaTask),
        takeLatest<ListTaskAction>(onRequestUpdate, listUpdateSagaTask)
    ])
}

export default listSaga
