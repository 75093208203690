import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/sourceCategories/list'
import listByLevelReducer, { listByLevelActions } from '@app-store/slices/sourceCategories/listByLevel'
import listBySearchReducer, { listBySearchActions } from '@app-store/slices/sourceCategories/listBySearch'

export const sourceCategoriesReducer = combineReducers({
    list: listReducer,
    listByLevel: listByLevelReducer,
    listBySearch: listBySearchReducer
})

export const sourceCategoriesActions = {
    list: listActions,
    listByLevel: listByLevelActions,
    listBySearch: listBySearchActions
}
