import { combineReducers } from 'redux'
import importAttendeesReducer, { importAttendeesActions } from '@app-store/slices/onbordingOrganizer/importAttendees'
import historyImportAttendeesReducer, {
    historyImportAttendeesActions
} from '@app-store/slices/onbordingOrganizer/historyImportAttendees'
import oneBrandReducer, { oneBrandActions } from '@app-store/slices/onbordingOrganizer/oneBrand'
import oneBrandByEventReducer, { oneBrandByEventActions } from '@app-store/slices/onbordingOrganizer/oneBrandByEvent'
import editBrandingReducer, { editBrandingActions } from '@app-store/slices/onbordingOrganizer/editBranding'
import createBrandingReducer, { createBrandingActions } from '@app-store/slices/onbordingOrganizer/createBranding'
import deleteHistoryImportAttendeesReducer, {
    deleteHistoryImportAttendeesActions
} from '@app-store/slices/onbordingOrganizer/deleteHistoryImportAttendees'

export const onbordingOrganizerReducer = combineReducers({
    importAttendees: importAttendeesReducer,
    historyImportAttendees: historyImportAttendeesReducer,
    oneBrand: oneBrandReducer,
    oneBrandByEvent: oneBrandByEventReducer,
    editBranding: editBrandingReducer,
    createBranding: createBrandingReducer,
    deleteHistoryImportAttendees: deleteHistoryImportAttendeesReducer
})

export const onbordingOrganizerActions = {
    importAttendees: importAttendeesActions,
    historyImportAttendees: historyImportAttendeesActions,
    oneBrand: oneBrandActions,
    oneBrandByEvent: oneBrandByEventActions,
    editBranding: editBrandingActions,
    createBranding: createBrandingActions,
    deleteHistoryImportAttendees: deleteHistoryImportAttendeesActions
}
