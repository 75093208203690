import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: STATUS.IDLE,
    errors: null
}

const importAttendeesSlice = createSlice({
    name: 'onbordingOrganizer/importAttendees',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state) {
            state.status = STATUS.READY
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const importAttendeesActions = importAttendeesSlice.actions
export default importAttendeesSlice.reducer
