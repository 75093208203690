import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers } from '../reducers'

const listMeeting = createSlice({
    name: 'meeting/list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        onUserActivityChange(state, action) {
            state.data?.forEach((data, index) => {
                const updateActivityLogs = ids => {
                    ids?.filter(({ _id }) => action.payload._id === _id).forEach(
                        item => (item.activityLogs = action.payload.activityLogs)
                    )
                }
                updateActivityLogs(data?.guestIds)
                updateActivityLogs(data?.hostIds)
            })
        }
    }
})

export const listMeetingActions = listMeeting.actions
export default listMeeting.reducer
