import { combineReducers } from 'redux'
import staticEnumsReducer, { staticEnumsActions } from '@store/slices/badges/staticEnums'
import createBadgeReducer, { createBadgeActions } from '@app-store/slices/badges/create'
import oneBadgeReducer, { oneBadgeActions } from '@app-store/slices/badges/oneBadge'
import editBadgeReducer, { editBadgeActions } from '@app-store/slices/badges/editBadge'
import listReducer, { listActions } from '@app-store/slices/badges/list'

export const badgesReducers = combineReducers({
    staticEnums: staticEnumsReducer,
    create: createBadgeReducer,
    one: oneBadgeReducer,
    edit: editBadgeReducer,
    list: listReducer
})

export const badgesActions = {
    staticEnums: staticEnumsActions,
    create: createBadgeActions,
    one: oneBadgeActions,
    edit: editBadgeActions,
    list: listActions
}
