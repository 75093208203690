import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IRunCampaignStepPayload {
    campaignId: string
    stepId: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const runCampaignStep = createSlice({
    name: 'campaigns/run-campaign-step',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IRunCampaignStepPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const runCampaignStepActions = runCampaignStep.actions
export default runCampaignStep.reducer
