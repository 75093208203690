import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const matchingSelectionCompaniesSlice = createSlice({
    name: 'ui/matching-selection-companies',
    initialState: {
        ...listUIInitialState
    },
    reducers: {
        ...listUIReducers
    }
})

export const matchingSelectionCompaniesActions = matchingSelectionCompaniesSlice.actions
export default matchingSelectionCompaniesSlice.reducer
