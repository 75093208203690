import { combineReducers } from 'redux'
import oneHallReducer, { oneHallActions } from '@app-store/slices/hall/oneHall'
import listHallReducer, { listHallActions } from '@app-store/slices/hall/list'
import listByCompanyGroupsReducer, { listByCompanyGroupsActions } from '@app-store/slices/hall/listByCompanyGroups'
import deleteHallReducer, { deleteHallActions } from '@app-store/slices/hall/delete'
import createHallReducer, { createHallActions } from '@app-store/slices/hall/create'
import editHallReducer, { editHallActions } from '@app-store/slices/hall/edit'

export const hallReducer = combineReducers({
    list: listHallReducer,
    oneHall: oneHallReducer,
    create: createHallReducer,
    delete: deleteHallReducer,
    edit: editHallReducer,
    listByCompanyGroups: listByCompanyGroupsReducer
})

export const hallActions = {
    list: listHallActions,
    oneHall: oneHallActions,
    create: createHallActions,
    delete: deleteHallActions,
    edit: editHallActions,
    listByCompanyGroups: listByCompanyGroupsActions
}
