import { createSlice } from '@reduxjs/toolkit'

interface ICommonState {
    searchValue: string
    standard: string[]
    selectedMainCategory: string
    leftSelectedCategory: string
    allCategories: any
    expandedKey: string
    searchedData: any
}

const initialState: ICommonState = {
    searchValue: '',
    standard: [],
    selectedMainCategory: '',
    leftSelectedCategory: '',
    allCategories: [],
    expandedKey: '',
    searchedData: []
}

const categoriesSlice = createSlice({
    name: 'ui/categories',
    initialState,
    reducers: {
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeStandard(state, action) {
            state.standard = action.payload
        },
        onChangeMainCategory(state, action) {
            state.selectedMainCategory = action.payload
        },
        onChangeLeftSelectedCategory(state, action) {
            state.leftSelectedCategory = action.payload
        },
        onSetMainCategories(state, action) {
            state.allCategories = action.payload
        },
        onChangeExpandedKey(state, action) {
            state.expandedKey = action.payload
        },
        onChangeSearchedData(state, action) {
            state.searchedData = action.payload
        },
        onChangeItems(state, action) {
            Object.keys(action.payload).map(key => {
                state[key] = action.payload[key]
            })
        },
        onResetData(state) {
            Object.keys(initialState).map(key => {
                state[key] = initialState[key]
            })
        }
    }
})

export const categoriesActions = categoriesSlice.actions
export default categoriesSlice.reducer
