import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateMeetingRequestPayload } from '@app-store/slices/meetingRequests/create'

interface TaskAction extends Action {
    payload: ICreateMeetingRequestPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingRequests.create

function* createMeetingRequestSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/meeting-requests`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess()
        } else {
            notification.error({
                message: response.error || 'Something went wrong.'
            })
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload?.sagaCB?.onError()
    }
}

function* createMeetingRequestSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createMeetingRequestSagaTask)
}

export default createMeetingRequestSaga
