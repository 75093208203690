import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const matchingSelectionContactsSlice = createSlice({
    name: 'ui/matching-selection-contacts',
    initialState: {
        ...listUIInitialState,
        pagination: {
            ...listUIInitialState.pagination,
            itemsPerPage: 6
        }
    },
    reducers: listUIReducers
})

export const matchingSelectionContactsActions = matchingSelectionContactsSlice.actions
export default matchingSelectionContactsSlice.reducer
