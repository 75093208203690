export * from './list'

export enum SubscriptionOrderStatus {
    PENDING = 'Pending',
    PAID = 'Paid',
    CANCELLED = 'Cancelled',
    REJECTED = 'Rejected',
    WAITING = 'Waiting'
}

export const SubscriptionOrderStatusColors = {
    Pending: '#FFC22D',
    Paid: '#22B254',
    Cancelled: '#F14D4D',
    Rejected: '#F14D4D',
    Waiting: '#FFC22D'
}
