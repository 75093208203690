import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOneRatingRequestPayload } from '@app-store/slices/momentum/oneRating'

interface TaskAction extends Action {
    payload: IOneRatingRequestPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.momentum.one

function* momentumOneRatingSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/meeting/rating/${payload.rateId}`)
        if (response.success) {
            yield put(onSuccess(response.result.data))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload?.sagaCB?.onError()
    }
}

function* momentumOneRatingSaga(): any {
    yield takeLatest<TaskAction>(onRequest, momentumOneRatingSagaTask)
}

export default momentumOneRatingSaga
