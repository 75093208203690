import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRemoveCompanyPayload } from '@app-store/slices/matchLists/removeCompany'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IRemoveCompanyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchLists.removeCompany
const { onRemoveCompanies } = rootActions.matchLists.oneMatchList

function* removeCompanySagaTask({ payload }: TaskAction) {
    const { matchedCompanies, _id } = yield select((state: RootState) => state.matchLists.oneMatchList.data)
    const newMatchedCompanies = matchedCompanies
        .filter(
            item =>
                item.company && item.company !== 'null' && !payload.data.ids.includes(item.company?._id || item.company)
        )
        .map(item => ({
            company: item.company?._id || item.company,
            BPARate: item.BPARate || 0,
            ITPRate: item.ITPRate || 0,
            matchRate: item.matchRate || 0
        }))
    try {
        const response = yield axios.put(`/companies/match-lists/${_id}`, {
            matchedCompanies: newMatchedCompanies
        })
        if (response.success) {
            notification.success({
                message: `The ${payload.data.ids.length === 1 ? 'company' : 'companies'} deleted successfully.`
            })
            yield put(onSuccess(response.result))
            yield put(onRemoveCompanies(payload.data.ids))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* removeCompanySaga(): any {
    yield takeLatest<TaskAction>(onRequest, removeCompanySagaTask)
}

export default removeCompanySaga
