import { createSlice } from '@reduxjs/toolkit'
import { ICreateRfqDataPayload } from '@store/slices/rfqs/createRfq'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const initialData: ICreateRfqDataPayload = {
    _id: '',
    title: '',
    type: 'Product',
    condition: '',
    description: '',
    eventId: '',
    company: '',
    invitedCompanies: [],
    link: '',
    deliveryTerms: 'EXW',
    deliveryDate: '',
    expireDate: '',
    products: [],
    services: [],
    costs: [],
    currency: '',
    targetPrice: 0,
    attachments: []
}

const rfqsSlice = createSlice({
    name: 'ui/rfqs',
    initialState: {
        ...listUIInitialState,
        data: initialData,
        addProductModalVisible: false,
        selectProductModalVisible: false,
        addServiceModalVisible: false,
        selectServiceModalVisible: false,
        modalsModelName: 'rfqs',
        type: 'selling'
    },
    reducers: {
        addDataItems(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.data[item] = action.payload[item]
            }
        },
        addItems(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state[item] = action.payload[item]
            }
        },
        addProduct(state, action) {
            state.data.products = [...state.data.products, action.payload]
        },
        removeProduct(state, action) {
            const newArr = state.data.products.filter(i => i.product !== action.payload._id)
            state.data.products = newArr
        },
        editProduct(state, action) {
            const newArr = state.data.products
            const { index, key, value } = action.payload
            newArr[index][key] = value
            state.data.products = newArr
        },
        addService(state, action) {
            state.data.services = [...state.data.services, action.payload]
        },
        removeService(state, action) {
            const newArr = state.data.services.filter(i => i.service !== action.payload._id)
            state.data.services = newArr
        },
        editService(state, action) {
            const newArr = state.data.services
            const { index, key, value } = action.payload
            newArr[index][key] = value
            state.data.services = newArr
        },
        addInvitedCompany(state, action) {
            state.data.invitedCompanies = [...state.data.invitedCompanies, action.payload]
        },
        removeInvitedCompany(state, action) {
            const newArr = state.data.invitedCompanies.filter(i => i !== action.payload)
            state.data.invitedCompanies = newArr
        },
        resetItems(state) {
            state.data = initialData
        },
        ...listUIReducers
    }
})

export const rfqsActions = rfqsSlice.actions
export default rfqsSlice.reducer
