import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOneSharedListPayload } from '@app-store/slices/sharedLists/oneSharedList'

interface TaskAction extends Action {
    payload: IOneSharedListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.oneSharedList

function* getOneSharedListTask({ payload }: TaskAction) {
    try {
        const params = {}
        if (payload.sort) Object.assign(params, { sort: payload.sort })
        if (payload.needPopulate) Object.assign(params, { needPopulate: payload.needPopulate })
        if (payload.populates) Object.assign(params, { populates: payload.populates })
        const response = yield axios.get(`/companies/shared-lists/one/${payload.sharedListId}`, { params })
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getOneSharedList(): any {
    yield takeLatest<TaskAction>(onRequest, getOneSharedListTask)
}

export default getOneSharedList
