import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.crm.list

function* listCRMSagaTask({ payload }) {
    const params = yield getParams(payload)
    try {
        const response = yield axios.get(`/integrations/crms`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listCRMSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listCRMSagaTask)
}

export default listCRMSaga
