import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IShareItpPayload } from '@app-store/slices/companies/shareItp'

interface TaskAction extends Action {
    payload: IShareItpPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.shareItp

function* shareItpSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/share-itp`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess()
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Matching?.ITP_shared_successfully || 'ITP shared successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* shareItpSaga(): any {
    yield takeLatest<TaskAction>(onRequest, shareItpSagaTask)
}

export default shareItpSaga
