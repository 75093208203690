import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDisconnectPayload } from '@app-store/slices/crm/disconnect'

interface TaskAction extends Action {
    payload: IDisconnectPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.crm.disconnect

function* disconnectSeriveSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.patch(`/integrations/crms/disconnect/${payload.crmId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.CRM_has_been_disconnected || 'CRM has been disconnected.',
                duration: 3000
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload.sagaCB.onError(error)
    }
}

function* disconnectSeriveSaga(): any {
    yield takeLatest<TaskAction>(onRequest, disconnectSeriveSagaTask)
}

export default disconnectSeriveSaga
