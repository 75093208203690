import { createSlice } from '@reduxjs/toolkit'

export interface ISettersOptionsManageSubscriptionDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    pureFilters?: any
}

const initialState: ISettersOptionsManageSubscriptionDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    pureFilters: {}
}

const settersOptionsManageSubscriptionSlice = createSlice({
    name: 'ui/settersOptionsManageSubscription-list',
    initialState,
    reducers: {
        onChangePagination(state, action) {
            state.pagination = { ...state.pagination, ...action.payload }
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onResetFilters(state) {
            state.filters = {}
            state.pureFilters = {}
        }
    }
})

export const settersOptionsManageSubscriptionActions = settersOptionsManageSubscriptionSlice.actions
export default settersOptionsManageSubscriptionSlice.reducer
