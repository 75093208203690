import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import { confirmPasswordCognitoUser } from '@utils/authenticateService'
import { IConfirmPasswordPayload } from '@app-store/slices/auth/confirmPassword.slice'
import notification from 'antd/lib/notification'
import { history } from '@components/App'
import routes from '@utils/constants/routes'
import { RootState } from '@app-store/store'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: IConfirmPasswordPayload
}

const { onFailure, onSuccess, onRequest } = rootActions.auth.confirmPassword

function* confirmPasswordSagaTask({ payload }: TaskAction) {
    const { data: i18n } = yield select((store: RootState) => store.localization.locals)
    try {
        const response = yield confirmPasswordCognitoUser(payload)
        history.replace({
            pathname: routes.login._
        })
        notification.success({
            message: i18n?.General?.Your_password_changed_successfully || 'Your password has been changed successfully.'
        })
        yield put(onSuccess(response))
    } catch (e) {
        yield put(onFailure([e || i18n?.General?.Something_went_wrong || 'Something went wrong.']))
        notification.error({
            message: e || i18n?.General?.Something_went_wrong || 'Something went wrong.'
        })
    }
}

function* confirmPasswordSaga(): any {
    yield takeLatest<TaskAction>(onRequest, confirmPasswordSagaTask)
}

export default confirmPasswordSaga
