import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IPostMessagePayload {
    data: {
        roomId: string
        text?: string
        alias?: string
        emoji?: string
        avatar?: string
        attachments: {
            color?: string
            text?: string
            ts?: string
            thumb_url?: string
            message_link?: string
            collapsed?: boolean
            author_name?: string
            author_link?: string
            author_icon?: string
            title?: string
            title_link?: string
            title_link_download?: string
            image_url?: string
            audio_url?: string
            video_url?: string
            fields?: {
                short?: boolean
                title: string
                value: string
            }[]
        }[]
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const postMessageSlice = createSlice({
    name: 'chat/post-message',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IPostMessagePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.messages
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const postMessageActions = postMessageSlice.actions
export default postMessageSlice.reducer
