import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IOneMatchListPayload extends ISagaCb {
    matchListId: string
}

export interface IOneMatchListState {
    status: string
    data: any
    errors: any
}

const initialState: IOneMatchListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneMatchList = createSlice({
    name: 'matchList/one-match-list',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneMatchListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onRemoveCompanies(state, action) {
            const newData = {
                ...state.data,
                matchedCompanies: state.data.matchedCompanies.filter(
                    item =>
                        item.company &&
                        item.company !== 'null' &&
                        !action.payload.includes(item.company?._id || item.company)
                )
            }
            state.data = newData
        },
        onAddCompanies(state, action) {
            const newData = {
                ...state.data,
                matchedCompanies: [
                    ...state.data.matchedCompanies,
                    ...action.payload.map(item => ({
                        ...item,
                        company: item?.company?.company || item?.company
                    }))
                ]
            }
            state.data = newData
        }
    }
})

export const oneMatchListActions = oneMatchList.actions
export default oneMatchList.reducer
