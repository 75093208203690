import { createSlice } from '@reduxjs/toolkit'

export interface IServiceDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    sort?: string
    projection?: any
    needPopulate: boolean
    populates?: any
}

const initialState: IServiceDataState = {
    pagination: {
        itemsPerPage: 8,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    sort: 'name',
    projection: [],
    needPopulate: true,
    populates: ['category']
}

const serviceSlice = createSlice({
    name: 'ui/service',
    initialState,
    reducers: {
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        onChangeSort(state, action) {
            state.sort = action.payload
        },
        addTotal(state) {
            state.pagination = { ...state.pagination, total: state.pagination.total + 1 }
        }
    }
})

export const serviceActions = serviceSlice.actions
export default serviceSlice.reducer
