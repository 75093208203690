import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/companySubscriptions/list'
import oneCompanySubscriptionReducer, {
    oneCompanySubscriptionActions
} from '@app-store/slices/companySubscriptions/one'
import addUserReducer, { addUserActions } from '@app-store/slices/companySubscriptions/addUser'
import editUserReducer, { editUserActions } from '@app-store/slices/companySubscriptions/editUser'
import deleteUserReducer, { deleteUserActions } from '@app-store/slices/companySubscriptions/deleteUser'
import addFeatureReducer, { addFeatureActions } from '@app-store/slices/companySubscriptions/addFeature'
import editFeatureReducer, { editFeatureActions } from '@app-store/slices/companySubscriptions/editFeature'
import deleteFeatureReducer, { deleteFeatureActions } from '@app-store/slices/companySubscriptions/deleteFeature'
import getActiveReducer, { getActiveActions } from '@app-store/slices/companySubscriptions/getActive'
import getSummaryReducer, { getSummaryActions } from '@app-store/slices/companySubscriptions/getSummary'

export const companySubscriptionReducers = combineReducers({
    list: listReducer,
    one: oneCompanySubscriptionReducer,
    addUser: addUserReducer,
    editUser: editUserReducer,
    deleteUser: deleteUserReducer,
    addFeature: addFeatureReducer,
    editFeature: editFeatureReducer,
    deleteFeature: deleteFeatureReducer,
    getActive: getActiveReducer,
    getSummary: getSummaryReducer
})

export const companySubscriptionActions = {
    list: listActions,
    one: oneCompanySubscriptionActions,
    addUser: addUserActions,
    editUser: editUserActions,
    deleteUser: deleteUserActions,
    addFeature: addFeatureActions,
    editFeature: editFeatureActions,
    deleteFeature: deleteFeatureActions,
    getActive: getActiveActions,
    getSummary: getSummaryActions
}
