import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IShareMeetingLinkPayload } from '@app-store/slices/meetings/shareMeetingLink'

interface TaskAction extends Action {
    payload: IShareMeetingLinkPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.shareMeetingLink

function* shareMeetingLinkSagaTask({ payload }) {
    const sagaCB = payload.sagaCB
    delete payload.sagaCB

    try {
        const response = yield axios.get('/meeting/share-meeting-link', { params: payload })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (sagaCB?.onSuccess) sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* shareMeetingLinkSaga(): any {
    yield takeLatest<TaskAction>(onRequest, shareMeetingLinkSagaTask)
}

export default shareMeetingLinkSaga
