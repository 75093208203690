import { IState, IDataState, IListState } from '@typings/requestPayloads'
import { STATUS } from '@features/General'

export const listInitialState: IListState = {
    status: STATUS.IDLE,
    data: [],
    pagination: {
        skip: 0,
        hasTotal: true,
        total: 0,
        limit: 10
    },
    errors: null
}

export const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

export const listUIInitialState: IDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    searchType: 'mongo',
    filters: {},
    pureFilters: {},
    sort: '-createdAt',
    projection: [],
    needPopulate: true,
    populates: [],
    selectedRowKeys: [],
    hiddenColumns: []
}
