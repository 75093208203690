import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const ordersSlice = createSlice({
    name: 'ui/orders',
    initialState: { ...listUIInitialState, populates: ['company', 'subscription', 'user'] },
    reducers: listUIReducers
})

export const ordersActions = ordersSlice.actions
export default ordersSlice.reducer
