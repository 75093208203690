import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IOneSpeakerPayload {
    speakerId: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneSpeaker = createSlice({
    name: 'speakers/one-speaker',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneSpeakerPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneSpeakerActions = oneSpeaker.actions
export default oneSpeaker.reducer
