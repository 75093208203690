import { createSlice } from '@reduxjs/toolkit'
export interface IDuplicateCompaniesListDataState extends IDataState {}
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
import { IDataState } from '@typings/requestPayloads'
const duplicateCompaniesListInitialState: IDuplicateCompaniesListDataState = {
    ...listUIInitialState
}

const duplicateCompaniesListSlice = createSlice({
    name: 'ui/duplicate-companies-list',
    initialState: duplicateCompaniesListInitialState,
    reducers: {
        ...listUIReducers
    }
})

export const duplicateCompaniesListActions = duplicateCompaniesListSlice.actions
export default duplicateCompaniesListSlice.reducer
