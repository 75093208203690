import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'
import cloneDeep from 'lodash/cloneDeep'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers } from '../reducers'

const listMeetingRequests = createSlice({
    name: 'meeting-requests/list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        replaceItem(state, action) {
            const findIndex = state.data.findIndex(item => item._id === action.payload._id)
            if (findIndex > -1) {
                const newState = cloneDeep(action.payload)
                if (!newState.hostId?.lastname) {
                    newState.hostId = state.data[findIndex].hostId
                }
                state.data[findIndex] = newState
            }
        }
    }
})

export const listMeetingRequestsActions = listMeetingRequests.actions
export default listMeetingRequests.reducer
