import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.eventTopics.dateList

function* dateListSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/event/event-topic/list-dates`)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* dateListSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, dateListSagaTask)
}

export default dateListSaga
