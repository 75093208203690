import { combineReducers } from 'redux'
import getMessagesReducer, { getMessagesActions } from '@app-store/slices/chat/getMessages'
import getUsersReducer, { getUsersActions } from '@app-store/slices/chat/getUsers'
import registerReducer, { registerActions } from '@app-store/slices/chat/register'
import sendMessageReducer, { sendMessageActions } from '@app-store/slices/chat/sendMessage'
import postMessageReducer, { postMessageActions } from '@app-store/slices/chat/postMessage'
import createDirectMessageReducer, { createDirectMessageActions } from '@app-store/slices/chat/createDirectMessage'
import deleteDirectMessageReducer, { deleteDirectMessageActions } from '@app-store/slices/chat/deleteDirectMessage'
import listDirectMessageReducer, { listDirectMessageActions } from '@app-store/slices/chat/listDirectMessage'
import loginReducer, { loginActions } from '@app-store/slices/chat/login'
import ignoreUserReducer, { ignoreUserActions } from '@app-store/slices/chat/ignoreUser'
import uploadFileReducer, { uploadFileActions } from '@app-store/slices/chat/uploadFile'

export const chatReducer = combineReducers({
    getMessages: getMessagesReducer,
    getUsers: getUsersReducer,
    register: registerReducer,
    sendMessage: sendMessageReducer,
    postMessage: postMessageReducer,
    createDirectMessage: createDirectMessageReducer,
    deleteDirectMessage: deleteDirectMessageReducer,
    listDirectMessage: listDirectMessageReducer,
    login: loginReducer,
    ignoreUser: ignoreUserReducer,
    uploadFile: uploadFileReducer
})

export const chatActions = {
    getMessages: getMessagesActions,
    getUsers: getUsersActions,
    register: registerActions,
    sendMessage: sendMessageActions,
    postMessage: postMessageActions,
    createDirectMessage: createDirectMessageActions,
    deleteDirectMessage: deleteDirectMessageActions,
    listDirectMessage: listDirectMessageActions,
    login: loginActions,
    ignoreUser: ignoreUserActions,
    uploadFile: uploadFileActions
}
