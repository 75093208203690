import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers, manipulateListReducers } from '../reducers'

const listByAdminSlice = createSlice({
    name: 'hub/tags/list-by-admin',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        ...manipulateListReducers
    }
})

export const listByAdminActions = listByAdminSlice.actions
export default listByAdminSlice.reducer
