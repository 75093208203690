import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { ISendAgendasPayload } from '@app-store/slices/reporting/sendAgendas'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: ISendAgendasPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.reporting.sendAgendas

function* sendAgendasSagaTask({ payload }) {
    try {
        const params: any = {}
        if (payload.companies) {
            params.companies = payload.companies
        }
        if (payload.status) {
            params.status = payload.status
        }
        if (payload.participants) {
            params.participants = payload.participants
        }
        if (payload.meetingDate) {
            params.meetingDate = payload.meetingDate
        }
        if (payload.timeShift) {
            params.timeShift = payload.timeShift
        }
        const response = yield axios.get(`/reporting/agendas/send`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess()
        } else if (response.status === 503) {
            notification.success({
                message:
                    "The Agenda is being prepared, this could take some time. You will receive an email once it's ready with all details."
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* sendAgendasSaga(): any {
    yield takeLatest<TaskAction>(onRequest, sendAgendasSagaTask)
}

export default sendAgendasSaga
