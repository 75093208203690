import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    data: any
    company: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: {},
    company: [],
    errors: null
}

const oneCompanySlice = createSlice({
    name: 'hub/companies/one-company',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onCompanyData(state, action) {
            state.status = STATUS.READY
            state.company = [...state.company, action.payload]
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onUploadImage(state, action) {
            state.status = STATUS.RUNNING
        },
        onEditRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onEditFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onChangeField(state, action) {
            const data = action.payload
            for (const key in data) {
                state.data[key] = data[key]
            }
        }
    }
})

export const oneCompanyActions = oneCompanySlice.actions
export default oneCompanySlice.reducer
