import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IChangeStatusUserPayload {
    id: string
    status: string
}

interface IChangeStatusState {
    status: string
    errors: any
}

const initialState: IChangeStatusState = {
    status: STATUS.IDLE,
    errors: null
}

const userChangeStatusSlice = createSlice({
    name: 'hub/user/changeStatus',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IChangeStatusUserPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const userChangeStatusActions = userChangeStatusSlice.actions
export default userChangeStatusSlice.reducer
