import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditGlobalRenamingPayload } from '@app-store/slices/eventSettings/editGlobalRenaming'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IEditGlobalRenamingPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.editGlobalRenaming

function* editGlobalRenamingSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/event/settings/${payload.settingId}/global-renaming`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Names_updated_successfully || 'Names updated successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editGlobalRenamingSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editGlobalRenamingSagaTask)
}

export default editGlobalRenamingSaga
