import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICheckPromoCodeValidationPayload extends ISagaCb {
    code: string
}
export interface IState {
    status: string
    data: any
    errors: any
}
const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const checkPromoCodeValidation = createSlice({
    name: 'subscription-payment/check-promo-code-validation',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICheckPromoCodeValidationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onRemoveCard(state, action) {
            state.data = state.data.filter(item => item.id !== action.payload)
        },
        onAddCard(state, action) {
            const newData = [...state.data]
            newData.push(action.payload)
            state.data = newData
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const checkPromoCodeValidationActions = checkPromoCodeValidation.actions
export default checkPromoCodeValidation.reducer
