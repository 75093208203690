import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IStaticEnumsState {
    status: string
    result: any
    errors: any
}

const initialState: IStaticEnumsState = {
    status: STATUS.IDLE,
    result: {},
    errors: null
}

const staticEnumsSlice = createSlice({
    name: '/notifications/static-enums',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const staticEnumsActions = staticEnumsSlice.actions
export default staticEnumsSlice.reducer
