import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.calendar.list

function* listSagaTask({ payload }: ListTaskAction) {
    try {
        const params = yield getParams(payload)
        const response = yield axios.get('/meeting/user-availabilities/calendar', {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            if (typeof payload.sagaCB?.onError === 'function') {
                yield payload.sagaCB?.onError()
            }
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB?.onError()
        }
    }
}

function* listSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listSagaTask)
}

export default listSaga
