import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IContactListVerifyPayload } from '@app-store/slices/contacts/contactListVerify'

interface TaskAction extends Action {
    payload: IContactListVerifyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.contacts.contactListVerify
function* contactListVerifySagaTask({ payload }) {
    try {
        const response = yield axios.put('/companies/contacts/contact-detail/verify', {
            ...payload.data
        })
        if (response.success) {
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload.sagaCB?.onError) {
            yield payload.sagaCB?.onError()
        }
    }
}

function* contactListVerifySaga(): any {
    yield takeLatest<TaskAction>(onRequest, contactListVerifySagaTask)
}

export default contactListVerifySaga
