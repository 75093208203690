import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { rootActions } from '@app-store/slices'
import { IResumePlanPayload } from '@app-store/slices/subscriptionPayment/resumePlan'

interface TaskAction extends Action {
    payload: IResumePlanPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.resumePlan

function* resumePlanSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/subscriptions/payments/resume/${payload.subscriptionId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* resumePlanSaga(): any {
    yield takeLatest<TaskAction>(onRequest, resumePlanSagaTask)
}

export default resumePlanSaga
