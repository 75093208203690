import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.labelTag.list

function* listLabelTagSagaTask({ payload }) {
    try {
        const params = yield getParams(payload)
        const response = yield axios.get(`/attachments/badges`, { params })

        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listLabelTagSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listLabelTagSagaTask)
}

export default listLabelTagSaga
