import oneSaga from './oneSaga'
import listSaga from './listSaga'
import addFeatureSaga from './addFeatureSaga'
import editFeatureSaga from './editFeatureSaga'
import deleteFeatureSaga from './deleteFeatureSaga'
import getActiveSaga from './getActive'

const eventSubscriptionsSagas = [
    oneSaga(),
    listSaga(),
    addFeatureSaga(),
    editFeatureSaga(),
    deleteFeatureSaga(),
    getActiveSaga()
]

export default eventSubscriptionsSagas
