import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/countries/list'
import oneCountryReducer, { oneCountryActions } from '@app-store/slices/countries/oneCountry'

export const countriesReducer = combineReducers({
    list: listReducer,
    one: oneCountryReducer
})

export const countriesActions = {
    list: listActions,
    one: oneCountryActions
}
