import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IMeetingByOperatorPayloadState } from '@app-store/slices/meetingStatistics/meetingByOperator'

interface TaskAction extends Action {
    payload: IMeetingByOperatorPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingStatistics.meetingByOperator

function* meetingByOperatorSagaTask({ payload }: TaskAction) {
    const params: any = {}
    if (payload.query) {
        params.query = payload.query
    }
    try {
        const response = yield axios.get(`/meeting/stats/meeting-by-operator`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result.data))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* meetingByOperatorSaga(): any {
    yield takeLatest<TaskAction>(onRequest, meetingByOperatorSagaTask)
}

export default meetingByOperatorSaga
