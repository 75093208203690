import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/companies/list'
import listSearchReducer, { listSearchActions } from '@app-store/slices/companies/listSearch'
import assignedListReducer, { assignedListActions } from '@app-store/slices/companies/assignedList'
import oneCompanyRecducer, { oneCompanyActions } from '@app-store/slices/companies/oneCompany'
import editReducer, { editActions } from '@app-store/slices/companies/edit'
import editItpReducer, { editItpActions } from '@app-store/slices/companies/editItp'
import advancedListReducer, { advancedListActions } from '@app-store/slices/companies/advancedList'
import publishedListReducer, { publishedListActions } from '@app-store/slices/companies/publishedList'
import publishedListSearchReducer, { publishedListSearchActions } from '@app-store/slices/companies/publishedListSearch'
import prePublishCompanyReducer, { prePublishCompanyActions } from '@app-store/slices/companies/prePublishCompany'
import publishCompanyReducer, { publishCompanyActions } from '@app-store/slices/companies/publisheCompany'
import statisticsReducer, { statisticsActions } from '@app-store/slices/companies/statistics'
import contactCompanyRecducer, { contactCompanyActions } from '@app-store/slices/companies/contactCompany'
import contactStaticEnumsReducer, { contactStaticEnumsActions } from '@app-store/slices/companies/contactStaticEnums'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/companies/staticEnums'
import onboardingReducer, { onboardingActions } from '@app-store/slices/companies/onboarding'
import contactProfileReducer, { contactProfileActions } from '@app-store/slices/companies/contactProfile'
import onboardingExhibitorReducer, { onboardingExhibitorActions } from '@app-store/slices/companies/onboardingExhibitor'
import itpStaticEnumsReducer, { itpStaticEnumsActions } from '@app-store/slices/companies/itpStaticEnums'
import industryListReducer, { industryListActions } from '@app-store/slices/companies/industryList'
import checkStatusEventReducer, { checkStatusEventActions } from '@app-store/slices/companies/checkStatusEvent'
import itpListReducer, { itpListActions } from '@app-store/slices/companies/itpList'
import createItpReducer, { createItpActions } from '@app-store/slices/companies/createItp'
import deleteItpReducer, { deleteItpActions } from '@app-store/slices/companies/deleteItp'
import getPreSignedUploadURLReducer, {
    getPreSignedUploadURLActions
} from '@app-store/slices/companies/getPreSignedUploadURL'
import fileUploadReducer, { fileUploadActions } from '@app-store/slices/companies/fileUpload'
import createCompanyReducer, { createCompanyActions } from './createCompany'
import companyViewReducer, { companyViewActions } from './view.slice'
import deleteCompanyReducer, { deleteCompanyActions } from './delete'
import shareItpReducer, { shareItpActions } from './shareItp'
import deactiveCompanyReducer, { deactiveCompanyActions } from './deactiveCompany'
import itpByTokenReducer, { itpByTokenActions } from './itpByToken'
import editItpByTokenReducer, { editItpByTokenActions } from './editItpByToken'
import statusStatisticsReducer, { statusStatisticsActions } from './statusStatistics'
import internalPublishCompanyReducer, { internalPublishCompanyActions } from './internalPublishCompany'
import searchReducer, { searchActions } from './search'
import internalPublishedListSearchReducer, {
    internalPublishedListSearchActions
} from '@app-store/slices/companies/internalPublishedListSearch'
import duplicateListReducer, { duplicateListActions } from '@app-store/slices/companies/duplicateList'
import mergeDuplicateListReducer, { mergeDuplicateListActions } from '@app-store/slices/companies/mergeDuplicateList'
import companyVerifyReducer, { companyVerifyActions } from './companyVerify'

export const companiesReducer = combineReducers({
    list: listReducer,
    itpList: itpListReducer,
    listSearch: listSearchReducer,
    edit: editReducer,
    editItp: editItpReducer,
    createItp: createItpReducer,
    deleteItp: deleteItpReducer,
    oneCompany: oneCompanyRecducer,
    assignedList: assignedListReducer,
    prePublishCompany: prePublishCompanyReducer,
    publishCompany: publishCompanyReducer,
    statistics: statisticsReducer,
    contactCompany: contactCompanyRecducer,
    advancedList: advancedListReducer,
    contactStaticEnums: contactStaticEnumsReducer,
    staticEnums: staticEnumsReducer,
    onboarding: onboardingReducer,
    contactProfile: contactProfileReducer,
    onboardingExhibitor: onboardingExhibitorReducer,
    itpStaticEnums: itpStaticEnumsReducer,
    checkStatusEvent: checkStatusEventReducer,
    getPreSignedUploadURL: getPreSignedUploadURLReducer,
    fileUpload: fileUploadReducer,
    createCompany: createCompanyReducer,
    view: companyViewReducer,
    delete: deleteCompanyReducer,
    publishedList: publishedListReducer,
    publishedListSearch: publishedListSearchReducer,
    shareItp: shareItpReducer,
    industryList: industryListReducer,
    deactive: deactiveCompanyReducer,
    itpByToken: itpByTokenReducer,
    editItpByToken: editItpByTokenReducer,
    statusStatistics: statusStatisticsReducer,
    internalPublishCompany: internalPublishCompanyReducer,
    search: searchReducer,
    internalPublishedListSearch: internalPublishedListSearchReducer,
    duplicateList: duplicateListReducer,
    mergeDuplicateList: mergeDuplicateListReducer,
    companyVerify: companyVerifyReducer
})

export const companiesActions = {
    list: listActions,
    itpList: itpListActions,
    listSearch: listSearchActions,
    edit: editActions,
    createItp: createItpActions,
    deleteItp: deleteItpActions,
    editItp: editItpActions,
    oneCompany: oneCompanyActions,
    assignedList: assignedListActions,
    prePublishCompany: prePublishCompanyActions,
    publishCompany: publishCompanyActions,
    statistics: statisticsActions,
    contactCompany: contactCompanyActions,
    advancedList: advancedListActions,
    contactStaticEnums: contactStaticEnumsActions,
    staticEnums: staticEnumsActions,
    onboarding: onboardingActions,
    contactProfile: contactProfileActions,
    onboardingExhibitor: onboardingExhibitorActions,
    itpStaticEnums: itpStaticEnumsActions,
    checkStatusEvent: checkStatusEventActions,
    getPreSignedUploadURL: getPreSignedUploadURLActions,
    fileUpload: fileUploadActions,
    createCompany: createCompanyActions,
    view: companyViewActions,
    delete: deleteCompanyActions,
    publishedList: publishedListActions,
    publishedListSearch: publishedListSearchActions,
    shareItp: shareItpActions,
    industryList: industryListActions,
    deactive: deactiveCompanyActions,
    itpByToken: itpByTokenActions,
    editItpByToken: editItpByTokenActions,
    statusStatistics: statusStatisticsActions,
    internalPublishCompany: internalPublishCompanyActions,
    search: searchActions,
    internalPublishedListSearch: internalPublishedListSearchActions,
    duplicateList: duplicateListActions,
    mergeDuplicateList: mergeDuplicateListActions,
    companyVerify: companyVerifyActions
}
