import { combineReducers } from 'redux'
import createSubscriptionPaymentReducer, {
    createSubscriptionPaymentActions
} from '@app-store/slices/subscriptionPayment/create'
import onePaymentReducer, { onePaymentActions } from '@app-store/slices/subscriptionPayment/one'
import cancelPlanReducer, { cancelPlanActions } from '@app-store/slices/subscriptionPayment/cancelPlan'
import pausePlanReducer, { pausePlanActions } from '@app-store/slices/subscriptionPayment/pausePlan'
import resumePlanReducer, { resumePlanActions } from '@app-store/slices/subscriptionPayment/resumePlan'
import addCardReducer, { addCardActions } from '@app-store/slices/subscriptionPayment/addCard'
import removeCardReducer, { removeCardActions } from '@app-store/slices/subscriptionPayment/removeCard'
import cardListReducer, { cardListActions } from '@app-store/slices/subscriptionPayment/cardList'
import updateDefaultCardReducer, {
    updateDefaultCardActions
} from '@app-store/slices/subscriptionPayment/updateDefaultCard'
import getDefaultCardReducer, { getDefaultCardActions } from '@app-store/slices/subscriptionPayment/getDefaultCard'
import invoiceListReducer, { invoiceListActions } from '@app-store/slices/subscriptionPayment/invoiceList'
import paymentListReducer, { paymentListActions } from '@app-store/slices/subscriptionPayment/paymentList'
import oneInvoiceReducer, { oneInvoiceActions } from '@app-store/slices/subscriptionPayment/oneInvoice'
import generateLinkReducer, { generateLinkActions } from '@app-store/slices/subscriptionPayment/generateLink'
import checkPromoCodeValidationReducer, {
    checkPromoCodeValidationActions
} from '@app-store/slices/subscriptionPayment/checkPromoCodeValidation'

export const subscriptionPaymentReducers = combineReducers({
    create: createSubscriptionPaymentReducer,
    one: onePaymentReducer,
    cancelPlan: cancelPlanReducer,
    pausePlan: pausePlanReducer,
    resumePlan: resumePlanReducer,
    addCard: addCardReducer,
    removeCard: removeCardReducer,
    cardList: cardListReducer,
    updateDefaultCard: updateDefaultCardReducer,
    getDefaultCard: getDefaultCardReducer,
    invoiceList: invoiceListReducer,
    paymentList: paymentListReducer,
    oneInvoice: oneInvoiceReducer,
    generateLink: generateLinkReducer,
    checkPromoCodeValidation: checkPromoCodeValidationReducer
})

export const subscriptionPaymentActions = {
    create: createSubscriptionPaymentActions,
    one: onePaymentActions,
    cancelPlan: cancelPlanActions,
    pausePlan: pausePlanActions,
    resumePlan: resumePlanActions,
    addCard: addCardActions,
    removeCard: removeCardActions,
    cardList: cardListActions,
    updateDefaultCard: updateDefaultCardActions,
    getDefaultCard: getDefaultCardActions,
    invoiceList: invoiceListActions,
    paymentList: paymentListActions,
    oneInvoice: oneInvoiceActions,
    generateLink: generateLinkActions,
    checkPromoCodeValidation: checkPromoCodeValidationActions
}
