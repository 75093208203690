import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'
import { ISagaCb } from '@typings/requestPayloads'

interface OptOut {
    optOutType: string
}
export interface IUnSubscribePayload extends ISagaCb {
    data: {
        email: string
        reason?: string
        optOut?: OptOut[]
    }
}

export interface IState {
    status: string
    success: boolean
    result: boolean
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    success: true,
    result: false,
    errors: null
}

const unSubscribeSlice = createSlice({
    name: 'hub/unsubscribe',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IUnSubscribePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const unSubscribeActions = unSubscribeSlice.actions
export default unSubscribeSlice.reducer
