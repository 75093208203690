import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/tours/list'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/tours/staticEnums'
import createTourReducer, { createTourActions } from './create'
import editTourReducer, { editTourActions } from './edit'
import oneTourReducer, { oneTourActions } from './oneTour'
import deleteTourReducer, { deleteTourActions } from './delete'

export const tourReducers = combineReducers({
    list: listReducer,
    staticEnums: staticEnumsReducer,
    create: createTourReducer,
    edit: editTourReducer,
    one: oneTourReducer,
    delete: deleteTourReducer
})

export const tourActions = {
    list: listActions,
    staticEnums: staticEnumsActions,
    create: createTourActions,
    edit: editTourActions,
    one: oneTourActions,
    delete: deleteTourActions
}
