import { createSlice } from '@reduxjs/toolkit'

export interface IEventDetailsViewState {
    leavingModal: boolean
    finishModal: boolean
}

const initialState: IEventDetailsViewState = {
    leavingModal: false,
    finishModal: false
}

const eventDetailsViewSlice = createSlice({
    name: 'organizer/event-details/view',
    initialState,
    reducers: {
        setLeavingModal(state, action) {
            state.leavingModal = action.payload
        },
        setFinishModal(state, action) {
            state.finishModal = action.payload
        }
    }
})

export const eventDetailsViewActions = eventDetailsViewSlice.actions
export default eventDetailsViewSlice.reducer
