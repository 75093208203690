import { combineReducers } from 'redux'
import createConferenceReducer, { createConferenceActions } from '@app-store/slices/conferences/createConference'
import editConferenceReducer, { editConferenceActions } from '@app-store/slices/conferences/editConference'
import deleteConferenceReducer, { deleteConferenceActions } from '@app-store/slices/conferences/deleteConference'
import oneConferenceReducer, { oneConferenceActions } from '@app-store/slices/conferences/oneConference'
import listReducer, { listActions } from '@app-store/slices/conferences/list'

export const conferencesReducer = combineReducers({
    list: listReducer,
    create: createConferenceReducer,
    edit: editConferenceReducer,
    oneConference: oneConferenceReducer,
    delete: deleteConferenceReducer
})

export const conferencesActions = {
    list: listActions,
    create: createConferenceActions,
    edit: editConferenceActions,
    oneConference: oneConferenceActions,
    delete: deleteConferenceActions
}
