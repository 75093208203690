import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ICreateMeetingRequestPayload extends ISagaCb {
    data?: {
        contactIds?: string[]
        guestCompanyId?: string
        duration?: number
        requestedTimes?: string[]
        content?: string
        type?: string
        place?: string
        location?: string
    }
}

export interface ICreateMeetingRequestState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateMeetingRequestState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createMeetingRequest = createSlice({
    name: 'meeting-requests/create',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateMeetingRequestPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createMeetingRequestActions = createMeetingRequest.actions
export default createMeetingRequest.reducer
