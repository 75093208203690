import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IStatusStatisticsPayload extends ISagaCb {
    fromDate?: string
    toDate?: string
    actionBy?: string
}

export interface IStatusStatisticsState {
    status: string
    data: any
    errors: any
}

const initialState: IStatusStatisticsState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const statusStatisticsSlice = createSlice({
    name: 'companies/status-statistics',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IStatusStatisticsPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const statusStatisticsActions = statusStatisticsSlice.actions
export default statusStatisticsSlice.reducer
