import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteServicePayload } from '@app-store/slices/services/deleteService'

interface TaskAction extends Action {
    payload: IDeleteServicePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.services.delete

function* deleteServiceSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/companies/services`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Service_deleted_successfully || 'Service deleted successfully.'
            })
            yield payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteServiceSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteServiceSagaTask)
}

export default deleteServiceSaga
