import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IEventReportPayload } from '@app-store/slices/reporting/eventReport'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IEventReportPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.reporting.eventReport

function* eventReportSagaTask({ payload }) {
    try {
        // const params: any = {}
        const response = yield axios.get(`/reporting/agendas/event-report?format=${payload.format || 'pdf'}`, {
            responseType: 'blob'
        })
        if (response?.status === 503 || response?.message) {
            notification.success({
                message:
                    "The Report is being prepared, this could take some time. You will receive an email once it's ready with all details."
            })
        } else if (response) {
            yield put(onSuccess(response))
            yield payload?.sagaCB?.onSuccess(response)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* eventReportSaga(): any {
    yield takeLatest<TaskAction>(onRequest, eventReportSagaTask)
}

export default eventReportSaga
