import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IAuditTrailReportPayload } from '@app-store/slices/reporting/auditTrailReport'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IAuditTrailReportPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.reporting.auditTrailReport

function* auditTrailReportSagaTask({ payload }) {
    try {
        const response = yield axios.get(`reporting/audit-trail`, {
            params: payload
        })
        if (response) {
            yield put(onSuccess(response.result.data))
            yield payload?.sagaCB?.onSuccess(response)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* auditTrailReportSaga(): any {
    yield takeLatest<TaskAction>(onRequest, auditTrailReportSagaTask)
}

export default auditTrailReportSaga
