import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IDeleteSurveyPayload extends ISagaCb {
    surId: string
}

export interface IDeleteSurveyState {
    status: string
    result: any
    errors: any
}

const initialState: IDeleteSurveyState = {
    status: STATUS.IDLE,
    result: null,
    errors: null
}

const deleteSurvey = createSlice({
    name: 'meeting/survey/:id',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IDeleteSurveyPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const deleteSurveyActions = deleteSurvey.actions
export default deleteSurvey.reducer
