import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IDeleteTablePayload extends ISagaCb {
    tableId: string
}

export interface IDeleteTableState {
    status: string
    statusItems: string[]
    data: any
    errors: any
}

const initialState: IDeleteTableState = {
    status: STATUS.IDLE,
    statusItems: [],
    data: null,
    errors: null
}

const deleteTable = createSlice({
    name: 'table/delete',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IDeleteTablePayload>) {
            state.status = STATUS.RUNNING
            state.statusItems = [...state.statusItems, action.payload.tableId]
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.response
            state.statusItems = state.statusItems.filter(item => item !== action.payload.tableId)
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload.error
            state.statusItems = state.statusItems.filter(item => item !== action.payload.tableId)
        }
    }
})

export const deleteTableActions = deleteTable.actions
export default deleteTable.reducer
