import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRejectMeetingRequestByIDPayload } from '@app-store/slices/meetingRequests/rejectById'

interface TaskAction extends Action {
    payload: IRejectMeetingRequestByIDPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingRequests.rejectByID

function* rejectMeetingRequestByIDSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/meeting/meeting-requests/reject/${payload.requestId}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload?.sagaCB?.onError(error)
    }
}

function* rejectMeetingRequestByIDSaga(): any {
    yield takeLatest<TaskAction>(onRequest, rejectMeetingRequestByIDSagaTask)
}

export default rejectMeetingRequestByIDSaga
