import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IVerificationValidatePayload extends ISagaCb {
    id: string
    data: {
        code: number
    }
}

export interface IVerificationValidateState {
    status: string
    data: any
    errors: any
}

const initialState: IVerificationValidateState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const verificationValidateSlice = createSlice({
    name: '/notifications/verification-validate',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IVerificationValidatePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const verificationValidateActions = verificationValidateSlice.actions
export default verificationValidateSlice.reducer
