import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IVerificationValidatePayload } from '@app-store/slices/notifications/verificationValidate'

interface TaskAction extends Action {
    payload: IVerificationValidatePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.notification.verificationValidate

function* verificationValidateSagaTask({ payload }) {
    try {
        const response = yield axios.patch(`/notifications/verification/validate/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload.sagaCB?.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload?.sagaCB?.onError(error)
    }
}

function* verificationValidateSaga(): any {
    yield takeLatest<TaskAction>(onRequest, verificationValidateSagaTask)
}

export default verificationValidateSaga
