import { LOCALSTORAGE_KEYS } from '@features/General'
import { ability, defineRulesFor } from '@ability/index'
import store from '@app-store/store'
import { rootActions } from '@app-store/slices'

export const applyPermissions = async (p: any) => {
    const contactInfo: any = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.CONTACT_INFO))
    const permissions = p || contactInfo?.role
    if (permissions) {
        store.dispatch(rootActions.ui.companyProfile.setPermissionApplied(false))
        await ability.update(defineRulesFor(permissions))
        store.dispatch(rootActions.ui.companyProfile.setPermissionApplied(true))
    }
}
