import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IMeetingRequestPayloadState } from '@app-store/slices/meetingStatistics/meetingRequest'

interface TaskAction extends Action {
    payload: IMeetingRequestPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingStatistics.meetingRequest

function* meetingRequestSagaTask({ payload }: TaskAction) {
    const params: any = {}
    if (payload.query) {
        params.query = payload.query
    }
    try {
        const response = yield axios.get(`/meeting/stats/meeting-request`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* meetingRequestSaga(): any {
    yield takeLatest<TaskAction>(onRequest, meetingRequestSagaTask)
}

export default meetingRequestSaga
