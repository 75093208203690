import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ISelectCompaniesByTokenPayload extends ISagaCb {
    token?: string
    data: {
        selectedCompanies?: string[]
        rejectedCompanies?: string[]
        selectedSlots?: string[]
    }
}

export interface ISelectCompaniesByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: ISelectCompaniesByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const selectCompaniesByToken = createSlice({
    name: 'sharedList/select-companies-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ISelectCompaniesByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const selectCompaniesByTokenActions = selectCompaniesByToken.actions
export default selectCompaniesByToken.reducer
