import createRatingSaga from './createRatingSaga'
import editRatingSaga from './editRatingSaga'
import deleteRatingSaga from './deleteRatingSaga'
import listRatingSaga from './listRatingSaga'
import oneRatingSaga from './oneRatingSaga'
import notHappenedSaga from './notHappenedSaga'
import createRatingByTokenSaga from './createRatingByTokenSaga'
import editRatingByTokenSaga from './editRatingByTokenSaga'
import notHappenedByTokenSaga from './notHappenedByTokenSaga'
const momentumSagas = [
    createRatingSaga(),
    editRatingSaga(),
    deleteRatingSaga(),
    listRatingSaga(),
    oneRatingSaga(),
    notHappenedSaga(),
    createRatingByTokenSaga(),
    editRatingByTokenSaga(),
    notHappenedByTokenSaga()
]

export default momentumSagas
