import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IConnectPayload extends ISagaCb {
    data: {
        name: string
        type: string
        code: string
    }
}

export interface IConnectState {
    status: string
    data: any
    errors: any
}

const initialState: IConnectState = {
    status: STATUS.IDLE,
    data: {},
    errors: null
}

const connect = createSlice({
    name: 'crm/connect',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IConnectPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const connectActions = connect.actions
export default connect.reducer
