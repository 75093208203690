import React from 'react'
import ConfigProvider from 'antd/lib/config-provider'

interface IProps {}

const IntlWrapper: React.FC<IProps> = ({ children }) => {
    return (
        <ConfigProvider>
            <>{children}</>
        </ConfigProvider>
    )
}

export default IntlWrapper
