import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { SubscriptionPaymentMethods } from '@features/Settings/Account/Subscription'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateSubscriptionPayload extends ISagaCb {
    data: {
        order: string
        method: string
        billingDetails?: {
            companyName: string
            billingEmail: string
            billingAddress: string
            city: string
            zipCode: string
            country: string
        }
        promoCode?: string
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createSubscriptionPayment = createSlice({
    name: 'subscription-payment/create',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateSubscriptionPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createSubscriptionPaymentActions = createSubscriptionPayment.actions
export default createSubscriptionPayment.reducer
