import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface ICreateHallPayload extends ISagaCb {
    data: {
        name: string
        address: string
        notes: string
        fixed?: boolean
        public?: boolean
    }
}

export interface ICreateHallState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateHallState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createHall = createSlice({
    name: 'hall/create',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateHallPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createHallActions = createHall.actions
export default createHall.reducer
