import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICancelOrderPayload } from '@app-store/slices/subscriptionOrder/cancel'
import { SubscriptionOrderStatus } from '@features/Settings/Subscriptions/Order'

interface TaskAction extends Action {
    payload: ICancelOrderPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.orders.cancel
const { onEditRecordInList } = rootActions.orders.list

function* cancelOrderTask({ payload }) {
    try {
        const response = yield axios.patch(`/subscriptions/orders/cancel/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            yield put(
                onEditRecordInList({
                    id: payload.id,
                    data: { status: SubscriptionOrderStatus.CANCELLED }
                })
            )
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* cancelOrderSaga(): any {
    yield takeLatest<TaskAction>(onRequest, cancelOrderTask)
}

export default cancelOrderSaga
