import { createSlice } from '@reduxjs/toolkit'

export interface IModuleDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
}

const initialState: IModuleDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null
}

const moduleSlice = createSlice({
    name: 'ui/module-list',
    initialState,
    reducers: {
        onChangePagination(state, action) {
            state.pagination = { ...state.pagination, ...action.payload }
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        }
    }
})

export const moduleActions = moduleSlice.actions
export default moduleSlice.reducer
