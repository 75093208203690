import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ImportUrlRequestType = {
    id: string
    downloadQuick?: boolean
}

export interface IGetPreSignedImportUrlState {
    status: string
    data: {
        file: string
        downloadURL: string
    }
    errors: any
}

const initialState: IGetPreSignedImportUrlState = {
    status: STATUS.IDLE,
    data: {
        file: '',
        downloadURL: ''
    },
    errors: null
}

const getPreSignedImportUrlSlice = createSlice({
    name: 'hub/data-import/get-presigned-import-URL',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ImportUrlRequestType>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getPreSignedImportUrlActions = getPreSignedImportUrlSlice.actions
export default getPreSignedImportUrlSlice.reducer
