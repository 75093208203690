import { createSlice } from '@reduxjs/toolkit'
import { AnyCnameRecord } from 'dns'

export interface IOtpLoginDataItems {
    seconds: number
    email: string
    cognitoUser: AnyCnameRecord
}

const initialState: IOtpLoginDataItems = {
    seconds: 60,
    email: '',
    cognitoUser: null
}

const otpLoginSlice = createSlice({
    name: 'ui/otp-login',
    initialState: initialState,
    reducers: {
        set(state, action) {
            state[action.payload.type] = action.payload.value
        },
        reset(state) {
            Object.assign(state, initialState)
        },
        reduceSeconds(state) {
            const newSeconds = state.seconds > 0 ? state.seconds - 1 : 0
            state.seconds = newSeconds
        },
        resetSeconds(state) {
            state.seconds = 60
        },
        setLoggedInInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const key of objectkeys) {
                state[key] = action.payload[key]
            }
        }
    }
})

export const otpLoginActions = otpLoginSlice.actions
export default otpLoginSlice.reducer
