import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/quotations/list'
import createReducer, { createActions } from '@app-store/slices/quotations/create'
import sendQuotationsReducer, { sendActions } from '@app-store/slices/quotations/send'
import createSendQuotationsReducer, { createSendActions } from '@app-store/slices/quotations/createSend'
import declineReducer, { declineActions } from '@app-store/slices/quotations/decline'
import closeReducer, { closeActions } from '@app-store/slices/quotations/close'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/quotations/staticEnums'
import oneQuotationReducer, { oneQuotationActions } from '@store/slices/quotations/oneQuotation'
import createCustomReducer, { createCustomActions } from '@store/slices/quotations/createCustom'
import getListStatisticsReducer, { getListStatisticsActions } from '@app-store/slices/quotations/getListStatistics'

export const quotationsReducer = combineReducers({
    list: listReducer,
    send: sendQuotationsReducer,
    createSend: createSendQuotationsReducer,
    create: createReducer,
    decline: declineReducer,
    close: closeReducer,
    staticEnums: staticEnumsReducer,
    one: oneQuotationReducer,
    createCustom: createCustomReducer,
    getListStatistics: getListStatisticsReducer
})

export const quotationsActions = {
    list: listActions,
    send: sendActions,
    createSend: createSendActions,
    create: createActions,
    decline: declineActions,
    close: closeActions,
    staticEnums: staticEnumsActions,
    one: oneQuotationActions,
    createCustom: createCustomActions,
    getListStatistics: getListStatisticsActions
}
