import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.meetingRequests.list

function* listMeetingRequestSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/meeting/meeting-requests`, { params })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            if (payload?.sagaCB?.onError) payload?.sagaCB?.onError()
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listMeetingRequestSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listMeetingRequestSagaTask)
}

export default listMeetingRequestSaga
