import { createSlice } from '@reduxjs/toolkit'
import { listUIReducers } from '../reducers'
import { listUIInitialState } from '@utils/constants/requestConstants'

const linkedInAccountSlice = createSlice({
    name: 'ui/linkedin-account-list',
    initialState: {
        ...listUIInitialState,
        newLinkedInAccountModal: {
            isOpen: false
        }
    },
    reducers: {
        ...listUIReducers,
        onChangeNewLinkedInAccountModal(state, action) {
            state.newLinkedInAccountModal.isOpen = action.payload
        }
    }
})

export const linkedInAccountActions = linkedInAccountSlice.actions
export default linkedInAccountSlice.reducer
