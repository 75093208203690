import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationType } from '@typings/requestPayloads'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers, manipulateListReducers } from '../reducers'

export interface ICompanyInternalPublishedListSearchQueryPayload {
    pagination?: PaginationType
    userType?: string
}

const internalPublishedListSearchSlice = createSlice({
    name: 'hub/companies/internal-published-list-search',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICompanyInternalPublishedListSearchQueryPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        ...manipulateListReducers
    }
})

export const internalPublishedListSearchActions = internalPublishedListSearchSlice.actions
export default internalPublishedListSearchSlice.reducer
