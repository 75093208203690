import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditNotificationPayload } from '@app-store/slices/eventSettings/editNotification'

interface TaskAction extends Action {
    payload: IEditNotificationPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.editNotification

function* editNotificationSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/event/settings/${payload.settingId}/notification`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.Event?.Notification_setting_updated_successfully ||
                    'Notification setting updated successfully.',
                duration: 5000
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editNotificationSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editNotificationSagaTask)
}

export default editNotificationSaga
