import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { ISendTestAgendasPayload } from '@app-store/slices/reporting/sendTestAgendas'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: ISendTestAgendasPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.reporting.sendTestAgendas

function* sendTestAgendasSagaTask({ payload }: TaskAction) {
    try {
        const params: any = {}
        if (payload.companies) {
            params.companies = payload.companies
        }
        if (payload.status) {
            params.status = payload.status
        }
        if (payload.participants) {
            params.participants = payload.participants
        }
        if (payload.meetingDate) {
            params.meetingDate = payload.meetingDate
        }
        if (payload.timeShift) {
            params.timeShift = payload.timeShift
        }
        params.email = payload.email
        const response = yield axios.post(`/reporting/agendas/send-test`, params)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield payload?.sagaCB?.onError()
        yield put(onFailure(error))
    }
}

function* sendTestAgendasSaga(): any {
    yield takeLatest<TaskAction>(onRequest, sendTestAgendasSagaTask)
}

export default sendTestAgendasSaga
