import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IMatchListStatsPayloadState } from '@app-store/slices/companyStatistics/matchListStats'

interface TaskAction extends Action {
    payload: IMatchListStatsPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.companyStatistics.matchListStats

function* matchListStatsSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/companies/stats/match-list/${payload.matchListId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload.sagaCB?.onError === 'function') {
            payload.sagaCB?.onError()
        }
    }
}

function* matchListStatsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, matchListStatsSagaTask)
}

export default matchListStatsSaga
