import { Action } from 'redux'
import { ICheckEventPublishabilityPayload } from '@app-store/slices/events/checkEventPublishability'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { put, takeLatest } from 'redux-saga/effects'
interface TaskAction extends Action {
    payload: ICheckEventPublishabilityPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.events.checkEventPublishability

function* checkEventPublishabilitySagaTask({ payload }) {
    try {
        const response = yield axios.get(`/event/profile`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}
function* checkEventPublishabilitySaga(): any {
    yield takeLatest<TaskAction>(onRequest, checkEventPublishabilitySagaTask)
}

export default checkEventPublishabilitySaga
