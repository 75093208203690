import getMessagesSaga from './getMessages'
import registerSaga from './register'
import sendMessageSaga from './sendMessage'
import postMessageSaga from './postMessage'
import createDirectMessageSaga from './createDirectMessage'
import deleteDirectMessageSaga from './deleteDirectMessage'
import listDirectMessageSaga from './listDirectMessage'
import getUsersSaga from './getUsers'
import loginSaga from './login'
import ignoreUserSaga from './ignoreUser'
import uploadFileSaga from './uploadFile'

const chatSagas = [
    getMessagesSaga(),
    registerSaga(),
    sendMessageSaga(),
    postMessageSaga(),
    createDirectMessageSaga(),
    deleteDirectMessageSaga(),
    listDirectMessageSaga(),
    getUsersSaga(),
    loginSaga(),
    ignoreUserSaga(),
    uploadFileSaga()
]

export default chatSagas
