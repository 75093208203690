import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/transactions/list'

export const transactionReducers = combineReducers({
    list: listReducer
})

export const transactionActions = {
    list: listActions
}
