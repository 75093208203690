import { combineReducers } from 'redux'
import createReducer, { createLinkedInAccountActions } from '@app-store/slices/linkedInAccount/create'
import otpReducer, { otpLinkedInAccountActions } from '@app-store/slices/linkedInAccount/otp'
import listReducer, { listActions } from '@app-store/slices/linkedInAccount/list'
import deleteReducer, { deleteLinkedInAccountActions } from '@app-store/slices/linkedInAccount/delete'
export const linkedInAccountReducers = combineReducers({
    create: createReducer,
    otp: otpReducer,
    list: listReducer,
    delete: deleteReducer
})

export const linkedInAccountActions = {
    create: createLinkedInAccountActions,
    otp: otpLinkedInAccountActions,
    list: listActions,
    delete: deleteLinkedInAccountActions
}
