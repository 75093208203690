import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { CorrectionURLRequestType } from '@app-store/slices/dataImport/getPreSignedCorrectionURL'

interface TaskAction extends Action {
    payload: CorrectionURLRequestType
}

const { onRequest, onSuccess, onFailure } = rootActions.dataImport.getPreSignedCorrectionURL

function* getPreCorrectionSignedURLSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/companies/imports/correction-excel/${payload.id}`)
        if (response.success) {
            if (payload.downloadQuick) {
                const link = document.createElement('a')
                link.setAttribute('download', response.result.file)
                link.href = response.result.downloadURL
                document.body.appendChild(link)
                link.click()
            }
            yield put(onSuccess(response.result))
        } else {
            throw 'Some thing wrong.'
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getPreCorrectionSignedURLSaga(): any {
    yield takeLatest<TaskAction>(onRequest, getPreCorrectionSignedURLSagaTask)
}

export default getPreCorrectionSignedURLSaga
