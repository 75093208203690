import { combineReducers } from 'redux'
import bundlesListReducer, { bundlesListActions } from '@app-store/slices/localization/bundlesList'
import keysListReducer, { keysListActions } from '@app-store/slices/localization/keysList'
import languageTypeReducer, { languageTypesActions } from '@app-store/slices/localization/languageTypes'
import localsReducer, { localsActions } from '@app-store/slices/localization/locals'
import updateLanguageReducer, { updateLanguageActions } from '@app-store/slices/localization/updateLanguage'

export const localizationReducers = combineReducers({
    bundlesList: bundlesListReducer,
    keysList: keysListReducer,
    languageType: languageTypeReducer,
    locals: localsReducer,
    updateLanguage: updateLanguageReducer
})

export const localizationActions = {
    bundlesList: bundlesListActions,
    keysList: keysListActions,
    languageTypes: languageTypesActions,
    locals: localsActions,
    updateLanguage: updateLanguageActions
}
