import { combineReducers } from 'redux'
import generateQRReducer, { generateQRActions } from '@app-store/slices/qr/generateQR'

export const qrReducers = combineReducers({
    generateQR: generateQRReducer
})

export const qrActions = {
    generateQR: generateQRActions
}
