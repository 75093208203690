import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAddMeetingPayload } from '@app-store/slices/meetings/addMeeting'
// import { history } from '@components/App'
// import routes from '@utils/constants/routes'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IAddMeetingPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.addMeeting

function* addMeetingSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/add-meeting`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (response?.result?._id) {
                if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
                const { data: i18n } = yield select((store: RootState) => store.localization.locals)
                notification.success({
                    message: i18n?.Meeting?.Meeting_added_successfully || 'Meeting added successfully.'
                })
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* addMeetingSaga(): any {
    yield takeLatest<TaskAction>(onRequest, addMeetingSagaTask)
}

export default addMeetingSaga
