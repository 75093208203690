import { combineReducers } from 'redux'
import { eventDetailsReducer, eventDetailsActions } from './eventDetails'

export const organizerReducer = combineReducers({
    eventDetails: eventDetailsReducer
})

export const organizerActions = {
    eventDetails: eventDetailsActions
}
