import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/categories/list'
import listByLevelReducer, { listByLevelActions } from '@app-store/slices/categories/listByLevel'
import listBySearchReducer, { listBySearchActions } from '@app-store/slices/categories/listBySearch'
import createCategoryReducer, { createCategoryActions } from '@app-store/slices/categories/createCategory'
import editCategoryReducer, { editCategoryActions } from '@app-store/slices/categories/editCategory'
import listByTokenReducer, { listByTokenActions } from '@app-store/slices/categories/listByToken'

export const categoriesReducer = combineReducers({
    list: listReducer,
    listByLevel: listByLevelReducer,
    listBySearch: listBySearchReducer,
    createCategory: createCategoryReducer,
    editCategory: editCategoryReducer,
    listByToken: listByTokenReducer
})

export const categoriesActions = {
    list: listActions,
    listByLevel: listByLevelActions,
    listBySearch: listBySearchActions,
    createCategory: createCategoryActions,
    editCategory: editCategoryActions,
    listByToken: listByTokenActions
}
