import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/feature/list'
import editReducer, { editFeatureActions } from '@app-store/slices/feature/edit'
import deleteReducer, { deleteFeatureActions } from '@app-store/slices/feature/delete'
import createReducer, { createFeatureActions } from '@app-store/slices/feature/create'
import oneFeatureReducer, { oneFeatureActions } from '@app-store/slices/feature/one'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/feature/staticEnums'
export const featureReducer = combineReducers({
    list: listReducer,
    edit: editReducer,
    create: createReducer,
    delete: deleteReducer,
    one: oneFeatureReducer,
    staticEnums: staticEnumsReducer
})

export const featureActions = {
    list: listActions,
    edit: editFeatureActions,
    create: createFeatureActions,
    delete: deleteFeatureActions,
    one: oneFeatureActions,
    staticEnums: staticEnumsActions
}
