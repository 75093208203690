import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.meetings.list

function* listMeetingSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/meeting`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload?.sagaCB?.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listMeetingSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listMeetingSagaTask)
}

export default listMeetingSaga
