import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeletePropertyByTokenPayload } from '@app-store/slices/properties/deleteByToken'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IDeletePropertyByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.properties.deleteByToken

function* deletePropertyByTokenSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete(`/attachments/properties/meeting-notes/${payload.propertyId}`, {
            data: {
                token: payload.token
            }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess()
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            yield notification.success({
                message: i18n?.Matching?.Property_deleted_successfully || 'Property deleted successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deletePropertyByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deletePropertyByTokenSagaTask)
}

export default deletePropertyByTokenSaga
