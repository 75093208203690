import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IMoveToCompanyPayload } from '@store/slices/contacts/moveToCompany'

interface TaskAction extends Action {
    payload: IMoveToCompanyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.contacts.moveToCompany
function* moveToCompanySagaTask({ payload }) {
    try {
        const response = yield axios.patch(`/companies/contacts/${payload.id}/move/${payload.companyId}`)
        if (response.success) {
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload.sagaCB?.onError) {
            yield payload.sagaCB?.onError()
        }
    }
}

function* moveToCompanySaga(): any {
    yield takeLatest<TaskAction>(onRequest, moveToCompanySagaTask)
}

export default moveToCompanySaga
