import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IGetOneWishListPayload {
    id?: string
}
export interface IOneWishListState {
    status: typeof STATUS[keyof typeof STATUS]
    data: {
        wishedCompanies: string[]
    } & any
    errors: any
}

const initialState: IOneWishListState = {
    status: STATUS.IDLE,
    data: {
        wishedCompanies: []
    },
    errors: null
}

const oneWishListSlice = createSlice({
    name: 'wish-lists/one-wishList',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetOneWishListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onReset(state) {
            state.status = STATUS.ERROR
            state.data = null
            state.errors = null
        },
        setNewWishedCompanies(state, action) {
            if (state.data?.wishedCompanies) {
                state.data.wishedCompanies = state.data.wishedCompanies.filter(
                    item => action.payload.wishedCompanies.findIndex(x => x === item._id) > -1
                )
            }
        }
    }
})

export const oneWishListActions = oneWishListSlice.actions
export default oneWishListSlice.reducer
