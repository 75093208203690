import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import notification from 'antd/lib/notification'
import axios from '@utils/request'
import { ILinkedinEnrichPayload } from '@app-store/slices/reporting/linkedinEnrich'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: ILinkedinEnrichPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.reporting.linkedinEnrich

function* linkedinEnrichSagaTask({ payload }: TaskAction) {
    const { data: i18n } = yield select((store: RootState) => store.localization.locals)
    try {
        const response = yield axios.post(`/reporting/linkedin-scrape`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess()
            notification.success({
                message: i18n?.TradeHub?.Data_enriched_successfully || 'Data has been enriched successfully.'
            })
        } else {
            notification.error({
                message:
                    response?.result?.message ||
                    i18n?.TradeHub?.Something_wrong_try_again_later ||
                    'Something went wrong. Please try again later.'
            })
            yield put(onFailure(''))
            throw new Error('')
        }
    } catch (error) {
        notification.error({
            message: error || i18n?.General?.Something_went_wrong || 'Something went wrong.'
        })
        if (typeof payload?.sagaCB?.onError === 'function') {
            yield payload?.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* linkedinEnrichSaga(): any {
    yield takeLatest<TaskAction>(onRequest, linkedinEnrichSagaTask)
}

export default linkedinEnrichSaga
