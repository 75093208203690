import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEmailStatusPayload } from '@app-store/slices/campaigns/emailStatus'

interface TaskAction extends Action {
    payload: IEmailStatusPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.emailStatus

function* emailStatusSagaTask({ payload }) {
    try {
        const params: any = {
            query: payload.query
        }

        const response = yield axios.get(`/notifications/emails`, {
            params
        })
        if (response.success) {
            yield payload?.sagaCB.onSuccess(response.result.data)
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* emailStatusSaga(): any {
    yield takeLatest<TaskAction>(onRequest, emailStatusSagaTask)
}

export default emailStatusSaga
