import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditStepPayload } from '@app-store/slices/campaigns/editStep'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IEditStepPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.editStep
const { onChangeStep } = rootActions.ui.emailCampaign

function* editStepSagaTask({ payload }) {
    try {
        const response = yield axios.put(
            `/notifications/campaigns/${payload.campaignId}/step/${payload.stepId}`,
            payload.data
        )
        if (response.success) {
            onChangeStep({
                index: payload.index,
                data: { hasChanged: false }
            })
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Settings?.Step_updated_successfully || 'Step updated successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload?.sagaCB?.onError === 'function') {
            payload?.sagaCB?.onError()
        }
    }
}

function* editStepSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editStepSagaTask)
}

export default editStepSaga
