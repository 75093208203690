import listSaga from './listSaga'
import listSearch from './listSearchSaga'
import markAsReadSaga from './markAsReadSaga'
import sendNotificationSaga from './SendNotificationSaga'
import staticEnumsSaga from './staticEnumsSaga'
import verificationInitiateSaga from './verificationInitiateSaga'
import verificationValidateSaga from './verificationValidateSaga'

const notificaionsSaga = [
    listSaga(),
    listSearch(),
    markAsReadSaga(),
    staticEnumsSaga(),
    sendNotificationSaga(),
    verificationInitiateSaga(),
    verificationValidateSaga()
]

export default notificaionsSaga
