import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'

interface TaskAction extends Action {
    payload: {
        id: string
    }
}

const { onRequest, onSuccess, onFailure } = rootActions.contacts.assignedList

// This saga is for getting specific assigned list contacts
function* assignedListSagaTask({ payload }) {
    const params = yield getParams(payload)
    try {
        const response = yield axios.get(`/companies/contacts/assigned-list`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            yield put(onFailure(''))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* assignedListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, assignedListSagaTask)
}

export default assignedListSaga
