import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IFindUserPayload extends ISagaCb {
    data: {
        email: any
        eventId?: string
        eventDomain?: string
        captcha?: string
    }
}

const initialState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const findUserSlice = createSlice({
    name: 'hub/user/find-user',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IFindUserPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onChangeData(state, action) {
            const data = action.payload
            for (const key in data) {
                state.data[key] = data[key]
            }
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const findUserActions = findUserSlice.actions
export default findUserSlice.reducer
