import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICompaniesStatisticsPayload extends ISagaCb {}

export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const statisticsSlice = createSlice({
    name: 'hub/companies/statistics',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICompaniesStatisticsPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onChangeItemCount(state, action) {
            state.data[action.payload.key] = action.payload.value
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const statisticsActions = statisticsSlice.actions
export default statisticsSlice.reducer
