import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditMatchPayload } from '@app-store/slices/eventSettings/editMatch'

interface TaskAction extends Action {
    payload: IEditMatchPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.editMatch

function* editMatchSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/event/settings/${payload.settingId}/match`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Match_setting_updated_successfully || 'Match setting updated successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editMatchSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editMatchSagaTask)
}

export default editMatchSaga
