import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditDefaultVisibilityPayload } from '@app-store/slices/eventSettings/editDefaultVisibility'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IEditDefaultVisibilityPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.editDefaultVisibility

function* editDefaultVisibilityByIdSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/event/settings/${payload.settingId}/default-visibility`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.Event?.Visibility_setting_updated_successfully || 'Visibility setting updated successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editDefaultVisibilityByIdSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editDefaultVisibilityByIdSagaTask)
}

export default editDefaultVisibilityByIdSaga
