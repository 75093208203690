import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const transactionsSlice = createSlice({
    name: 'ui/transactions',
    initialState: { ...listUIInitialState },
    reducers: listUIReducers
})

export const transactionsActions = transactionsSlice.actions
export default transactionsSlice.reducer
