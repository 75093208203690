import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateOrderPayload } from '@app-store/slices/subscriptionOrder/create'
import notification from 'antd/lib/notification'
import { SubscriptionOrderStatus } from '@features/Settings/Subscriptions/Order'

interface TaskAction extends Action {
    payload: ICreateOrderPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.orders.create

function* createOrderSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/subscriptions/orders`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            if (response.result.status === SubscriptionOrderStatus.WAITING) {
                notification.info({
                    message:
                        'You request for upgrade has been submitted and will be processed by the administrator sooner.'
                })
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createOrderSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createOrderSagaTask)
}

export default createOrderSaga
