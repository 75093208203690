import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onEditRequest, onEditFailure } = rootActions.companies.oneCompany
const { setEditCompanyProfileModal, setCategoryCompanyProfileModal } = rootActions.ui.companyProfile

function* editOneSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/${payload.id}`, { ...payload.data })
        if (response.success) {
            yield put(onRequest({ companyId: payload.id }))
            yield put(setEditCompanyProfileModal(false))
            yield put(setCategoryCompanyProfileModal(false))
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload.sagaCB.onSuccess(response.result)
            }
        } else {
            if (typeof payload.sagaCB?.onError === 'function') {
                payload.sagaCB?.onError(response)
            }
        }
    } catch (error) {
        yield put(onEditFailure(error))
        if (typeof payload.sagaCB?.onError === 'function') {
            payload.sagaCB?.onError(error)
        }
    }
}

function* editOneSaga(): any {
    yield takeLatest<TaskAction>(onEditRequest, editOneSagaTask)
}

export default editOneSaga
