import { createSlice } from '@reduxjs/toolkit'
import { ICreateQuotationDataPayload } from '@store/slices/quotations/create'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const initialData: ICreateQuotationDataPayload = {
    _id: '',
    title: '',
    condition: '',
    deliveryTerms: 'EXW',
    deliveryDate: '',
    expireDate: '',
    discount: null,
    fee: null,
    tax: null,
    description: '',
    purchaseTerm: '',
    products: [],
    services: [],
    receiverCompanies: [],
    receiverContacts: [],
    costs: [],
    totalAmount: 0,
    finalAmount: 0,
    favouriteContacts: []
}

const quotationsSlice = createSlice({
    name: 'ui/quotation',
    initialState: { ...listUIInitialState, data: initialData },
    reducers: {
        addItems(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.data[item] = action.payload[item]
            }
        },
        addProduct(state, action) {
            state.data.products = [action.payload]
        },
        removeProduct(state, action) {
            const newArr = state.data.products.filter(i => i.product !== action.payload._id)
            state.data.products = newArr
        },
        editProduct(state, action) {
            const newArr = state.data.products
            const { index, key, value } = action.payload
            newArr[index][key] = value
            state.data.products = newArr
        },
        addService(state, action) {
            state.data.services = [action.payload]
        },
        removeService(state, action) {
            const newArr = state.data.services.filter(i => i.service !== action.payload._id)
            state.data.services = newArr
        },
        changeCompanyContacts(state, action) {
            let newState = state.data.receiverContacts
            const contacts = newState.find(item => item.company === action.payload.company)
            if (contacts) {
                contacts.contacts = action.payload.contacts
            } else {
                newState = [...newState, action.payload]
            }
            state.data.receiverContacts = newState
        },
        editService(state, action) {
            const newArr = state.data.services
            const { index, key, value } = action.payload
            newArr[index][key] = value
            state.data.services = newArr
        },
        addCompay(state, action) {
            state.data.receiverCompanies = [...state.data.receiverCompanies, action.payload]
        },
        removeCompay(state, action) {
            const newArr = state.data.receiverCompanies.filter(i => i._id !== action.payload)
            state.data.receiverCompanies = newArr
        },
        resetItems(state) {
            state.data = initialData
        },
        ...listUIReducers
    }
})

export const quotationsActions = quotationsSlice.actions
export default quotationsSlice.reducer
