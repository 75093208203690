import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IAllMatchListsStatsPayloadState extends ISagaCb {
    query?: any
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const allMatchListsStatsSlice = createSlice({
    name: 'company-statistics/all-match-list-stats',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IAllMatchListsStatsPayloadState>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const allMatchListsStatsActions = allMatchListsStatsSlice.actions
export default allMatchListsStatsSlice.reducer
