import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

const { onRequest, onSuccess, onFailure } = rootActions.eventSubscriptions.getActive

function* getActiveSagaTask() {
    try {
        const response = yield axios.get('subscriptions/event-subscriptions/active')

        if (response.success) {
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getActiveSaga(): any {
    yield takeLatest(onRequest, getActiveSagaTask)
}

export default getActiveSaga
