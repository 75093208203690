import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const editListSlice = createSlice({
    name: 'ui/published-companies-list',
    initialState: { ...listUIInitialState, populates: ['managers', 'editors'] },
    reducers: listUIReducers
})

export const editListActions = editListSlice.actions
export default editListSlice.reducer
