import createSaga from './createSaga'
import listSaga from './listSaga'
import oneSaga from './oneSaga'
import editSaga from './editSaga'
import publishListSaga from './publishListSaga'
import internalPublishListSaga from './internalPublishListSaga'
import prePublishListSaga from './prePublishListSaga'
import deleteEditListSaga from './deleteSaga'
import publishContactListSaga from './publishContactListSaga'
import internalPublishContactListSaga from './internalPublishContactListSaga'
const editListSagas = [
    createSaga(),
    listSaga(),
    oneSaga(),
    editSaga(),
    publishListSaga(),
    internalPublishListSaga(),
    prePublishListSaga(),
    deleteEditListSaga(),
    publishContactListSaga(),
    internalPublishContactListSaga()
]

export default editListSagas
