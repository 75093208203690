import { createSlice } from '@reduxjs/toolkit'
import store from 'store'
import { STATUS, LOCALSTORAGE_KEYS } from '@features/General'
const onboarding = store.get(LOCALSTORAGE_KEYS.ONBOARDING_ORGANIZER)
const initialState = {
    status: STATUS.IDLE,
    details: {
        eventId: null,
        name: '',
        subdomain: '',
        countryId: '',
        description: '',
        cityId: '',
        eventTypeId: '',
        attendees: [10, 100000],
        exhibitors: [1, 10000],
        sponsors: [1, 1000],
        sponsors_applicable: true,
        timestamps: [],
        timezone: ''
    },
    brand: {
        logoImage: '',
        bgImage: '',
        bgPattern: '',
        colorCode: '',
        paletteColor: '',
        logoColor: ''
    },
    categories: [],
    images: {}
}

const onboardingActionsSlice = createSlice({
    name: 'hub/companies/onboarding',
    initialState: onboarding || initialState,
    reducers: {
        setDetails(state, action) {
            state['details'][action.payload.type] = action.payload.value
        },
        setCategories(state, action) {
            state['categories'] = [...action.payload]
        },
        setAllDetails(state, action) {
            state['details'] = { ...action.payload.value }
        },
        setBrand(state, action) {
            state['brand'][action.payload.type] = action.payload.value
        },
        setImage(state, action) {
            state['images'][action.payload.type] = action.payload.value
        },
        reset(state) {
            store.remove(LOCALSTORAGE_KEYS.ONBOARDING_ORGANIZER)
            Object.assign(state, initialState)
        },
        onRequestDetails(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccessDetails(state, action) {
            state.status = STATUS.READY
            state = initialState
        },
        onFailureDetails(state, action) {
            state.status = STATUS.ERROR
        },
        onRequestBrand(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccessBrand(state, action) {
            state.status = STATUS.READY
            state = initialState
        },
        onFailureBrand(state, action) {
            state.status = STATUS.ERROR
        },
        onRequestCategory(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccessCategory(state) {
            state.status = STATUS.READY
            state = initialState
        },
        onFailureCategory(state) {
            state.status = STATUS.ERROR
        }
    }
})

export const onboardingActions = onboardingActionsSlice.actions
export default onboardingActionsSlice.reducer
