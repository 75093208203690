import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IActiveFeaturesPayload } from '@app-store/slices/eventSettings/activeFeatures'
import { LOCALSTORAGE_KEYS } from '@features/General'

interface TaskAction extends Action {
    payload: IActiveFeaturesPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.activeFeatures

function* getActiveFeaturesTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get('/subscriptions/listener/active-features')
        if (response.success) {
            yield put(onSuccess(response.result))
            localStorage.setItem(LOCALSTORAGE_KEYS.ACTIVE_FEATURES, JSON.stringify(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getActiveFeatures(): any {
    yield takeLatest<TaskAction>(onRequest, getActiveFeaturesTask)
}

export default getActiveFeatures
