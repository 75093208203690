import { createSlice } from '@reduxjs/toolkit'

export interface IContactScanDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    sort?: string
    projection?: any
    needPopulate: boolean
    populates?: any
}

const initialState: IContactScanDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    sort: '-createdAt',
    projection: [],
    needPopulate: false,
    populates: [{ path: 'companyId' }]
}

const contactScanSlice = createSlice({
    name: 'ui/contact-scans',
    initialState,
    reducers: {
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        }
    }
})

export const contactScanActions = contactScanSlice.actions
export default contactScanSlice.reducer
