import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.editItpByToken
const { setIsEditItpProfile } = rootActions.ui.itp
function* editItpByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.patch(`/companies/itps/by-token/${payload.token}`, { ...payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(setIsEditItpProfile(true))
            yield payload?.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editItpByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editItpByTokenSagaTask)
}

export default editItpByTokenSaga
