import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IBulkMatchListPayload } from '@app-store/slices/sharedLists/bulkMatchList'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IBulkMatchListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.bulkMatchList

function* bulkMatchListSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/companies/shared-lists/bulk-match-list`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Matching?.Match_list_shared_successfully || 'Match list shared successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* bulkMatchListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, bulkMatchListSagaTask)
}

export default bulkMatchListSaga
