import createSaga from './createSaga'
import bulkMatchListSaga from './bulkMatchListSaga'
import deleteSaga from './deleteSaga'
import editCompaniesSaga from './editCompaniesSaga'
import editSaga from './editSaga'
import infoByTokenSaga from './infoByTokenSaga'
import listSaga from './listSaga'
import listSearchSaga from './listSearchSaga'
import oneSharedListSaga from './oneSharedListSaga'
import listWithCompanySaga from './listWithCompanySaga'
import selectCompaniesSaga from './selectCompaniesSaga'
import rejectCompaniesSaga from './rejectCompaniesSaga'
import selectCompaniesByTokenSaga from './selectCompaniesByToken'
import changeStatusSaga from './changeStatusSaga'
import staticEnumsSaga from './staticEnumsSaga'
import companyMatchListsSaga from './companyMatchListsSaga'
import reShareSaga from './reShareSaga'
import matchListSaga from './matchListSaga'

const sharedListsSaga = [
    createSaga(),
    bulkMatchListSaga(),
    editCompaniesSaga(),
    editSaga(),
    infoByTokenSaga(),
    listSaga(),
    listSearchSaga(),
    oneSharedListSaga(),
    listWithCompanySaga(),
    selectCompaniesSaga(),
    rejectCompaniesSaga(),
    selectCompaniesByTokenSaga(),
    deleteSaga(),
    changeStatusSaga(),
    staticEnumsSaga(),
    companyMatchListsSaga(),
    reShareSaga(),
    matchListSaga()
]

export default sharedListsSaga
