import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(utc)

export const getOffsetTime = offset => {
    if (offset) {
        return offset.replace('UTC', '')
    }
    const date = dayjs().format()
    const m = date.includes('+') ? date.split('+') : date.split('-')
    return (date.includes('+') ? '+' : '-') + m[m.length - 1]
}

export const getTimezoneOffset = (timezone, offsetValue = undefined) => {
    const diff = offsetValue || dayjs().tz(timezone.toString()).utcOffset()
    let hourDiff: any = Math.floor(diff / 60)
    let minuteDiff: any = Math.floor(diff % 60)
    const hourNumber = Math.abs(hourDiff)
    const minuteNumber = Math.abs(minuteDiff)
    if (hourNumber.toString().length === 1) {
        hourDiff = (hourDiff.toString().includes('-') ? '-0' : '0') + hourNumber.toString()
    }
    if (minuteDiff === 0) {
        minuteDiff = '00'
    }

    const value =
        'UTC' +
        (hourNumber !== 0 || minuteNumber !== 0
            ? (hourDiff > 0 ? '+' : hourDiff === 0 && minuteDiff < 0 ? '-' : '') +
              hourDiff.toString() +
              ':' +
              (minuteNumber === 0 ? minuteDiff : minuteNumber.toString())
            : '')
    return value
}

export const getOffsetFromTimezone = timeShift => {
    const timezone = timeShift || getTimezoneOffset(dayjs.tz.guess())
    const modifier: any = timezone.replace('UTC', '').split(':')
    const offset = modifier[0] * 60 + (modifier[0].includes('-') ? 0 - Number(modifier[1]) : Number(modifier[1]))
    return offset || 0
}

export const getTimeStampsAsOffset = (timestamps, offset, oldOffsetTime) => {
    const newTimeStamps = []
    const format = 'YYYY-MM-DD hh:mm AZ'
    const now = dayjs().utcOffset(offset)

    let offsetTime = (oldOffsetTime ? oldOffsetTime : getTimezoneOffset(dayjs.tz.guess())).replace('UTC', '')
    if (!offsetTime) {
        offsetTime = '+00:00'
    }
    timestamps
        .filter(item => item.date && item.startTime && item.endTime)
        .sort((a, b) => {
            const getTime = item => {
                const dateString = item.date.format()
                const date = dateString.substring(0, 10)
                const time = dayjs(date + 'T' + item.startTime.format('HH:mm:ss') + offsetTime, format).utc()
                return time.format()
            }
            return getTime(a).localeCompare(getTime(b))
        })
        .forEach(item => {
            const dateString = item.date.format()
            const date = dateString.substring(0, 10)

            const utcStartTime = dayjs(date + 'T' + item.startTime.format('HH:mm:ss') + offsetTime, format).utc()
            const newStartTime = dayjs(utcStartTime, format).utcOffset(offset)
            let utcEndTime = dayjs(item.endTime.format('YYYY-MM-DDTHH:mm:ss') + offsetTime, format).utc()
            if (!item.endTime.format().includes(offsetTime) || !item.endTime.format().includes(date)) {
                utcEndTime = dayjs(date + 'T' + item.endTime.format('HH:mm:ss') + offsetTime, format).utc()
            }
            const newEndTime = dayjs(utcEndTime, format).utcOffset(offset)
            const diffDay = newEndTime.diff(newStartTime, 'day', true)
            let lastEndTime: any = ''
            let lastIndex = -1
            if (newTimeStamps.length) {
                lastIndex = newTimeStamps.length - 1
                const lastItem = newTimeStamps[lastIndex]
                const dateString = lastItem.date.format()
                const date = dateString.substring(0, 10)
                let lastOffsetTime = dateString.substring(dateString.length - 6)
                if (lastOffsetTime.includes('Z')) {
                    lastOffsetTime = '+00:00'
                }
                const utcEndTime = dayjs(
                    date + 'T' + lastItem.endTime.format('HH:mm:ss') + lastOffsetTime,
                    format
                ).utc()
                lastEndTime = dayjs(utcEndTime, format).utcOffset(offset)
            }
            if (
                newStartTime.isSame(newEndTime, 'day') ||
                (diffDay > 0 && diffDay <= 1 && newEndTime.format().includes('T00:00'))
            ) {
                if (lastEndTime && lastEndTime?.isSame(newStartTime)) {
                    newTimeStamps[lastIndex].endTime = newEndTime
                    newTimeStamps[lastIndex].disabledEndDate = now.diff(newEndTime, 'minutes') > 0
                } else {
                    newTimeStamps.push({
                        startTime: newStartTime,
                        endTime: newEndTime,
                        date: newStartTime,
                        disabled: now.diff(newStartTime, 'minutes') > 0,
                        disabledEndDate: now.diff(newEndTime, 'minutes') > 0
                    })
                }
            } else {
                const endTime = dayjs(newEndTime, format).set('hour', 0).set('minute', 0)
                if (lastEndTime && lastEndTime?.isSame(newStartTime)) {
                    newTimeStamps[lastIndex].endTime = endTime
                    newTimeStamps[lastIndex].disabledEndDate = now.diff(endTime, 'minutes') > 0
                } else {
                    if (!newStartTime.isSame(endTime)) {
                        newTimeStamps.push({
                            startTime: newStartTime,
                            endTime,
                            date: newStartTime,
                            disabled: now.diff(newStartTime, 'minutes') > 0,
                            disabledEndDate: now.diff(endTime, 'minutes') > 0
                        })
                    }
                }

                const startTime = dayjs(newEndTime, format).set('hour', 0).set('minute', 0)
                if (!startTime.isSame(newEndTime)) {
                    newTimeStamps.push({
                        startTime: startTime,
                        endTime: newEndTime,
                        date: newEndTime,
                        disabled: now.diff(startTime, 'minutes') > 0,
                        disabledEndDate: now.diff(newEndTime, 'minutes') > 0
                    })
                }
            }
        })
    return newTimeStamps
}

export const getDayName = (dayIndex: number) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    return days[dayIndex]
}

export const getStartOrEndTimeOfDate = (value = '', type = 'start') => {
    const date = value ? dayjs(value, 'YYYY-MM-DD hh:mm AZ') : dayjs()
    if (type === 'start') {
        return date.utc().set('hour', 0).set('minute', 0).set('second', 0).format()
    } else {
        return date.utc().set('hour', 23).set('minute', 59).set('second', 59).format()
    }
}

export const getTimezoneGroupByRegion = () => {
    const validCities = [
        'Baker Island',
        'Howland Island',
        'Pago Pago',
        'Honolulu',
        'Marquesas Islands',
        'Anchorage',
        'Los Angeles',
        'Denver',
        'Chicago',
        'New York',
        'Caracas',
        'La Paz',
        'Santiago',
        "St. John's",
        'Buenos Aires',
        'South Georgia and the South Sandwich Islands',
        'Azores',
        'London',
        'Lisbon',
        'Berlin',
        'Paris',
        'Cairo',
        'Johannesburg',
        'Moscow',
        'Riyadh',
        'Tehran',
        'Dubai',
        'Baku',
        'Kabul',
        'Islamabad',
        'Karachi',
        'New Delhi',
        'Colombo',
        'Kathmandu',
        'Dhaka',
        'Almaty',
        'Yangon',
        'Bangkok',
        'Jakarta',
        'Beijing',
        'Singapore',
        'Eucla',
        'Tokyo',
        'Seoul',
        'Adelaide',
        'Darwin',
        'Sydney',
        'Port Moresby',
        'Lord Howe Island',
        'Nouméa',
        'Solomon Islands',
        'Auckland',
        'Suva',
        'Chatham Islands',
        "Nuku'alofa",
        'Kiritimati'
    ]
    const timezones = Intl.supportedValuesOf('timeZone')
    const mappedValues = {}
    const regions = []
    timezones.map(timezone => {
        const splitTimezone = timezone.split('/')
        const region = splitTimezone[0]
        if (!mappedValues[region]) {
            mappedValues[region] = []
            regions.push(region)
        }
        mappedValues[region].push(timezone)
    })
    const timeShifts = []
    regions.forEach(region => {
        mappedValues[region].forEach(timezone => {
            const value = getTimezoneOffset(timezone)
            const timeshift = timeShifts.find(time => time.value === value && time.region === region)
            const city = timezone.split('/')[1]
            if (!timeshift) {
                timeShifts.push({ value, city, region })
            } else if (validCities.includes(city)) timeshift.city = timeshift.city + ',' + city
        })
    })

    const data = timeShifts
        .sort((a, b) => {
            const getValue = value => Number(value.value.replace('UTC', '').replace(':', ''))
            return getValue(b) - getValue(a)
        })
        .reduce((acc, item) => {
            if (!acc[item.region]) {
                acc[item.region] = []
            }
            acc[item.region].push(item)
            return acc
        }, {})
    return data
}

export const getTimezoneRegionValue = timezoneOffset => {
    const timeZoneByRegoin = getTimezoneGroupByRegion()
    const currentTimeZone = dayjs.tz.guess()
    const continent = currentTimeZone.split('/')[0]
    if (timeZoneByRegoin[continent]) {
        const timeZone = timeZoneByRegoin[continent].find(item => item.value === timezoneOffset)
        if (timeZone) {
            return `${timeZone.value}_${timeZone.region}`
        }
    }

    for (const key in timeZoneByRegoin) {
        const list = timeZoneByRegoin[key]
        for (const item of list) {
            if (item.value === timezoneOffset) {
                return `${item.value}_${item.region}`
            }
        }
    }
}
