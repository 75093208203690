import loginSaga from './loginSaga'
import signupSaga from './signupSaga'
import forgotPasswordSaga from './forgotPasswordSaga'
import confirmPasswordSaga from './confirmPasswordSaga'
import changePasswordSaga from './changePasswordSaga'
import sendOtpCodeSaga from './sendOtpCode'
import loginWithOtpSaga from './loginWithOtpSaga'
import resendConfirmationCodeSaga from './resendConfirmationCode'
import confirmRegistrationSaga from './confirmRegistration'

const authSaga = [
    loginSaga(),
    signupSaga(),
    forgotPasswordSaga(),
    confirmPasswordSaga(),
    changePasswordSaga(),
    sendOtpCodeSaga(),
    loginWithOtpSaga(),
    resendConfirmationCodeSaga(),
    confirmRegistrationSaga()
]

export default authSaga
