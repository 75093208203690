import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers, manipulateListReducers } from '../reducers'
import { IListQueryPaginationPayload } from '@typings/requestPayloads'

const listCRMSlice = createSlice({
    name: 'CRM/list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        ...manipulateListReducers
    }
})

export const listCRMActions = listCRMSlice.actions
export default listCRMSlice.reducer
