import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IMatchListGroupStatsPayloadState extends ISagaCb {}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const matchListGroupStatsSlice = createSlice({
    name: 'company-statistics/match-list-group-stats',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IMatchListGroupStatsPayloadState>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const matchListGroupStatsActions = matchListGroupStatsSlice.actions
export default matchListGroupStatsSlice.reducer
