import { combineReducers } from 'redux'
import createEventCampaignReducer, { createEventCampaignActions } from '@app-store/slices/campaigns/createEventCampaign'
import createWishCampaignReducer, { createWishCampaignActions } from '@app-store/slices/campaigns/createWishCampaign'
import createEventStepReducer, { createEventStepActions } from '@app-store/slices/campaigns/createEventStep'
import createWishStepReducer, { createWishStepActions } from '@app-store/slices/campaigns/createWishStep'
import editCampaignReducer, { editCampaignActions } from '@app-store/slices/campaigns/editCampaign'
import editStepReducer, { editStepActions } from '@app-store/slices/campaigns/editStep'
import eventCampaignListReducer, { eventCampaignListActions } from '@app-store/slices/campaigns/eventCampaignList'
import wishCampaignListReducer, { wishCampaignListActions } from '@app-store/slices/campaigns/wishCampaignList'
import runCampaignReducer, { runCampaignActions } from '@app-store/slices/campaigns/runCampaign'
import runCampaignStepReducer, { runCampaignStepActions } from '@app-store/slices/campaigns/runCampaignStep'
import deleteCampaignReducer, { deleteCampaignActions } from '@app-store/slices/campaigns/deleteCampaign'
import deleteCampaignStepReducer, { deleteCampaignStepActions } from '@app-store/slices/campaigns/deleteCampaignStep'
import oneCampaignReducer, { oneCampaignActions } from '@app-store/slices/campaigns/oneCampaign'
import pauseCampaignReducer, { pauseCampaignActions } from '@app-store/slices/campaigns/pauseCampaign'
import resumeCampaignReducer, { resumeCampaignActions } from '@app-store/slices/campaigns/resumeCampaign'
import getStepStatisticsReducer, { getStepStatisticsActions } from '@app-store/slices/campaigns/getStepStatistics'
import emailStatusReducer, { emailStatusActions } from '@app-store/slices/campaigns/emailStatus'
import getStepMeetingStatisticsReducer, {
    getStepMeetingStatisticsActions
} from '@app-store/slices/campaigns/getStepMeetingStatistics'
export const campaignsReducer = combineReducers({
    createEventCampaign: createEventCampaignReducer,
    createWishCampaign: createWishCampaignReducer,
    createEventStep: createEventStepReducer,
    createWishStep: createWishStepReducer,
    editCampaign: editCampaignReducer,
    editStep: editStepReducer,
    eventCampaignList: eventCampaignListReducer,
    wishCampaignList: wishCampaignListReducer,
    runCampaign: runCampaignReducer,
    runCampaignStep: runCampaignStepReducer,
    oneCampaign: oneCampaignReducer,
    deleteCampaign: deleteCampaignReducer,
    deleteCampaignStep: deleteCampaignStepReducer,
    resumeCampaign: resumeCampaignReducer,
    pauseCampaign: pauseCampaignReducer,
    getStepStatistics: getStepStatisticsReducer,
    emailStatus: emailStatusReducer,
    getStepMeetingStatistics: getStepMeetingStatisticsReducer
})

export const campaignsActions = {
    createEventCampaign: createEventCampaignActions,
    createWishCampaign: createWishCampaignActions,
    createEventStep: createEventStepActions,
    createWishStep: createWishStepActions,
    editCampaign: editCampaignActions,
    editStep: editStepActions,
    eventCampaignList: eventCampaignListActions,
    wishCampaignList: wishCampaignListActions,
    runCampaign: runCampaignActions,
    runCampaignStep: runCampaignStepActions,
    oneCampaign: oneCampaignActions,
    deleteCampaign: deleteCampaignActions,
    deleteCampaignStep: deleteCampaignStepActions,
    resumeCampaign: resumeCampaignActions,
    pauseCampaign: pauseCampaignActions,
    getStepStatistics: getStepStatisticsActions,
    emailStatus: emailStatusActions,
    getStepMeetingStatistics: getStepMeetingStatisticsActions
}
