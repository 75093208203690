import { ISearch } from '@features/General'
export * from './buttons'
export * from './columns'
export * from './filters'

export const listSearch = (companyId: string, placeholder: string): ISearch => ({
    width: companyId ? '40rem' : '35rem',
    placeholder,
    hasSearchParam: true,
    fields: ['status'],
    align: companyId ? 'right' : 'left'
})

export const listQuery = companyId => {
    const query: any = { agenda: 'meetingAgenda' }
    if (companyId) {
        query.$or = [
            {
                guestCompanyId: companyId
            },
            {
                hostCompanyId: companyId
            }
        ]
    }
    return query
}
