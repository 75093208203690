import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import QueryString from 'qs'
import { history } from '@components/App'

type ComponentTypes = 'dropdown' | 'submenu' | 'rangebar' | 'checkbox' | 'search' | 'autocomplete'
type AggregationType = string[]

interface IMakeRequestQuery {
    flatQuery?: any[]
    aggregations?: AggregationType[]
}

interface IMakeSearchQueryKeyValue {
    type: ComponentTypes
}

export const changeDataType = (type, data) => {
    switch (type) {
        case 'ArrayToString':
            return data.join('')
        case '$regx':
            return { $regex: `(?i)${data}` }
        default:
            return data
    }
}

export const makeAggregationQuery = (aggregations: AggregationType[]) => {
    const search = history.location.search
    const takenQueryFromUrl: any = QueryString.parse(search, { ignoreQueryPrefix: true })
    let resultObject = {}
    for (const item of aggregations) {
        const validKey = item[0]
        const validValueKey = item[1]
        const conditionType = item[2] || undefined
        if (takenQueryFromUrl[validValueKey]) {
            resultObject = {
                ...resultObject,
                [validKey]: changeDataType(conditionType, takenQueryFromUrl[validValueKey])
            }
        }
    }
    return resultObject
}

export const makeRequestQuery = (options: IMakeRequestQuery) => {
    try {
        const { flatQuery = [], aggregations = [] } = options
        const takenQueryFromUrl: any = QueryString.parse(history.location.search, { ignoreQueryPrefix: true })
        const result = {}

        for (const item of flatQuery) {
            const pickFrom = item[0] as string
            const fillTo = item[1] as string | string[]
            const condition = item[2] as string | string[] | undefined
            const existValue = takenQueryFromUrl[pickFrom]
            let object = {}

            if (existValue) {
                if (Array.isArray(fillTo)) {
                    object = {
                        [condition[0]]: fillTo.map(item => ({
                            [item]: { [condition[1]]: `(?i)${existValue}` }
                        }))
                    }
                } else {
                    if (typeof condition === 'string') {
                        object = {
                            [fillTo]: { [condition]: `(?i)${existValue}` }
                        }
                    } else {
                        if (Array.isArray(existValue)) {
                            object = { [fillTo]: { $in: existValue } }
                        } else {
                            object = { [fillTo]: existValue }
                        }
                    }
                }
            }

            Object.assign(result, object)
        }

        // make aggregations
        if (!isEmpty(aggregations)) {
            Object.assign(result, makeAggregationQuery(aggregations))
        }

        return `${JSON.stringify(result)}`
    } catch (e) {
        throw new Error(e)
    }
}

const defaultOptions: IMakeSearchQueryKeyValue = {
    type: 'autocomplete'
}

export const makeSearchQueryByKeyValue = (key, value, option = defaultOptions) => {
    const { type } = option
    const parsedQuery = QueryString.parse(location.search, { ignoreQueryPrefix: true })
    if (!isEmpty(value)) {
        switch (type) {
            case 'rangebar':
                parsedQuery[key] = [value[0], value[1]]
                break
            case 'search':
                parsedQuery[key] = value
                break
            case 'dropdown':
            case 'checkbox':
            default:
                parsedQuery[key] = value
                break
        }
    } else {
        delete parsedQuery[key]
    }

    const query = QueryString.stringify({ ...parsedQuery }, { arrayFormat: 'brackets', encode: false })
    history.replace({ search: query })
}

export const findActiveFilterFromQuery = (filterData: any[], key: string): number => {
    const search = history.location.search
    const query = QueryString.parse(search, { ignoreQueryPrefix: true })
    if (query[key]) {
        return filterData.findIndex(item => isEqual(query[key], item.value))
    }

    return 0
}

export const $orQuerySearch = (keywords: string[], value: string, stringyfy = false): any => {
    let query = {}
    if (value.trim()) {
        query = {
            $or: keywords.map(item => ({
                [item]: { $regex: `(?i)${value}` }
            }))
        }
    }
    return stringyfy ? JSON.stringify(query) : query
}

export const $andOfOrsQueryListSearch = (keywords: string[], allWords: string[]): any => {
    let query = {}
    if (allWords.length) {
        query = {
            $and: allWords.map(value => ({
                $or: keywords.map(key => ({ [key]: { $regex: `(?i)${value}` } }))
            }))
        }
    }
    return query
}

export const $inQuerySearch = (keyword: string, values: any[], stringyfy = false): any => {
    let query = null
    if (!isEmpty(values)) query = { [keyword]: { $in: values } }
    return stringyfy ? JSON.stringify(query) : query
}
