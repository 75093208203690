type ListQueryMapType = {
    flatQuery?: any[]
    aggregations?: any[]
}

/**
 * @index 1 = pick from
 * @index 2 = fill to
 * @index 3 = condition
 */
export const companyAdvancedListQueryMap = {
    flatQuery: [
        ['keyword', ['name', 'email', 'website', 'address', 'HQAddress'], ['$or', '$regex']],
        ['batch', 'batch'],
        ['status', 'status'],
        ['userType', 'userType', '$regex'],
        ['name', 'name', '$regex'],
        ['website', 'website', '$regex'],
        ['brief', 'brief', '$regex'],
        ['country', 'country'],
        ['email', 'email', '$regex'],
        ['tel', 'tel', '$regex'],
        ['address', 'address', '$regex'],
        ['HQAddress', 'HQAddress', '$regex'],
        ['yearFounded', 'yearFounded', '$regex'],
        ['postcode', 'postCode', '$regex'],
        ['mobile', 'mobile', '$regex'],
        ['companyType', 'companyType'],
        ['companySize', 'companySize'],
        ['numberOfEmployees', 'numberOfEmployees'],
        ['ownershipStatus', 'ownershipStatus'],
        ['territory', 'territory'],
        ['city', 'city']
    ],
    aggregations: [
        ['contacts.lastname', 'lastname', '$regex'],
        ['contacts.firstname', 'firstname', '$regex'],
        ['contacts.mobile', 'mobile', '$regex'],
        ['contacts.gender', 'gender', '$regex'],
        ['contacts.age', 'age', '$regex'],
        ['contacts.email', 'email', '$regex'],
        ['contacts.jobDepartment', 'jobDepartment'],
        ['contacts.seniorityLevel', 'seniorityLevel']
    ]
}

export const contactAdvancedListQueryMap: ListQueryMapType = {
    flatQuery: [
        ['keyword', ['firstname', 'lastname', 'email'], ['$or', '$regex']],
        ['batch', 'batch'],
        ['firstname', 'firstname', '$regex'],
        ['lastname', 'lastname', '$regex'],
        ['fullname', 'fullname', '$regex'],
        ['email', 'email', '$regex'],
        ['mobile', 'mobile', '$regex'],
        ['gender', 'gender', '$regex'],
        ['age', 'age', '$regex'],
        ['company', 'company', '$regex'],
        ['jobDepartment', 'jobDepartment'],
        ['seniorityLevel', 'seniorityLevel']
    ],
    aggregations: [
        ['company.status', 'status', 'ArrayToString'],
        ['company.name', 'name', '$regex'],
        ['company.address', 'address', '$regex'],
        ['company.HQAddress', 'HQAddress', '$regex'],
        ['company.yearFounded', 'yearFounded', '$regex'],
        ['company.postcode', 'postcode', '$regex'],
        ['company.city', 'city'],
        ['company.territory', 'territory'],
        ['company.companyType', 'companyType'],
        ['company.companySize', 'companySize'],
        ['company.numberOfEmployees', 'numberOfEmployees']
    ]
}

export const contactAssignListQueryMap: ListQueryMapType = {
    flatQuery: [
        ['keyword', ['firstname', 'lastname', 'email'], ['$or', '$regex']],
        ['editList', 'editList']
    ]
}

export const companiesAssignedListQueryMap: ListQueryMapType = {
    flatQuery: [
        ['keyword', ['name', 'email', 'website', 'address', 'HQAddress'], ['$or', '$regex']],
        ['editList', 'editList'],
        ['userType', 'userType']
    ]
}

export const contactListQueryMap: ListQueryMapType = {
    flatQuery: [
        ['keyword', ['firstname', 'lastname', 'email'], ['$or', '$regex']],
        ['batch', 'batch'],
        ['firstname', 'firstname', '$regex'],
        ['lastname', 'lastname', '$regex'],
        ['fullname', 'fullname', '$regex'],
        ['email', 'email', '$regex'],
        ['mobile', 'mobile', '$regex'],
        ['gender', 'gender', '$regex'],
        ['age', 'age', '$regex'],
        ['company', 'company', '$regex'],
        ['jobDepartment', 'jobDepartment'],
        ['seniorityLevel', 'seniorityLevel']
    ]
}

export const companiesListQueryMap: ListQueryMapType = {
    flatQuery: [
        ['keyword', ['name', 'email', 'website', 'address', 'HQAddress'], ['$or', '$regex']],
        ['batch', 'batch'],
        ['status', 'status', '$in'],
        ['userType', 'userType'],
        ['name', 'name', '$regex'],
        ['website', 'website', '$regex'],
        ['brief', 'brief', '$regex'],
        ['country', 'country'],
        ['email', 'email', '$regex'],
        ['tel', 'tel', '$regex'],
        ['mobile', 'mobile', '$regex'],
        ['companyType', 'companyType'],
        ['companySize', 'companySize'],
        ['numberOfEmployees', 'numberOfEmployees'],
        ['ownershipStatus', 'ownershipStatus'],
        ['territory', 'territory'],
        ['city', 'city'],
        ['address', 'address', '$regex'],
        ['HQAddress', 'HQAddress', '$regex'],
        ['yearFounded', 'yearFounded', '$regex'],
        ['postcode', 'postcode', '$regex']
    ]
}

export const editListQueryMap: ListQueryMapType = {
    flatQuery: [['keyword', ['title', 'status'], ['$or', '$regex']]]
}

export const userListQueryMap: ListQueryMapType = {
    flatQuery: [['keyword', ['firstname', 'lastname', 'email'], ['$or', '$regex']]]
}
