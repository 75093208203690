import listSaga from './listSaga'
import editProductSaga from './editProductSaga'
import oneProductSaga from './oneProductSaga'
import deleteProductSaga from './deleteProductSaga'
import createProductSaga from './createProductSaga'
import productStaticEnumsSaga from './productStaticEnumsSaga'

const productsSagas = [
    listSaga(),
    oneProductSaga(),
    editProductSaga(),
    deleteProductSaga(),
    createProductSaga(),
    productStaticEnumsSaga()
]

export default productsSagas
