import { Action } from 'redux'
import { put, takeLatest, all } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateListPayload } from '@app-store/slices/rfqs/createOrder'

interface TaskAction extends Action {
    payload: ICreateListPayload
}

const { onRequest, onSuccess, onFailure, onRequestClear, onClear } = rootActions.rfqs.createOrder

function* createOrderSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/orders`, {
            ...payload.data
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* clearSagaTask({ payload }) {
    yield put(onClear(''))
}

function* createOrderSaga(): any {
    yield all([takeLatest<TaskAction>(onRequest, createOrderSagaTask), takeLatest(onRequestClear, clearSagaTask)])
}

export default createOrderSaga
