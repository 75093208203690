import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IContactsStatusStatisticsPayload } from '@app-store/slices/contacts/contactsStatusStatistics'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IContactsStatusStatisticsPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.contacts.contactsStatusStatistics

function* contactsStatusStatisticsSagaTask({ payload }) {
    try {
        const queryString = ['fromDate', 'toDate', 'actionBy']
            .filter(key => payload[key])
            .map(key => `${key}=${payload[key]}`)
            .join('&')
        const response = yield axios.get(`companies/contacts/company-status-statistics?${queryString}`)
        if (response) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* contactsStatusStatisticsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, contactsStatusStatisticsSagaTask)
}

export default contactsStatusStatisticsSaga
