import { combineReducers } from 'redux'
import listCRMSlices, { listCRMActions } from './list'
import CRMAuthUrlSlices, { CRMAuthUrlActions } from './authUrl'
import oneCRMSlices, { oneCRMActions } from './one'
import connectSlices, { connectActions } from './connect'
import disconnectSlices, { disconnectActions } from './disconnect'

export const crmReducer = combineReducers({
    list: listCRMSlices,
    authUrl: CRMAuthUrlSlices,
    one: oneCRMSlices,
    connect: connectSlices,
    disconnect: disconnectSlices
})

export const crmActions = {
    list: listCRMActions,
    authUrl: CRMAuthUrlActions,
    one: oneCRMActions,
    connect: connectActions,
    disconnect: disconnectActions
}
