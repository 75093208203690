import { combineReducers } from 'redux'
import oneSurvayReducer, { oneSurvayActions } from '@app-store/slices/survey/oneSurvey'
import feedbackListReducer, { feedbackListActions } from '@app-store/slices/survey/feedbackList'
import oneSurvayByTokenReducer, { oneSurvayByTokenActions } from '@app-store/slices/survey/oneSurveyByToken'
import createSurveyFeedbackReducer, { createSurveyFeedbackActions } from '@app-store/slices/survey/createSurveyFeedback'
import createSurveyFeedbackByTokenReducer, {
    createSurveyFeedbackByTokenActions
} from '@app-store/slices/survey/createSurveyFeedbackByToken'
import listSurveyReducer, { listSurveyActions } from '@app-store/slices/survey/list'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/survey/staticEnums'
import createSurveyReducer, { createSurveyActions } from '@app-store/slices/survey/createSurvey'
import deleteSurveyReducer, { deleteSurveyActions } from '@app-store/slices/survey/deleteSurvey'
import editSurveyReducer, { editSurveyActions } from '@app-store/slices/survey/editSurvey'

export const surveyReducer = combineReducers({
    oneSurvey: oneSurvayReducer,
    oneSurveyByToken: oneSurvayByTokenReducer,
    createSurveyFeedback: createSurveyFeedbackReducer,
    createSurveyFeedbackByToken: createSurveyFeedbackByTokenReducer,
    list: listSurveyReducer,
    feedbackList: feedbackListReducer,
    staticEnums: staticEnumsReducer,
    createSurvey: createSurveyReducer,
    deleteSurvey: deleteSurveyReducer,
    editSurvey: editSurveyReducer
})

export const surveyActions = {
    oneSurvey: oneSurvayActions,
    feedbackList: feedbackListActions,
    oneSurveyByToken: oneSurvayByTokenActions,
    createSurveyFeedback: createSurveyFeedbackActions,
    createSurveyFeedbackByToken: createSurveyFeedbackByTokenActions,
    list: listSurveyActions,
    staticEnums: staticEnumsActions,
    createSurvey: createSurveyActions,
    deleteSurvey: deleteSurveyActions,
    editSurvey: editSurveyActions
}
