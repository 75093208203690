import { createSlice } from '@reduxjs/toolkit'

export interface ILabelTagDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
}

const initialState: ILabelTagDataState = {
    pagination: {
        itemsPerPage: 1000,
        currentPage: 1,
        total: 0
    },
    searchValue: null
}

const labelTagSlice = createSlice({
    name: 'ui/label-tag-list',
    initialState,
    reducers: {
        onChangePagination(state, action) {
            state.pagination = { ...state.pagination, ...action.payload }
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        }
    }
})

export const labelTagActions = labelTagSlice.actions
export default labelTagSlice.reducer
