import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IGenerateLinkPayload } from '@app-store/slices/subscriptionPayment/generateLink'

interface TaskAction extends Action {
    payload: IGenerateLinkPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.generateLink

function* generateLinkSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/subscriptions/payments`, payload.data)
        if (response.success) {
            yield payload.sagaCB?.onSuccess(response.result)
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* generateLinkSaga(): any {
    yield takeLatest<TaskAction>(onRequest, generateLinkSagaTask)
}

export default generateLinkSaga
