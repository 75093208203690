import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
import { initialState } from '@utils/constants/requestConstants'

export interface IEditFeaturePayload extends ISagaCb {
    subscriptionId: string
    featureId: string
    data: any
}

const editFeatureSlice = createSlice({
    name: 'subscription/event-subscription-edit-feature',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditFeaturePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editFeatureActions = editFeatureSlice.actions
export default editFeatureSlice.reducer
