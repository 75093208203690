import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IInfoByTokenPayload } from '@app-store/slices/sharedLists/infoByToken'

interface TaskAction extends Action {
    payload: IInfoByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.infoByToken

function* getInfoByTokenTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/companies/shared-lists/info?token=${payload.token}`)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getInfoByToken(): any {
    yield takeLatest<TaskAction>(onRequest, getInfoByTokenTask)
}

export default getInfoByToken
