import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteTemplatePayload } from '@app-store/slices/templates/delete'

interface TaskAction extends Action {
    payload: IDeleteTemplatePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.templates.delete
const { onRemoveFromList } = rootActions.templates.list
function* deleteTemplateSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/notifications/templates/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(onRemoveFromList(payload.id))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.Template_deleted_successfully || 'Template deleted successfully.'
            })
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteTemplateSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteTemplateSagaTask)
}

export default deleteTemplateSaga
