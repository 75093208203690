import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ITotalActiveUsersPayloadState } from '@app-store/slices/userStatistics/totalActiveUsers'

interface TaskAction extends Action {
    payload: ITotalActiveUsersPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.userStatistics.totalActiveUsers

function* totalActiveUsersSagaTask({ payload }: TaskAction) {
    const params: any = {}
    if (payload.query) {
        params.query = payload.query
    }
    try {
        const response = yield axios.get(`/users/stats/total-active-user`, { params })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload.sagaCB?.onError === 'function') {
            payload.sagaCB?.onError()
        }
    }
}

function* totalActiveUsersSaga(): any {
    yield takeLatest<TaskAction>(onRequest, totalActiveUsersSagaTask)
}

export default totalActiveUsersSaga
