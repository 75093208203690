import { createSlice } from '@reduxjs/toolkit'

export interface ICommonState {
    detailsModal: {
        isOpen: boolean
        data: any
        keys: string[]
    }
}

const initialState: ICommonState = {
    detailsModal: {
        isOpen: false,
        data: null,
        keys: []
    }
}

const detailsModalSlice = createSlice({
    name: 'ui/audit-trails-details-modal',
    initialState,
    reducers: {
        onChangeDetailsModal(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.detailsModal[item] = action.payload[item]
            }
        }
    }
})

export const detailsModalActions = detailsModalSlice.actions
export default detailsModalSlice.reducer
