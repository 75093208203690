import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAllExhibitorMeetingStatsPayloadState } from '@app-store/slices/meetingStatistics/allExhibitorMeetingStats'

interface TaskAction extends Action {
    payload: IAllExhibitorMeetingStatsPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingStatistics.allExhibitorMeetingStats

function* allExhibitorMeetingStatsSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/meeting/stats/exhibitor/all-meetings`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* allExhibitorMeetingStatsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, allExhibitorMeetingStatsSagaTask)
}

export default allExhibitorMeetingStatsSaga
