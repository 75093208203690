import { createSlice } from '@reduxjs/toolkit'

export interface IServiceViewState {
    newServiceModal: boolean
    editServiceModal: boolean
    viewServiceModal: boolean
    serviceId: any
}

const initialState: IServiceViewState = {
    newServiceModal: false,
    editServiceModal: false,
    viewServiceModal: false,
    serviceId: null
}

const serviceViewSlice = createSlice({
    name: 'dashboard/company_profile/view',
    initialState,
    reducers: {
        setNewServiceModal(state, action) {
            state.newServiceModal = action.payload
        },
        setEditServiceModal(state, action) {
            state.editServiceModal = action.payload.visible
            state.serviceId = action.payload.serviceId
        },
        setViewServiceModal(state, action) {
            state.viewServiceModal = action.payload.visible
            state.serviceId = action.payload.serviceId
        }
    }
})

export const serviceViewActions = serviceViewSlice.actions
export default serviceViewSlice.reducer
