import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IPublishContactRequestPayload } from '@app-store/slices/editList/publishContactList'

interface TaskAction extends Action {
    payload: IPublishContactRequestPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.editList.publishContactList

function* publishContactListSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/contacts/publish/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.List_published_successfully || 'List has been published successfully.'
            })
            yield put(rootActions.ui.directory.onSelectRows([]))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* publishContactListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, publishContactListSagaTask)
}

export default publishContactListSaga
