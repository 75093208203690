import { combineReducers } from 'redux'
import totalActiveUsersReducer, { totalActiveUsersActions } from '@app-store/slices/userStatistics/totalActiveUsers'

export const userStatisticsReducers = combineReducers({
    totalActiveUsers: totalActiveUsersReducer
})

export const userStatisticsActions = {
    totalActiveUsers: totalActiveUsersActions
}
