import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListState, ISagaCb, PaginationType } from '@typings/requestPayloads'

interface IPagination extends PaginationType {
    categorizeLimit?: number
    categorizeSkip?: number
}
export interface IDuplicateListQueryPaginationPayload extends ISagaCb {
    data: {
        pagination?: IPagination
        key?: string
        minDoc?: number
        similarityPercentage?: number
        necessaryProjectionArray?: string[]
        keyValue?: string
    }
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const duplicateListSlice = createSlice({
    name: 'hub/companies/duplicate-companies-list',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IDuplicateListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const duplicateListActions = duplicateListSlice.actions
export default duplicateListSlice.reducer
