import { createSlice } from '@reduxjs/toolkit'
import store from 'store'
import { STATUS } from '@features/General'
const onboardingExhibitor = store.get('onboardingExhibitor')
const initialState = {
    event: {
        _id: '',
        timestamps: []
    },
    locationItp: {
        selectedArea: [],
        selectedCountries: {}
    },
    companySize: [],
    email: null,
    type: '',
    itpType: ['Enterprise'],
    positions: [],
    jobDepartments: [],
    seniorityLevels: [],
    fundingStage: [],
    tags: [],
    sectors: [],
    demandingSectors: [],
    unavailable: {},
    status: {
        itp: STATUS.IDLE,
        company: STATUS.IDLE,
        calendar: STATUS.IDLE,
        upload: STATUS.IDLE,
        profile: STATUS.IDLE
    },
    profileImage: null,
    companyImage: null,
    firstname: '',
    lastname: '',
    linkedin: '',
    twitter: '',
    company: '',
    conversationStarter: '',
    description: '',
    company_name: '',
    company_website: '',
    company_tel: '',
    company_email: '',
    company_brief: '',
    company_country: '',
    company_city: '',
    company_keywords1: '',
    company_keywords2: '',
    company_keywords3: '',
    company_address: ''
}

const onboardingExhibitorActionsSlice = createSlice({
    name: 'hub/companies/onboardingExhibitor',
    initialState: onboardingExhibitor || initialState,
    reducers: {
        set(state, action) {
            state[action.payload.type] = action.payload.value
            store.set('onboardingExhibitor', { ...state })
        },
        reset(state) {
            store.remove('onboardingExhibitor')
            Object.assign(state, initialState)
        },
        onRequest(state, action) {
            state.status.company = STATUS.RUNNING
        },
        onUploadImage(state, action) {
            state.status.upload = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status.company = STATUS.READY
            store.remove('onboardingExhibitor')
            for (const key in initialState) {
                state[key] = initialState[key]
            }
        },
        onFailure(state, action) {
            state.status.company = STATUS.ERROR
        },
        onSaveITP(state) {
            state.status.itp = STATUS.RUNNING
        },
        onSaveITPSucces(state) {
            state.status.itp = STATUS.READY
        },
        onSaveITPFailure(state) {
            state.status.itp = STATUS.ERROR
        },
        onGetProfile(state) {
            state.status.profile = STATUS.RUNNING
        },
        onGetProfileSucces(state, action) {
            state.status.profile = STATUS.READY
            const data = action.payload
            for (const key in data) {
                state[key] = data[key]
            }
        },
        onGetProfileFailure(state) {
            state.status.profile = STATUS.ERROR
        }
    }
})

export const onboardingExhibitorActions = onboardingExhibitorActionsSlice.actions
export default onboardingExhibitorActionsSlice.reducer
