import { Ability, AbilityBuilder, defineAbility } from '@casl/ability'
import isArray from 'lodash/isArray'

export const ability = new Ability()

// type CanType = [string, string]

const Can = (access, operator = 'or'): boolean => {
    if (isArray(access[0])) {
        if (operator === 'or') {
            return access.reduce((prev, cur) => prev || ability.can(cur[0], cur[1]), false)
        } else {
            return access.reduce((prev, cur) => prev && ability.can(cur[0], cur[1]), true)
        }
    }

    return ability.can(access[0], access[1])
}

export default Can

const title_replacements = {
    'GET/api/v0.3/users/': 'FetchUsersList',
    '/api/v0.3/users/roles-permissions/': 'ViewPermissionsList',
    '/api/v0.3/users/roles-permissions/upload-url': 'GetUploadPresignedPermissionsURL',
    '/api/v0.3/users/chat-details/': 'ViewChatDetails',
    '/api/v0.3/companies/source-categories/': 'ViewSourceCategoriesList',
    '/api/v0.3/companies/product-libs/': 'AddProductLib',
    '/api/v0.3/companies/source-products/': 'AddSourceProduct',
    '/api/v0.3/companies/product-libs/:id': 'EditProductLib',
    '/api/v0.3/companies/source-products/:id': 'EditSourceProduct',
    '/api/v0.3/companies/source-products/one/:id': 'ViewOneSourceProduct',
    '/api/v0.3/companies/product-libs/one/:id': 'ViewOneProductLib',
    'GET/api/v0.3/companies/match-lists/': 'ViewListMatchList',
    '/api/v0.3/companies/shared-lists/list-with-company': 'ViewMatchesList',
    '/api/v0.3/companies/contacts/advanced-list': 'ViewContactsAdvancedList',
    '/api/v0.3/companies/wish-lists/add-company/:id': 'AddCompanyToWishList',
    '/api/v0.3/companies/wish-lists/add-contact/:id': 'AddContactToWishList',
    'GET/api/v0.3/companies/rfqs/': 'FetchAllRFQs',
    'GET/api/v0.3/companies/quotations/': 'FetchAllQuotations',
    'GET/api/v0.3/subscriptions/': 'FetchSubscriptionList',
    'PUT/api/v0.3/notifications/campaigns/:id': 'EditEventCampaign',
    'DELETE/api/v0.3/companies/:id': 'RemoveOneCompany',
    '/api/v0.3/users/add-user-with-role': 'AddUserWithRole',
    '/api/v0.3/companies/wish-lists/remove-company/:id': 'RemoveCompanyFromWishlist',
    '/api/v0.3/companies/shared-lists/status/:id': 'ChangeSharedListCompanyStatus',
    'GET/api/v0.3/companies/categories/': 'FetchAllEventCategories',
    'GET/api/v0.3/companies/wish-lists/': 'FetchWishListsList',
    'GET/api/v0.3/subscriptions/company-subscriptions/': 'ViewCompanySubscription',
    'GET/api/v0.3/subscriptions/orders/': 'FetchAllSubscriptionOrders',
    'GET/api/v0.3/subscriptions/transactions/': 'FetchAllSubscriptionTransactions',
    'GET/api/v0.3/companies/tags/': 'FetchTagsList',
    '/api/v0.3/companies/contacts/published-list': 'ViewContactsPublishedList',
    '/api/v0.3/companies/contacts/assigned-list': 'ViewContactAssignedList'
}

const service_replacements = {}

export const defineRulesFor = (permissions: any): any => {
    const { can, rules } = new AbilityBuilder(undefined)

    // This logic depends on how the server sends the permissions array
    if (Array.isArray(permissions)) {
        permissions?.forEach(p => {
            if (p.isAllowed && p.service && p.title) {
                can(
                    title_replacements[p.type + p.route] ?? title_replacements[p.route] ?? p.title,
                    service_replacements[p.route] ?? p.service
                )
            }
        })
    }

    return rules
}

export function defineAbilityFor(user) {
    return defineAbility(can => {
        can('read', 'Article', { published: true })
        can('read', 'Article', { published: false, sharedWith: user.id })
    })
}
