import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateTemplatePayload } from '@app-store/slices/templates/create'

interface TaskAction extends Action {
    payload: ICreateTemplatePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.templates.create
const { onAddToList } = rootActions.templates.list
function* createTemplateSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/notifications/templates${payload.query || ''}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            yield put(onAddToList(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Template_created_successfully || 'Template created successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createTemplateSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createTemplateSagaTask)
}

export default createTemplateSaga
