import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {}

const { onRequest, onSuccess, onFailure } = rootActions.companies.getPreSignedUploadURL

function* getPreUploadSignedURLSagaTask() {
    try {
        const { result } = yield axios.get('/companies/upload-url')
        yield put(onSuccess(result))
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getPreUploadSignedURLSaga(): any {
    yield takeLatest<TaskAction>(onRequest, getPreUploadSignedURLSagaTask)
}

export default getPreUploadSignedURLSaga
