import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const prePublishCompanySlice = createSlice({
    name: 'hub/companies/pre-publish-company',
    initialState,
    reducers: {
        onRequest(state) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const prePublishCompanyActions = prePublishCompanySlice.actions
export default prePublishCompanySlice.reducer
