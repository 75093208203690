import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICancelMeetingByTokenPayload extends ISagaCb {
    token: string
}

export interface ICancelMeetingByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: ICancelMeetingByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const cancelMeetingByToken = createSlice({
    name: 'meeting/cancel-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICancelMeetingByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const cancelMeetingByTokenActions = cancelMeetingByToken.actions
export default cancelMeetingByToken.reducer
