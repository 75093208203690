import { put, select, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { RootState } from '@app-store/store'
import routes from '@utils/constants/routes'
import { history } from '@components/App'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.dataImport.initiateImport

function* initiateImportSagaTask({ payload }: TaskAction) {
    try {
        const dataImport = yield select((store: RootState) => store.dataImport)
        const file = dataImport.getPreSignedUpload.data.file
        const name = dataImport.flow[payload].batchName
        let importType = ''
        let url = '/companies/imports/excel/initial'
        switch (payload) {
            case 'host':
                importType = 'eventHostAttendees'
                break
            case 'guest':
                importType = 'eventGuestAttendees'
                break
            case 'category':
                importType = 'eventCategories'
                url = '/companies/imports/event/category/excel/initial'
                break
            case 'itp':
                importType = 'itp'
                break
        }
        const data: any = { file, batchName: name }
        if (importType) {
            data.importType = importType
        }
        const response = yield axios.post(url, data)
        if (response.success) {
            if (payload === 'category') {
                response.result.supportedNames = {
                    categories: response.result.supportedNames
                }
            }
            yield put(onSuccess(Object.assign({}, response.result, payload ? { importType: payload } : {})))
            history.push({
                pathname: payload === 'itp' ? routes.itp_import.map_columns._ : routes.database_import.map_columns._,
                search: location.search
            })
        } else {
            yield put(onFailure('Something went wrong'))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* initiateImportSaga(): any {
    yield takeLatest<TaskAction>(onRequest, initiateImportSagaTask)
}

export default initiateImportSaga
