import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateAvailabilityPayload, ICreateAvailabilityByTokenPayload } from '@app-store/slices/calendar/typings'

interface TaskAction extends Action {
    payload: ICreateAvailabilityPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.calendar.createAvailability

function* createAvailabilitySagaTask({ payload }) {
    try {
        const response = yield axios.post(`/meeting/user-availabilities/calendar`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

interface TaskActionByToken extends Action {
    payload: ICreateAvailabilityByTokenPayload
}

const {
    onRequest: onRequestByToken,
    onSuccess: onSuccessByToken,
    onFailure: onFailureByToken
} = rootActions.calendar.createAvailabilityByToken

function* createAvailabilityByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.post(
            `/meeting/user-availabilities/calendar/by-token/${payload.token}`,
            payload.data
        )
        if (response.success) {
            yield put(onSuccessByToken(response.result))
            yield payload?.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailureByToken(error))
    }
}

function* createAvailabilitySaga(): any {
    yield takeLatest<TaskAction>(onRequest, createAvailabilitySagaTask)
}

function* createAvailabilityByToken(): any {
    yield takeLatest<TaskActionByToken>(onRequestByToken, createAvailabilityByTokenSagaTask)
}

export const createAvailabilityByTokenSaga = createAvailabilityByToken
export default createAvailabilitySaga
