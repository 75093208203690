import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRemoveCompanyFromWishListPayload } from '@app-store/slices/wishLists/removeCompany'
interface TaskAction extends Action {
    payload: IRemoveCompanyFromWishListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.wishLists.removeCompany

function* removeCompanyWishListsSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/wish-lists/remove-company/${payload.id}`, { ...payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(rootActions.wishLists.oneWishList.setNewWishedCompanies(response.result))
            if (payload?.sagaCB) {
                payload?.sagaCB?.onSuccess(response.result)
            }
        } else {
            yield put(onFailure(''))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* removeCompanyWishListsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, removeCompanyWishListsSagaTask)
}

export default removeCompanyWishListsSaga
