import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
// import { makeRequestQuery } from '@utils/helpers/queryHelpers'
import { IContactAdvancedListQueryPayload } from '@app-store/slices/contacts/advancedList'
// import { contactAdvancedListQueryMap } from '@utils/QueryMaps'

interface TaskAction extends Action {
    payload: IContactAdvancedListQueryPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.contacts.advancedList
const { onChangeItemCount } = rootActions.companies.statistics

// This saga is for getting specific advanced list contacts
function* advancedListSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        // const query = makeRequestQuery(contactAdvancedListQueryMap)
        const response = yield axios.get(`/companies/contacts/advanced-list`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            yield payload?.sagaCB?.onSuccess(response.result)
            if (!payload.noSaveInStore) {
                yield put(onChangeItemCount({ key: 'publishedContacts', value: response.result.total }))
            }
        } else {
            yield put(onFailure(''))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* advancedListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, advancedListSagaTask)
}

export default advancedListSaga
