import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface DetailAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.quotations.getListStatistics
function* getListStatisticsSaga({ payload }) {
    try {
        const response = yield axios.get(`/companies/quotations/coming-going`)
        if (response.success) {
            yield put(onSuccess({ data: response.result }))
            if (payload.sagaCB) {
                payload.sagaCB.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getListStatistics(): any {
    yield takeLatest<DetailAction>(onRequest, getListStatisticsSaga)
}

export default getListStatistics
