import { combineReducers } from 'redux'
import listStaticEnumsReducer, { listStaticEnumsActions } from '@app-store/slices/meetings/listStaticEnums'
import getAvailableSlotsReducer, { getAvailableSlotsActions } from '@app-store/slices/meetings/getAvailableSlots'
import createMeetingReducer, { createMeetingActions } from '@app-store/slices/meetings/createMeeting'
import deleteMeetingReducer, { deleteMeetingActions } from '@app-store/slices/meetings/deleteMeeting'
import editMeetingReducer, { editMeetingActions } from '@app-store/slices/meetings/editMeeting'
import listReducer, { listMeetingActions } from '@app-store/slices/meetings/list'
import getUnavailableSlotsReducer, { getUnavailableSlotsActions } from '@app-store/slices/meetings/getUnavailableSlots'
import addToAgendaReducer, { addToAgendaActions } from '@app-store/slices/meetings/addToAgenda'
import addMeetingReducer, { addMeetingActions } from '@app-store/slices/meetings/addMeeting'
import deleteUnavailabilityReducer, {
    deleteUnavailabilityActions
} from '@app-store/slices/meetings/deleteUnavailability'
import createMeetingCostReducer, { createMeetingCostActions } from '@app-store/slices/meetings/createMeetingCost'
import updateMeetingCostReducer, { updateMeetingCostActions } from '@app-store/slices/meetings/updateMeetingCost'
import meetingCostListReducer, { meetingCostListActions } from '@app-store/slices/meetings/meetingCostList'
import getMeetingCostReducer, { getMeetingCostActions } from '@app-store/slices/meetings/getMeetingCost'
import addBadgeReducer, { addBadgeActions } from '@app-store/slices/meetings/addBadge'
import cancelMeetingByTokenReducer, { cancelMeetingByTokenActions } from '@app-store/slices/meetings/cancelByToken'
import getUnavailableSlotsByTokenReducer, {
    getUnavailableSlotsByTokenActions
} from '@app-store/slices/meetings/getUnavailableSlotsByToken'
import addToAgendaByTokenReducer, { addToAgendaByTokenActions } from '@app-store/slices/meetings/addToAgendaByToken'
import generateMeetingLinkTokenReducer, {
    generateMeetingLinkTokenActions
} from '@app-store/slices/meetings/generateMeetingLinkToken'
import shareMeetingLinkReducer, { shareMeetingLinkActions } from '@app-store/slices/meetings/shareMeetingLink'

export const meetingsReducer = combineReducers({
    listStaticEnums: listStaticEnumsReducer,
    getAvailableSlots: getAvailableSlotsReducer,
    create: createMeetingReducer,
    delete: deleteMeetingReducer,
    edit: editMeetingReducer,
    list: listReducer,
    getUnavailableSlots: getUnavailableSlotsReducer,
    addToAgenda: addToAgendaReducer,
    addMeeting: addMeetingReducer,
    deleteUnavailability: deleteUnavailabilityReducer,
    createMeetingCost: createMeetingCostReducer,
    updateMeetingCost: updateMeetingCostReducer,
    meetingCostList: meetingCostListReducer,
    getMeetingCost: getMeetingCostReducer,
    addBadge: addBadgeReducer,
    cancelMeetingByToken: cancelMeetingByTokenReducer,
    getUnavailableSlotsByToken: getUnavailableSlotsByTokenReducer,
    addToAgendaByToken: addToAgendaByTokenReducer,
    generateMeetingLinkToken: generateMeetingLinkTokenReducer,
    shareMeetingLink: shareMeetingLinkReducer
})

export const meetingsActions = {
    listStaticEnums: listStaticEnumsActions,
    getAvailableSlots: getAvailableSlotsActions,
    create: createMeetingActions,
    delete: deleteMeetingActions,
    edit: editMeetingActions,
    list: listMeetingActions,
    getUnavailableSlots: getUnavailableSlotsActions,
    addToAgenda: addToAgendaActions,
    addMeeting: addMeetingActions,
    deleteUnavailability: deleteUnavailabilityActions,
    createMeetingCost: createMeetingCostActions,
    updateMeetingCost: updateMeetingCostActions,
    meetingCostList: meetingCostListActions,
    getMeetingCost: getMeetingCostActions,
    addBadge: addBadgeActions,
    cancelMeetingByToken: cancelMeetingByTokenActions,
    getUnavailableSlotsByToken: getUnavailableSlotsByTokenActions,
    addToAgendaByToken: addToAgendaByTokenActions,
    generateMeetingLinkToken: generateMeetingLinkTokenActions,
    shareMeetingLink: shareMeetingLinkActions
}
