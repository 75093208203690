import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
import { initialState } from '@utils/constants/requestConstants'

export interface IAddUserPayload extends ISagaCb {
    subscriptionId: string
    data: {
        _id: string
        status: string
        type?: string
        creditLimit?: number
        features?: string[]
        countries?: string[]
        locations?: string[]
    }
}

const addUserSlice = createSlice({
    name: 'subscription/company-subscription-add-user',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IAddUserPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const addUserActions = addUserSlice.actions
export default addUserSlice.reducer
