import { put, takeLatest, select } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { history } from '@components/App'
import { RootState } from '@app-store/store'
import { setEventCookie } from '@features/Auth'
import store from '@app-store/store'
import { getTimeStamps } from '@features/Settings/Details'

interface TaskAction extends Action {
    payload: any
}

const {
    onRequestDetails: onRequest,
    onSuccessDetails: onSuccess,
    onFailureDetails: onFailure,
    setDetails
} = rootActions.companies.onboarding

function* detailsSagaTask({ payload }) {
    const { details } = yield select((store: RootState) => store.companies.onboarding)
    const timestamps = getTimeStamps(details.timestamps, details.timezone)
    try {
        const response = yield axios.post(
            `/event`,
            { ...details, timestamps },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                transformRequest: (data, headers) => {
                    delete headers['event-id']
                    return JSON.stringify(data)
                }
            }
        )

        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(
                setDetails({
                    type: 'eventId',
                    value: response.result._id
                })
            )
            yield put(rootActions.companies.contactProfile.onRequest({}))
            setEventCookie(response.result._id)
            store.dispatch(rootActions.users.profile.onRequest({}))
            history.push(payload.nextURL)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* detailsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, detailsSagaTask)
}

export default detailsSaga
