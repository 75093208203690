import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeactiveCompanyPayload } from '@app-store/slices/companies/deactiveCompany'

interface TaskAction extends Action {
    payload: IDeactiveCompanyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.deactive

function* deactiveCompanySagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/deactive/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.Company_deactived_successfully || 'Company deactived successfully.'
            })
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deactiveCompanySaga(): any {
    yield takeLatest<TaskAction>(onRequest, deactiveCompanySagaTask)
}

export default deactiveCompanySaga
