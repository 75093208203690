import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onSuccess, onRequest, onFailure } = rootActions.companies.createCompany
const { setCreateCompanyModal } = rootActions.ui.companyProfile

function* createCompanySagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(setCreateCompanyModal(false))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createCompanySaga(): any {
    yield takeLatest<TaskAction>(onRequest, createCompanySagaTask)
}

export default createCompanySaga
