import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const subscriptionInvoicesSlice = createSlice({
    name: 'ui/subscription-invoices',
    initialState: {
        ...listUIInitialState,
        populates: ['subscription']
    },
    reducers: listUIReducers
})

export const subscriptionInvoicesActions = subscriptionInvoicesSlice.actions
export default subscriptionInvoicesSlice.reducer
