import { createSlice } from '@reduxjs/toolkit'

export interface ICommonState {
    subscriptionModal: {
        isOpen?: boolean
        isCustom?: boolean
        editData?: any
    }
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    pureFilters?: any
    sort?: string
    needPopulate: boolean
    populates?: any
}

const initialState: ICommonState = {
    subscriptionModal: {
        isOpen: false,
        isCustom: false,
        editData: null
    },
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    pureFilters: {},
    sort: '-createdAt',
    needPopulate: true,
    populates: [{ path: 'features', select: '_id name featureType isActive' }]
}

const manageSubscriptionSlice = createSlice({
    name: 'ui/manageSubscription',
    initialState,
    reducers: {
        setSubscriptionModalInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.subscriptionModal[item] = action.payload[item]
            }
        },
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        onChangeFilters(state, action) {
            state.filters = action.payload
        },
        onChangePureFilters(state, action) {
            state.pureFilters = action.payload
        },
        addPureFilter(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const key of objectkeys) {
                state.pureFilters[key] = action.payload[key]
            }
        },
        onResetFilters(state) {
            state.filters = {}
            state.pureFilters = {}
        }
    }
})

export const manageSubscriptionActions = manageSubscriptionSlice.actions
export default manageSubscriptionSlice.reducer
