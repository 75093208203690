import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICheckPromoCodeValidationPayload } from '@app-store/slices/subscriptionPayment/checkPromoCodeValidation'

interface TaskAction extends Action {
    payload: ICheckPromoCodeValidationPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.checkPromoCodeValidation

function* checkPromoCodeValidationSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/subscriptions/payments/validate-promoCode?code=${payload.code}`)

        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            yield payload.sagaCB?.onError()
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* checkPromoCodeValidationSaga(): any {
    yield takeLatest<TaskAction>(onRequest, checkPromoCodeValidationSagaTask)
}

export default checkPromoCodeValidationSaga
