import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditUserPayload } from '@app-store/slices/companySubscriptions/editUser'

interface TaskAction extends Action {
    payload: IEditUserPayload
}

const { onSuccess, onRequest, onFailure } = rootActions.companySubscriptions.editUser

function* editUserSagaTask({ payload }) {
    try {
        const response = yield axios.put(
            `/subscriptions/company-subscriptions/user/${payload.subscriptionId}/${payload.userId}`,
            payload.data
        )
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
        } else {
            if (typeof payload.sagaCB?.onError === 'function') {
                yield payload.sagaCB?.onError()
            }
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* editUserSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editUserSagaTask)
}

export default editUserSaga
