import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IGenerateListPayload } from '@app-store/slices/matchLists/generateList'
interface TaskAction extends Action {
    payload: IGenerateListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchLists.generateList

function* generateListSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/match-lists/generate`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result.data)
        } else {
            yield payload.sagaCB?.onError(response.error)
            throw new Error('')
        }
    } catch (error) {
        if (payload.sagaCB) {
            yield payload.sagaCB?.onError(error)
        }
        yield put(onFailure(error))
    }
}

function* generateListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, generateListSagaTask)
}

export default generateListSaga
