import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/meetingAgenda/list'
import oneMeetingAgendaReducer, { oneMeetingAgendaActions } from '@app-store/slices/meetingAgenda/oneMeeting'
import meetingInfoByTokenReducer, {
    meetingInfoByTokenActions
} from '@app-store/slices/meetingAgenda/meetingInfoByToken'

export const meetingAgendaReducer = combineReducers({
    list: listReducer,
    oneMeeting: oneMeetingAgendaReducer,
    meetingInfoByToken: meetingInfoByTokenReducer
})

export const meetingAgendaActions = {
    list: listActions,
    oneMeeting: oneMeetingAgendaActions,
    meetingInfoByToken: meetingInfoByTokenActions
}
