import { ISagaCb } from '@typings/requestPayloads'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'

export interface IContactByTokenPayload extends ISagaCb {
    token: string
}

export interface IContactByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: IContactByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const contactByToken = createSlice({
    name: 'hub/contacts/contact-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IContactByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const contactByTokenActions = contactByToken.actions
export default contactByToken.reducer
