import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const matchListMeetingsSlice = createSlice({
    name: 'ui/match-list-meetings',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const matchListMeetingsActions = matchListMeetingsSlice.actions
export default matchListMeetingsSlice.reducer
