import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/templates/list'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/templates/staticEnums'
import createTemplateReducer, { createTemplateActions } from './create'
import editTemplateReducer, { editTemplateActions } from './edit'
import oneTemplateReducer, { oneTemplateActions } from './oneTemplate'
import evaluatePlaceholderReducer, { evaluatePlaceholderActions } from './evaluatePlaceholder'
import deleteTemplateReducer, { deleteTemplateActions } from './delete'

export const templateReducers = combineReducers({
    list: listReducer,
    staticEnums: staticEnumsReducer,
    create: createTemplateReducer,
    edit: editTemplateReducer,
    one: oneTemplateReducer,
    evaluatePlaceholder: evaluatePlaceholderReducer,
    delete: deleteTemplateReducer
})

export const templateActions = {
    list: listActions,
    staticEnums: staticEnumsActions,
    create: createTemplateActions,
    edit: editTemplateActions,
    one: oneTemplateActions,
    evaluatePlaceholder: evaluatePlaceholderActions,
    delete: deleteTemplateActions
}
