import { combineReducers } from 'redux'
import createMeetingRequestReducer, { createMeetingRequestActions } from '@app-store/slices/meetingRequests/create'
import listMeetingRequestsReducer, { listMeetingRequestsActions } from '@app-store/slices/meetingRequests/list'
import oneMeetingRequestReducer, { oneMeetingRequestActions } from '@app-store/slices/meetingRequests/oneMeetingRequest'
import editMeetingRequestReducer, { editMeetingRequestActions } from '@app-store/slices/meetingRequests/edit'
import cancelMeetingRequestReducer, { cancelMeetingRequestActions } from '@app-store/slices/meetingRequests/cancel'
import getContactsAvailabilityReducer, {
    getContactsAvailabilityActions
} from '@app-store/slices/meetingRequests/getContactsAvailability'
import unavailableTimeSlotsByTokenReducer, {
    unavailableTimeSlotsByTokenActions
} from '@store/slices/meetingRequests/unavailableTimeSlotsByToken'

// requests by ID
import rejectMeetingRequestByIDReducer, {
    rejectMeetingRequestByIDActions
} from '@app-store/slices/meetingRequests/rejectById'
import acceptMeetingRequestByIDReducer, {
    acceptMeetingRequestByIDActions
} from '@app-store/slices/meetingRequests/acceptById'
import suggestMeetingRequestByIDReducer, {
    suggestMeetingRequestByIDActions
} from '@app-store/slices/meetingRequests/suggestById'

// requests by TOKEN
import listHallByTokenReducer, { listHallByTokenActions } from '@app-store/slices/meetingRequests/listHallByToken'
import listTableByTokenReducer, { listTableByTokenActions } from '@app-store/slices/meetingRequests/listTableByToken'
import rejectMeetingRequestByTokenReducer, {
    rejectMeetingRequestByTokenActions
} from '@app-store/slices/meetingRequests/rejectByToken'
import acceptMeetingRequestByTokenReducer, {
    acceptMeetingRequestByTokenActions
} from '@app-store/slices/meetingRequests/acceptByToken'
import meetingRequestInfoByTokenReducer, {
    meetingRequestInfoByTokenActions
} from '@app-store/slices/meetingRequests/infoByToken'
import suggestMeetingRequestByTokenReducer, {
    suggestMeetingRequestByTokenActions
} from '@app-store/slices/meetingRequests/suggestByToken'
import infoByLinkReducer, { infoByLinkActions } from '@app-store/slices/meetingRequests/infoByLink'
import directMeetingByLinkReducer, {
    directMeetingByLinkActions
} from '@app-store/slices/meetingRequests/directMeetingByLink'

export const meetingRequestsReducer = combineReducers({
    create: createMeetingRequestReducer,
    edit: editMeetingRequestReducer,
    list: listMeetingRequestsReducer,
    one: oneMeetingRequestReducer,
    cancel: cancelMeetingRequestReducer,
    getContactsAvailability: getContactsAvailabilityReducer,
    unavailableTimeSlotsByToken: unavailableTimeSlotsByTokenReducer,
    rejectByID: rejectMeetingRequestByIDReducer,
    acceptByID: acceptMeetingRequestByIDReducer,
    suggestByID: suggestMeetingRequestByIDReducer,
    listHallByToken: listHallByTokenReducer,
    listTableByToken: listTableByTokenReducer,
    infoByToken: meetingRequestInfoByTokenReducer,
    rejectByToken: rejectMeetingRequestByTokenReducer,
    acceptByToken: acceptMeetingRequestByTokenReducer,
    suggestByToken: suggestMeetingRequestByTokenReducer,
    infoByLink: infoByLinkReducer,
    directMeetingByLink: directMeetingByLinkReducer
})

export const meetingRequestsActions = {
    create: createMeetingRequestActions,
    edit: editMeetingRequestActions,
    list: listMeetingRequestsActions,
    one: oneMeetingRequestActions,
    cancel: cancelMeetingRequestActions,
    getContactsAvailability: getContactsAvailabilityActions,
    unavailableTimeSlotsByToken: unavailableTimeSlotsByTokenActions,
    rejectByID: rejectMeetingRequestByIDActions,
    acceptByID: acceptMeetingRequestByIDActions,
    suggestByID: suggestMeetingRequestByIDActions,
    listHallByToken: listHallByTokenActions,
    listTableByToken: listTableByTokenActions,
    infoByToken: meetingRequestInfoByTokenActions,
    rejectByToken: rejectMeetingRequestByTokenActions,
    acceptByToken: acceptMeetingRequestByTokenActions,
    suggestByToken: suggestMeetingRequestByTokenActions,
    infoByLink: infoByLinkActions,
    directMeetingByLink: directMeetingByLinkActions
}
