import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.users.list

function* listSagaTask({ payload }) {
    let selectedQuery = null
    if (payload.search) {
        selectedQuery = {
            $or: [
                { firstname: { $regex: `(?i)${payload.search}` } },
                { lastname: { $regex: `(?i)${payload.search}` } },
                { email: { $regex: `(?i)${payload.search}` } }
            ]
        }
    }
    const query: any = payload.query || undefined
    const params = yield getParams(payload)
    if (query || selectedQuery) Object.assign(params, { query: { ...(query || {}), ...selectedQuery } })

    try {
        const response = yield axios.get(`/users`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            if (payload?.sagaCB) {
                payload?.sagaCB?.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listSagaTask)
}

export default listSaga
