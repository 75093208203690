import allMeetingStatsSaga from './allMeetingStatsSaga'
import allExhibitorMeetingStatsSaga from './allExhibitorMeetingStatsSaga'
import exhibitorMeetingByDateSaga from './exhibitorMeetingByDateSaga'
import exhibitorMeetingCostsSaga from './exhibitorMeetingCostsSaga'
import exhibitorMeetingRatingsSaga from './exhibitorMeetingRatingsSaga'
import meetingByDateSaga from './meetingByDateSaga'
import meetingByOperatorSaga from './meetingByOperatorSaga'
import meetingCostsSaga from './meetingCostsSaga'
import meetingRatingsSaga from './meetingRatingsSaga'
import meetingRequestSaga from './meetingRequestSaga'
import plannedMeetingSaga from './plannedMeetingSaga'
import meetingRequestByStatusSaga from './meetingRequestByStatusSaga'

const meetingStatisticsSagas = [
    allMeetingStatsSaga(),
    allExhibitorMeetingStatsSaga(),
    exhibitorMeetingByDateSaga(),
    exhibitorMeetingCostsSaga(),
    exhibitorMeetingRatingsSaga(),
    meetingByDateSaga(),
    meetingByOperatorSaga(),
    meetingCostsSaga(),
    meetingRatingsSaga(),
    meetingRequestSaga(),
    plannedMeetingSaga(),
    meetingRequestByStatusSaga()
]

export default meetingStatisticsSagas
