import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditEventPayload } from '@app-store/slices/events/edit'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IEditEventPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.events.edit

function* editEventSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.patch(`/event/${payload.eventId}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            notification.success({
                message: payload?.sagaCB?.successMessage || 'Event edited successfully.'
            })
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            yield payload?.sagaCB?.onError()
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editEventSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editEventSagaTask)
}

export default editEventSaga
