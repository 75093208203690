import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { ICreateSurveyFeedbackPayload } from '@app-store/slices/survey/createSurveyFeedback'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: ICreateSurveyFeedbackPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.survey.createSurveyFeedback

function* createSurveyFeedbackSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/meeting/survey-feedback`, { ...payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB) payload.sagaCB?.onError()
    }
}

function* createSurveyFeedbackSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createSurveyFeedbackSagaTask)
}

export default createSurveyFeedbackSaga
