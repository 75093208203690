import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IVerificationInitiatePayload } from '@app-store/slices/notifications/verificationInitiate'

interface TaskAction extends Action {
    payload: IVerificationInitiatePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.notification.verificationInitiate

function* verificationInitiateSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/notifications/verification/initiate`, payload.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: (data, headers) => {
                headers['event-id'] = payload.eventId
                return JSON.stringify(data)
            }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* verificationInitiateSaga(): any {
    yield takeLatest<TaskAction>(onRequest, verificationInitiateSagaTask)
}

export default verificationInitiateSaga
