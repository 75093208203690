import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: {},
    errors: null
}

const oneEventSlice = createSlice({
    name: 'hub/companies/one-event',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onChangeFields(state, action) {
            const data = action.payload
            for (const key in data) {
                state.data[key] = data[key]
            }
        }
    }
})

export const oneEventActions = oneEventSlice.actions
export default oneEventSlice.reducer
