import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { rootActions } from '@app-store/slices'
import { IUpdateDefaultCardPayload } from '@app-store/slices/subscriptionPayment/updateDefaultCard'

interface TaskAction extends Action {
    payload: IUpdateDefaultCardPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.updateDefaultCard

function* updateDefaultCardSagaTask({ payload }) {
    try {
        const response = yield axios.patch('/subscriptions/payments/card', payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* updateDefaultCardSaga(): any {
    yield takeLatest<TaskAction>(onRequest, updateDefaultCardSagaTask)
}

export default updateDefaultCardSaga
