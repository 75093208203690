import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IEditNewFeaturePayload extends ISagaCb {
    id: string
    data: {
        title: string
        description: string
        module: string
        newFeature: boolean
    }
}

export interface IEditNewFeatureState {
    status: string
    data: any
    errors: any
}

const initialState: IEditNewFeatureState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editNewFeature = createSlice({
    name: 'newFeature/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditNewFeaturePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onReset(state) {
            state.status = STATUS.ERROR
            state.data = null
            state.errors = null
        }
    }
})

export const editNewFeatureActions = editNewFeature.actions
export default editNewFeature.reducer
