import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { rootActions } from '@app-store/slices'
import store from '@app-store/store'
import { callAvailableSlotsEndpoint } from './getAvailableSlots'

dayjs.extend(timezone)

export const getFormValues = (formMode, params, setInit, isMeetingHubEvent, week, form) => {
    const formValues: any = {
        meetingType: 'In-person',
        status: 'Conducted',
        meetingDuration: 15,
        addDuration: 15,
        bufferTime: 0,
        floorPlanLink: 'https://drive.google.com/file/d/11mnzItQnZ0AY4Rze8oHvoFQZs_bqGUm4/view',
        hallId: ''
    }
    if (formMode !== 'edit') {
        if (params?.guestCompanyId || params?.hostCompanyId) {
            const companies = []
            if (params?.guestCompanyId) {
                companies.push(params.guestCompanyId)
                formValues.guestCompanyId = params.guestCompanyId
            }
            if (params?.hostCompanyId) {
                companies.push(params.hostCompanyId)
                formValues.hostCompanyId = params.hostCompanyId
            }
            if (companies.length) {
                store.dispatch(
                    rootActions.companies.list.onRequest({
                        query: { _id: { $in: companies } },
                        sagaCB: {
                            onSuccess: result => {
                                setInit(state => ({
                                    ...state,
                                    guestCompany: result.data.find(item => item._id === params.guestCompanyId),
                                    hostCompany: result.data.find(item => item._id === params.hostCompanyId)
                                }))
                            }
                        }
                    })
                )
            }
        }
        if (params?.hostId) {
            formValues.hostIds = [params?.hostId]
        }
        form.setFieldsValue(formValues)
    } else if (formMode === 'edit' && params?.id) {
        store.dispatch(
            rootActions.meetingAgenda.oneMeeting.onRequest({
                meetingId: params?.id,
                sagaCB: {
                    onSuccess: result => {
                        const selectedTime = []
                        let date = dayjs(result.startTime, 'YYYY-MM-DD hh:mm AZ')
                        const endTime = dayjs(result.endTime, 'YYYY-MM-DD hh:mm AZ')
                        let x = 1
                        while (!date.isSame(endTime) && x < 5) {
                            selectedTime.push(dayjs(date, 'YYYY-MM-DD hh:mm AZ').format('YYYY-MM-DD hh:mm AZ'))
                            x += 1
                            date = dayjs(date, 'YYYY-MM-DD hh:mm AZ').add(15, 'minutes')
                        }
                        Object.assign(formValues, {
                            timeShift: dayjs.tz.guess(),
                            meetingType: result.meetingType,
                            guestCompanyId: result.guestCompanyId?._id,
                            hostCompanyId: result.hostCompanyId?._id,
                            hostIds: result.hostIds?.map(item => item._id) || [],
                            guestIds: result.guestIds?.map(item => item._id) || [],
                            guests: result.guests?.map(item => item._id) || [],
                            meetingDuration: result.meetingDuration,
                            bufferTime: result.bufferTime,
                            floorPlanLink: result.floorPlanLink,
                            hallId: result.place || result.hallId?._id || result.hallId || '',
                            description: result.description || '',
                            selectedTime: [selectedTime],
                            forceAvailable: [{ startDate: result.startTime, endDate: result.endTime }],
                            tableId: result.tableId?._id || '',
                            reminderDuration: result.reminderDuration || '',
                            reminderType: result.reminderType
                        })
                        form.setFieldsValue(formValues)
                        setInit(state => ({
                            ...state,
                            guestCompany: result.guestCompanyId,
                            hostCompany: result.hostCompanyId,
                            guestIds: result.guestIds,
                            hostIds: result.hostIds,
                            guests: result.guests,
                            hallId: result.hallId,
                            tableId: result.tableId
                        }))
                        callAvailableSlotsEndpoint(
                            result.guestCompanyId,
                            result.guestIds,
                            result.hostIds,
                            result.guestCompanyId,
                            isMeetingHubEvent,
                            week
                        )
                    }
                }
            })
        )
    }
}
