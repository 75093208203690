import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { IGetMessagesPayload } from '@app-store/slices/chat/getMessages'

interface TaskAction extends Action {
    payload: IGetMessagesPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.getMessages

function* getMessagesSagaTask({ payload }: TaskAction) {
    try {
        const params = {
            username: payload.username
        }

        const response = yield axios.get(`/im.messages`, {
            params
        })

        if (response.status === 200) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.data }))
            yield payload.sagaCB.onSuccess(response.data?.messages)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getMessagesSaga(): any {
    yield takeLatest<TaskAction>(onRequest, getMessagesSagaTask)
}

export default getMessagesSaga
