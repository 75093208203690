import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { IListDirectMessagePayload } from '@app-store/slices/chat/listDirectMessage'

interface TaskAction extends Action {
    payload: IListDirectMessagePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.listDirectMessage

function* listDirectMessageSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/im.list`)

        if (response.status === 200) {
            yield put(onSuccess(response.data))
            yield payload?.sagaCB?.onSuccess(response.data.room)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listDirectMessageSaga(): any {
    yield takeLatest<TaskAction>(onRequest, listDirectMessageSagaTask)
}

export default listDirectMessageSaga
