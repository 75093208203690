import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateMatchListPayload extends ISagaCb {
    data: {
        title: string
        matchedCompanies: any
        ownerCompany: string
    }
}

export interface ICreateMatchListState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateMatchListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createMatchList = createSlice({
    name: 'matchList/create',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateMatchListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onReset(state) {
            state.status = STATUS.ERROR
            state.data = null
            state.errors = null
        }
    }
})

export const createMatchListActions = createMatchList.actions
export default createMatchList.reducer
