import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
export interface IOneSharedListPayload {
    sharedListId: string
    sort?: any
    needPopulate?: any
    populates?: any
}

export interface IOneSharedListState {
    status: string
    data: any
    errors: any
}

const initialState: IOneSharedListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneSharedList = createSlice({
    name: 'sharedList/one-shared-list',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneSharedListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data || action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onChangeCompanyStatus(state, action) {
            const newData = cloneDeep(state.data)
            const company = newData.matchListDetail.find(item => item.company._id === action.payload.companyId)
            company.status = action.payload.status
            state.data = newData
        }
    }
})

export const oneSharedListActions = oneSharedList.actions
export default oneSharedList.reducer
