import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateTablePayload } from '@app-store/slices/table/create'

interface TaskAction extends Action {
    payload: ICreateTablePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.table.create

function* createTableSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/table`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) {
                payload.sagaCB?.onSuccess(response.result)
            }
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Table_created_successfully || 'Table created successfully.'
            })
        } else {
            if (payload?.sagaCB?.onError) {
                payload.sagaCB.onError()
            }
            throw new Error('')
        }
    } catch (error) {
        if (payload?.sagaCB?.onError) {
            payload.sagaCB.onError()
        }
        yield put(onFailure(error))
    }
}

function* createTableSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createTableSagaTask)
}

export default createTableSaga
