import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import { forgotpasswordCognitoUser } from '@utils/authenticateService'
import { IForgotPasswordPayload } from '@app-store/slices/auth/forgotPassword.slice'
import notification from 'antd/lib/notification'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: IForgotPasswordPayload
}

const { onFailure, onSuccess, onRequest } = rootActions.auth.forgotPassword

function* forgotPasswordSagaTask({ payload }: TaskAction) {
    const { data: i18n } = yield select((store: RootState) => store.localization.locals)
    try {
        const response = yield forgotpasswordCognitoUser(payload.data)
        yield payload.sagaCB?.onSuccess()
        notification.success({
            message:
                i18n?.General?.Confirmation_code_sent_your_email || 'Confirmation code has been sent to your email.'
        })
        yield put(onSuccess(response))
    } catch (e) {
        yield put(onFailure([i18n?.General?.Email_is_incorrect || 'Email is incorrect.']))
    }
}

function* forgotPasswordSaga(): any {
    yield takeLatest<TaskAction>(onRequest, forgotPasswordSagaTask)
}

export default forgotPasswordSaga
