import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { TokenListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure, onRequestList, onSuccessPageList } = rootActions.properties.listByToken

function* listPropertyByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/attachments/properties/meeting-notes?token=${payload.token}`)
        if (response.success) {
            yield put(onSuccess({ keepOldData: payload.keepOldData || false, ...response.result }))
            yield put(onSuccessPageList(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listPropertyByTokenSaga(): any {
    yield takeEvery<TokenListTaskAction>(onRequestList, listPropertyByTokenSagaTask)
    yield takeLatest<TokenListTaskAction>(onRequest, listPropertyByTokenSagaTask)
}

export default listPropertyByTokenSaga
