import { combineReducers } from 'redux'
import wishListsCreateReducer, { wishListsCreateActions } from '@app-store/slices/wishLists/create'
import wishListsListReducer, { wishListsListActions } from '@app-store/slices/wishLists/list'
import wishListsEditReducer, { wishListsEditActions } from '@app-store/slices/wishLists/edit'
import oneWishListReducer, { oneWishListActions } from '@app-store/slices/wishLists/oneWishList'
import wishListsAddCompanyReducer, { wishListsAddCompanyActions } from '@app-store/slices/wishLists/addCompany'
import wishListsRemoveCompanyReducer, { wishListsRemoveCompanyActions } from '@app-store/slices/wishLists/removeCompany'
import wishListsAddContactReducer, { wishListsAddContactActions } from '@app-store/slices/wishLists/addContact'

export const wishListsReducer = combineReducers({
    create: wishListsCreateReducer,
    list: wishListsListReducer,
    edit: wishListsEditReducer,
    oneWishList: oneWishListReducer,
    addCompany: wishListsAddCompanyReducer,
    removeCompany: wishListsRemoveCompanyReducer,
    addContact: wishListsAddContactReducer
})

export const wishListsActions = {
    create: wishListsCreateActions,
    list: wishListsListActions,
    edit: wishListsEditActions,
    oneWishList: oneWishListActions,
    addCompany: wishListsAddCompanyActions,
    removeCompany: wishListsRemoveCompanyActions,
    addContact: wishListsAddContactActions
}
