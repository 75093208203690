import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
interface IData {
    manuallyValidation?: boolean
    contactType?: string
    primary?: boolean
    contactIds: string[]
}
export interface IContactListVerifyPayload extends ISagaCb {
    data: IData
}

export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const contactListVerifySlice = createSlice({
    name: 'hub/contacts/contact-list-verify',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IContactListVerifyPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const contactListVerifyActions = contactListVerifySlice.actions
export default contactListVerifySlice.reducer
