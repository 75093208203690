import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ICompanyPublishedListQueryPayload } from '@app-store/slices/companies/publishedList'

interface TaskAction extends Action {
    payload: ICompanyPublishedListQueryPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.publishedList

// This saga is for getting specific advanced list companies
function* publishedListSagaTask({ payload }) {
    const params = yield getParams(payload)
    try {
        const response = yield axios.get(`/companies/published-list`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload?.sagaCB?.onSuccess(response.result)
            }
        } else {
            yield put(onFailure(''))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* publishedListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, publishedListSagaTask)
}

export default publishedListSaga
