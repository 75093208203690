import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOneFeaturePayload } from '@app-store/slices/feature/one'

interface TaskAction extends Action {
    payload: IOneFeaturePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.feature.one
function* oneFeatureTaskSaga({ payload }) {
    try {
        const response = yield axios.get(`/subscriptions/features/one/${payload.featureId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneFeatureSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneFeatureTaskSaga)
}

export default oneFeatureSaga
