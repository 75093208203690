import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
import { IDataState } from '@typings/requestPayloads'
export interface IEventSubscriptionUIState extends IDataState {
    subscriptionModal: {
        isOpen?: boolean
        id?: string
    }
}
const EventSubscriptionInitialState: IEventSubscriptionUIState = {
    ...listUIInitialState,
    populates: ['company', 'subscription'],
    subscriptionModal: {
        isOpen: false,
        id: ''
    }
}
const eventSubscriptionsSlice = createSlice({
    name: 'ui/event-subscriptions',
    initialState: EventSubscriptionInitialState,
    reducers: {
        ...listUIReducers,
        setSubscriptionModalInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.subscriptionModal[item] = action.payload[item]
            }
        }
    }
})

export const eventSubscriptionsActions = eventSubscriptionsSlice.actions
export default eventSubscriptionsSlice.reducer
