import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IEventProfilesState {
    status: string
    data: any
    errors: any
}

const initialState: IEventProfilesState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const eventProfiles = createSlice({
    name: 'events/get-event-profiles',
    initialState,
    reducers: {
        onRequest(state) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
            state.data = {}
        },
        onChangeData(state, action) {
            state.data = action.payload
        }
    }
})

export const eventProfilesActions = eventProfiles.actions
export default eventProfiles.reducer
