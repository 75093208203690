import { IColumn } from '@features/General'
import { meetingStatusColors, MeetingStatus } from '@features/Meeting/MeetingAgenda'
import { history } from '@components/App'
import routes from '@utils/constants/routes'
import PencilLineIcon from 'remixicon-react/PencilLineIcon'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import DirectionLineIcon from 'remixicon-react/DirectionLineIcon'
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon'
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon'

export const listColumns = (
    timezone?: string,
    i18n?: any,
    roles: any = {},
    showActions = false,
    isUser = true
): IColumn[] => [
    {
        title: i18n?.General?.Date,
        dataIndex: 'date',
        key: 'startTime',
        type: 'dateTime',
        format: 'dayMonthName',
        timezone,
        hasLabelTag: !isUser,
        labelTagModelName: 'meetings',
        labelTagActionName: 'addBadge',
        labelTagType: 'Meeting'
    },
    {
        title: i18n?.General?.Time,
        dataIndex: 'hours',
        key: 'hours',
        type: 'dateTime',
        format: 'startAndEndTime',
        timezone
    },
    {
        title: i18n?.General?.Table || 'Table',
        dataIndex: 'tableId',
        type: 'nested',
        fields: ['name']
    },
    {
        title: i18n?.General?.Companies,
        dataIndex: 'companies',
        type: 'companies',
        companiesType: 'names',
        hostsField: 'hostCompanyId',
        guestField: 'guestCompanyId'
    },
    {
        title: i18n?.General?.Attendees,
        dataIndex: 'attendeesUsers',
        type: 'attendees',
        attendeesType: 'users',
        hostsField: 'hostIds',
        guestField: 'guestIds',
        showActions,
        showUserActivityStatus: !isUser
    },
    {
        title: i18n?.General?.Email,
        dataIndex: 'attendeesEmails',
        type: 'attendees',
        attendeesType: 'emails',
        hostsField: 'hostIds',
        guestField: 'guestIds'
    },
    {
        title: i18n?.General?.Country,
        dataIndex: 'attendeesCountries',
        type: 'attendees',
        attendeesType: 'countries',
        hostsField: 'hostIds',
        guestField: 'guestIds'
    },
    {
        title: i18n?.General?.Where,
        dataIndex: 'hall',
        type: 'nested',
        fields: ['name'],
        aliasDataIndex: 'meetingType'
    },
    {
        title: i18n?.General?.Status,
        dataIndex: 'statusBadge',
        key: 'status',
        type: 'badge',
        bg: value => meetingStatusColors[value],
        width: 85
    },
    {
        dataIndex: 'actions',
        type: 'actions',
        actions: record => [
            {
                title: i18n?.General?.Edit,
                icon: PencilLineIcon,
                key: 'edit',
                onClick: record => {
                    history.push(routes.meetings.meeting_agenda.edit._.replace(':id', record._id))
                },
                abilityAction: 'UpdateMeeting',
                abilityService: 'Meeting',
                hide: ![MeetingStatus.SCHEDULED, MeetingStatus.RESCHEDULED].includes(record?.status)
            },
            {
                title: i18n?.Meeting?.['Cancel Meeting'],
                icon: CloseLineIcon,
                key: 'cancel',
                abilityAction: 'UpdateMeeting',
                abilityService: 'Meeting',
                confirm: {
                    title: i18n?.Meeting?.['Cancel Meeting'],
                    okText: i18n?.Meeting?.['Cancel Meeting'],
                    actionName: 'edit',
                    storeField: 'meetingId',
                    content: i18n?.Meeting?.Meeting_Cancel_Description,
                    extraPayload: {
                        meetingId: record._id,
                        data: { status: MeetingStatus.CANCELED }
                    }
                },
                hide: ![MeetingStatus.SCHEDULED, MeetingStatus.RESCHEDULED].includes(record?.status)
            },
            {
                title: i18n?.Meeting?.Conduct_Meeting,
                icon: DirectionLineIcon,
                key: 'conduct',
                abilityAction: 'UpdateMeeting',
                abilityService: 'Meeting',
                confirm: {
                    title: i18n?.Meeting?.Conduct_Meeting,
                    actionName: 'edit',
                    storeField: 'meetingId',
                    content: i18n?.Meeting?.Meeting_Conduct_Description,
                    okText: i18n?.Meeting?.Conduct_Meeting,
                    extraPayload: {
                        meetingId: record._id,
                        data: { status: MeetingStatus.CONDUCTED }
                    }
                },
                hide: ![MeetingStatus.SCHEDULED, MeetingStatus.RESCHEDULED].includes(record?.status)
            },
            {
                title: i18n?.Meeting?.Mis_Meeting,
                icon: EyeOffLineIcon,
                key: 'mis',
                abilityAction: 'UpdateMeeting',
                abilityService: 'Meeting',
                confirm: {
                    title: i18n?.Meeting?.Mis_Meeting,
                    okText: i18n?.Meeting?.Mis_Meeting,
                    actionName: 'edit',
                    storeField: 'meetingId',
                    content: i18n?.Meeting?.Meeting_Mis_Description,
                    extraPayload: {
                        meetingId: record._id,
                        data: { status: MeetingStatus.MISSED }
                    }
                },
                hide: ![MeetingStatus.SCHEDULED, MeetingStatus.RESCHEDULED].includes(record?.status)
            },
            {
                title: i18n?.Meeting?.Delete_Meeting,
                icon: DeleteBinLineIcon,
                key: 'delete',
                abilityAction: 'DeleteMeeting',
                abilityService: 'Meeting',
                confirm: {
                    title: i18n?.Meeting?.Delete_Meeting,
                    okText: i18n?.Meeting?.Delete_Meeting,
                    storeField: 'meetingId',
                    content: i18n?.Meeting?.Delete_Meeting_Description,
                    extraPayload: {
                        meetingId: record._id
                    }
                },
                hide:
                    ![MeetingStatus.CANCELED].includes(record?.status) &&
                    (roles.isAdmin || roles.isTeamManager || roles.isOperation)
            }
        ]
    }
]
