import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ILoginPayload extends ISagaCb {
    email: string
    password: string
    isActivation?: boolean
    eventId?: string
}

export interface ILoginState {
    status: string
    data: {
        token: string
    }
    errors: any
}

const initialState: ILoginState = {
    status: STATUS.IDLE,
    data: {
        token: ''
    },
    errors: null
}

const loginSlice = createSlice({
    name: 'auth/login',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ILoginPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data.token = action.payload.token
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const loginActions = loginSlice.actions
export default loginSlice.reducer
