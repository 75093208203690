import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IGetAvailableSlotsPayload } from '@app-store/slices/meetings/getAvailableSlots'

interface TaskAction extends Action {
    payload: IGetAvailableSlotsPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.getAvailableSlots

function* getAvailableSlotsTask({ payload }: TaskAction) {
    try {
        const { sagaCB, ...clonedPayload } = Object.assign({}, payload)
        const response = yield axios.post(`/meeting/get-available-slots`, clonedPayload)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(''))
    }
}

function* getAvailableSlots(): any {
    yield takeLatest<TaskAction>(onRequest, getAvailableSlotsTask)
}

export default getAvailableSlots
