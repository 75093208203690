import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { ILoginPayload } from '@app-store/slices/chat/login'

interface TaskAction extends Action {
    payload: ILoginPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.login

function* loginSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/login`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* loginSaga(): any {
    yield takeLatest<TaskAction>(onRequest, loginSagaTask)
}

export default loginSaga
