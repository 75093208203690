import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAddContactToWishListPayload } from '@app-store/slices/wishLists/addContact'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IAddContactToWishListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.wishLists.addContact

function* addContactWishListsSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/wish-lists/add-contact/${payload.id}`, { ...payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            // yield put(rootActions.wishLists.oneWishList.onRequest({ id: payload.id }))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.TradeHub?.Contacts_added_wishlist_successfully ||
                    'Contacts have been added to your wishlist successfully.'
            })
            if (payload?.sagaCB) {
                payload?.sagaCB?.onSuccess(response.result)
            }
        } else {
            yield put(onFailure(''))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* addContactWishListsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, addContactWishListsSagaTask)
}

export default addContactWishListsSaga
