import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { IPaymentListPayload } from '@app-store/slices/subscriptionPayment/paymentList'

interface TaskAction extends Action {
    payload: IPaymentListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.paymentList

function* paymentListSagaTask({ payload }: TaskAction) {
    try {
        const params = yield getParams(payload || {})
        let url = '/subscriptions/payments'
        if (payload.subscription) {
            url += `/${payload.subscription}`
        }
        const response = yield axios.get(url, {
            params
        })

        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* paymentListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, paymentListSagaTask)
}

export default paymentListSaga
