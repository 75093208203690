import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICheckSesAccountStatusPayload extends ISagaCb {
    email: string
}

export interface ICheckSesAccountStatusState {
    status: string
    data: null
    errors: any
}

const initialState: ICheckSesAccountStatusState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const checkSesAccountStatus = createSlice({
    name: 'email-credential/check-ses-account-status',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICheckSesAccountStatusPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const checkSesAccountStatusActions = checkSesAccountStatus.actions
export default checkSesAccountStatus.reducer
