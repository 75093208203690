import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateServicePayload } from '@app-store/slices/services/createService'
import store from '@app-store/store'

interface TaskAction extends Action {
    payload: ICreateServicePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.services.create

function* createServiceSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/services`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
            store.dispatch(
                rootActions.services.list.addService({ ...response.result, serviceImages: payload.serviceImages })
            )
            store.dispatch(rootActions.ui.services.addTotal())
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createServiceSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createServiceSagaTask)
}

export default createServiceSaga
