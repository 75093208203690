import { combineReducers } from 'redux'
import cloneDeep from 'lodash/cloneDeep'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, listInitialState } from '@utils/constants/requestConstants'
import {
    IOneItemPayload,
    IDeletePayload,
    IDeleteTokenPayload,
    IListQueryPaginationPayload,
    IListQueryPaginationTokenPayload
} from '@typings/requestPayloads'
import {
    ICreateAvailabilityPayload,
    ICreateAvailabilityByTokenPayload,
    IEditAvailabilityPayload,
    IEditAvailabilityByTokenPayload,
    IGetConnectedCalendarPayload,
    ISyncPayload,
    ISyncByTokenPayload,
    ISyncAuthenticateURLPayload,
    ISyncAuthenticateUrlByTokenPayload
} from './typings'
import { sideEffectReducers, listSideEffectReducers } from '../reducers'

const create = createSlice({
    name: 'calendar/create-Availability',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateAvailabilityPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const createByToken = createSlice({
    name: 'calendar/create-availability-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateAvailabilityByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const edit = createSlice({
    name: 'calendar/edit-Availability',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IEditAvailabilityPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const editByToken = createSlice({
    name: 'calendar/edit-availability-by-token',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IEditAvailabilityByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const getConnectedCalendar = createSlice({
    name: 'calendar/get-connected-calendar',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetConnectedCalendarPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers,
        onRemoveRecord(state, action) {
            state.data = cloneDeep(state.data).filter(item => item._id !== action.payload)
        }
    }
})

const getConnectedCalendarByToken = createSlice({
    name: 'calendar/get-connected-calendar-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetConnectedCalendarPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers,
        onRemoveRecord(state, action) {
            state.data = cloneDeep(state.data).filter(item => item._id !== action.payload)
        }
    }
})

const deleteCalendar = createSlice({
    name: 'calendar/delete',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneItemPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const listSlice = createSlice({
    name: 'hub/calendar/list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers
    }
})
const listByTokenSlice = createSlice({
    name: 'hub/calendar/list-by-token',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers
    }
})
const oneAvailability = createSlice({
    name: 'calendar/one-availability',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneItemPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const removeSync = createSlice({
    name: 'calendar/remove-sync',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IDeletePayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const removeSyncByToken = createSlice({
    name: 'calendar/remove-sync-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IDeleteTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})

const sync = createSlice({
    name: 'calendar/sync',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ISyncPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const syncByToken = createSlice({
    name: 'calendar/sync-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ISyncByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const syncAuthenticateURL = createSlice({
    name: 'calendar/sync-authenticate-url',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ISyncAuthenticateURLPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})
const syncAuthenticateUrlByToken = createSlice({
    name: 'calendar/sync-authenticate-url-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ISyncAuthenticateUrlByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        ...sideEffectReducers
    }
})

export const calendarReducers = combineReducers({
    createAvailability: create.reducer,
    editAvailability: edit.reducer,
    oneAvailability: oneAvailability.reducer,
    sync: sync.reducer,
    syncAuthenticateURL: syncAuthenticateURL.reducer,
    getConnectedCalendar: getConnectedCalendar.reducer,
    removeSync: removeSync.reducer,
    list: listSlice.reducer,
    delete: deleteCalendar.reducer,
    syncAuthenticateUrlByToken: syncAuthenticateUrlByToken.reducer,
    syncByToken: syncByToken.reducer,
    createAvailabilityByToken: createByToken.reducer,
    listByToken: listByTokenSlice.reducer,
    editAvailabilityByToken: editByToken.reducer,
    getConnectedCalendarByToken: getConnectedCalendarByToken.reducer,
    removeSyncByToken: removeSyncByToken.reducer
})

export const calendarActions = {
    createAvailability: create.actions,
    editAvailability: edit.actions,
    oneAvailability: oneAvailability.actions,
    sync: sync.actions,
    syncAuthenticateURL: syncAuthenticateURL.actions,
    getConnectedCalendar: getConnectedCalendar.actions,
    removeSync: removeSync.actions,
    list: listSlice.actions,
    delete: deleteCalendar.actions,
    syncAuthenticateUrlByToken: syncAuthenticateUrlByToken.actions,
    syncByToken: syncByToken.actions,
    createAvailabilityByToken: createByToken.actions,
    listByToken: listByTokenSlice.actions,
    editAvailabilityByToken: editByToken.actions,
    getConnectedCalendarByToken: getConnectedCalendarByToken.actions,
    removeSyncByToken: removeSyncByToken.actions
}
