import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateListPayload extends ISagaCb {
    data: {
        title: string
        companies: string[]
        editors: string[]
        managers: string[]
        query?: any
        changeCompanyStatus?: boolean
    }
}

export interface ICreateState {
    status: string
    data: any[]
    errors: any
}

const initialState: ICreateState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const create = createSlice({
    name: 'hub/editList/create',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createActions = create.actions
export default create.reducer
