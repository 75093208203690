import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IEditCategoryPayload extends ISagaCb {
    _id?: string
    data: {
        title: string
        categoryCode?: string
        type?: string
        standard?: string
        parentCategory?: string
    }
}

export interface IEditCategoryState {
    status: string
    data: any
    errors: any
}

const initialState: IEditCategoryState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editCategory = createSlice({
    name: 'hub/categories/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditCategoryPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editCategoryActions = editCategory.actions
export default editCategory.reducer
