import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IGenerateLinkPayload extends ISagaCb {
    data: {
        method: 'StripeSubscriptionPaymentLink'
        subscription: string
        user: string
        order: string
        company: string
        planType: 'Yearly' | 'Monthly'
        userOption?: string
        setterOption?: string
        promoCode?: string
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const generateLinkPayment = createSlice({
    name: 'subscription-payment/generate-link',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IGenerateLinkPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const generateLinkActions = generateLinkPayment.actions
export default generateLinkPayment.reducer
