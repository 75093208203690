import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'
import { ISagaCb } from '@typings/requestPayloads'
export interface ISendInvitationPayload extends ISagaCb {
    data: {
        userId: string
    }
}

export interface IState {
    status: string
    success: boolean
    result: boolean
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    success: true,
    result: false,
    errors: null
}

const sendInvitationSlice = createSlice({
    name: 'hub/users/send-invitation',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ISendInvitationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const sendInvitationActions = sendInvitationSlice.actions
export default sendInvitationSlice.reducer
