import { createSlice } from '@reduxjs/toolkit'

export interface IEmailCampaignState {
    newCampaignModalVisible: boolean
    newStepsModalVisible: boolean
    copyModal: boolean
    isWishCampaign: boolean
    showContactsWishCampaign: boolean
}

const initialState: IEmailCampaignState = {
    newCampaignModalVisible: false,
    newStepsModalVisible: false,
    copyModal: false,
    isWishCampaign: false,
    showContactsWishCampaign: false
}

const emailCampaignSlice = createSlice({
    name: 'organizer/event-details/mail-campaign',
    initialState,
    reducers: {
        setNewCampaignModalVisible(state, action) {
            state.newCampaignModalVisible = action.payload
            state.isWishCampaign = false
            state.showContactsWishCampaign = false
        },
        setNewWishCampaignModal(state, action) {
            state.newCampaignModalVisible = action.payload.visible
            state.isWishCampaign = action.payload.isWishCampaign
            state.showContactsWishCampaign = action.payload.showContactsWishCampaign
        },
        openCopyModal(state, action) {
            state.newCampaignModalVisible = action.payload
            state.copyModal = action.payload
            state.isWishCampaign = false
            state.showContactsWishCampaign = false
        },
        wishCampaignCopyModal(state, action) {
            state.newCampaignModalVisible = action.payload.visible
            state.copyModal = action.payload.visible
            state.isWishCampaign = action.payload.isWishCampaign
            state.showContactsWishCampaign = action.payload.showContactsWishCampaign
        },
        setNewStepsModalVisible(state, action) {
            state.newStepsModalVisible = action.payload
        }
    }
})

export const emailCampaignActions = emailCampaignSlice.actions
export default emailCampaignSlice.reducer
