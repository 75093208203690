import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteHistoryImportAttendeesPayload } from '@app-store/slices/onbordingOrganizer/deleteHistoryImportAttendees'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IDeleteHistoryImportAttendeesPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.onbordingOrganizer.deleteHistoryImportAttendees

function* deleteHistoryImportAttendeesSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete(`/companies/imports/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.History_deleted_successfully || 'History deleted successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteHistoryImportAttendeesSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteHistoryImportAttendeesSagaTask)
}

export default deleteHistoryImportAttendeesSaga
