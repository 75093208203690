import { createSlice } from '@reduxjs/toolkit'
import { listUIReducers } from '../reducers'
import { listUIInitialState } from '@utils/constants/requestConstants'

const auditTrailSlice = createSlice({
    name: 'ui/auditTrail',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const auditTrailActions = auditTrailSlice.actions
export default auditTrailSlice.reducer
