import { combineReducers } from 'redux'
import createServiceReducer, { createServiceActions } from '@app-store/slices/services/createService'
import editServiceReducer, { editServiceActions } from '@app-store/slices/services/editService'
import deleteServiceReducer, { deleteServiceActions } from '@app-store/slices/services/deleteService'
import oneServiceReducer, { oneServiceActions } from '@app-store/slices/services/oneService'
import listReducer, { listActions } from '@app-store/slices/services/list'
import serviceViewReducer, { serviceViewActions } from './view.slice'

export const servicesReducer = combineReducers({
    list: listReducer,
    create: createServiceReducer,
    edit: editServiceReducer,
    oneService: oneServiceReducer,
    delete: deleteServiceReducer,
    view: serviceViewReducer
})

export const servicesActions = {
    list: listActions,
    create: createServiceActions,
    edit: editServiceActions,
    oneService: oneServiceActions,
    delete: deleteServiceActions,
    view: serviceViewActions
}
