import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.meetings.meetingCostList

function* meetingCostListSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/meeting/costs`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* meetingCostListSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, meetingCostListSagaTask)
}

export default meetingCostListSaga
