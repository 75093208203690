import { rootActions } from '@store/slices'
import { IresetPasswordPayload } from '@app-store/slices/users/resetPassword'
import axios from '@utils/request'
import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest } from 'redux-saga/effects'

interface TaskAction extends Action {
    payload: IresetPasswordPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.resetPassword
const { setResetPasswordModal } = rootActions.ui.companyProfile

function* resetPasswordSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/users/reset-password`, payload)
        if (response.success) {
            notification.success({
                message: `Password of ${payload.email} has been changed successfully.`
            })
            yield put(onSuccess(response.result))
            yield put(setResetPasswordModal(false))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* resetPasswordSaga(): any {
    yield takeLatest<TaskAction>(onRequest, resetPasswordSagaTask)
}

export default resetPasswordSaga
