import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IInternalPublishCompanyPayload } from '@app-store/slices/companies/internalPublishCompany'

interface TaskAction extends Action {
    payload: IInternalPublishCompanyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.internalPublishCompany
function* internalPublishCompanySagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/internal-publish/${payload.id}`, { condition: payload.condition })
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.Internal_published_successfully || 'Internal published successfully.'
            })
            yield put(rootActions.ui.directory.onSelectRows([]))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* internalPublishCompanySaga(): any {
    yield takeLatest<TaskAction>(onRequest, internalPublishCompanySagaTask)
}

export default internalPublishCompanySaga
