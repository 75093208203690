import { combineReducers } from 'redux'
import { onboardingReducer, onboardingActions } from './onboarding'

export const exhibitorReducer = combineReducers({
    onboarding: onboardingReducer
})

export const exhibitorActions = {
    onboarding: onboardingActions
}
