import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IOneTourPayload } from '@app-store/slices/tours/oneTour'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IOneTourPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.tours.one

function* oneTourSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/event/tour/one/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneTourSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneTourSagaTask)
}

export default oneTourSaga
