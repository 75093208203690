import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/newFeature/list'
import createReducer, { createNewFeatureActions } from '@app-store/slices/newFeature/create'
import editReducer, { editNewFeatureActions } from '@app-store/slices/newFeature/edit'
import deleteReducer, { deleteNewFeatureActions } from '@app-store/slices/newFeature/delete'
import newFeatureByIdReducer, { newFeatureByIDActions } from '@app-store/slices/newFeature/newFeatureById'
import newFeatureByModuleIdReducer, {
    newFeatureByModuleIDActions
} from '@app-store/slices/newFeature/newFeatureByModuleId'

export const newFeatureReducers = combineReducers({
    list: listReducer,
    create: createReducer,
    edit: editReducer,
    delete: deleteReducer,
    newFeatureById: newFeatureByIdReducer,
    newFeatureByModuleId: newFeatureByModuleIdReducer
})

export const newFeatureActions = {
    list: listActions,
    create: createNewFeatureActions,
    edit: editNewFeatureActions,
    delete: deleteNewFeatureActions,
    newFeatureById: newFeatureByIDActions,
    newFeatureByModuleId: newFeatureByModuleIDActions
}
