import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: {},
    errors: null
}

interface IEditPayload {
    id: string
    data: any
    avatar: any
}

interface IEditPayload {
    id: string
    data: any
}

const contactProfileSlice = createSlice({
    name: 'hub/companies/contact-profile',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onEditRequest(state, action: PayloadAction<IEditPayload>) {
            state.status = STATUS.RUNNING
        },
        onEditFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onDeleteRequest(state, action: PayloadAction<IEditPayload>) {
            state.status = STATUS.RUNNING
        },
        onDeleteFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const contactProfileActions = contactProfileSlice.actions
export default contactProfileSlice.reducer
