import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAllMeetingStatsPayloadState } from '@app-store/slices/meetingStatistics/allMeetingStats'

interface TaskAction extends Action {
    payload: IAllMeetingStatsPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingStatistics.allMeetingStats

function* allMeetingStatsSagaTask({ payload }: TaskAction) {
    try {
        const params: any = {}
        if (payload.query) {
            params.query = payload.query
        }
        const response = yield axios.get(`/meeting/stats/all-meetings`, { params })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB.onError()
        }
    }
}

function* allMeetingStatsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, allMeetingStatsSagaTask)
}

export default allMeetingStatsSaga
