import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IGetMessagesPayload extends ISagaCb {
    username: string
    noSaveInStore?: boolean
}

const initialState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const getMessagesSlice = createSlice({
    name: 'chat/get-messages',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetMessagesPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            if (action.payload?.noSaveInStore !== true) {
                state.data = action.payload.messages
            }
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getMessagesActions = getMessagesSlice.actions
export default getMessagesSlice.reducer
