import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISignupPayload {
    email: string
    firstname: string
    lastname: string
    companyname?: string
    temporary_password?: string
    password: string
    isSubscriptionSignup?: boolean
}

export interface ISignupState {
    status: string
    data: {
        token: string
    }
    errors: any
}

const initialState: ISignupState = {
    status: STATUS.IDLE,
    data: {
        token: ''
    },
    errors: null
}

const signupSlice = createSlice({
    name: 'auth/signup',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ISignupPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data.token = action.payload.token
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const signupActions = signupSlice.actions
export default signupSlice.reducer
