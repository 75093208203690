import { Action } from 'redux'
import QueryString from 'qs'
import notification from 'antd/lib/notification'
import store from '@app-store/store'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import { ISendOtpCodePayload } from '@app-store/slices/auth/sendOtpCode'
import { sendOtpCodeCognitoUser } from '@utils/authenticateService'
import { history } from '@components/App'
import routes from '@utils/constants/routes'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: ISendOtpCodePayload
}

const { onFailure, onSuccess, onRequest } = rootActions.auth.sendOtpCode

function* sendOtpSaga({ payload }: TaskAction) {
    const { data: i18n } = yield select((store: RootState) => store.localization.locals)
    try {
        const response = yield sendOtpCodeCognitoUser({ email: payload.email })
        history.push({
            pathname: routes.login.otp.code._,
            search: QueryString.stringify({ email: payload.email })
        })
        notification.success({
            message: i18n?.General?.Otp_code_sent_your_email || 'Otp code has been sent to your email.'
        })
        if (response?.challengeType === 'OTP_CODE') {
            yield put(onSuccess(response))
            yield payload.sagaCB?.onSuccess()
            store.dispatch(rootActions.ui.otpLogin.resetSeconds())
        }
    } catch (e) {
        yield put(onFailure(i18n?.General?.Email_is_incorrect || 'Email is incorrect'))
    }
}

function* sendOtpCodeSaga(): any {
    yield takeLatest<TaskAction>(onRequest, sendOtpSaga)
}

export default sendOtpCodeSaga
