import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { IListQueryPaginationPayload } from '@app-store/slices/meetingRequests/listTableByToken'

interface TaskAction extends Action {
    payload: IListQueryPaginationPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingRequests.listTableByToken

function* listMeetingRequestSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/meeting/meeting-requests/tables`, {
            params: {
                ...params,
                token: payload.token,
                tableId: payload.tableId
            }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess()
        } else {
            if (payload?.sagaCB?.onError) payload?.sagaCB?.onError()
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listMeetingRequestSaga(): any {
    yield takeLatest<TaskAction>(onRequest, listMeetingRequestSagaTask)
}

export default listMeetingRequestSaga
