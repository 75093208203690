import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import notification from 'antd/lib/notification'
import { IEditBadgePayload } from '@app-store/slices/badges/editBadge'

interface TaskAction extends Action {
    payload: IEditBadgePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.badges.edit

function* editBadgeSagaTask({ payload }) {
    try {
        const response = yield axios.patch(`/event/settings/badge/${payload.id}`, payload.data)
        if (response.success) {
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Badge_updated_successfully || 'Badge updated successfully.'
            })
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editBadgeSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editBadgeSagaTask)
}

export default editBadgeSaga
