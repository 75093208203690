import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditCompaniesSharedListPayload {
    sharedListId: string
    data: {
        selectedCompanies: any
    }
}

export interface IEditCompaniesSharedListState {
    status: string
    data: any
    errors: any
}

const initialState: IEditCompaniesSharedListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editCompaniesSharedList = createSlice({
    name: 'sharedList/edit-companies',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditCompaniesSharedListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editCompaniesSharedListActions = editCompaniesSharedList.actions
export default editCompaniesSharedList.reducer
