import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ICreateSurveyFeedbackPayload extends ISagaCb {
    data: {
        surId: string
        stars?: number
        typeId?: string
        answer?: any
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createSurveyFeedback = createSlice({
    name: 'survey/create-survey-feedback',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateSurveyFeedbackPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createSurveyFeedbackActions = createSurveyFeedback.actions
export default createSurveyFeedback.reducer
