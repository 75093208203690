import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IConfirmRegistrationPayload extends ISagaCb {
    code: string
    username?: any
}

export interface IConfirmRegistrationState {
    status: string
    data: {
        token: string
    }
    errors: any
}

const initialState: IConfirmRegistrationState = {
    status: STATUS.IDLE,
    data: {
        token: ''
    },
    errors: null
}

const confirmRegistrationSlice = createSlice({
    name: 'auth/rconfirm-registeration',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IConfirmRegistrationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data.token = action.payload.token
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const confirmRegistrationActions = confirmRegistrationSlice.actions
export default confirmRegistrationSlice.reducer
