import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditDefaultVisibilityPayload {
    settingId: string
    data: any
}

export interface IEditDefaultVisibilityState {
    status: string
    data: any
    errors: any
}

const initialState: IEditDefaultVisibilityState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editDefaultVisibility = createSlice({
    name: 'event-setting/edit-default-visibility',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditDefaultVisibilityPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editDefaultVisibilityActions = editDefaultVisibility.actions
export default editDefaultVisibility.reducer
