import listSaga from './listSaga'
import editConferenceSaga from './editConferenceSaga'
import oneConferenceSaga from './oneConferenceSaga'
import deleteConferenceSaga from './deleteConferenceSaga'
import createConferenceSaga from './createConferenceSaga'

const conferencesSagas = [
    listSaga(),
    oneConferenceSaga(),
    editConferenceSaga(),
    deleteConferenceSaga(),
    createConferenceSaga()
]

export default conferencesSagas
