import { createSlice } from '@reduxjs/toolkit'

export interface ICallLogDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
}

const initialState: ICallLogDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null
}

const callLogSlice = createSlice({
    name: 'ui/call-log-list',
    initialState,
    reducers: {
        onChangePagination(state, action) {
            state.pagination = { ...state.pagination, ...action.payload }
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        }
    }
})

export const callLogActions = callLogSlice.actions
export default callLogSlice.reducer
