import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditMatchListPayload } from '@app-store/slices/matchLists/edit'

interface TaskAction extends Action {
    payload: IEditMatchListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchLists.edit

function* editMatchListSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/companies/match-lists/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editMatchListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editMatchListSagaTask)
}

export default editMatchListSaga
