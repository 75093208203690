import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/tags/list'
import createTagReducer, { createTagActions } from '@app-store/slices/tags/create'
import createTagByAdminReducer, { createTagByAdminActions } from '@app-store/slices/tags/createByAdmin'
import deleteTagReducer, { deleteTagActions } from '@app-store/slices/tags/delete'
import editTagReducer, { editTagActions } from '@app-store/slices/tags/edit'
import listByTokenReducer, { listByTokenActions } from '@app-store/slices/tags/listByToken'
import createTagByTokenReducer, { createTagByTokenActions } from '@app-store/slices/tags/createByToken'
import editTagByAdminReducer, { editTagByAdminActions } from '@app-store/slices/tags/editByAdmin'
import listByAdminReducer, { listByAdminActions } from '@app-store/slices/tags/listByAdmin'
import deleteTagByAdminReducer, { deleteTagByAdminActions } from '@app-store/slices/tags/deleteByAdmin'
export const tagsReducer = combineReducers({
    list: listReducer,
    create: createTagReducer,
    createByAdmin: createTagByAdminReducer,
    delete: deleteTagReducer,
    edit: editTagReducer,
    listByToken: listByTokenReducer,
    createByToken: createTagByTokenReducer,
    listByAdmin: listByAdminReducer,
    editByAdmin: editTagByAdminReducer,
    deleteByAdmin: deleteTagByAdminReducer
})

export const tagsActions = {
    list: listActions,
    create: createTagActions,
    createByAdmin: createTagByAdminActions,
    delete: deleteTagActions,
    edit: editTagActions,
    listByToken: listByTokenActions,
    createByToken: createTagByTokenActions,
    listByAdmin: listByAdminActions,
    editByAdmin: editTagByAdminActions,
    deleteByAdmin: deleteTagByAdminActions
}
