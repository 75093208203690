import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IPrePublishRequestPayload } from '@app-store/slices/editList/prePublishList'
import { history } from '@components/App'
import routes from '@utils/constants/routes'

interface TaskAction extends Action {
    payload: IPrePublishRequestPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.editList.prePublishList

function* prePublishListSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/edit-lists/pre-publish/${payload.id}`, { ...payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.List_pre_published_successfully || 'List has been pre published successfully.'
            })
            history.replace(routes.directory.assigned._)
            yield put(rootActions.ui.directory.onSelectRows([]))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* prePublishListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, prePublishListSagaTask)
}

export default prePublishListSaga
