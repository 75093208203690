import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateServiceData {
    _id?: string
    name: string
    price?: number
    currency?: string
    description?: string
    category?: string
    type: 'selling'
    company: string
    images: string[]
}
export interface ICreateServicePayload extends ISagaCb {
    data: ICreateServiceData
    serviceImages?: any
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createService = createSlice({
    name: 'service/create-service',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateServicePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createServiceActions = createService.actions
export default createService.reducer
