import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import cloneDeep from 'lodash/cloneDeep'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers, manipulateListReducers } from '../reducers'

const itpListSlice = createSlice({
    name: 'hub/companies/itp-list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onChangeItem(state, action) {
            const newData = cloneDeep(state.data)
            const item = newData.find(item => item._id === action.payload.id)
            Object.keys(action.payload.data).map(key => {
                item[key] = action.payload.data[key]
            })
            if (action.payload.data.status === 'Active') {
                newData
                    .filter(itpItem => itpItem._id !== item._id)
                    .forEach(itpItemp => {
                        itpItemp.status = 'Inactive'
                    })
            }
            state.data = newData
        },
        ...listSideEffectReducers,
        ...manipulateListReducers
    }
})

export const itpListActions = itpListSlice.actions
export default itpListSlice.reducer
