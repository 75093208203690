import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IShareItpPayload extends ISagaCb {
    data: {
        batch?: string[]
        sharedWithCompanies?: {
            company: string
            contacts: string[]
        }[]
        emailContent?: string
        itpId?: string
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const shareItp = createSlice({
    name: 'company/share-itp',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IShareItpPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const shareItpActions = shareItp.actions
export default shareItp.reducer
