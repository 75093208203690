import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { makeRequestQuery } from '@utils/helpers/queryHelpers'
import { IContactAdvancedListQueryPayload } from '@app-store/slices/contacts/advancedList'
import { companyAdvancedListQueryMap } from '@utils/QueryMaps'

interface TaskAction extends Action {
    payload: IContactAdvancedListQueryPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.advancedList
const { onChangeItemCount } = rootActions.companies.statistics

// This saga is for getting specific advanced list companies
function* advancedListSagaTask({ payload }) {
    try {
        const params = yield getParams(payload)
        let query: any = payload.query || undefined

        if (payload?.userType && payload?.userType !== 'all') {
            query.userType = payload?.userType
        }
        if (payload.query) {
            query = { ...query, ...payload.query }
        } else {
            const requestQuery = makeRequestQuery(companyAdvancedListQueryMap)
            query = { ...query, ...JSON.parse(requestQuery) }
        }
        if (query) Object.assign(params, { query })
        const response = yield axios.get(`/companies/advanced-list`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload?.sagaCB?.onSuccess(response.result)
            }
            yield put(onChangeItemCount({ key: 'publishedCompanies', value: response.result.total }))
        } else {
            yield put(onFailure(''))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* advancedListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, advancedListSagaTask)
}

export default advancedListSaga
