import { createSlice } from '@reduxjs/toolkit'
import store from 'store'
import { STATUS, LOCALSTORAGE_KEYS } from '@features/General'
const onboarding = store.get(LOCALSTORAGE_KEYS.ONBOARDING_ORGANIZER)
const initialState = {
    status: STATUS.IDLE,
    details: {
        title: null,
        type: 'Product',
        deliveryDate: null,
        expireDate: '2022-10-31',
        description: null
    },
    products: [],
    services: [],
    categories: [],
    attachments: [],
    images: {},
    id: null
}

const rfqCreateSlice = createSlice({
    name: 'hub/rfqs/create',
    initialState: onboarding || initialState,
    reducers: {
        setDetails(state, action) {
            state['details'][action.payload.type] = action.payload.value
        },
        setCategories(state, action) {
            state['categories'] = [...action.payload]
        },
        setAllDetails(state, action) {
            state['details'] = { ...action.payload.value }
        },
        editProducts(state, action) {
            state['products'][action.payload.indis][action.payload.key] = action.payload.value
        },
        removeProducts(state, action) {
            const newArr = state.products.filter(i => i.product !== action.payload._id)
            state['products'] = newArr
        },
        setProducts(state, action) {
            const arr = []
            arr.push(action.payload)
            state.products = [...state.products, action.payload]
        },
        setServices(state, action) {
            const arr = []
            arr.push(action.payload)
            state.services = [...state.services, action.payload]
        },
        editServices(state, action) {
            state['services'][action.payload.indis][action.payload.key] = action.payload.value
        },
        removeServices(state, action) {
            const newArr = state.services.filter(i => i._id !== action.payload._id)
            state['services'] = newArr
        },
        setAttachments(state, action) {
            state['attachments'] = action.payload
        },
        setImage(state, action) {
            state['images'][action.payload.type] = action.payload.value
        },
        reset(state) {
            store.remove(LOCALSTORAGE_KEYS.ONBOARDING_ORGANIZER)
            Object.assign(state, initialState)
        },
        onRequestDetails(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccessDetails(state, action) {
            state.status = STATUS.READY
            state = initialState
        },
        onRequestId(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccessId(state, action) {
            state.status = STATUS.READY
            state['id'] = state.id
        },
        onFailureDetails(state, action) {
            state.status = STATUS.ERROR
        },
        onRequestBrand(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccessBrand(state, action) {
            state.status = STATUS.READY
            state = initialState
        },
        onFailureBrand(state, action) {
            state.status = STATUS.ERROR
        },
        onRequestCategory(state) {
            state.status = STATUS.RUNNING
        },
        onSuccessCategory(state) {
            state.status = STATUS.READY
            state = initialState
        },
        onFailureCategory(state) {
            state.status = STATUS.ERROR
        }
    }
})

export const createActions = rfqCreateSlice.actions
export default rfqCreateSlice.reducer
