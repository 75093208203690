import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IUrlPropertyPayload } from '@app-store/slices/properties/urls'

interface TaskAction extends Action {
    payload: IUrlPropertyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.properties.urls

function* urlPropertySagaTask({ payload }) {
    try {
        const response = yield axios.post(`/attachments/urls`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* urlPropertySaga(): any {
    yield takeLatest<TaskAction>(onRequest, urlPropertySagaTask)
}

export default urlPropertySaga
