import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteEventTopicPayload } from '@app-store/slices/eventTopics/deleteEventTopic'

interface TaskAction extends Action {
    payload: IDeleteEventTopicPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventTopics.delete

function* deleteEventTopicSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/event/event-topic/${payload.eventTopicId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Event_deleted_successfully || 'Event deleted successfully.'
            })
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteEventTopicSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteEventTopicSagaTask)
}

export default deleteEventTopicSaga
