import { createSlice } from '@reduxjs/toolkit'

export interface ICommonState {
    selectedRows: any[]
    shareModal: {
        isOpen: boolean
    }
    groupModal: {
        isOpen: boolean
    }
    emailCredentialModal: {
        isOpen: boolean
        contactId?: string
        companyId?: string
    }
    availabilityCalendarModal: {
        isOpen: boolean
    }
    duplicateCompanyModal: {
        isOpen: boolean
    }
    successGroupModal: {
        isOpen?: boolean
        groupId: string
    }
    matchListModal: {
        isOpen: boolean
    }
    enrichModal: {
        isOpen: boolean
        companyId?: string
        contactId?: string
    }
    enrichInfoModal: {
        isOpen: boolean
        matchingSuggestions: any
        companyId: string
        companyName: string
        logo: string
    }
    editAssignedListModal: {
        isOpen: boolean
    }
    newWishListModal: {
        isOpen: boolean
        ownerCompany: string
        listName: string
        ids: string[]
    }
    sendMessageModal: {
        type: 'contact' | 'company'
        isOpen: boolean
        receivers: string[]
    }
    publishModal: {
        isOpen: boolean
        isInternalPublish: boolean
        publishButtontitle: string
        id?: string
    }
    verifyContactModal: {
        isOpen: boolean
        isCompany: boolean
        companyName?: string
        companyId?: string
    }
}

const initialState: ICommonState = {
    selectedRows: [],
    shareModal: {
        isOpen: false
    },
    groupModal: {
        isOpen: false
    },
    emailCredentialModal: {
        isOpen: false,
        contactId: '',
        companyId: ''
    },
    availabilityCalendarModal: {
        isOpen: false
    },
    duplicateCompanyModal: {
        isOpen: false
    },
    successGroupModal: {
        isOpen: false,
        groupId: ''
    },
    matchListModal: {
        isOpen: false
    },
    enrichModal: {
        isOpen: false,
        companyId: '',
        contactId: ''
    },
    enrichInfoModal: {
        isOpen: false,
        matchingSuggestions: null,
        companyId: '',
        companyName: '',
        logo: null
    },
    editAssignedListModal: {
        isOpen: false
    },
    newWishListModal: {
        isOpen: false,
        ownerCompany: '',
        listName: '',
        ids: []
    },
    sendMessageModal: {
        isOpen: false,
        receivers: [],
        type: 'contact'
    },
    publishModal: {
        isOpen: false,
        isInternalPublish: false,
        publishButtontitle: '',
        id: ''
    },
    verifyContactModal: {
        isOpen: false,
        isCompany: false,
        companyName: '',
        companyId: ''
    }
}

const directorySlice = createSlice({
    name: 'ui/directory',
    initialState,
    reducers: {
        onSelectRows(state, acion) {
            state.selectedRows = acion.payload
        },
        toggleShareModal(state) {
            state.shareModal.isOpen = !state.shareModal.isOpen
        },
        toggleGroupModal(state) {
            state.groupModal.isOpen = !state.groupModal.isOpen
        },
        setEmailCredentialModal(state, action) {
            state.emailCredentialModal.isOpen = !state.emailCredentialModal.isOpen
            state.emailCredentialModal.companyId = action.payload.companyId
            state.emailCredentialModal.contactId = action.payload.contactId
        },
        setAvailabilityCalendarModal(state) {
            state.availabilityCalendarModal.isOpen = !state.availabilityCalendarModal.isOpen
        },
        setDuplicateCompanyModal(state) {
            state.duplicateCompanyModal.isOpen = !state.duplicateCompanyModal.isOpen
        },
        setSuccessGroupModal(state, action) {
            state.successGroupModal.isOpen = !state.successGroupModal.isOpen
            state.successGroupModal.groupId = action.payload.groupId
        },
        toggleMatchListModal(state) {
            state.matchListModal.isOpen = !state.matchListModal.isOpen
        },
        setEnrichModalInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.enrichModal[item] = action.payload[item]
            }
        },
        setEnrichInfoModalInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.enrichInfoModal[item] = action.payload[item]
            }
        },
        setVerifyContactModalVisible(state, action) {
            state.verifyContactModal.isOpen = action.payload.isOpen
            state.verifyContactModal.isCompany = action.payload.isCompany
            state.verifyContactModal.companyName = action.payload.companyName
            state.verifyContactModal.companyId = action.payload.companyId
        },
        onChangeEditAssignedListModal(state, action) {
            state.editAssignedListModal.isOpen = action.payload
        },
        onChangeNewWishListModal(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.newWishListModal[item] = action.payload[item]
            }
        },
        onChangeSendMessageModal(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.sendMessageModal[item] = action.payload[item]
            }
        },
        onChangePublishModal(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.publishModal[item] = action.payload[item]
            }
        }
    }
})

export const directoryActions = directorySlice.actions
export default directorySlice.reducer
