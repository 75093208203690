import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IGetStepStatisticsPayload } from '@app-store/slices/campaigns/getStepStatistics'

interface TaskAction extends Action {
    payload: IGetStepStatisticsPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.getStepStatistics

function* getStepStatisticsSagaTask({ payload }) {
    try {
        const params = {
            query: { step: payload.stepId, campaign: payload.campaignId }
        }
        const response = yield axios.get(`/notifications/emails/statistics`, {
            params
        })
        if (response.success) {
            yield payload?.sagaCB.onSuccess(response.result)
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getStepStatisticsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, getStepStatisticsSagaTask)
}

export default getStepStatisticsSaga
