import { put, takeEvery } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IGetStepMeetingStatisticsPayload } from '@app-store/slices/campaigns/getStepMeetingStatistics'

interface TaskAction extends Action {
    payload: IGetStepMeetingStatisticsPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.getStepMeetingStatistics

function* getStepMeetingStatisticsSagaSagaTask({ payload }) {
    try {
        const params = {
            query: { stepId: payload.stepId, campaignId: payload.campaignId }
        }
        const response = yield axios.get(`/meeting/stats/meeting-by-campaign`, {
            params
        })
        if (response.success) {
            yield payload?.sagaCB.onSuccess(response.result)
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getStepMeetingStatisticsSagaSaga(): any {
    yield takeEvery<TaskAction>(onRequest, getStepMeetingStatisticsSagaSagaTask)
}

export default getStepMeetingStatisticsSagaSaga
