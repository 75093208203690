import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IDeleteMatchListGroupPayload {
    matchListGroupId: string
}

export interface IDeleteMatchListGroupState {
    status: string
    data: any
    errors: any
}

const initialState: IDeleteMatchListGroupState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const deleteMatchListGroup = createSlice({
    name: 'matchListGroup/delete',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IDeleteMatchListGroupPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const deleteMatchListGroupActions = deleteMatchListGroup.actions
export default deleteMatchListGroup.reducer
