import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IOneRatingRequestPayload extends ISagaCb {
    rateId: string
}

export interface IOneRatingState {
    status: string
    data: any
    errors: any
}

const initialState: IOneRatingState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneRating = createSlice({
    name: 'momentum/one-rating',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneRatingRequestPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneRatingActions = oneRating.actions
export default oneRating.reducer
