import { combineReducers } from 'redux'
import createReducer, { createMatchListActions } from '@app-store/slices/matchLists/create'
import editReducer, { editMatchListActions } from '@app-store/slices/matchLists/edit'
import generateListReducer, { generateListActions } from '@app-store/slices/matchLists/generateList'
import generateBpaMatchReducer, { generateBpaMatchActions } from '@app-store/slices/matchLists/generateBpaMatch'
import generateBpaMatchBatchReducer, {
    generateBpaMatchBatchActions
} from '@app-store/slices/matchLists/generateBpaMatchBatch'
import calculateScoreReducer, { calculateScoreActions } from '@app-store/slices/matchLists/calculateScore'
import listReducer, { listMatchListActions } from '@app-store/slices/matchLists/list'
import listByGroupReducer, { listByGroupActions } from '@app-store/slices/matchLists/listByGroup'
import oneMatchListReducer, { oneMatchListActions } from '@app-store/slices/matchLists/oneMatchList'
import deleteReducer, { deleteMatchListActions } from '@app-store/slices/matchLists/delete'
import matchListViewReducer, { matchListViewActions } from '@app-store/slices/matchLists/view.slice'
import removeCompanyReducer, { removeCompanyActions } from '@app-store/slices/matchLists/removeCompany'
import addCompaniesReducer, { addCompaniesActions } from '@app-store/slices/matchLists/addCompanies'

export const matchListsReducer = combineReducers({
    create: createReducer,
    edit: editReducer,
    generateList: generateListReducer,
    generateBpaMatch: generateBpaMatchReducer,
    generateBpaMatchBatch: generateBpaMatchBatchReducer,
    calculateScore: calculateScoreReducer,
    list: listReducer,
    listByGroup: listByGroupReducer,
    oneMatchList: oneMatchListReducer,
    delete: deleteReducer,
    view: matchListViewReducer,
    removeCompany: removeCompanyReducer,
    addCompanies: addCompaniesReducer
})

export const matchListsActions = {
    create: createMatchListActions,
    edit: editMatchListActions,
    generateList: generateListActions,
    generateBpaMatch: generateBpaMatchActions,
    generateBpaMatchBatch: generateBpaMatchBatchActions,
    calculateScore: calculateScoreActions,
    list: listMatchListActions,
    listByGroup: listByGroupActions,
    oneMatchList: oneMatchListActions,
    delete: deleteMatchListActions,
    view: matchListViewActions,
    removeCompany: removeCompanyActions,
    addCompanies: addCompaniesActions
}
