import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateRatingPayload } from '@app-store/slices/momentum/createRating'

interface TaskAction extends Action {
    payload: ICreateRatingPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.momentum.create

function* momentumCreateRateSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/rating`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* momentumCreateRateSaga(): any {
    yield takeLatest<TaskAction>(onRequest, momentumCreateRateSagaTask)
}

export default momentumCreateRateSaga
