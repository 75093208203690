import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IGetPreSignedUploadURLState {
    status: string
    data: {
        file: string
        uploadURL: string
    }
    errors: any
}

const initialState: IGetPreSignedUploadURLState = {
    status: STATUS.IDLE,
    data: {
        file: '',
        uploadURL: ''
    },
    errors: null
}

const getPreSignedUploadURLSlice = createSlice({
    name: 'hub/companies/get-presigned-upload-URL',
    initialState,
    reducers: {
        onRequest(state) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getPreSignedUploadURLActions = getPreSignedUploadURLSlice.actions
export default getPreSignedUploadURLSlice.reducer
