import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditTablePayload } from '@app-store/slices/table/edit'

interface TaskAction extends Action {
    payload: IEditTablePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.table.edit

function* editTableSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.patch(`/meeting/table/${payload.tableId}`, payload.data)
        if (response.success) {
            yield put(onSuccess({ response: response.result, tableId: payload.tableId }))
            if (payload?.sagaCB) {
                payload.sagaCB.onSuccess()
            }
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Table_edited_successfully || 'Table edited successfully.'
            })
        } else {
            if (payload?.sagaCB?.onError) {
                payload.sagaCB.onError()
            }
            throw new Error('')
        }
    } catch (error) {
        if (payload?.sagaCB?.onError) {
            payload.sagaCB.onError()
        }
        yield put(onFailure({ error, tableId: payload.tableId }))
    }
}

function* editTableSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editTableSagaTask)
}

export default editTableSaga
