import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRemoveCardPayload } from '@app-store/slices/subscriptionPayment/removeCard'

interface TaskAction extends Action {
    payload: IRemoveCardPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.removeCard
const { onRemoveCard } = rootActions.subscriptionPayment.cardList

function* removeCardSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/subscriptions/payments/card`, { data: payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(onRemoveCard(payload.data.card))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* removeCardSaga(): any {
    yield takeLatest<TaskAction>(onRequest, removeCardSagaTask)
}

export default removeCardSaga
