import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.listStaticEnums

function* listMeetingStaticEnumsSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/meeting/static-enums`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listMeetingAgendaSaga(): any {
    yield takeLatest<TaskAction>(onRequest, listMeetingStaticEnumsSagaTask)
}

export default listMeetingAgendaSaga
