import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateGroupPayload } from '@app-store/slices/group/create'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICreateGroupPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.group.create
const { onAddToList } = rootActions.group.list

function* createGroupSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/group`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(onAddToList(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Group_created_successfully || 'Group created successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createGroupSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createGroupSagaTask)
}

export default createGroupSaga
