import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IChatLoginPayload } from '@app-store/slices/chatDetails/login'
import { LOCALSTORAGE_KEYS } from '@features/General'

interface TaskAction extends Action {
    payload: IChatLoginPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chatDetails.login

function* chatLoginSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/users/chat-details/login`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield localStorage.setItem(LOCALSTORAGE_KEYS.ROCKET_CHAT_INFO, JSON.stringify(response.result))
            yield payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* chatLoginSaga(): any {
    yield takeLatest<TaskAction>(onRequest, chatLoginSagaTask)
}

export default chatLoginSaga
