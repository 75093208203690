import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type IUploadImagePayload = string

export interface IUploadImageState {
    uploadProgress: 0
    status: string
    errors: any
}

const initialState: IUploadImageState = {
    uploadProgress: 0,
    status: STATUS.IDLE,
    errors: null
}

const UploadImageSlice = createSlice({
    name: 'user/upload-image',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IUploadImagePayload>) {
            state.uploadProgress = 0
            state.status = STATUS.RUNNING
        },
        onSuccess(state) {
            state.status = STATUS.READY
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onUploadFileProgress(state, action: PayloadAction<any>) {
            state.uploadProgress = action.payload
        }
    }
})

export const UploadImageActions = UploadImageSlice.actions
export default UploadImageSlice.reducer
