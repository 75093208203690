import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListState, ISagaCb } from '@typings/requestPayloads'
export interface ICallStatisticPayload extends ISagaCb {
    data: {
        startTime: string
        endTime: string
        metrics?: boolean
    }
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const callStatisticSlice = createSlice({
    name: 'call-statistic',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICallStatisticPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const callStatisticActions = callStatisticSlice.actions
export default callStatisticSlice.reducer
