import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type IFileUploadPayload = string

export interface IFileUploadState {
    uploadProgress: 0
    status: string
    errors: any
}

const initialState: IFileUploadState = {
    uploadProgress: 0,
    status: STATUS.IDLE,
    errors: null
}

const fileUploadSlice = createSlice({
    name: 'hub/data-import/file-upload',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IFileUploadPayload>) {
            state.uploadProgress = 0
            state.status = STATUS.RUNNING
        },
        onSuccess(state) {
            state.status = STATUS.READY
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onUploadFileProgress(state, action: PayloadAction<any>) {
            state.uploadProgress = action.payload
        }
    }
})

export const fileUploadActions = fileUploadSlice.actions
export default fileUploadSlice.reducer
