import axios from 'axios'
import { getAuthToken } from './helpers/chatHelpers'
import { chatServerURLWithVersion } from 'config'
// export const vchost = "vc server link"
const { token, userId } = getAuthToken()
let headers

if (token) {
    headers = {
        'X-Auth-Token': token,
        'X-User-Id': userId
    }
} else {
    headers = {}
}

const instance = axios.create({
    baseURL: chatServerURLWithVersion,
    timeout: 3000,
    headers
})

export default instance
