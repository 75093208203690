import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteSharedListPayload } from '@app-store/slices/sharedLists/delete'

interface TaskAction extends Action {
    payload: IDeleteSharedListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.delete

function* deleteSharedListSagaTask({ payload }) {
    try {
        const sharedListId = payload.sharedListId || payload.data.sharedListId
        const response = yield axios.delete(`/companies/shared-lists/${sharedListId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Matching?.Shared_list_deleted_successfully || 'Shared list deleted successfully.'
            })
            yield payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteSharedListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteSharedListSagaTask)
}

export default deleteSharedListSaga
