import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    data: any
    company: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: [],
    company: [],
    errors: null
}

const contactCompanySlice = createSlice({
    name: 'hub/rfq/contact-company-profile',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload?.data || action.payload
        },
        onCompanyData(state, action) {
            state.status = STATUS.READY
            state.company = action.payload?.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const contactCompanyProfileActions = contactCompanySlice.actions
export default contactCompanySlice.reducer
