import { createSlice } from '@reduxjs/toolkit'

export interface IProductViewState {
    newProductModal: boolean
    editProductModal: boolean
    viewProductModal: boolean
    productId: any
}

const initialState: IProductViewState = {
    newProductModal: false,
    editProductModal: false,
    viewProductModal: false,
    productId: null
}

const productViewSlice = createSlice({
    name: 'dashboard/company_profile/view',
    initialState,
    reducers: {
        setNewProductModal(state, action) {
            state.newProductModal = action.payload
        },
        setEditProductModal(state, action) {
            state.editProductModal = action.payload.visible
            state.productId = action.payload.productId
        },
        setViewProductModal(state, action) {
            state.viewProductModal = action.payload.visible
            state.productId = action.payload.productId
        }
    }
})

export const productViewActions = productViewSlice.actions
export default productViewSlice.reducer
