import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'
import { ISagaCb } from '@typings/requestPayloads'

export interface ICreateWishListsPayload extends ISagaCb {
    data: any
}

interface ICreateState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const wishListsCreateSlice = createSlice({
    name: 'hub/wish-lists/create',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateWishListsPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const wishListsCreateActions = wishListsCreateSlice.actions
export default wishListsCreateSlice.reducer
