import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteMatchListGroupPayload } from '@app-store/slices/matchListGroups/delete'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IDeleteMatchListGroupPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchListGroups.delete

function* deleteMatchListGroupSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete(`/companies/match-list-groups/${payload.matchListGroupId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.Matching?.Match_list_group_deleted_successfully || 'Match list group deleted successfully.'
            })
            yield put(rootActions.matchListGroups.list.onRemoveFromList(payload.matchListGroupId))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteMatchListGroupSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteMatchListGroupSagaTask)
}

export default deleteMatchListGroupSaga
