import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

export interface ILocalizationDataItems {
    data?: any
}

const initialState: ILocalizationDataItems = {
    data: {}
}

const localizationSlice = createSlice({
    name: 'ui/localization',
    initialState,
    reducers: {
        addData(state, action: PayloadAction<ILocalizationDataItems>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.data = { ...state.data, ...action.payload[item] }
            }
        },
        reset(state) {
            state.data = {}
        },
        extraReducers: (builder: any) => {
            builder.addCase(PURGE, state => {
                state = initialState
            })
        }
    }
})

export const localizationActions = localizationSlice.actions
export default localizationSlice.reducer
