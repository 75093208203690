import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IEditPropertyByTokenPayload extends ISagaCb {
    propertyId: string
    data: {
        content?: string
        visibility?: string
        token: string
    }
}

export interface IEditPropertyByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: IEditPropertyByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editPropertyByToken = createSlice({
    name: 'property/edit-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditPropertyByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editPropertyByTokenActions = editPropertyByToken.actions
export default editPropertyByToken.reducer
