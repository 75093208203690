import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IEditServicePayload extends ISagaCb {
    id: string
    data: any
    serviceImages?: any
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editService = createSlice({
    name: 'service/edit-service',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IEditServicePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editServiceActions = editService.actions
export default editService.reducer
