import listSaga from './listSaga'
import createSaga from './createSaga'
import deleteSaga from './deleteSaga'
import editSaga from './editSaga'
import onePropertySaga from './onePropertySaga'
import urlPropertySaga from './urlSaga'
import oneUrlSaga from './oneUrlSaga'
import listSearchSaga from './listSearchSaga'
import listByTokenSaga from './listByTokenSaga'
import createByTokenSaga from './createByTokenSaga'
import deleteByTokenSaga from './deleteByTokenSaga'
import editByTokenSaga from './editByTokenSaga'

const propertiesSaga = [
    listSaga(),
    createSaga(),
    deleteSaga(),
    editSaga(),
    onePropertySaga(),
    urlPropertySaga(),
    oneUrlSaga(),
    listSearchSaga(),
    listByTokenSaga(),
    createByTokenSaga(),
    deleteByTokenSaga(),
    editByTokenSaga()
]

export default propertiesSaga
