import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IPausePlanPayload extends ISagaCb {
    resumeAt?: string
    subscriptionId: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const pausePlan = createSlice({
    name: 'subscription-payment/pause-plan',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IPausePlanPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const pausePlanActions = pausePlan.actions
export default pausePlan.reducer
