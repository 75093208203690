import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { rootActions } from '@app-store/slices'
import { OneTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.calendar.oneAvailability

function* oneAvailabilitySagaTask({ payload }) {
    try {
        const response = yield axios.get(`/meeting/user-availabilities/calendar/one/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneAvailabilitySaga(): any {
    yield takeLatest<OneTaskAction>(onRequest, oneAvailabilitySagaTask)
}

export default oneAvailabilitySaga
