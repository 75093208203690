import { createSlice } from '@reduxjs/toolkit'

export interface IUserViewState {
    from: string
}

const initialState: IUserViewState = {
    from: ''
}

const userViewSlice = createSlice({
    name: 'user/view',
    initialState,
    reducers: {
        setFrom(state, action) {
            state.from = action.payload
        }
    }
})

export const userViewActions = userViewSlice.actions
export default userViewSlice.reducer
