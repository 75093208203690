export const awsDevConfig = {
    BASEURL: 'https://apiv3.aladdinb2b.com/api/v0.3',
    AWS_CLIEND_ID: '24ec93dg85c5rrral0i2nkmlo5',
    AWS_USER_POOL: 'eu-west-1_5z9KfiCwx'
}
export const awsQaConfig = {
    BASEURL: 'https://apiv3.aladdinb2b.com/api/v0.3',
    AWS_CLIEND_ID: '24ec93dg85c5rrral0i2nkmlo5',
    AWS_USER_POOL: 'eu-west-1_5z9KfiCwx'
}
export const awsProdConfig = {
    BASEURL: 'https://prod-apiv3.aladdinb2b.com/api/v0.3',
    AWS_CLIEND_ID: 'vvs4a92miao4a61o9o20ggug6',
    AWS_USER_POOL: 'eu-west-1_4rHcagdaz'
}

console.log(`It's running in ${process.env.NODE_ENV}`)

export const awsConfig =
    process.env.NODE_ENV === 'development'
        ? awsDevConfig
        : process.env.NODE_ENV === 'production'
        ? awsProdConfig
        : awsDevConfig
