import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateRatingByTokenPayload } from '@app-store/slices/momentum/createRatingByToken'

interface TaskAction extends Action {
    payload: ICreateRatingByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.momentum.createByToken

function* momentumCreateRateByTokenSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/add-rating-by-meeting-token`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* momentumCreateRateByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, momentumCreateRateByTokenSagaTask)
}

export default momentumCreateRateByTokenSaga
