import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CorrectionURLRequestType = {
    id: string
    downloadQuick?: boolean
}

export interface IGetPreSignedCorrectionURLState {
    status: string
    data: any
    errors: any
}

const initialState: IGetPreSignedCorrectionURLState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const getPreSignedCorrectionURLSlice = createSlice({
    name: 'hub/data-import/get-presigned-correction-URL',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<CorrectionURLRequestType>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getPreSignedCorrectionURLActions = getPreSignedCorrectionURLSlice.actions
export default getPreSignedCorrectionURLSlice.reducer
