import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IEditUserPayload extends ISagaCb {
    id: string
    data: any
    avatar: any
}

interface IEditState {
    status: string
    data: any
    errors: any
}

const initialState: IEditState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const userEditSlice = createSlice({
    name: 'hub/user/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditUserPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const userEditActions = userEditSlice.actions
export default userEditSlice.reducer
