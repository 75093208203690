import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import { IConfirmRegistrationPayload } from '@app-store/slices/auth/confirmRegistration'
import { confirmRegistrationCognitoUser } from '@utils/authenticateService'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: IConfirmRegistrationPayload
}

const { onFailure, onSuccess, onRequest } = rootActions.auth.confirmRegistration

function* confirmRegistrationSaga({ payload }: TaskAction) {
    try {
        const response = yield confirmRegistrationCognitoUser({ code: payload.code, username: payload.username })
        yield put(onSuccess(response))
        yield payload.sagaCB?.onSuccess()
    } catch (e) {
        yield put(onFailure('code is incorrect'))
    }
}

function* confirmRegistrationCodeSaga(): any {
    yield takeLatest<TaskAction>(onRequest, confirmRegistrationSaga)
}

export default confirmRegistrationCodeSaga
