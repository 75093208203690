import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ILanguageTypesState {
    status: string
    language: string
    languages: any
    direction: string
    errors: any
}

const initialState: ILanguageTypesState = {
    status: STATUS.IDLE,
    language: '',
    languages: [],
    direction: 'ltr',
    errors: null
}
const languageTypes = createSlice({
    name: 'localization/language-types',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.languages = action.payload.supportedLanguages
            state.direction = action.payload.direction
            state.language = action.payload.language
        },
        setLang(state, action) {
            state.direction = action.payload.direction
            state.language = action.payload.language
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const languageTypesActions = languageTypes.actions
export default languageTypes.reducer
