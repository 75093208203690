import { createSlice } from '@reduxjs/toolkit'

export interface IExhibitorOnboardingViewState {
    skipSyncCalendarModal: boolean
    serviceViewModal: {
        isOpen: boolean
        data: Record<string, string>
    }
    selectedService: Record<string, string>
}

const initialState: IExhibitorOnboardingViewState = {
    skipSyncCalendarModal: false,
    serviceViewModal: {
        isOpen: false,
        data: {}
    },
    selectedService: {}
}

const exhibitorOnboardingViewSlice = createSlice({
    name: 'exhibitor/onboarding/view',
    initialState,
    reducers: {
        setskipSyncCalendarModal(state, action) {
            state.skipSyncCalendarModal = action.payload
        },
        setServiceViewModal(state, action) {
            state.serviceViewModal = action.payload
        },
        setSelectedService(state, action) {
            state.selectedService = action.payload
        }
    }
})

export const exhibitorOnboardingViewActions = exhibitorOnboardingViewSlice.actions
export default exhibitorOnboardingViewSlice.reducer
