import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditMeetingRequestPayload extends ISagaCb {
    requestId?: string
    data: any
}

export interface IEditMeetingRequestState {
    status: string
    data: any
    errors: any
}

const initialState: IEditMeetingRequestState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editMeetingRequest = createSlice({
    name: 'meeting-requests/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditMeetingRequestPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editMeetingRequestActions = editMeetingRequest.actions
export default editMeetingRequest.reducer
