import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditRatingPayload extends ISagaCb {
    rateId: string
    data: {
        stars: number
        meetingId?: string
        participantUserType?: string
        participantCompanyId?: string
    }
}

export interface IEditRatingState {
    status: string
    data: any
    errors: any
}

const initialState: IEditRatingState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editRating = createSlice({
    name: 'momentum/edit-rating',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditRatingPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editRatingActions = editRating.actions
export default editRating.reducer
