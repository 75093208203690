import initiateImportSaga from './initiateImportSaga'
import getPreSignedUploadURLSaga from './getPreSignedUploadURLSaga'
import finalizeImportSaga from './finalizeImportSaga'
import listSaga from './listSaga'
import fileUploadSaga from './fileUploadSaga'
import oneImportSaga from './oneImportSaga'
import getPreSignedCorrectionURLSaga from './getPreSignedCorrectionURLSaga'
import getPreSignedImportURLSaga from './getPreSignedImportURLSaga'
import getTemplateSaga from './getTemplate'

const dataImportSagas = [
    initiateImportSaga(),
    getPreSignedUploadURLSaga(),
    finalizeImportSaga(),
    listSaga(),
    fileUploadSaga(),
    oneImportSaga(),
    getPreSignedCorrectionURLSaga(),
    getPreSignedImportURLSaga(),
    getTemplateSaga()
]

export default dataImportSagas
