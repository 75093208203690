import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { makeRequestQuery } from '@utils/helpers/queryHelpers'
import { ListTaskAction } from '@typings/requestPayloads'
import { editListQueryMap } from '@utils/QueryMaps'

const { onRequest, onSuccess, onFailure } = rootActions.editList.list

function* listSagaTask({ payload }: ListTaskAction) {
    const params = yield getParams(payload)
    const query = payload.query || {}
    const editListQuery = makeRequestQuery(editListQueryMap)
    if (query) Object.assign(params, { query: { ...query, ...JSON.parse(editListQuery) } })
    try {
        const response = yield axios.get(`/companies/edit-lists`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listSagaTask)
}

export default listSaga
