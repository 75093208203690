import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IVisitorPotentialValuesPayloadState } from '@app-store/slices/companyStatistics/visitorPotentialValues'

interface TaskAction extends Action {
    payload: IVisitorPotentialValuesPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.companyStatistics.visitorPotentialValues

function* visitorPotentialValuesSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/companies/stats/visitor/potential-values`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* visitorPotentialValuesSaga(): any {
    yield takeLatest<TaskAction>(onRequest, visitorPotentialValuesSagaTask)
}

export default visitorPotentialValuesSaga
