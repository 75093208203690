import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IOneEventByDomainPayload extends ISagaCb {}

export interface IOneEventByDomain {
    status: string
    success: boolean
    data: any
    errors: any
}

const initialState: IOneEventByDomain = {
    status: STATUS.IDLE,
    success: true,
    data: null,
    errors: null
}

const oneEventByDomainSlice = createSlice({
    name: 'hub/events/one-event-by-domain',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneEventByDomainPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneEventByDomainActions = oneEventByDomainSlice.actions
export default oneEventByDomainSlice.reducer
