import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    data: {
        [key: string]: string[]
    }
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: {
        endpointType: [],
        featureType: [],
        method: []
    },
    errors: null
}

const staticEnumsSlice = createSlice({
    name: 'feature/static-enums',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const staticEnumsActions = staticEnumsSlice.actions
export default staticEnumsSlice.reducer
