import { combineReducers } from 'redux'
import allMeetingStatsReducer, { allMeetingStatsActions } from '@app-store/slices/meetingStatistics/allMeetingStats'
import allExhibitorMeetingStatsReducer, {
    allExhibitorMeetingStatsActions
} from '@app-store/slices/meetingStatistics/allExhibitorMeetingStats'
import exhibitorMeetingByDateReducer, {
    exhibitorMeetingByDateActions
} from '@app-store/slices/meetingStatistics/exhibitorMeetingByDate'
import exhibitorMeetingCostsReducer, {
    exhibitorMeetingCostsActions
} from '@app-store/slices/meetingStatistics/exhibitorMeetingCosts'
import exhibitorMeetingRatingsReducer, {
    exhibitorMeetingRatingsActions
} from '@app-store/slices/meetingStatistics/exhibitorMeetingRatings'
import meetingByDateReducer, { meetingByDateActions } from '@app-store/slices/meetingStatistics/meetingByDate'
import meetingByOperatorReducer, {
    meetingByOperatorActions
} from '@app-store/slices/meetingStatistics/meetingByOperator'
import meetingCostsReducer, { meetingCostsActions } from '@app-store/slices/meetingStatistics/meetingCosts'
import meetingRatingsReducer, { meetingRatingsActions } from '@app-store/slices/meetingStatistics/meetingRatings'
import meetingRequestReducer, { meetingRequestActions } from '@app-store/slices/meetingStatistics/meetingRequest'
import plannedMeetingReducer, { plannedMeetingActions } from '@app-store/slices/meetingStatistics/plannedMeeting'
import meetingRequestByStatusReducer, {
    meetingRequestByStatusActions
} from '@app-store/slices/meetingStatistics/meetingRequestByStatus'

export const meetingStatisticsReducer = combineReducers({
    allMeetingStats: allMeetingStatsReducer,
    allExhibitorMeetingStats: allExhibitorMeetingStatsReducer,
    exhibitorMeetingByDate: exhibitorMeetingByDateReducer,
    exhibitorMeetingCosts: exhibitorMeetingCostsReducer,
    exhibitorMeetingRatings: exhibitorMeetingRatingsReducer,
    meetingByDate: meetingByDateReducer,
    meetingByOperator: meetingByOperatorReducer,
    meetingCosts: meetingCostsReducer,
    meetingRatings: meetingRatingsReducer,
    meetingRequest: meetingRequestReducer,
    plannedMeeting: plannedMeetingReducer,
    meetingRequestByStatus: meetingRequestByStatusReducer
})

export const meetingStatisticsActions = {
    allMeetingStats: allMeetingStatsActions,
    allExhibitorMeetingStats: allExhibitorMeetingStatsActions,
    exhibitorMeetingByDate: exhibitorMeetingByDateActions,
    exhibitorMeetingCosts: exhibitorMeetingCostsActions,
    exhibitorMeetingRatings: exhibitorMeetingRatingsActions,
    meetingByDate: meetingByDateActions,
    meetingByOperator: meetingByOperatorActions,
    meetingCosts: meetingCostsActions,
    meetingRatings: meetingRatingsActions,
    meetingRequest: meetingRequestActions,
    plannedMeeting: plannedMeetingActions,
    meetingRequestByStatus: meetingRequestByStatusActions
}
