import { createSlice } from '@reduxjs/toolkit'
import store from 'store'
import { STATUS, LOCALSTORAGE_KEYS } from '@features/General'
const onboarding = store.get(LOCALSTORAGE_KEYS.ONBOARDING_ORGANIZER)
const initialState = {
    status: STATUS.IDLE,
    details: {
        title: null,
        description: null,
        condition: null,
        deliveryDate: '2022-06-18T00:00:00',
        expireDate: '2023-06-18T00:00:00',
        rfq: null,
        products: [],
        services: []
    }
}

const sendQuotationsSlice = createSlice({
    name: 'hub/quotations/send',
    initialState: onboarding || initialState,
    reducers: {
        setDetails(state, action) {
            state['details'][action.payload.type] = action.payload.value
        },
        setProducts(state, action) {
            state['details'].products.push(action.payload)
        },
        setServices(state, action) {
            // const arr = []
            // arr.push(action.payload)
            // state['details']['services'] = arr
            state['details'].services = [...state['details'].services, action.payload]
        },
        removeServices(state, action) {
            const newArr = state['details'].services.filter(i => i._id !== action.payload._id)
            state['details'].services = newArr
        },
        setDetailsServices(state, action) {
            state['details'].products.push(action.payload)
        },
        editServices(state, action) {
            state['details']['services'][action.payload.indis][action.payload.key] = action.payload.value
        },
        editProducts(state, action) {
            state['details']['products'][action.payload.indis][action.payload.key] = action.payload.value
        },
        removeProducts(state, action) {
            const newArr = state['details']['products'].filter(i => i.productCode != action.payload._id)
            state['details']['products'] = newArr
        }
    }
})

export const sendActions = sendQuotationsSlice.actions
export default sendQuotationsSlice.reducer
