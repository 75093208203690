import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ILocalsPayload } from '@app-store/slices/localization/locals'
import { COOKIE_KEYS, getCookie } from '@features/General'

interface TaskAction extends Action {
    payload: ILocalsPayload
}
const { onRequest, onSuccess, onFailure } = rootActions.localization.locals

function* localsSagaTask({ payload }) {
    const eventId = getCookie(COOKIE_KEYS.ALA_EVENT)
    const language = getCookie(COOKIE_KEYS.LANG)
    const { bundle, extraKeys } = payload

    const params = { language: language || 'en' }
    if (bundle) Object.assign(params, { bundle })
    if (extraKeys) Object.assign(params, { extraKeys })

    try {
        const response = yield axios.get(
            eventId ? '/integrations/localization' : '/integrations/localization/by-language',
            {
                params
            }
        )
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* localsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, localsSagaTask)
}

export default localsSaga
