import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditStepPayload {
    id?: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const checkStatusEvent = createSlice({
    name: 'campaign/check-status-event',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IEditStepPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action?.payload?.response?.data?.error || action.payload
        }
    }
})

export const checkStatusEventActions = checkStatusEvent.actions
export default checkStatusEvent.reducer
