import { createSlice } from '@reduxjs/toolkit'

export interface IContactViewState {
    copyInvitationModal: boolean
}

const initialState: IContactViewState = {
    copyInvitationModal: false
}

const contactViewSlice = createSlice({
    name: 'ui/contact',
    initialState,
    reducers: {
        setCopyInvitationModal(state, action) {
            state.copyInvitationModal = action.payload
        }
    }
})

export const contactActions = contactViewSlice.actions
export default contactViewSlice.reducer
