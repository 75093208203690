import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICallStatisticPayload } from '@app-store/slices/callLog/callStatistic'

interface TaskAction extends Action {
    payload: ICallStatisticPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.callLog.callStatistic

function* callStatisticSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/integrations/maqsam/call-statistics`, {
            params: payload.data
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* callStatisticSaga(): any {
    yield takeLatest<TaskAction>(onRequest, callStatisticSagaTask)
}

export default callStatisticSaga
