import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const matchListsSlice = createSlice({
    name: 'ui/match-list',
    initialState: { ...listUIInitialState, populates: [{ path: 'ownerCompany', select: '_id name' }] },
    reducers: listUIReducers
})

export const matchListsActions = matchListsSlice.actions
export default matchListsSlice.reducer
