import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditVisibilityPayload {
    settingId: string
    data: any
}

export interface IEditVisibilityState {
    status: string
    data: any
    errors: any
}

const initialState: IEditVisibilityState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editVisibility = createSlice({
    name: 'event-setting/edit-visibility',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditVisibilityPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editVisibilityActions = editVisibility.actions
export default editVisibility.reducer
