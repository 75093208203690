import { combineReducers } from 'redux'
import unSubscribeByTokenReducer, { unSubscribeByTokenActions } from '@store/slices/unSubscribe/unSubscribeByToken'
import unSubscribeReducer, { unSubscribeActions } from '@store/slices/unSubscribe/unSubscribe'
import unSubscribeInfoByTokenReducer, {
    unSubscribeInfoByTokenActions
} from '@store/slices/unSubscribe/unSubscribeInfoByToken'

export const unsubscribeReducers = combineReducers({
    unSubscribeByToken: unSubscribeByTokenReducer,
    unSubscribe: unSubscribeReducer,
    unSubscribeInfoByToken: unSubscribeInfoByTokenReducer
})

export const unsubscribeActions = {
    unSubscribeByToken: unSubscribeByTokenActions,
    unSubscribe: unSubscribeActions,
    unSubscribeInfoByToken: unSubscribeInfoByTokenActions
}
