import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers } from '../reducers'

const list = createSlice({
    name: 'email-credential/list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        onRemoveItem(state, action) {
            state.data = state.data.filter(item => item._id !== action.payload)
        },
        onEditItem(state, action) {
            const newData = [...state.data]
            const item = newData.find(item => item._id === action.payload._id)
            for (const key in action.payload) {
                item[key] = action.payload[key]
            }
            state.data = newData
        },
        onAddItem(state, action) {
            state.data = [...state.data, action.payload]
        }
    }
})

export const listActions = list.actions
export default list.reducer
