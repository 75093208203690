import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditUserStatusPayload {
    status: 'Inactive' | 'Active'
    id: string
}

interface IEditUserStatusState {
    status: string
    data: any
    errors: any
}

const initialState: IEditUserStatusState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const userEditStatusSlice = createSlice({
    name: 'hub/user/edit-status',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditUserStatusPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const userEditStatusActions = userEditStatusSlice.actions
export default userEditStatusSlice.reducer
