import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EnrichSource } from '@features/Directory/constants'
import { ISagaCb } from '@typings/requestPayloads'
export interface ILinkedinEnrichPayload extends ISagaCb {
    data: {
        ids?: string[]
        editList?: string
        source?: keyof typeof EnrichSource
        enrichContacts?: boolean
        checkCondition?: 'all' | 'company' | 'contacts'
        matchingUrl?: string
        assignedId?: string
        reportName?: string
        relatedRecordId?: string
        relatedRecordType?: string
        filters?: any
        editUrl?: string
        searchText?: string
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const linkedinEnrichSlice = createSlice({
    name: 'reporting/linkedin-enrich',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ILinkedinEnrichPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const linkedinEnrichActions = linkedinEnrichSlice.actions
export default linkedinEnrichSlice.reducer
