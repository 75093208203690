import { combineReducers } from 'redux'
import { authReducer, authActions } from '@app-store/slices/auth'
import { userReducer, userActions } from '@app-store/slices/users'
import { organizerReducer, organizerActions } from '@app-store/slices/organizer'
import { exhibitorReducer, exhibitorActions } from '@app-store/slices/exhibitor'
import { matchEngineReducer, matchEngineActions } from '@app-store/slices/match_engine'
import { emailCredentialReducer, emailCredentialActions } from '@app-store/slices/emailCredential'
import { campaignsReducer, campaignsActions } from '@app-store/slices/campaigns'
import { companiesReducer, companiesActions } from '@app-store/slices/companies'
import { chatDetailsReducer, chatDetailsActions } from '@app-store/slices/chatDetails'
import { chatReducer, chatActions } from '@app-store/slices/chat'
import { contactsReducer, contactsActions } from '@app-store/slices/contacts'
import { groupReducer, groupActions } from '@app-store/slices/group'
import { emailsReducer, emailsActions } from '@app-store/slices/emails'
import { meetingAgendaReducer, meetingAgendaActions } from '@app-store/slices/meetingAgenda'
import { meetingsReducer, meetingsActions } from '@app-store/slices/meetings'
import { hallReducer, hallActions } from '@app-store/slices/hall'
import { eventSettingReducer, eventSettingActions } from '@app-store/slices/eventSettings'
import { tableReducer, tableActions } from '@app-store/slices/table'
import { propertiesReducer, propertiesActions } from '@app-store/slices/properties'
import { matchListsReducer, matchListsActions } from '@app-store/slices/matchLists'
import { matchListGroupsReducer, matchListGroupsActions } from '@app-store/slices/matchListGroups'
import { sharedListsReducer, sharedListsActions } from '@app-store/slices/sharedLists'
import { uiReducer, uiActions } from '@app-store/slices/ui'
import { countriesReducer, countriesActions } from '@app-store/slices/countries'
import { citiesReducer, citiesActions } from '@app-store/slices/cities'
import { eventsReducer, eventsActions } from '@app-store/slices/events'
import { categoriesReducer, categoriesActions } from '@app-store/slices/categories'
import { eventTypeReducer, eventTypeActions } from '@app-store/slices/event-type'
import { sourceCategoriesReducer, sourceCategoriesActions } from '@app-store/slices/sourceCategories'
import { dataImportReducer, dataImportActions } from '@app-store/slices/dataImport'
import onboardingTemplatesReducer, { onboardingTemplatesActions } from '@app-store/slices/onboardingTemplates'
import { onbordingOrganizerReducer, onbordingOrganizerActions } from '@app-store/slices/onbordingOrganizer'
import { meetingRequestsReducer, meetingRequestsActions } from '@app-store/slices/meetingRequests'
import { eventTopicsReducer, eventTopicsActions } from '@app-store/slices/eventTopics'
import { conferencesReducer, conferencesActions } from '@app-store/slices/conferences'
import { sessionsReducer, sessionsActions } from '@app-store/slices/sessions'
import { speakersReducer, speakersActions } from '@app-store/slices/speakers'
import { wishListsReducer, wishListsActions } from '@app-store/slices/wishLists'
import { rolesPermissonsReducer, rolesPermissonsActions } from '@app-store/slices/rolesPermissions'
import { editListReducers, editListActions } from '@app-store/slices/editList'
import { tagsReducer, tagsActions } from '@app-store/slices/tags'
import { productsReducer, productsActions } from '@app-store/slices/products'
import { servicesReducer, servicesActions } from '@app-store/slices/services'
import { companyStatisticsReducer, companyStatisticsActions } from '@app-store/slices/companyStatistics'
import { meetingStatisticsReducer, meetingStatisticsActions } from '@app-store/slices/meetingStatistics'
import { momentumReducer, momentumActions } from '@app-store/slices/momentum'
import { notificationReducer, notificationActions } from '@app-store/slices/notifications'
import { scansReducer, scansActions } from '@app-store/slices/scans'
import { surveyReducer, surveyActions } from '@app-store/slices/survey'
import { integrationServicesReducer, integrationServicesActions } from '@app-store/slices/integrationServices'
import { crmReducer, crmActions } from '@app-store/slices/crm'
import { templateReducers, templateActions } from '@app-store/slices/templates'
import { localizationReducers, localizationActions } from '@app-store/slices/localization'
import { subscriptionReducers, subscriptionActions } from '@app-store/slices/subscriptions'
import { companySubscriptionReducers, companySubscriptionActions } from '@app-store/slices/companySubscriptions'
import { subscriptionOrderReducers, subscriptionOrderActions } from '@app-store/slices/subscriptionOrder'
import { subscriptionPaymentReducers, subscriptionPaymentActions } from '@app-store/slices/subscriptionPayment'
import { supportReducer, supportActions } from '@app-store/slices/support'
import { reportingReducers, reportingActions } from '@app-store/slices/reporting'
import { badgesReducers, badgesActions } from '@app-store/slices/badges'
import { rfqsReducer, rfqsActions } from '@app-store/slices/rfqs'
import { quotationsReducer, quotationsActions } from '@app-store/slices/quotations'
import { tourReducers, tourActions } from '@app-store/slices/tours'
import { demoReducers, demoActions } from '@app-store/slices/demos'
import { transactionReducers, transactionActions } from '@app-store/slices/transactions'
import { linkedInAccountReducers, linkedInAccountActions } from '@app-store/slices/linkedInAccount'
import { auditTrailsReducers, auditTrailsActions } from '@app-store/slices/auditTrails'
import { callLogReducers, callLogActions } from '@app-store/slices/callLog'
import { calendarReducers, calendarActions } from '@app-store/slices/calendar'
import { newFeatureReducers, newFeatureActions } from '@app-store/slices/newFeature'
import { moduleReducers, moduleActions } from '@app-store/slices/module'
import { labelTagReducers, labelTagActions } from '@app-store/slices/labelTag'
import { qrReducers, qrActions } from '@app-store/slices/qr'
import { userStatisticsReducers, userStatisticsActions } from '@app-store/slices/userStatistics'
import { featureReducer, featureActions } from '@app-store/slices/feature'
import { eventSubscriptionReducers, eventSubscriptionActions } from '@app-store/slices/eventSubscriptions'
import { unsubscribeReducers, unsubscribeActions } from '@store/slices/unSubscribe'
export const rootReducer = combineReducers({
    auth: authReducer,
    organizer: organizerReducer,
    exhibitor: exhibitorReducer,
    matchEngine: matchEngineReducer,
    emailCredential: emailCredentialReducer,
    campaigns: campaignsReducer,
    companies: companiesReducer,
    chatDetails: chatDetailsReducer,
    chat: chatReducer,
    contacts: contactsReducer,
    group: groupReducer,
    users: userReducer,
    emails: emailsReducer,
    meetingAgenda: meetingAgendaReducer,
    meetings: meetingsReducer,
    hall: hallReducer,
    eventSetting: eventSettingReducer,
    ui: uiReducer,
    countries: countriesReducer,
    cities: citiesReducer,
    events: eventsReducer,
    categories: categoriesReducer,
    eventType: eventTypeReducer,
    table: tableReducer,
    properties: propertiesReducer,
    matchLists: matchListsReducer,
    matchListGroups: matchListGroupsReducer,
    sharedLists: sharedListsReducer,
    sourceCategories: sourceCategoriesReducer,
    dataImport: dataImportReducer,
    onboardingTemplates: onboardingTemplatesReducer,
    onbordingOrganizer: onbordingOrganizerReducer,
    meetingRequests: meetingRequestsReducer,
    eventTopics: eventTopicsReducer,
    conferences: conferencesReducer,
    sessions: sessionsReducer,
    speakers: speakersReducer,
    tags: tagsReducer,
    wishLists: wishListsReducer,
    editList: editListReducers,
    rolesPermissions: rolesPermissonsReducer,
    companyStatistics: companyStatisticsReducer,
    meetingStatistics: meetingStatisticsReducer,
    products: productsReducer,
    services: servicesReducer,
    momentum: momentumReducer,
    notification: notificationReducer,
    scans: scansReducer,
    survey: surveyReducer,
    integrationServices: integrationServicesReducer,
    crm: crmReducer,
    templates: templateReducers,
    localization: localizationReducers,
    subscriptions: subscriptionReducers,
    companySubscriptions: companySubscriptionReducers,
    orders: subscriptionOrderReducers,
    subscriptionPayment: subscriptionPaymentReducers,
    support: supportReducer,
    reporting: reportingReducers,
    badges: badgesReducers,
    rfqs: rfqsReducer,
    quotations: quotationsReducer,
    tours: tourReducers,
    demos: demoReducers,
    transactions: transactionReducers,
    linkedInAccounts: linkedInAccountReducers,
    auditTrails: auditTrailsReducers,
    callLog: callLogReducers,
    calendar: calendarReducers,
    newFeature: newFeatureReducers,
    module: moduleReducers,
    labelTag: labelTagReducers,
    qr: qrReducers,
    userStatistics: userStatisticsReducers,
    feature: featureReducer,
    eventSubscriptions: eventSubscriptionReducers,
    unSubscribe: unsubscribeReducers
})

export const rootActions = {
    auth: authActions,
    organizer: organizerActions,
    exhibitor: exhibitorActions,
    matchEngine: matchEngineActions,
    emailCredential: emailCredentialActions,
    campaigns: campaignsActions,
    companies: companiesActions,
    chatDetails: chatDetailsActions,
    chat: chatActions,
    contacts: contactsActions,
    group: groupActions,
    users: userActions,
    emails: emailsActions,
    meetingAgenda: meetingAgendaActions,
    meetings: meetingsActions,
    hall: hallActions,
    table: tableActions,
    eventSetting: eventSettingActions,
    ui: uiActions,
    rolesPermissions: rolesPermissonsActions,
    countries: countriesActions,
    cities: citiesActions,
    events: eventsActions,
    categories: categoriesActions,
    eventType: eventTypeActions,
    properties: propertiesActions,
    matchLists: matchListsActions,
    matchListGroups: matchListGroupsActions,
    sharedLists: sharedListsActions,
    sourceCategories: sourceCategoriesActions,
    dataImport: dataImportActions,
    onboardingTemplates: onboardingTemplatesActions,
    onbordingOrganizer: onbordingOrganizerActions,
    meetingRequests: meetingRequestsActions,
    eventTopics: eventTopicsActions,
    conferences: conferencesActions,
    sessions: sessionsActions,
    speakers: speakersActions,
    tags: tagsActions,
    wishLists: wishListsActions,
    editList: editListActions,
    companyStatistics: companyStatisticsActions,
    meetingStatistics: meetingStatisticsActions,
    products: productsActions,
    services: servicesActions,
    momentum: momentumActions,
    notification: notificationActions,
    scans: scansActions,
    survey: surveyActions,
    integrationServices: integrationServicesActions,
    crm: crmActions,
    templates: templateActions,
    localization: localizationActions,
    subscriptions: subscriptionActions,
    orders: subscriptionOrderActions,
    companySubscriptions: companySubscriptionActions,
    subscriptionPayment: subscriptionPaymentActions,
    support: supportActions,
    reporting: reportingActions,
    badges: badgesActions,
    rfqs: rfqsActions,
    quotations: quotationsActions,
    tours: tourActions,
    demos: demoActions,
    transactions: transactionActions,
    linkedInAccounts: linkedInAccountActions,
    auditTrails: auditTrailsActions,
    callLog: callLogActions,
    calendar: calendarActions,
    newFeature: newFeatureActions,
    module: moduleActions,
    labelTag: labelTagActions,
    qr: qrActions,
    userStatistics: userStatisticsActions,
    feature: featureActions,
    eventSubscriptions: eventSubscriptionActions,
    unSubscribe: unsubscribeActions
}
