import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const templatesSlice = createSlice({
    name: 'demos/templates',
    initialState,
    reducers: {
        onRequest(state) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const templatesActions = templatesSlice.actions
export default templatesSlice.reducer
