// import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

const { onRequest, onSuccess, onFailure } = rootActions.quotations.createCustom

function* createCustomSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/quotations/custom`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                payload.sagaCB?.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createCustomSaga(): any {
    yield takeLatest(onRequest, createCustomSagaTask)
}

export default createCustomSaga
