import { createSlice } from '@reduxjs/toolkit'

export interface IContactViewState {
    from: string
}

const initialState: IContactViewState = {
    from: ''
}

const contactViewSlice = createSlice({
    name: 'contact/view',
    initialState,
    reducers: {
        setFrom(state, action) {
            state.from = action.payload
        }
    }
})

export const contactViewActions = contactViewSlice.actions
export default contactViewSlice.reducer
