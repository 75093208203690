import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/companyStatistics/list'
import devicesReducer, { devicesActions } from '@app-store/slices/companyStatistics/devices'
import exhibitorDevicesReducer, { exhibitorDevicesActions } from '@app-store/slices/companyStatistics/exhibitorDevices'
import exhibitorOrdersReducer, { exhibitorOrdersActions } from '@app-store/slices/companyStatistics/exhibitorOrders'
import exhibitorPotentialValuesReducer, {
    exhibitorPotentialValuesActions
} from '@app-store/slices/companyStatistics/exhibitorPotentialValues'
import exhibitorQutationRFQStatsReducer, {
    exhibitorQutationRFQStatsActions
} from '@app-store/slices/companyStatistics/exhibitorQutationRFQStats'
import matchListGroupStatsReducer, {
    matchListGroupStatsActions
} from '@app-store/slices/companyStatistics/matchListGroupStats'
import matchListStatsReducer, { matchListStatsActions } from '@app-store/slices/companyStatistics/matchListStats'
import allMatchListsStatsReducer, {
    allMatchListsStatsActions
} from '@app-store/slices/companyStatistics/allMatchListsStats'
import ordersReducer, { ordersActions } from '@app-store/slices/companyStatistics/orders'
import potentialValuesReducer, { potentialValuesActions } from '@app-store/slices/companyStatistics/potentialValues'
import qutationRFQStatsReducer, { qutationRFQStatsActions } from '@app-store/slices/companyStatistics/qutationRFQStats'
import visitorPotentialValuesReducer, {
    visitorPotentialValuesActions
} from '@app-store/slices/companyStatistics/visitorPotentialValues'
import contactsStateReducer, { contactsStateActions } from '@app-store/slices/companyStatistics/contactsState'

export const companyStatisticsReducer = combineReducers({
    list: listReducer,
    devices: devicesReducer,
    exhibitorDevices: exhibitorDevicesReducer,
    exhibitorOrders: exhibitorOrdersReducer,
    exhibitorPotentialValues: exhibitorPotentialValuesReducer,
    exhibitorQutationRFQStats: exhibitorQutationRFQStatsReducer,
    matchListGroupStats: matchListGroupStatsReducer,
    matchListStats: matchListStatsReducer,
    orders: ordersReducer,
    potentialValues: potentialValuesReducer,
    qutationRFQStats: qutationRFQStatsReducer,
    visitorPotentialValues: visitorPotentialValuesReducer,
    allMatchListsStats: allMatchListsStatsReducer,
    contactsState: contactsStateReducer
})

export const companyStatisticsActions = {
    list: listActions,
    devices: devicesActions,
    exhibitorDevices: exhibitorDevicesActions,
    exhibitorOrders: exhibitorOrdersActions,
    exhibitorPotentialValues: exhibitorPotentialValuesActions,
    exhibitorQutationRFQStats: exhibitorQutationRFQStatsActions,
    matchListGroupStats: matchListGroupStatsActions,
    matchListStats: matchListStatsActions,
    orders: ordersActions,
    potentialValues: potentialValuesActions,
    qutationRFQStats: qutationRFQStatsActions,
    visitorPotentialValues: visitorPotentialValuesActions,
    allMatchListsStats: allMatchListsStatsActions,
    contactsState: contactsStateActions
}
