import React from 'react'
import { useDispatch } from 'react-redux'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import { COOKIE_KEYS, getCookie } from '@features/General'
import { getSubDomain } from '@features/Auth'
import routes from '@utils/constants/routes'
import { rootActions } from '@app-store/slices'
import { useUser } from '../../../hooks'

function PrivateRoute({ children, component: Component, ...rest }: RouteProps) {
    const hasToken = !isEmpty(getCookie(COOKIE_KEYS.ALA_TOKEN))

    return (
        <Route
            {...rest}
            render={props =>
                hasToken ? (
                    <>{children || <Component {...props} />}</>
                ) : (
                    <Redirect
                        to={{
                            pathname: routes.login._,
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    )
}

export function FlatRouterProvider({ children, ...rest }: RouteProps) {
    const hasToken = !isEmpty(getCookie(COOKIE_KEYS.ALA_TOKEN))
    const { user } = useUser()
    const dispatch = useDispatch()

    const redirectionRoute = () => {
        const isAdmin = find(
            user?.eventInfos,
            item => item?.role?.title === 'Admin' && item?.role?.type === 'Organizer'
        )
        const subdomain = getSubDomain()
        if ((!subdomain && isAdmin) || user?.eventInfos?.length === 0) {
            return routes.organizer.events._
        } else if (!subdomain) {
            dispatch(rootActions.companies.onboarding.reset())
            return routes.exhibitor.onboarding.events._
        } else {
            return routes.organizer._
        }
    }
    return (
        <Route
            {...rest}
            render={({ location }) => (
                <Redirect
                    to={{
                        pathname: hasToken && user ? redirectionRoute() : routes.login._,
                        state: { from: location }
                    }}
                />
            )}
        />
    )
}

export default PrivateRoute
