import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {}

const { onRequest, onSuccess, onFailure } = rootActions.scans.analytics

function* analyticsSagaTask() {
    try {
        const params = {}
        const response = yield axios.get(`companies/scan/analytics`, { params })

        if (response.success) {
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* analyticsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, analyticsSagaTask)
}

export default analyticsSaga
