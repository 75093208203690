import { LOCALSTORAGE_KEYS } from '@features/General'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.events.searchEvents

function* searchEventsSagaTask({ payload }: ListTaskAction) {
    try {
        const params: any = {
            ...payload.pagination,
            query: payload.query
        }
        const response = yield axios.get(`/search/events`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            localStorage.setItem(LOCALSTORAGE_KEYS.EVENT_LIST, JSON.stringify(response.result.data))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* searchEventsSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, searchEventsSagaTask)
}

export default searchEventsSaga
