import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateProductData {
    _id?: string
    name: string
    price?: number
    targetPrice?: number
    color?: string
    productCode?: string
    currency?: string
    brand?: string
    description?: string
    quantity?: number
    category?: string
    type: 'selling'
    company: string
    images: string[]
}
export interface ICreateProductPayload extends ISagaCb {
    data: ICreateProductData
    productImages?: any
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createProduct = createSlice({
    name: 'product/create-product',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateProductPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createProductActions = createProduct.actions
export default createProduct.reducer
