import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditListEditPayload } from '@app-store/slices/editList/edit'

interface TaskAction extends Action {
    payload: IEditListEditPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.editList.edit

function* editSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/edit-lists/${payload.id}`, { ...payload.data })
        if (response.success) {
            yield payload.sagaCB?.onSuccess(response.result)
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.Assigned_list_updated_successfully || 'Assigned list updated successfully.'
            })
        } else {
            yield payload.sagaCB?.onError()
        }
    } catch (error) {
        yield payload.sagaCB?.onError()
        yield put(onFailure(error))
    }
}

function* editSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editSagaTask)
}

export default editSaga
