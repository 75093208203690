import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRejectOrderPayload } from '@app-store/slices/subscriptionOrder/reject'

interface TaskAction extends Action {
    payload: IRejectOrderPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.orders.reject

function* rejectOrderTask({ payload }) {
    try {
        const response = yield axios.get(`/subscriptions/orders/reject/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* rejectOrderSaga(): any {
    yield takeLatest<TaskAction>(onRequest, rejectOrderTask)
}

export default rejectOrderSaga
