import { combineReducers } from 'redux'
import downloadAgendasReducer, { downloadAgendasActions } from '@app-store/slices/reporting/downloadAgendas'
import sendAgendasReducer, { sendAgendasActions } from '@app-store/slices/reporting/sendAgendas'
import sendTestAgendasReducer, { sendTestAgendasActions } from '@app-store/slices/reporting/sendTestAgendas'
import eventReportReducer, { eventReportActions } from '@app-store/slices/reporting/eventReport'
import linkedinEnrichReducer, { linkedinEnrichActions } from '@app-store/slices/reporting/linkedinEnrich'
import scrapeReportReducer, { scrapeReportActions } from '@app-store/slices/reporting/scrapeReport'
import auditTrailReportReducer, { auditTrailReportActions } from '@app-store/slices/reporting/auditTrailReport'
import queueStatisticsReducer, { queueStatisticsActions } from '@app-store/slices/reporting/queueStatistics'
import salesNavigatorFiltersReducer, {
    salesNavigatorFiltersActions
} from '@app-store/slices/reporting/salesNavigatorFilters'
import updateCompanyStatsReducer, { updateCompanyStatsActions } from './updateCompanyStats'
import meetingReportReducer, { meetingReportActions } from '@app-store/slices/reporting/meetingReport'
import surveyReportReducer, { surveyReportActions } from '@app-store/slices/reporting/surveyReport'
import meetingRequestReportReducer, {
    meetingRequestReportActions
} from '@app-store/slices/reporting/meetingRequestReport'

export const reportingReducers = combineReducers({
    downloadAgendas: downloadAgendasReducer,
    sendAgendas: sendAgendasReducer,
    eventReport: eventReportReducer,
    sendTestAgendas: sendTestAgendasReducer,
    linkedinEnrich: linkedinEnrichReducer,
    scrapeReport: scrapeReportReducer,
    auditTrailReport: auditTrailReportReducer,
    queueStatistics: queueStatisticsReducer,
    salesNavigatorFilters: salesNavigatorFiltersReducer,
    updateCompanyStats: updateCompanyStatsReducer,
    meetingReport: meetingReportReducer,
    surveyReport: surveyReportReducer,
    meetingRequestReport: meetingRequestReportReducer
})

export const reportingActions = {
    downloadAgendas: downloadAgendasActions,
    sendAgendas: sendAgendasActions,
    eventReport: eventReportActions,
    sendTestAgendas: sendTestAgendasActions,
    linkedinEnrich: linkedinEnrichActions,
    scrapeReport: scrapeReportActions,
    auditTrailReport: auditTrailReportActions,
    queueStatistics: queueStatisticsActions,
    salesNavigatorFilters: salesNavigatorFiltersActions,
    updateCompanyStats: updateCompanyStatsActions,
    meetingReport: meetingReportActions,
    surveyReport: surveyReportActions,
    meetingRequestReport: meetingRequestReportActions
}
