import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IContactsStatusStatisticsPayload extends ISagaCb {
    fromDate?: string
    toDate?: string
    actionBy?: string
}

export interface IContactsStatusStatisticsState {
    status: string
    data: any
    errors: any
}

const initialState: IContactsStatusStatisticsState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const contactsStatusStatisticsSlice = createSlice({
    name: 'companies/contacts-status-statistics',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IContactsStatusStatisticsPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const contactsStatusStatisticsActions = contactsStatusStatisticsSlice.actions
export default contactsStatusStatisticsSlice.reducer
