import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationType } from '@typings/requestPayloads'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers } from '../reducers'

export interface IListQueryPaginationPayload {
    pagination?: PaginationType
    id?: string
}

const assignedListSlice = createSlice({
    name: 'hub/contacts/assigned-list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers
    }
})

export const assignedListActions = assignedListSlice.actions
export default assignedListSlice.reducer
