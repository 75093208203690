import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListState } from '@typings/requestPayloads'

const initialState: IListState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const getActiveSlice = createSlice({
    name: 'subscription/get-active-event-subscription',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getActiveActions = getActiveSlice.actions
export default getActiveSlice.reducer
