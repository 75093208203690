import { combineReducers } from 'redux'
import listScansReducer, { listScansActions } from '@app-store/slices/scans/list'
import createScanReducer, { createScanActions } from '@app-store/slices/scans/create'
import oneScanReducer, { oneScanActions } from '@app-store/slices/scans/one'
import analyticsReducer, { analyticsActions } from '@app-store/slices/scans/analytics'

export const scansReducer = combineReducers({
    list: listScansReducer,
    create: createScanReducer,
    one: oneScanReducer,
    analytics: analyticsReducer
})

export const scansActions = {
    list: listScansActions,
    create: createScanActions,
    one: oneScanActions,
    analytics: analyticsActions
}
