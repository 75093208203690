import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'
import { AnyCnameRecord } from 'dns'

export interface IAuthSliderState {
    status: string
    email: string
    password?: string
    cognitoUser: AnyCnameRecord
    isActivation: boolean
    redirectUrl: string
    errors: any
}

const initialState: IAuthSliderState = {
    status: STATUS.IDLE,
    email: '',
    password: '',
    cognitoUser: null,
    isActivation: false,
    redirectUrl: '',
    errors: null
}

const authSlider = createSlice({
    name: 'ui/auth-slider',
    initialState,
    reducers: {
        setLoggedInInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state[item] = action.payload[item]
            }
        }
    }
})

export const authSliderActions = authSlider.actions
export default authSlider.reducer

// https://redux-toolkit.js.org/api/createSlice
