import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICheckDomainPayload extends ISagaCb {
    subdomain: string
}

export interface ICheckDomain {
    status: string
    success: boolean
    result: boolean
    errors: any
}

const initialState: ICheckDomain = {
    status: STATUS.IDLE,
    success: true,
    result: false,
    errors: null
}

const checkDomainSlice = createSlice({
    name: 'hub/events/check-domain',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICheckDomainPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const checkDomainActions = checkDomainSlice.actions
export default checkDomainSlice.reducer
