import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEvaluatePlaceholderPayload } from '@app-store/slices/templates/evaluatePlaceholder'

interface TaskAction extends Action {
    payload: IEvaluatePlaceholderPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.templates.evaluatePlaceholder

function* evaluatePlaceholderSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/notifications/templates/evaluate-placeholder`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload.sagaCB?.onError === 'function') {
            payload.sagaCB?.onError()
        }
    }
}

function* evaluatePlaceholderSaga(): any {
    yield takeLatest<TaskAction>(onRequest, evaluatePlaceholderSagaTask)
}

export default evaluatePlaceholderSaga
