import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IOneCityPayload } from '@app-store/slices/cities/oneCity'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IOneCityPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.cities.one

function* oneCitySagaTask({ payload }) {
    try {
        const response = yield axios.get(`/companies/cities/one/${payload.cityId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') {
            payload.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* oneCitySaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneCitySagaTask)
}

export default oneCitySaga
