import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { getOffsetFromTimezone, getStartOrEndTimeOfDate } from '@features/General/dayjs/hanlers'

dayjs.extend(timezone)
export const prepareDownloadAndSendQuery = (companyId, pureFilters, isMeetingReport = false) => {
    const query: any = {}
    if (companyId) {
        query.companies = [companyId]
    }
    Object.keys(pureFilters).forEach(key => {
        const value = pureFilters[key]
        if (isMeetingReport && key === 'meetingRequest') {
            if (value === false) query[key] = { $exists: false }
            else if (value === true) query[key] = { $exists: true }
        } else if (value.length) {
            if (key === 'meetingDate') {
                if (value[0]) {
                    query[isMeetingReport ? 'startTime' : key] = {
                        $gte: getStartOrEndTimeOfDate(value[0])
                    }
                }

                if (value[1]) {
                    const date = getStartOrEndTimeOfDate(value[1])
                    if (isMeetingReport) query.endTime = { $lte: date }
                    else query[key].$lte = date
                }
                // value.utc().set({ hour: 0, minute: 0, second: 0 }).format()
            } else if (key === 'timeShift') {
                const offset = getOffsetFromTimezone(value)
                query[key] = offset
            } else {
                query[key] = value
            }
        }
    })
    return query
}
