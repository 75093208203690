import { combineReducers } from 'redux'
import dataImportFlowReducer, { dataImportFlowActions } from '@app-store/slices/dataImport/flow'
import initiateImportReducer, { initiateImportActions } from '@app-store/slices/dataImport/initiateImport'
import finalizeImportReducer, { finalizeImportActions } from '@app-store/slices/dataImport/finalizeImport'
import fileUploadReducer, { fileUploadActions } from '@app-store/slices/dataImport/fileUpload'
import listReducer, { listActions } from '@app-store/slices/dataImport/list'
import oneImportReducer, { oneImportActions } from '@app-store/slices/dataImport/oneImport'
import getPreSignedCorrectionURLReducer, {
    getPreSignedCorrectionURLActions
} from '@app-store/slices/dataImport/getPreSignedCorrectionURL'
import getPreSignedUploadURLReducer, {
    getPreSignedUploadURLActions
} from '@app-store/slices/dataImport/getPreSignedUploadURL'
import getPreSignedImportUrlReducer, {
    getPreSignedImportUrlActions
} from '@app-store/slices/dataImport/getPreSignedImportURL'
import getTemplateReducer, { getTemplateActions } from '@app-store/slices/dataImport/getTemplate'

export const dataImportReducer = combineReducers({
    flow: dataImportFlowReducer,
    initiateImport: initiateImportReducer,
    finalizeImport: finalizeImportReducer,
    getPreSignedUpload: getPreSignedUploadURLReducer,
    getPreSignedCorrectionURL: getPreSignedCorrectionURLReducer,
    getPreSignedImportUrl: getPreSignedImportUrlReducer,
    list: listReducer,
    fileUpload: fileUploadReducer,
    oneImport: oneImportReducer,
    getTemplate: getTemplateReducer
})

export const dataImportActions = {
    flow: dataImportFlowActions,
    initiateImport: initiateImportActions,
    finalizeImport: finalizeImportActions,
    getPreSignedUpload: getPreSignedUploadURLActions,
    getPreSignedCorrectionURL: getPreSignedCorrectionURLActions,
    getPreSignedImportUrl: getPreSignedImportUrlActions,
    list: listActions,
    fileUpload: fileUploadActions,
    oneImport: oneImportActions,
    getTemplate: getTemplateActions
}
