import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IDisconnectPayload extends ISagaCb {
    crmId: string
}

export interface IDisconnectState {
    status: string
    crmId: string
    errors: any
}

const initialState: IDisconnectState = {
    status: STATUS.IDLE,
    crmId: undefined,
    errors: null
}

const disconnectSlice = createSlice({
    name: 'crm/disconnect',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IDisconnectPayload>) {
            state.status = STATUS.RUNNING
            state.crmId = actions.payload.crmId
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.crmId = undefined
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.crmId = undefined
            state.errors = action.payload
        }
    }
})

export const disconnectActions = disconnectSlice.actions
export default disconnectSlice.reducer
