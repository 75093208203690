import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const companyMatchlistsSlice = createSlice({
    name: 'ui/company-match-lists',
    initialState: {
        ...listUIInitialState,
        populates: [
            { path: 'sharedWithContact', select: 'firstname lastname email details' },
            {
                path: 'sharedWithCompany',
                select: '_id userType website img name updatedAt logo matchingPercentage matchingSuggestions'
            },
            { path: 'matchList', select: '_id title' }
        ]
    },
    reducers: listUIReducers
})

export const companyMatchlistsActions = companyMatchlistsSlice.actions
export default companyMatchlistsSlice.reducer
