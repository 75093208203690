import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateEmailCredential } from '@app-store/slices/emailCredential/create'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: ICreateEmailCredential
}

const { onRequest, onSuccess, onFailure } = rootActions.emailCredential.create

function* createSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/notifications/email-credentials`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(rootActions.emailCredential.list.onAddItem(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Your_credential_created_successfully || 'Your credential is created successfully.'
            })
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createSagaTask)
}

export default createSaga
