import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateVisibilityPayload } from '@app-store/slices/eventSettings/createVisibility'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICreateVisibilityPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.createVisibility

function* createEventSettingSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/event/settings/${payload.settingId}/group-visibility`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.Event?.Visibility_setting_created_successfully || 'Visibility setting created successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createEventSettingSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createEventSettingSagaTask)
}

export default createEventSettingSaga
