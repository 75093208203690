import { combineReducers } from 'redux'
import createSessionReducer, { createSessionActions } from '@app-store/slices/sessions/createSession'
import editSessionReducer, { editSessionActions } from '@app-store/slices/sessions/editSession'
import deleteSessionReducer, { deleteSessionActions } from '@app-store/slices/sessions/deleteSession'
import oneSessionReducer, { oneSessionActions } from '@app-store/slices/sessions/oneSession'
import listReducer, { listActions } from '@app-store/slices/sessions/list'

export const sessionsReducer = combineReducers({
    list: listReducer,
    create: createSessionReducer,
    edit: editSessionReducer,
    oneSession: oneSessionReducer,
    delete: deleteSessionReducer
})

export const sessionsActions = {
    list: listActions,
    create: createSessionActions,
    edit: editSessionActions,
    oneSession: oneSessionActions,
    delete: deleteSessionActions
}
