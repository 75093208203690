import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: string
}

const { onRequest, onSuccess, onFailure } = rootActions.dataImport.oneImport
const { onSuccess: finalizeImportSuccess } = rootActions.dataImport.finalizeImport

function* oneImportSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/companies/imports/one/${payload}`)

        if (response.success) {
            yield put(finalizeImportSuccess(response.result))
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneImportSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneImportSagaTask)
}

export default oneImportSaga
