import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteLabelTagPayload } from '@app-store/slices/labelTag/delete'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IDeleteLabelTagPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.labelTag.delete

function* deleteLabelTagSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete(`/attachments/badges/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.General?.Tag_deleted_successfully || 'Tag deleted successfully.'
            })
            yield put(rootActions.labelTag.list.onRemoveFromList(payload.id))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteLabelTagSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteLabelTagSagaTask)
}

export default deleteLabelTagSaga
