import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const auditTrailReportingSlice = createSlice({
    name: 'ui/audit-trail-reporting',
    initialState: {
        ...listUIInitialState
    },
    reducers: {
        ...listUIReducers
    }
})

export const auditTrailReportingActions = auditTrailReportingSlice.actions
export default auditTrailReportingSlice.reducer
