import listSaga from './listSaga'
import staticEnumsSaga from './staticEnumsSaga'
import createSaga from './createSaga'
import editSaga from './editSaga'
import oneTourSaga from './oneTourSaga'
import deleteTourSaga from './deleteSaga'

const toursSagas = [listSaga(), staticEnumsSaga(), createSaga(), editSaga(), oneTourSaga(), deleteTourSaga()]

export default toursSagas
