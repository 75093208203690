import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@store/slices'
import { IAddUserPayload } from '@app-store/slices/users/addUser'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: IAddUserPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.addUserCreate
const { setAddUserModal } = rootActions.ui.companyProfile

function* addUserCreateSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/users/add-user-with-role`, payload)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(setAddUserModal(false))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* addUserCreateSaga(): any {
    yield takeLatest<TaskAction>(onRequest, addUserCreateSagaTask)
}

export default addUserCreateSaga
