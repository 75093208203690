import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const incomingQuotationsSlice = createSlice({
    name: 'ui/incoming-quotations',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const incomingQuotationsActions = incomingQuotationsSlice.actions
export default incomingQuotationsSlice.reducer
