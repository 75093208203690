import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ISelectCompaniesPayload } from '@app-store/slices/sharedLists/selectCompanies'
interface TaskAction extends Action {
    payload: ISelectCompaniesPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.selectCompanies

function* selectCompaniesSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/shared-lists/select/${payload.sharedListId}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* selectCompaniesSaga(): any {
    yield takeLatest<TaskAction>(onRequest, selectCompaniesSagaTask)
}

export default selectCompaniesSaga
