import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditSubscriptionPayload } from '@app-store/slices/subscriptions/edit'
import notification from 'antd/lib/notification'
import store from '@app-store/store'

interface TaskAction extends Action {
    payload: IEditSubscriptionPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptions.edit
const { onEditSubscription } = rootActions.subscriptions.list

function* editSubscriptionSagaTask({ payload }) {
    try {
        const response = yield axios.patch(`/subscriptions/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            store.dispatch(
                rootActions.subscriptions.oneSubscription.onRequest({
                    id: payload.id,
                    needPopulate: true,
                    populates: [{ path: 'features', select: '_id name featureType isActive' }],
                    sagaCB: {
                        onSuccess: result => {
                            store.dispatch(onEditSubscription(result))
                        }
                    }
                })
            )
            yield payload.sagaCB?.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.subscription_edited_successfully || 'Subscription edited successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editSubscriptionSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editSubscriptionSagaTask)
}

export default editSubscriptionSaga
