import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditEventProfilesPayload extends ISagaCb {
    data: {
        companyProfileItems: any
        industries?: string
    }
    id: string
}
export interface IEditEventProfilesState {
    status: string
    data: any
    errors: any
}

const initialState: IEditEventProfilesState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editEventProfiles = createSlice({
    name: 'events/edit-event-profiles',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditEventProfilesPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
            state.data = {}
        }
    }
})

export const editEventProfilesActions = editEventProfiles.actions
export default editEventProfiles.reducer
