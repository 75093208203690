import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IInternalPublishContactRequestPayload } from '@app-store/slices/editList/internalPublishContactList'

interface TaskAction extends Action {
    payload: IInternalPublishContactRequestPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.editList.internalPublishContactList

function* internalPublishContactListSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/contacts/internal-publish/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.TradeHub?.List_internal_published_successfully ||
                    'List has been internal published successfully.'
            })
            yield put(rootActions.ui.directory.onSelectRows([]))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* internalPublishContactListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, internalPublishContactListSagaTask)
}

export default internalPublishContactListSaga
