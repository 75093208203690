import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRejectCompaniesPayload } from '@app-store/slices/sharedLists/rejectCompanies'
interface TaskAction extends Action {
    payload: IRejectCompaniesPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.rejectCompanies

function* rejectCompaniesSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/shared-lists/reject/${payload.sharedListId}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) {
                payload.sagaCB.onSuccess()
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* rejectCompaniesSaga(): any {
    yield takeLatest<TaskAction>(onRequest, rejectCompaniesSagaTask)
}

export default rejectCompaniesSaga
