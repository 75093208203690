import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteTourPayload } from '@app-store/slices/tours/delete'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IDeleteTourPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.tours.delete

function* deleteTourSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete(`/event/tour/${payload.id}`)
        if (response.success) {
            yield put(onSuccess({ response: response.result }))
            if (payload?.sagaCB) {
                payload.sagaCB.onSuccess()
            }
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Settings?.Tour_deleted_successfully || 'Tour deleted successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure({ error, id: payload.id }))
    }
}

function* deleteTourSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteTourSagaTask)
}

export default deleteTourSaga
