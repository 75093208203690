import listSaga from './listSaga'
import oneEventSaga from './oneEventSaga'
import editEventSaga from './editSaga'
import deleteEventSaga from './deleteSaga'
import searchEventsSaga from './searchEvents'
import checkDomainSaga from './checkDomainSaga'
import oneEventByDomain from './oneEventByDomain'
import checkEventPublishabilitySaga from './checkEventPublishability'

const eventsSagas = [
    listSaga(),
    searchEventsSaga(),
    oneEventSaga(),
    editEventSaga(),
    deleteEventSaga(),
    checkDomainSaga(),
    oneEventByDomain(),
    checkEventPublishabilitySaga()
]

export default eventsSagas
