import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const incomingRfqsSlice = createSlice({
    name: 'ui/incoming-rfqs',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const incomingRfqsActions = incomingRfqsSlice.actions
export default incomingRfqsSlice.reducer
