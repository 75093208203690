import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditMenuVisibilityPayload } from '@app-store/slices/eventSettings/editMenuVisibility'
import { LOCALSTORAGE_KEYS } from '@features/General'

interface TaskAction extends Action {
    payload: IEditMenuVisibilityPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.editMenuVisibility

function* editMenuVisibilitySagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/event/settings/${payload.settingId}/menuVisibility`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.Event?.Menu_visibility_setting_updated_successfully ||
                    'Menu visibility setting updated successfully.',
                duration: 5000
            })
            localStorage.setItem(LOCALSTORAGE_KEYS.MENU_VISIBILITY, JSON.stringify(response.result.menuVisibility))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editMenuVisibilitySaga(): any {
    yield takeLatest<TaskAction>(onRequest, editMenuVisibilitySagaTask)
}

export default editMenuVisibilitySaga
