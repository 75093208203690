import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteContactPayload } from '@app-store/slices/contacts/delete'

interface TaskAction extends Action {
    payload: IDeleteContactPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.contacts.delete

function* deleteContactSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/companies/contacts/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.Contact_deleted_successfully || 'Contact deleted successfully.'
            })
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteContactSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteContactSagaTask)
}

export default deleteContactSaga
