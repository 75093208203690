import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IDirectMeetingByLinkPayload extends ISagaCb {
    data: {
        link: string
        startTime: string
        endTime: string
        meetingDuration: string
        firstname: string
        lastname: string
        email: string
        jobPosition: string
        companyName: string
        website?: string
        chalengeId?: string
    }
}

export interface IDirectMeetingByLinkState {
    status: string
    data: any
    errors: any
}

const initialState: IDirectMeetingByLinkState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const directMeetingByLink = createSlice({
    name: 'meeting-requests/direct-meeting-by-link',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IDirectMeetingByLinkPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const directMeetingByLinkActions = directMeetingByLink.actions
export default directMeetingByLink.reducer
