import createSaga from './createSaga'
import editSaga from './editSaga'
import generateListSaga from './generateListSaga'
import generateBpaMatch from './generateBpaMatch'
import generateBpaMatchBatchSaga from './generateBpaMatchBatchSaga'
import calculateScoreSaga from './calculateScoreSaga'
import listSaga from './listSaga'
import listByGroupSaga from './listByGroupSaga'
import deleteSaga from './deleteSaga'
import oneMatchListSaga from './oneMatchListSaga'
import removeCompanySaga from './removeCompany'
import addCompaniesSaga from './addCompanies'

const matchListsSaga = [
    createSaga(),
    editSaga(),
    generateListSaga(),
    generateBpaMatch(),
    generateBpaMatchBatchSaga(),
    calculateScoreSaga(),
    listSaga(),
    listByGroupSaga(),
    oneMatchListSaga(),
    deleteSaga(),
    removeCompanySaga(),
    addCompaniesSaga()
]

export default matchListsSaga
