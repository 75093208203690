import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IOneSurvayByTokenPayload } from '@app-store/slices/survey/oneSurveyByToken'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IOneSurvayByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.survey.oneSurveyByToken

function* oneSurveyByTokenSagaTask({ payload }) {
    try {
        const params: any = {
            token: payload.token
        }
        if (payload.surId) {
            params.surId = payload.surId
        }
        const response = yield axios.get(`/meeting/survey/by-token`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneSurveyByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneSurveyByTokenSagaTask)
}

export default oneSurveyByTokenSaga
