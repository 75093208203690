import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import isEmpty from 'lodash/isEmpty'
import { ListTaskAction } from '@typings/requestPayloads'
const { onRequest, onSuccess, onFailure } = rootActions.newFeature.list

function* listSagaTask({ payload }) {
    try {
        const query: any = payload?.query || {}
        const pagination = payload?.pagination || undefined
        const params = {}

        if (!isEmpty(query)) Object.assign(params, { query })
        if (pagination) Object.assign(params, { ...pagination })

        const response = yield axios.get(`/reporting/modules/new-features`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listSagaTask)
}

export default listSaga
