import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IRenameSectionPayload extends ISagaCb {
    hallId: string
    data: {
        oldSectionName: string
        newSectionName: string
    }
}

export interface IRenameSectionState {
    status: string
    statusItems: string[]
    data: any
    errors: any
}

const initialState: IRenameSectionState = {
    status: STATUS.IDLE,
    statusItems: [],
    data: null,
    errors: null
}

const renameSection = createSlice({
    name: 'table/rename-section',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IRenameSectionPayload>) {
            state.status = STATUS.RUNNING
            state.statusItems = [...state.statusItems, action.payload.hallId]
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.response
            state.statusItems = state.statusItems.filter(item => item !== action.payload.tableId)
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload.error
            state.statusItems = state.statusItems.filter(item => item !== action.payload.tableId)
        }
    }
})

export const renameSectionActions = renameSection.actions
export default renameSection.reducer
