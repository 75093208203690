import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/auditTrails/list'
import detailsModalReducer, { detailsModalActions } from '@app-store/slices/auditTrails/detailsModal'
import meetingListReducer, { meetingListActions } from '@app-store/slices/auditTrails/meetingList'

export const auditTrailsReducers = combineReducers({
    list: listReducer,
    detailsModal: detailsModalReducer,
    meetingList: meetingListReducer
})

export const auditTrailsActions = {
    list: listActions,
    detailsModal: detailsModalActions,
    meetingList: meetingListActions
}
