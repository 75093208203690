import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { IDeleteDirectMessagePayload } from '@app-store/slices/chat/deleteDirectMessage'

interface TaskAction extends Action {
    payload: IDeleteDirectMessagePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.deleteDirectMessage

function* deleteDirectMessageSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/im.delete`, payload.data)

        if (response.status === 200) {
            yield put(onSuccess(response.data))
            yield payload?.sagaCB?.onSuccess(response.data.room)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteDirectMessageSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteDirectMessageSagaTask)
}

export default deleteDirectMessageSaga
