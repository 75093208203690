import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers, manipulateListReducers } from '../reducers'

export interface IPaymentListPayload extends IListQueryPaginationPayload {
    subscription?: string
}

const paymentListSlice = createSlice({
    name: 'subscription-payment/payment-list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IPaymentListPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        ...manipulateListReducers
    }
})

export const paymentListActions = paymentListSlice.actions
export default paymentListSlice.reducer
