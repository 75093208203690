import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ICreateSurveyFeedbackByTokenAnswerPayload {
    questionId: string
    answerOption?: {
        questionOptionId: string
        title: string
    }
    feedback?: string
}

export interface ICreateSurveyFeedbackByTokenDataPayload {
    surId: string
    stars?: number
    typeId?: string
    answer?: any
}

export interface ICreateSurveyFeedbackByTokenPayload extends ISagaCb {
    token: string
    data: ICreateSurveyFeedbackByTokenDataPayload
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createSurveyFeedbackByToken = createSlice({
    name: 'survey/create-survey-feedback-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateSurveyFeedbackByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createSurveyFeedbackByTokenActions = createSurveyFeedbackByToken.actions
export default createSurveyFeedbackByToken.reducer
