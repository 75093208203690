import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IMarkAsReadPayload } from '@app-store/slices/notifications/markAsRead'

interface TaskAction extends Action {
    payload: IMarkAsReadPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.notification.markAsRead

function* markAsReadSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/notifications/read/${payload.notificationId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* markAsReadSaga(): any {
    yield takeLatest<TaskAction>(onRequest, markAsReadSagaTask)
}

export default markAsReadSaga
