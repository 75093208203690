import listSaga from './listSaga'
import createSaga from './createSaga'
import declineQuotations from './decline'
import closeQuotations from './close'
import staticEnumsSaga from './staticEnumsSaga'
import oneQuotationSaga from './oneQuotationSaga'
import createCustomSaga from './createCustomSaga'
import getListStatistics from './getListStatistics'

const quotationsSagas = [
    listSaga(),
    createSaga(),
    declineQuotations(),
    closeQuotations(),
    staticEnumsSaga(),
    oneQuotationSaga(),
    createCustomSaga(),
    getListStatistics()
]

export default quotationsSagas
