import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    data: {
        [key: string]: string[]
    }
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: {
        currency: [],
        type: [],
        status: []
    },
    errors: null
}

const productStaticEnumsSlice = createSlice({
    name: 'hub/companies/product-static-enums',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const productStaticEnumsActions = productStaticEnumsSlice.actions
export default productStaticEnumsSlice.reducer
