import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.listSearch

function* listSearchSharedListSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/companies/shared-lists`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onFailure) payload.sagaCB.onError(error)
    }
}

function* listSearchSharedListSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listSearchSharedListSagaTask)
}

export default listSearchSharedListSaga
