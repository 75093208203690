import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface ICreateListPayload {
    name: string
}

export interface ICreateQuotationDataPayload {
    _id?: string
    title: string
    condition?: string
    deliveryTerms?: string
    deliveryDate?: string
    expireDate?: string
    discount?: number | null
    fee?: number | null
    tax?: number | null
    description?: string
    purchaseTerm?: string
    products?: {
        quantity: number
        unit: string
        currency?: string
        condition?: string
        targetPrice?: number
        name: string
        description: string
        color?: string
        brand?: string
        productCode?: string
        product: string
        images?: string[]
        categories: [string]
    }[]
    services?: {
        currency?: string
        targetPrice?: number
        service: string
        name: string
        description: string
        UnspscCode?: string
        categoryName?: string
        images?: string[]
        categories: [string]
    }[]
    rfq?: string
    receiverCompanies?: any
    receiverContacts?: any
    costs?: {
        amount: string
        costType: string
    }[]
    totalAmount?: number
    finalAmount?: number
    favouriteContacts: string[]
}

export interface ICreateState {
    status: string
    data: any[]
    errors: any
}

const initialState: ICreateState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const create = createSlice({
    name: 'hub/quotations/create',
    initialState,
    reducers: {
        onRequest(state, actions) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createActions = create.actions
export default create.reducer
