import { createSlice } from '@reduxjs/toolkit'
export interface ICRMIntegrationViewState {
    serviceViewModal: {
        isOpen: boolean
        data: Record<string, string>
    }
    selectedService: Record<string, string>
}

const initialState: ICRMIntegrationViewState = {
    serviceViewModal: {
        isOpen: false,
        data: {}
    },
    selectedService: {}
}

const crmIntegrationViewSlice = createSlice({
    name: 'ui/crm-itegration',
    initialState,
    reducers: {
        setServiceViewModal(state, action) {
            state.serviceViewModal = action.payload
        },
        setSelectedService(state, action) {
            state.selectedService = action.payload
        }
    }
})

export const crmIntegrationActions = crmIntegrationViewSlice.actions
export default crmIntegrationViewSlice.reducer
