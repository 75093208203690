import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICompaniesStatisticsPayload } from '@app-store/slices/companies/statistics'

interface TaskAction extends Action {
    payload: ICompaniesStatisticsPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.statistics
function* statisticsSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/companies/stats`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* statisticsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, statisticsSagaTask)
}

export default statisticsSaga
