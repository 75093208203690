import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IGenerateBpaMatchBatchPayload extends ISagaCb {
    data: {
        batchIds?: string[]
        groupIds?: string[]
        businessPotential: string
        lowMeetingPriority: boolean
        minScore: number
        maxMatches: number
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const generateBpaMatchBatch = createSlice({
    name: 'hub/matchLists/generate-bpa-match-batch',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IGenerateBpaMatchBatchPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.data = { count: 0, data: [] }
            state.errors = action.payload
        }
    }
})

export const generateBpaMatchBatchActions = generateBpaMatchBatch.actions
export default generateBpaMatchBatch.reducer
