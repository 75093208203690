import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface ICreateBadgePayload extends ISagaCb {
    data: any
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createBadgeSlice = createSlice({
    name: 'badge/create',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateBadgePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onCreateRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onCreateFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createBadgeActions = createBadgeSlice.actions
export default createBadgeSlice.reducer
