import listStaticEnumsSaga from './listStaticEnumsSaga'
import getAvailableSlotsSaga from './getAvailableSlotsSaga'
import createMeetingSaga from './createMeetingSaga'
import addMeetingSaga from './addMeetingSaga'
import deleteMeetingSaga from './deleteMeetingSaga'
import editMeetingSaga from './editMeetingSaga'
import listSaga from './listSaga'
import getUnavailableSlotsSaga from './getUnavailableSlotsSaga'
import addToAgendaSaga from './addToAgendaSaga'
import deleteUnavailabilitySaga from './deleteUnavailabilitySaga'
import meetingCostListSaga from './meetingCostList'
import getMeetingCostSaga from './getMeetingCost'
import createMeetingCostSaga from './createMeetingCostSaga'
import updateMeetingCostSaga from './updateMeetingCostSaga'
import addBadgeSaga from './addBadgeSaga'
import cancelByTokenSaga from './cancelByTokenSaga'
import getAvailableSlotsByTokenSaga from './getUnavailableSlotsByTokenSaga'
import addToAgendaByTokenSaga from './addToAgendaByToken'
import generateMeetingLinkTokenSaga from './generateMeetingLinkTokenSaga'
import shareMeetingLinkSaga from './shareMeetingLinkSaga'

const meetingsSaga = [
    listStaticEnumsSaga(),
    getAvailableSlotsSaga(),
    createMeetingSaga(),
    deleteMeetingSaga(),
    editMeetingSaga(),
    listSaga(),
    getUnavailableSlotsSaga(),
    addToAgendaSaga(),
    addMeetingSaga(),
    deleteUnavailabilitySaga(),
    meetingCostListSaga(),
    getMeetingCostSaga(),
    createMeetingCostSaga(),
    updateMeetingCostSaga(),
    addBadgeSaga(),
    cancelByTokenSaga(),
    getAvailableSlotsByTokenSaga(),
    addToAgendaByTokenSaga(),
    generateMeetingLinkTokenSaga(),
    shareMeetingLinkSaga()
]

export default meetingsSaga
