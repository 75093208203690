import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditMeetingPayload {
    settingId: string
    data: {
        minGuest?: number
        maxGuest?: number
        minHost?: number
        maxHost?: number
    }
}

export interface IEditMeetingState {
    status: string
    data: any
    errors: any
}

const initialState: IEditMeetingState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editMeeting = createSlice({
    name: 'event-setting/edit-meeting',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditMeetingPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editMeetingActions = editMeeting.actions
export default editMeeting.reducer
