import { rootActions } from '@store/slices'
import { AnonymousSupportPayload } from '@store/slices/support/anonymousSupport'
import axios from '@utils/request'
import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'

interface TaskAction extends Action {
    payload: AnonymousSupportPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.support.anonymousSupport

function* anonymousSupportSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/support/ticket/anonymous`, payload)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB.onSuccess()
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB.onError()
    }
}

function* anonymousSupportSaga(): any {
    yield takeLatest<TaskAction>(onRequest, anonymousSupportSagaTask)
}

export default anonymousSupportSaga
