import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICalculateScorePayload } from '@app-store/slices/matchLists/calculateScore'
interface TaskAction extends Action {
    payload: ICalculateScorePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchLists.calculateScore

function* calculateScoreSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/match-lists/calculate`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* calculateScoreSaga(): any {
    yield takeLatest<TaskAction>(onRequest, calculateScoreSagaTask)
}

export default calculateScoreSaga
