import listSaga from './listSaga'
import listByLevel from './listByLevelSaga'
import listBySearchSaga from './listBySearchSaga'
import createCategorySaga from './createCategorySaga'
import editCategorySaga from './editCategorySaga'
import listByTokenSaga from './listByToken'

const categoriesSagas = [
    listSaga(),
    listByLevel(),
    listBySearchSaga(),
    createCategorySaga(),
    editCategorySaga(),
    listByTokenSaga()
]

export default categoriesSagas
