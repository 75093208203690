// import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateTagPayload } from '@app-store/slices/tags/create'

interface TaskAction extends Action {
    payload: ICreateTagPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.tags.create
const { onAddToList } = rootActions.tags.list

function* createTagSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/companies/tags`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(onAddToList(response.result.data))
            yield payload.sagaCB?.onSuccess(response.result.data)
            // notification.success({
            //     message: 'Table created successfully.'
            // })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createTagSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createTagSagaTask)
}

export default createTagSaga
