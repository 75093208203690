import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { INewFeatureByIDPayload } from '@app-store/slices/newFeature/newFeatureById'

interface TaskAction extends Action {
    payload: INewFeatureByIDPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.newFeature.newFeatureById

function* newFeatureByIdSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/reporting/modules/new-features/one/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            yield payload.sagaCB?.onError()
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* newFeatureByIdSaga(): any {
    yield takeLatest<TaskAction>(onRequest, newFeatureByIdSagaTask)
}

export default newFeatureByIdSaga
