import { createSlice } from '@reduxjs/toolkit'

export interface IFeedbackListDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    sort?: string
    projection?: any
    needPopulate: boolean
    populates?: any
    wishlistModal?: {
        isOpen: boolean
    }
    selectedRowKeys?: string[]
}

const initialState: IFeedbackListDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    sort: '-createdAt',
    projection: [],
    needPopulate: true,
    populates: [],
    wishlistModal: {
        isOpen: false
    },
    selectedRowKeys: []
}

const feedbackListSlice = createSlice({
    name: 'ui/survey-feedback-list',
    initialState,
    reducers: {
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        onChangeSelectedRows(state, action) {
            state.selectedRowKeys = action.payload
        },
        toggleWishlistModal(state) {
            state.wishlistModal.isOpen = !state.wishlistModal.isOpen
        }
    }
})

export const feedbackListActions = feedbackListSlice.actions
export default feedbackListSlice.reducer
