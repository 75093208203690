import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ICreateRatingByTokenPayload extends ISagaCb {
    data: {
        stars: number
        meetingId: string
        participantCompanyId: string
    }
}

export interface ICreateRatingByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateRatingByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createRatingByToken = createSlice({
    name: 'momentum/create-rating-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateRatingByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createRatingByTokenActions = createRatingByToken.actions
export default createRatingByToken.reducer
