import { combineReducers } from 'redux'
import createReducer, { createMatchListGroupActions } from '@app-store/slices/matchListGroups/create'
import editReducer, { editMatchListGroupActions } from '@app-store/slices/matchListGroups/edit'
import deleteReducer, { deleteMatchListGroupActions } from '@app-store/slices/matchListGroups/delete'
import listReducer, { listMatchListGroupActions } from '@app-store/slices/matchListGroups/list'
import oneMatchListGroupReducer, { oneMatchListGroupActions } from '@app-store/slices/matchListGroups/oneMatchListGroup'
import matchListGroupViewReducer, { matchListGroupViewActions } from '@app-store/slices/matchListGroups/view.slice'

export const matchListGroupsReducer = combineReducers({
    create: createReducer,
    edit: editReducer,
    delete: deleteReducer,
    list: listReducer,
    oneMatchListGroup: oneMatchListGroupReducer,
    view: matchListGroupViewReducer
})

export const matchListGroupsActions = {
    create: createMatchListGroupActions,
    edit: editMatchListGroupActions,
    delete: deleteMatchListGroupActions,
    list: listMatchListGroupActions,
    oneMatchListGroup: oneMatchListGroupActions,
    view: matchListGroupViewActions
}
