import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { INotHappenedPayload } from '@app-store/slices/momentum/notHappened'

interface TaskAction extends Action {
    payload: INotHappenedPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.momentum.notHappened

function* notHappenedSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.patch(`/meeting/did-not-happen/${payload.meetingId}`, { ...payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload?.sagaCB?.onError()
    }
}

function* notHappenedSaga(): any {
    yield takeLatest<TaskAction>(onRequest, notHappenedSagaTask)
}

export default notHappenedSaga
