import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditCampaignPayload } from '@app-store/slices/campaigns/editCampaign'

interface TaskAction extends Action {
    payload: IEditCampaignPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.editCampaign

function* editCampaignSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/notifications/campaigns/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editCampaignSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editCampaignSagaTask)
}

export default editCampaignSaga
