import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IEvaluatePlaceholderPayload extends ISagaCb {
    data: {
        senderContact: string
        senderCompany: string
        receiver: string
        receiverCompany: string
        meetingType: string
        duration: number
        location?: string
        place?: string
        reason: string
        requestedTimes: string[]
        templateId: string
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const evaluatePlaceholder = createSlice({
    name: 'template/evaluate-placeholder',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IEvaluatePlaceholderPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const evaluatePlaceholderActions = evaluatePlaceholder.actions
export default evaluatePlaceholder.reducer
