import { put, takeLatest, select } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { history } from '@components/App'
import { RootState } from '@app-store/store'

interface TaskAction extends Action {
    payload: any
}

const {
    onRequestBrand: onRequest,
    onSuccessBrand: onSuccess,
    onFailureBrand: onFailure
} = rootActions.companies.onboarding
function* brandSagaTask({ payload }) {
    const { brand } = yield select((store: RootState) => store.companies.onboarding)
    try {
        const response = yield axios.post(`/event/branding`, {
            logoImage: payload?.brandImages?.logoImage || '',
            bgImage: payload?.brandImages?.bgImage || '',
            bgPattern: brand?.bgPattern || '',
            colorCode: brand?.colorCode || '',
            paletteColor: brand?.paletteColor || '',
            logoColor: brand?.logoColor || ''
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            history.push(payload.nextURL)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* brandSaga(): any {
    yield takeLatest<TaskAction>(onRequest, brandSagaTask)
}

export default brandSaga
