import { createSlice } from '@reduxjs/toolkit'

export interface ICommonState {
    selectedRows: any[]
}

const initialState: ICommonState = {
    selectedRows: []
}

const wishListSlice = createSlice({
    name: 'ui/wish-list',
    initialState,
    reducers: {
        onSelectRows(state, acion) {
            state.selectedRows = acion.payload
        }
    }
})

export const wishListActions = wishListSlice.actions
export default wishListSlice.reducer
