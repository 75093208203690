import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IGetSummaryPayload extends ISagaCb {
    needPopulate?: boolean
    populates?: any[]
    retentionType?: 'Yearly' | 'Monthly'
}

export interface IGetSummaryState {
    status: string
    data: any
    errors: any
}

const initialState: IGetSummaryState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const getSummarySlice = createSlice({
    name: 'subscription/get-summary',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetSummaryPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getSummaryActions = getSummarySlice.actions
export default getSummarySlice.reducer
