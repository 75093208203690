import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { $inQuerySearch } from '@utils/helpers/queryHelpers'
import { IMeetingReportPayload } from '@app-store/slices/reporting/meetingReport'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IMeetingReportPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.reporting.meetingReport

function* meetingReportSagaTask({ payload }) {
    try {
        const sagaCB = payload.sagaCB
        delete payload.sagaCB
        delete payload.eventTimeShift
        delete payload.timeShift
        let params: any = { ...payload }
        if (payload.companies) {
            ;['guestCompanyId', 'hostCompanyId'].forEach(key => {
                params = { ...params, ...$inQuerySearch(key, [...payload.companies], false) }
            })
            delete params.companies
        }
        if (payload.participants) {
            ;['hostIds', 'guestIds', 'guests'].forEach(key => {
                params = { ...params, ...$inQuerySearch(key, [...payload.participants], false) }
            })
            delete params.participants
        }
        ;['status', 'badges', 'meetingType'].forEach(key => {
            if (payload[key]) {
                params = { ...params, ...$inQuerySearch(key, [...payload[key]], false) }
            }
        })

        const response = yield axios.get(`/reporting/agendas/meeting-report?query=${JSON.stringify(params)}`, {
            responseType: 'blob'
        })
        if (response) {
            yield put(onSuccess(response))
            yield put(sagaCB.onSuccess(response))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* meetingReportSaga(): any {
    yield takeLatest<TaskAction>(onRequest, meetingReportSagaTask)
}

export default meetingReportSaga
