import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { INewFeatureByModuleIDPayload } from '@app-store/slices/newFeature/newFeatureByModuleId'

interface TaskAction extends Action {
    payload: INewFeatureByModuleIDPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.newFeature.newFeatureByModuleId

function* newFeatureByModuleIdSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/reporting/modules/new-features/${payload.moduleId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* newFeatureByModuleIdSaga(): any {
    yield takeLatest<TaskAction>(onRequest, newFeatureByModuleIdSagaTask)
}

export default newFeatureByModuleIdSaga
