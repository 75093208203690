import { createSlice } from '@reduxjs/toolkit'
import { listUIReducers } from '../reducers'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { directoryReducers } from '../reducers'

const internalPublishedContactsListSlice = createSlice({
    name: 'ui/internal-contacts-list',
    initialState: {
        ...listUIInitialState,
        filters: { ...listUIInitialState.filters, userType: 'all' },
        openSideFilter: false,
        pureFilters: { ...listUIInitialState.pureFilters, userType: 'all' },
        expandedContactKeys: [],
        expandedContactTreeKeys: [],
        expandedRowKeys: [],
        populates: [
            { path: 'country', select: 'name' },
            // { path: 'city', select: 'name' },
            { path: 'company', select: '_id name logo sectors demandingSectors' }
        ]
    },
    reducers: {
        ...listUIReducers,
        onResetFilters(state) {
            state.filters = { userType: 'all' }
            state.pureFilters = { userType: 'all' }
        },
        changeSideFilterVisibility(state, action) {
            state.openSideFilter = action.payload
        },
        ...directoryReducers
    }
})

export const internalPublishedContactsListActions = internalPublishedContactsListSlice.actions
export default internalPublishedContactsListSlice.reducer
