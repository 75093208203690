import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditUserStatusPayload } from '@app-store/slices/users/editStatus'

interface TaskAction extends Action {
    payload: IEditUserStatusPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.editStatus
function* editUserStatusSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/users/status/${payload.id}`, { status: payload.status })
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            yield put(onFailure(''))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editUserStatusSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editUserStatusSagaTask)
}

export default editUserStatusSaga
