import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateMeetingCostPayload } from '@app-store/slices/meetings/createMeetingCost'

interface TaskAction extends Action {
    payload: ICreateMeetingCostPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.createMeetingCost

function* createMeetingCostSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/costs`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (response?.result?._id) {
                if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createMeetingCostSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createMeetingCostSagaTask)
}

export default createMeetingCostSaga
