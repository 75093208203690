import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDuplicateListQueryPaginationPayload } from '@app-store/slices/companies/duplicateList'

interface TaskAction extends Action {
    payload: IDuplicateListQueryPaginationPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.duplicateList

function* duplicateListSagaTask({ payload }: TaskAction) {
    const { pagination, ...restParams } = payload.data
    const params = {
        ...pagination,
        ...restParams
    }
    try {
        const response = yield axios.get('/search/companies/categorize-companies', { params })
        if (response.success) {
            yield put(onSuccess(response.result.data))
            payload?.sagaCB?.onSuccess(response.result.data)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* duplicateListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, duplicateListSagaTask)
}

export default duplicateListSaga
