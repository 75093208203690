import { combineReducers } from 'redux'
import loginReducer, { loginActions } from '@app-store/slices/auth/login.slice'
import signupReducer, { signupActions } from '@app-store/slices/auth/signup.slice'
import forgotPasswordReducer, { forgotPasswordActions } from '@app-store/slices/auth/forgotPassword.slice'
import confirmPasswordReducer, { confirmPasswordActions } from '@app-store/slices/auth/confirmPassword.slice'
import changePasswordReducer, { changePasswordActions } from '@app-store/slices/auth/changePassword.slice'
import sendOtpCodeReducer, { sendOtpCodeActions } from '@app-store/slices/auth/sendOtpCode'
import loginWithOtpReducer, { loginWithOtpActions } from '@app-store/slices/auth/loginWithOtp.slice'
import confirmRegistrationReducer, { confirmRegistrationActions } from '@app-store/slices/auth/confirmRegistration'
import resendConfirmationCodeReducer, {
    resendConfirmationCodeActions
} from '@app-store/slices/auth/resendActivationCode'
export const authReducer = combineReducers({
    login: loginReducer,
    signup: signupReducer,
    forgotPassword: forgotPasswordReducer,
    confirmPassword: confirmPasswordReducer,
    changePassword: changePasswordReducer,
    sendOtpCode: sendOtpCodeReducer,
    loginWithOtp: loginWithOtpReducer,
    resendConfirmationCode: resendConfirmationCodeReducer,
    confirmRegistration: confirmRegistrationReducer
})

export const authActions = {
    login: loginActions,
    signup: signupActions,
    forgotPassword: forgotPasswordActions,
    confirmPassword: confirmPasswordActions,
    changePassword: changePasswordActions,
    sendOtpCode: sendOtpCodeActions,
    loginWithOtp: loginWithOtpActions,
    resendConfirmationCode: resendConfirmationCodeActions,
    confirmRegistration: confirmRegistrationActions
}
