import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IConfirmPasswordPayload {
    email: string
    password: string
    code: string
}

export interface IConfirmPasswordState {
    status: string
    data: any
    errors: any
}

const initialState: IConfirmPasswordState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const confirmPasswordSlice = createSlice({
    name: 'auth/confirm-password',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IConfirmPasswordPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const confirmPasswordActions = confirmPasswordSlice.actions
export default confirmPasswordSlice.reducer
