import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { listUIReducers } from '../reducers'
export type MeetingRequestsInfoModalTypes = {
    isOpen?: boolean
    modalState?: 'ACCEPT' | 'CONFIRM' | 'REJECT'
    requestInfo?: any
}

export interface IIncomingMeetingRequestsDataState {
    infoModal: MeetingRequestsInfoModalTypes
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    sort?: string
    projection?: any
    needPopulate: boolean
    populates?: any
}

const initialState: IIncomingMeetingRequestsDataState = {
    infoModal: {
        isOpen: false,
        modalState: 'ACCEPT',
        requestInfo: null
    },
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    sort: '-createdAt',
    projection: [],
    needPopulate: true,
    populates: ['location', 'hostId', 'contactIds']
}

const incomingMeetingRequestsSlice = createSlice({
    name: 'ui/meeting-requests',
    initialState,
    reducers: {
        ...listUIReducers,
        changeInfoModal(state, action: PayloadAction<MeetingRequestsInfoModalTypes>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.infoModal[item] = action.payload[item]
            }
        },
        resetInfoModal(state) {
            state.infoModal = initialState.infoModal
        }
    }
})

export const incomingMeetingRequestsActions = incomingMeetingRequestsSlice.actions
export default incomingMeetingRequestsSlice.reducer
