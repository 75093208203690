import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IListByLevelQueryPayload {
    query?: any
}

export interface IListByLevelPayload extends ISagaCb {
    parentCategory: string
    level?: number
    query?: any
    necessaryProjectionArray?: string[]
}

const initialState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const listByLevelSlice = createSlice({
    name: 'hub/source-categories/list-by-level',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListByLevelQueryPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const listByLevelActions = listByLevelSlice.actions
export default listByLevelSlice.reducer
