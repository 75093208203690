import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { IUploadFilePayload } from '@app-store/slices/chat/uploadFile'

interface TaskAction extends Action {
    payload: IUploadFilePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.uploadFile

function* uploadFileSagaTask({ payload }: TaskAction) {
    try {
        const headers = {
            'content-type': 'multipart/form-data'
        }
        const response = yield axios.post(`/rooms.upload/${payload.roomId}`, payload.data, { headers })
        if (response.status === 200) {
            yield put(onSuccess(response.data))
            yield payload?.sagaCB?.onSuccess(response.data.room)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* uploadFileMessageSaga(): any {
    yield takeLatest<TaskAction>(onRequest, uploadFileSagaTask)
}

export default uploadFileMessageSaga
