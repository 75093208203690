export const blackSubdomainList = [
    'web',
    'portal',
    'app',
    'prodweb',
    'event',
    'events',
    'dev',
    'localhost',
    't',
    'meeting'
]
