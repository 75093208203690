import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

const { onRequest, onSuccess, onFailure } = rootActions.demos.templates

function* templatesSagaTask() {
    try {
        const response = yield axios.get(`/companies/demos/templates`)

        if (response.success) {
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* templatesSaga(): any {
    yield takeLatest(onRequest, templatesSagaTask)
}

export default templatesSaga
