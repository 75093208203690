import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditPropertyPayload } from '@app-store/slices/properties/edit'

interface TaskAction extends Action {
    payload: IEditPropertyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.properties.edit

function* editPropertySagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/attachments/properties/${payload.propertyId}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess()
        } else {
            yield payload.sagaCB?.onError()
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload.sagaCB?.onError()
    }
}

function* editPropertySaga(): any {
    yield takeLatest<TaskAction>(onRequest, editPropertySagaTask)
}

export default editPropertySaga
