import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/rfqs/list'
import staticEnumsReducer, { staticEnumsActions } from '@store/slices/rfqs/staticEnums'
import oneRfqReducer, { oneRfqActions } from '@store/slices/rfqs/oneRfq'
import createReducer, { createActions } from '@app-store/slices/rfqs/create'
import inviteSuppliersReducer, { inviteSuppliersActions } from '@app-store/slices/rfqs/inviteSuppliers'
import declineReducer, { declineActions } from '@app-store/slices/rfqs/decline'
import closeReducer, { closeActions } from '@app-store/slices/rfqs/close'
import outgoingDetailReducer, { outgoingDetailActions } from '@app-store/slices/rfqs/outgoingDetail'
import createRfqReducer, { createRfqActions } from '@app-store/slices/rfqs/createRfq'
import editReducer, { editActions } from '@app-store/slices/rfqs/edit'
import orderReducer, { orderActions } from '@app-store/slices/rfqs/order'
import createOrderReducer, { createOrderActions } from '@app-store/slices/rfqs/createOrder'
import contactCompanyReducer, { contactCompanyProfileActions } from '@app-store/slices/rfqs/contactCompanyProfile'
import getStatisticsReducer, { getStatisticsActions } from '@app-store/slices/rfqs/getStatistics'
import getListStatisticsReducer, { getListStatisticsActions } from '@app-store/slices/rfqs/getListStatistics'

export const rfqsReducer = combineReducers({
    list: listReducer,
    staticEnums: staticEnumsReducer,
    one: oneRfqReducer,
    create: createReducer,
    createRfq: createRfqReducer,
    inviteSuppliers: inviteSuppliersReducer,
    decline: declineReducer,
    close: closeReducer,
    outgoingDetail: outgoingDetailReducer,
    edit: editReducer,
    order: orderReducer,
    createOrder: createOrderReducer,
    contactCompanyProfile: contactCompanyReducer,
    getStatistics: getStatisticsReducer,
    getListStatistics: getListStatisticsReducer
})

export const rfqsActions = {
    list: listActions,
    staticEnums: staticEnumsActions,
    one: oneRfqActions,
    create: createActions,
    createRfq: createRfqActions,
    inviteSuppliers: inviteSuppliersActions,
    decline: declineActions,
    close: closeActions,
    outgoingDetail: outgoingDetailActions,
    edit: editActions,
    order: orderActions,
    createOrder: createOrderActions,
    contactCompanyProfile: contactCompanyProfileActions,
    getStatistics: getStatisticsActions,
    getListStatistics: getListStatisticsActions
}
