import dateListSaga from './dateListSaga'
import listSaga from './listSaga'
import oneEventTopicSaga from './oneEventTopicSaga'
import editEventTopicSaga from './editEventTopicSaga'
import deleteEventTopicSaga from './deleteEventTopicSaga'
import createEventTopicSaga from './createEventTopicSaga'

const eventTopicSagas = [
    dateListSaga(),
    listSaga(),
    oneEventTopicSaga(),
    editEventTopicSaga(),
    deleteEventTopicSaga(),
    createEventTopicSaga()
]

export default eventTopicSagas
