import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IPlannedMeetingPayloadState } from '@app-store/slices/meetingStatistics/plannedMeeting'

interface TaskAction extends Action {
    payload: IPlannedMeetingPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingStatistics.plannedMeeting

function* plannedMeetingSagaTask({ payload }: TaskAction) {
    const params: any = {}
    if (payload.query) {
        params.query = payload.query
    }
    try {
        const response = yield axios.get(`/meeting/stats/all-meetings`, { params })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB.onError()
        }
    }
}

function* plannedMeetingSaga(): any {
    yield takeLatest<TaskAction>(onRequest, plannedMeetingSagaTask)
}

export default plannedMeetingSaga
