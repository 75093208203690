import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ModalType = 'RATING' | 'NOT_HAPPEND' | 'FEEDBACK_DONE' | 'TASK' | 'NOTE'

export interface MomentumModalType {
    type: ModalType
    isOpen?: boolean
    meetingId?: string
    targetUserInfo?: any
    rating?: any
}

const initialState: MomentumModalType = {
    type: 'RATING',
    isOpen: false,
    meetingId: null,
    targetUserInfo: null,
    rating: null
}

const momentumSlice = createSlice({
    name: 'ui/momentum',
    initialState,
    reducers: {
        openModal(state, action: PayloadAction<MomentumModalType>) {
            state.type = action.payload.type
            state.meetingId = action.payload?.meetingId || ''
            state.targetUserInfo = action.payload.targetUserInfo || null
            state.isOpen = true
            state.rating = action.payload.rating || null
        },
        closeModal(state) {
            state.type = 'RATING'
            state.meetingId = ''
            state.isOpen = false
            state.rating = null
        },
        changeModalType(state, action: PayloadAction<MomentumModalType>) {
            state.type = action.payload.type
        }
    }
})

export const momentumActions = momentumSlice.actions
export default momentumSlice.reducer
