import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IAcceptMeetingRequestByIDPayload extends ISagaCb {
    requestId: string
    data: {
        acceptedTime: string
    }
}

export interface IAcceptMeetingRequestByIDState {
    status: string
    data: any
    errors: any
}

const initialState: IAcceptMeetingRequestByIDState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const acceptMeetingRequestByID = createSlice({
    name: 'meeting-requests/accept-by-id',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IAcceptMeetingRequestByIDPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const acceptMeetingRequestByIDActions = acceptMeetingRequestByID.actions
export default acceptMeetingRequestByID.reducer
