import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
interface Endpoint {
    path: string
    method: string
    point?: number
    limit: number
    endpointType: string
    isFree: boolean
}

export interface ICreateFeaturePayload extends ISagaCb {
    data: {
        name: string
        featureType: string
        isActive: boolean
        endpoints: Endpoint[]
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createFeature = createSlice({
    name: 'feature/create',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateFeaturePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createFeatureActions = createFeature.actions
export default createFeature.reducer
