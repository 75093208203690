import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IEditWishListPayload {
    data: any
}

interface IEditState {
    status: string
    data: any
    errors: any
}

const initialState: IEditState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const wishListsEditSlice = createSlice({
    name: 'hub/wish-lists/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditWishListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const wishListsEditActions = wishListsEditSlice.actions
export default wishListsEditSlice.reducer
