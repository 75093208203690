import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/group/list'
import editReducer, { editGroupActions } from '@app-store/slices/group/edit'
import deleteReducer, { deleteGroupActions } from '@app-store/slices/group/delete'
import createReducer, { createGroupActions } from '@app-store/slices/group/create'
import oneGroupReducer, { oneGroupActions } from '@app-store/slices/group/one'

export const groupReducer = combineReducers({
    list: listReducer,
    edit: editReducer,
    create: createReducer,
    delete: deleteReducer,
    one: oneGroupReducer
})

export const groupActions = {
    list: listActions,
    edit: editGroupActions,
    create: createGroupActions,
    delete: deleteGroupActions,
    one: oneGroupActions
}
