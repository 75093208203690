import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IValidateAvailabilityPayload {
    hallId: string
    startDate: string
    endDate: string
}

export interface IValidateAvailabilityState {
    status: string
    data: null
    errors: any
}

const initialState: IValidateAvailabilityState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const validateAvailability = createSlice({
    name: 'table/validate-availability',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IValidateAvailabilityPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const validateAvailabilityActions = validateAvailability.actions
export default validateAvailability.reducer
