import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICompanyVerifyPayload } from '@app-store/slices/companies/companyVerify'

interface TaskAction extends Action {
    payload: ICompanyVerifyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.companyVerify
function* companyVerifySagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/${payload.companyId}/company-contact-detail/verify`, {
            ...payload.data
        })
        if (response.success) {
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload.sagaCB?.onError) {
            yield payload.sagaCB?.onError()
        }
    }
}

function* companyVerifySaga(): any {
    yield takeLatest<TaskAction>(onRequest, companyVerifySagaTask)
}

export default companyVerifySaga
