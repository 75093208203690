import { createSlice } from '@reduxjs/toolkit'

export interface IMatchEngineViewState {
    saveMatchEngineModal: boolean
    successMatchEngineModal: boolean
    selectedCompany: any
    filteredMatchList: any
    businessPotential: string
    matchType: string
}

const initialState: IMatchEngineViewState = {
    saveMatchEngineModal: false,
    successMatchEngineModal: false,
    selectedCompany: null,
    filteredMatchList: [],
    businessPotential: 'Sell',
    matchType: 'itp'
}

const matchEngineViewSlice = createSlice({
    name: 'operation/match_engine/view',
    initialState,
    reducers: {
        setSaveMatchEngineModal(state, action) {
            state.saveMatchEngineModal = action.payload
        },
        setItems(state, action) {
            Object.keys(action.payload).map(key => {
                state[key] = action.payload[key]
            })
        },
        setSuccessMatchEngineModal(state, action) {
            state.successMatchEngineModal = action.payload
        }
    }
})

export const matchEngineViewActions = matchEngineViewSlice.actions
export default matchEngineViewSlice.reducer
