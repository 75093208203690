import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    suppliers: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    suppliers: null,
    errors: null
}

const closeSlice = createSlice({
    name: 'hub/rfqs/close',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.suppliers = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const closeActions = closeSlice.actions
export default closeSlice.reducer
