import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: STATUS.IDLE,
    data: [],
    listData: [],
    filter: null,
    pagination: {
        skip: 0,
        hasTotal: true,
        total: 0,
        limit: 10
    },
    errors: null
}

const listSlice = createSlice({
    name: 'hub/rfqs/list',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onFilteredDataRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onFilteredData(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
            state.pagination = {
                ...state.pagination,
                skip: action.payload.skip,
                total: action.payload.total
            }
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            if (action.payload.keepOldData) {
                state.data = [...state.data, ...action.payload.data]
            } else {
                state.data = action.payload.data
            }

            state.pagination = {
                ...state.pagination,
                skip: action.payload.skip,
                total: action.payload.total
            }
        },
        onListData(state, action) {
            state.status = STATUS.READY
            state.listData = action.payload.data
            state.pagination = {
                ...state.pagination,
                skip: action.payload.skip,
                total: action.payload.total
            }
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onAllClear(state) {
            state.data = []
        },
        onFilter(state, action) {
            state.filter = action.payload
        }
    }
})

export const listActions = listSlice.actions
export default listSlice.reducer
