import createSubscriptionPaymentSaga from './create'
import onePaymentSaga from './oneSaga'
import cancelPlanSaga from './cancelPlan'
import pausePlanSaga from './pausePlan'
import resumePlanSaga from './resumePlan'
import cardListSaga from './cardList'
import addCardSaga from './addCard'
import removeCardSaga from './removeCard'
import updateDefaultCardSaga from './updateDefaultCard'
import getDefaultCardSaga from './getDefaultCard'
import invoiceListSaga from './invoiceList'
import paymentListSaga from './paymentList'
import oneInvoiceSaga from './oneInvoice'
import generateLinkSaga from './generateLink'
import checkPromoCodeValidationSaga from './checkPromoCodeValidation'

const subscriptionPaymentSagas = [
    createSubscriptionPaymentSaga(),
    onePaymentSaga(),
    cancelPlanSaga(),
    pausePlanSaga(),
    resumePlanSaga(),
    cardListSaga(),
    addCardSaga(),
    removeCardSaga(),
    updateDefaultCardSaga(),
    getDefaultCardSaga(),
    invoiceListSaga(),
    paymentListSaga(),
    oneInvoiceSaga(),
    generateLinkSaga(),
    checkPromoCodeValidationSaga()
]

export default subscriptionPaymentSagas
