import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.rfqs.order

function* orderSagaTask({ payload }) {
    try {
        const params = {
            query: { rfq: payload.id },
            populates: [
                { path: 'products.product' },
                { path: 'providerCompany', select: 'name logo country contacts' },
                { path: 'providerCompany.country', select: 'name' },
                { path: 'providerCompany.contacts', select: 'firstname lastname avatar jobTitle' }
            ],
            needPopulate: true
        }
        const response = yield axios.get(`/companies/orders`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* orderSaga(): any {
    yield takeLatest<TaskAction>(onRequest, orderSagaTask)
}

export default orderSaga
