import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IGetActivePayload extends ISagaCb {
    needPopulate?: boolean
    populates?: any[]
}

export interface IGetActiveState {
    status: string
    data: any
    errors: any
}

const initialState: IGetActiveState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const getActiveSlice = createSlice({
    name: 'subscription/get-active-subscription',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetActivePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onChangeUserData(state, action) {
            const newData = state.data
            const user = newData.users.find(item => item.userId._id === action.payload.userId)
            Object.keys(action.payload.data).map(key => {
                user[key] = action.payload.data[key]
            })
            state.data = newData
        },
        clearData(state) {
            state.data = null
        }
    }
})

export const getActiveActions = getActiveSlice.actions
export default getActiveSlice.reducer
