import { Action } from 'redux'
import { put, take, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onFailure } = rootActions.onbordingOrganizer.importAttendees
const { onRequest: getPreSignedUpload } = rootActions.dataImport.getPreSignedUpload
const { onRequest: fileUpload } = rootActions.dataImport.fileUpload
const { onRequest: initiateImport } = rootActions.dataImport.initiateImport

function* importAttendeesSagaTask({ payload }) {
    try {
        yield put(getPreSignedUpload())
        yield take('onboarding/data-import/get-presigned-upload-URL/onSuccess')
        yield put(fileUpload(payload.data))
        yield take('hub/data-import/file-upload/onSuccess')
        yield put(initiateImport(payload.type))
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* importAttendeesSaga(): any {
    yield takeLatest<TaskAction>(onRequest, importAttendeesSagaTask)
}

export default importAttendeesSaga
