import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const sharedListResponsesSlice = createSlice({
    name: 'ui/shared-list-responses',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const sharedListResponsesActions = sharedListResponsesSlice.actions
export default sharedListResponsesSlice.reducer
