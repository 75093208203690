import { Action } from 'redux'
import { ICheckDomainPayload } from '@app-store/slices/events/checkDomain'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { put, takeLatest } from 'redux-saga/effects'
interface TaskAction extends Action {
    payload: ICheckDomainPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.events.checkDomain

function* checkDomainSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/event/check-domain/${payload.subdomain}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}
function* checkDomainSaga(): any {
    yield takeLatest<TaskAction>(onRequest, checkDomainSagaTask)
}

export default checkDomainSaga
