import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IEditSubscriptionPayload extends ISagaCb {
    id: string
    data: {
        name: string
        description: string
        tooltip: string
        type: string
        subscriptionModel: string
        price: number
        yearlyPrice: number
        discount: number
        priority: number
        points: number
        validityPeriod: number
        maxSubscribers: number
        features: any[]
        usersOptions: any[]
        settersOptions: any[]
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editSubscription = createSlice({
    name: 'subscription/edit',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IEditSubscriptionPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editSubscriptionActions = editSubscription.actions
export default editSubscription.reducer
