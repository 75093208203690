import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IInfoByLinkPayload extends ISagaCb {
    data: {
        link: string
        userStartDate?: string
        userEndDate?: string
    }
}

export interface IInfoByLinkState {
    status: string
    data: any
    errors: any
}

const initialState: IInfoByLinkState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const infoByLink = createSlice({
    name: 'meeting-requests/info-by-link',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IInfoByLinkPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const infoByLinkActions = infoByLink.actions
export default infoByLink.reducer
