import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.meetingAgenda.list

function* listMeetingAgendaSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/meeting`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listMeetingAgendaSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listMeetingAgendaSagaTask)
}

export default listMeetingAgendaSaga
