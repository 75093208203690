import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onCompanyData, onFailure } = rootActions.rfqs.contactCompanyProfile

function* contactCompanySagaTask({ payload }) {
    const query: any = payload.query || undefined
    const params: any = payload.params || {
        query: `{"company":"${payload?.id}"}`,
        addPresignedURL: true
    }

    if (query) Object.assign(params, { query })

    try {
        const response = yield axios.get(`/companies/contacts`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(onCompanyData(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* contactCompanyProfileSaga(): any {
    yield takeLatest<TaskAction>(onRequest, contactCompanySagaTask)
}

export default contactCompanyProfileSaga
