import { createSlice } from '@reduxjs/toolkit'
import { PropertyRelatedRecordType } from '@utils/constants'

export interface IMatchEngineViewState {
    shareMatchListModal: boolean
    visibleSendEmailModal: boolean
    visibleTextNoteModal: boolean
    sharedListId: string
    subRecordId: string
    relatedRecordType?: keyof typeof PropertyRelatedRecordType
    email: string
    shareMatchListId: string | string[]
    editMatchListModal: boolean
    generateLinkMatchListModal: boolean
    successMatchEngineModal: boolean
    shareWithOwner?: boolean
    matchListGroupId?: string
    isReShare?: boolean
    reShareRecords?: any
}

const initialState: IMatchEngineViewState = {
    shareMatchListModal: false,
    visibleSendEmailModal: false,
    visibleTextNoteModal: false,
    sharedListId: '',
    subRecordId: '',
    email: '',
    shareMatchListId: '',
    editMatchListModal: false,
    generateLinkMatchListModal: false,
    successMatchEngineModal: false,
    shareWithOwner: false,
    matchListGroupId: '',
    relatedRecordType: 'Meeting',
    isReShare: false,
    reShareRecords: null
}

const matchListViewSlice = createSlice({
    name: 'match_lists/view',
    initialState,
    reducers: {
        setShareMatchListModal(state, action) {
            state.shareMatchListModal = action.payload.visible
            state.shareMatchListId = action.payload.matchListId
            state.shareWithOwner = action.payload.shareWithOwner
            state.matchListGroupId = action.payload.matchListGroupId
            state.isReShare = action.payload.isReShare
            state.reShareRecords = action.payload.reShareRecords
        },
        setTextNoteModal(state, action) {
            state.visibleTextNoteModal = action.payload.visible
            state.sharedListId = action.payload.sharedListId
            state.subRecordId = action.payload.subRecordId
            state.relatedRecordType = action.payload.relatedRecordType
        },
        setSendEmailModal(state, action) {
            state.visibleSendEmailModal = action.payload.visible
            state.email = action.payload.email
        },
        setEditMatchListModal(state, action) {
            state.editMatchListModal = action.payload
        },
        setGenerateLinkMatchListModal(state, action) {
            state.generateLinkMatchListModal = action.payload
        },
        setSuccessMatchEngineModal(state, action) {
            state.successMatchEngineModal = action.payload
        }
    }
})

export const matchListViewActions = matchListViewSlice.actions
export default matchListViewSlice.reducer
