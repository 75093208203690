import { combineReducers } from 'redux'
import dateListReducer, { dateListActions } from './dateList'
import eventPlannerViewReducer, { eventPlannerViewActions } from './view.slice'
import createEventTopicReducer, { createEventTopicActions } from '@app-store/slices/eventTopics/createEventTopic'
import editEventTopicReducer, { editEventTopicActions } from '@app-store/slices/eventTopics/editEventTopic'
import deleteEventTopicReducer, { deleteEventTopicActions } from '@app-store/slices/eventTopics/deleteEventTopic'
import oneEventTopicReducer, { oneEventTopicActions } from '@app-store/slices/eventTopics/oneEventTopic'
import listReducer, { listActions } from '@app-store/slices/eventTopics/list'

export const eventTopicsReducer = combineReducers({
    dateList: dateListReducer,
    list: listReducer,
    create: createEventTopicReducer,
    edit: editEventTopicReducer,
    delete: deleteEventTopicReducer,
    view: eventPlannerViewReducer,
    oneEventTopic: oneEventTopicReducer
})

export const eventTopicsActions = {
    dateList: dateListActions,
    view: eventPlannerViewActions,
    list: listActions,
    create: createEventTopicActions,
    delete: deleteEventTopicActions,
    edit: editEventTopicActions,
    oneEventTopic: oneEventTopicActions
}
