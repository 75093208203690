import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateWishCampaignStepPayload } from '@app-store/slices/campaigns/createWishStep'

interface TaskAction extends Action {
    payload: ICreateWishCampaignStepPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.createWishStep
const { onChangeStep } = rootActions.ui.emailCampaign

function* createWishStepSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/notifications/campaigns/${payload.campaignId}/wish-step`, payload.data)
        if (response.success) {
            onChangeStep({
                index: payload.index,
                data: { _id: response.result._id, hasChanged: false }
            })
            yield payload?.sagaCB.onSuccess(response.result)
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload?.sagaCB?.onError === 'function') {
            payload?.sagaCB?.onError()
        }
    }
}

function* createWishStepSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createWishStepSagaTask)
}

export default createWishStepSaga
