import downloadAgendasSaga from './downloadAgendasSaga'
import sendAgendasSaga from './sendAgendasSaga'
import sendTestAgendasSaga from './sendTestAgendas'
import eventReportSaga from './eventReportSaga'
import linkedinEnrichSaga from './linkedinEnrich'
import scrapeReportSaga from './scrapeReportSaga'
import queueStatisticsSaga from './queueStatisticsSaga'
import auditTrailReportSaga from './auditTrailReport'
import salesNavigatorFiltersSaga from './salesNavigatorFilters'
import updateCompanyStats from './updateCompanyStats'
import meetingReportSaga from './meetingReportSaga'
import surveyReportSaga from './surveyReportSaga'
import meetingRequestReportSaga from './meetingRequestReportSaga'

const reportingSagas = [
    downloadAgendasSaga(),
    sendAgendasSaga(),
    eventReportSaga(),
    sendTestAgendasSaga(),
    linkedinEnrichSaga(),
    scrapeReportSaga(),
    queueStatisticsSaga(),
    auditTrailReportSaga(),
    salesNavigatorFiltersSaga(),
    updateCompanyStats(),
    meetingReportSaga(),
    surveyReportSaga(),
    meetingRequestReportSaga()
]

export default reportingSagas
