import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateScanPayload } from '@app-store/slices/scans/create'

interface TaskAction extends Action {
    payload: ICreateScanPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.scans.create

function* createScanSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/scan`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* createScanSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createScanSagaTask)
}

export default createScanSaga
