import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface ICreateEventCampaignPayload extends ISagaCb {
    data: any
    fromLocation?: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createEventCampaign = createSlice({
    name: 'campaign/create-event-campaign',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateEventCampaignPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createEventCampaignActions = createEventCampaign.actions
export default createEventCampaign.reducer
