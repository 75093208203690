import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAddFeaturePayload } from '@app-store/slices/eventSubscriptions/addFeature'

interface TaskAction extends Action {
    payload: IAddFeaturePayload
}

const { onSuccess, onRequest, onFailure } = rootActions.eventSubscriptions.addFeature

function* addFeatureSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/subscriptions/event-subscriptions/${payload.subscriptionId}/add-features`, {
            newFeatures: payload.newFeatures
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* addFeatureSaga(): any {
    yield takeLatest<TaskAction>(onRequest, addFeatureSagaTask)
}

export default addFeatureSaga
