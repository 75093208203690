import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.quotations.list

function* listSagaTask({ payload }: ListTaskAction) {
    try {
        const params = {
            ...payload.pagination,
            query: payload.query,
            populates: [
                { path: 'products.product' },
                { path: 'company', select: 'name _id country address' },
                { path: 'contact', select: 'firstname lastname avatar email jobTitle _id' },
                { path: 'receiverCompanies', select: '_id name' }
                // { path: 'createdByContact', select: 'firstname lastname _id avatar' }
            ],
            needPopulate: true
        }

        const response = yield axios.get(`/companies/quotations`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listSagaTask)
}

export default listSaga
