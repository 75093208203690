import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IListQueryPaginationPayload } from '@app-store/slices/services/list'
import store from '@app-store/store'

interface TaskAction extends Action {
    payload: IListQueryPaginationPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.services.list

function* listSagaTask({ payload }: TaskAction) {
    try {
        const query: any = payload.query || undefined
        const hasTotal = payload.hasTotal || false
        const sort = payload.sort || undefined
        const limit = payload.limit || ''
        const necessaryProjectionArray = payload.necessaryProjectionArray || []
        const needPopulate = payload.needPopulate || false
        const populates = payload.populates || []
        const skip = payload.skip || 0
        const params = {}
        if (query) Object.assign(params, { query })
        if (hasTotal) Object.assign(params, { hasTotal })
        if (sort) Object.assign(params, { sort })
        if (limit) Object.assign(params, { limit })
        if (needPopulate) Object.assign(params, { needPopulate })
        if (populates) Object.assign(params, { populates })
        if (skip) Object.assign(params, { skip })
        if (necessaryProjectionArray.length) Object.assign(params, { necessaryProjectionArray })

        const response = yield axios.get(`/companies/services`, {
            params
        })

        if (response.success) {
            yield put(onSuccess(response.result))
            store.dispatch(rootActions.ui.services.onChangeTotal(response.result.total || 0))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<TaskAction>(onRequest, listSagaTask)
}

export default listSaga
