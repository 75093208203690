import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getOffsetFromTimezone } from '@features/General/dayjs/hanlers'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export const prepareExcelData = (data, timeZone) => {
    const tz = timeZone || dayjs.tz.guess()
    const getCompanyTitle = company => `${company?.name} (${company?.userType === 'guest' ? 'G' : 'H'})`
    const offset = getOffsetFromTimezone(tz)
    return data.map(item => ({
        'Meeting type': item.meetingType,
        'Meeting status': item.status,
        'Company 1 (type)': getCompanyTitle(item.hostCompanyId),
        'Company 2 (type)': getCompanyTitle(item.guestCompanyId),
        'Contact 1': item.hostIds?.map(host => `${host?.firstname} ${host?.lastname}`).join(', ') || '',
        'Contact 1 Email': item.hostIds?.map(host => `${host?.email || ''}`).join(', ') || '',
        'Contact 1 Mobile': item.hostIds?.map(host => `${host?.mobile?.toString() || ''}`).join(', ') + ',' || '',
        'Contact 2': item.guestIds?.map(guest => `${guest?.firstname} ${guest?.lastname}`).join(', ') || '',
        'Contact 2 Email': item.guestIds?.map(host => `${host?.email || ''}`).join(', ') || '',
        'Contact 2 Mobile': item.guestIds?.map(host => `${host?.mobile?.toString() || ''}`).join(', ') + ',' || '',
        'Meeting duration': item.meetingDuration.toString(),
        'Start time': dayjs(item.startTime, 'YYYY-MM-DD hh:mm AZ').utcOffset(offset).format('YYYY.MM.DD hh:mm a'),
        'End time': dayjs(item.endTime, 'YYYY-MM-DD hh:mm AZ').utcOffset(offset).format('YYYY.MM.DD hh:mm a'),
        Location: item.place || item.hallId?.name,
        Table: item.tableId?.name || '',
        'Company Import Date': dayjs(item.createdAt, 'YYYY-MM-DD hh:mm AZ')
            .utcOffset(offset)
            .format('YYYY.MM.DD hh:mm a')
    }))
}
