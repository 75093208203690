import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IShareMeetingLinkPayload extends ISagaCb {
    meetingId: string
    contactIds: string[]
    content?: string
}

export interface IShareMeetingLinkState {
    status: string
    data: any
    errors: any
}

const initialState: IShareMeetingLinkState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const shareMeetingLink = createSlice({
    name: 'meeting/share-meeting-link',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IShareMeetingLinkPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const shareMeetingLinkActions = shareMeetingLink.actions
export default shareMeetingLink.reducer
