import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRecordingQueryPaginationPayload } from '@app-store/slices/callLog/recording'

interface TaskAction extends Action {
    payload: IRecordingQueryPaginationPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.callLog.recording

function* recordingSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/integrations/maqsam/call-recording?id=${payload.id}`, {
            responseType: 'blob'
        })
        if (response) {
            yield put(onSuccess(response))
            yield payload.sagaCB?.onSuccess(response)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* recordingSaga(): any {
    yield takeLatest<TaskAction>(onRequest, recordingSagaTask)
}

export default recordingSaga
