import notification from 'antd/lib/notification'
import store from '@app-store/store'
import { rootActions } from '@app-store/slices'
import { prepareDownloadAndSendQuery } from '@features/Meeting/MeetingAgenda'

export const onDownload = (companyId, pureFilters) => {
    const payload: any = {
        ...prepareDownloadAndSendQuery(companyId, pureFilters || {}),
        sagaCB: {
            onSuccess: result => {
                const blob = new Blob([result], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `MeetingAgenda-${+new Date()}.pdf`
                link.click()
            }
        }
    }
    store.dispatch(rootActions.reporting.downloadAgendas.onRequest(payload))
}
export const onDownloadExcel = (companyId, pureFilters, modalName = 'meetingReport', fileName = 'MeetingAgenda') => {
    const payload: any = {
        ...prepareDownloadAndSendQuery(companyId, pureFilters || {}, modalName === 'meetingReport'),
        sagaCB: {
            onSuccess: result => {
                const blob = new Blob([result], { type: 'text/csv' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `${fileName}-${+new Date()}.csv`
                link.click()
            }
        }
    }
    store.dispatch(rootActions.reporting[modalName].onRequest(payload))
}

export const onSend = (companyId, pureFilters, cb = () => undefined, i18n) => {
    const payload: any = {
        ...prepareDownloadAndSendQuery(companyId, pureFilters || {}),
        sagaCB: {
            onSuccess: () => {
                cb()
                notification.success({
                    message: i18n?.Meeting?.Meeting_agenda_sent_successfully || 'Meeting agenda sent successfully.'
                })
            }
        }
    }
    store.dispatch(rootActions.reporting.sendAgendas.onRequest(payload))
}
