import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

interface IEditPayload extends ISagaCb {
    id: string
    data: any
    avatar: any
    isContactProfile?: boolean
}

interface IEditPayload {
    id: string
    data: any
}

const contactCompanySlice = createSlice({
    name: 'hub/companies/contact-company',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload?.data || action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onEditRequest(state, action: PayloadAction<IEditPayload>) {
            state.status = STATUS.RUNNING
        },
        onEditFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onEditSuccess(state, action: PayloadAction<IEditPayload>) {
            state.status = STATUS.READY
            state.data = action.payload?.data || action.payload
        },
        onDeleteRequest(state, action: PayloadAction<IEditPayload>) {
            state.status = STATUS.RUNNING
        },
        onDeleteFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const contactCompanyActions = contactCompanySlice.actions
export default contactCompanySlice.reducer
