import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.momentum.list

function* momentumListRatingSagaTask({ payload }: ListTaskAction) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/meeting/rating`, { params })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* momentumListRatingSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, momentumListRatingSagaTask)
}

export default momentumListRatingSaga
