import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IGetUnavailableSlotsPayload {
    guestIds?: string[]
    hostIds?: string[]
    query?: any
    sort?: string
}

export interface IGetUnavailableSlotsState {
    status: string
    data: {
        startDate: string
        endDate: string
        unavailable: {
            startDate: string
            endDate: string
            typeId?: string
            type?: string
        }[]
    }
    errors: any
}

const initialState: IGetUnavailableSlotsState = {
    status: STATUS.IDLE,
    data: {
        startDate: '',
        endDate: '',
        unavailable: []
    },
    errors: null
}

const getUnavailableSlots = createSlice({
    name: 'meeting/get-unavailable-slots',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetUnavailableSlotsPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        reset(state) {
            state = initialState
        }
    }
})

export const getUnavailableSlotsActions = getUnavailableSlots.actions
export default getUnavailableSlots.reducer
