import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { rootActions } from '@app-store/slices'
import { ICancelPlanPayload } from '@app-store/slices/subscriptionPayment/cancelPlan'

interface TaskAction extends Action {
    payload: ICancelPlanPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.cancelPlan

function* cancelPlanSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/subscriptions/payments/cancel-plan/${payload.subscriptionId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* cancelPlanSaga(): any {
    yield takeLatest<TaskAction>(onRequest, cancelPlanSagaTask)
}

export default cancelPlanSaga
