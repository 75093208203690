import notification from 'antd/lib/notification'
import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateListPayload } from '@app-store/slices/editList/create'

interface TaskAction extends Action {
    payload: ICreateListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.editList.create

function* createSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/edit-lists`, payload.data)
        if (response.success) {
            yield payload.sagaCB?.onSuccess(response.result)
            yield put(onSuccess(response.result))
        } else {
            notification.error({
                message: response.error.join(', ')
            })
            yield put(onFailure({}))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createSagaTask)
}

export default createSaga
