import { put, select, take, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateUsernPayload } from '@app-store/slices/users/create'
import { RootState } from '@app-store/store'

interface TaskAction extends Action {
    payload: ICreateUsernPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.create
const { toggleUserModal } = rootActions.ui.settings
const { onRequest: getPreSignedUploadURL } = rootActions.users.getPreSignedUpload
const { onRequest: fileUpload } = rootActions.users.uploadImage
function* createUserSagaTask({ payload }) {
    try {
        if (payload.avatar && payload.avatar.fileList && payload.avatar.fileList[0]) {
            yield put(getPreSignedUploadURL())
            yield take('user/get-presigned-upload-URL/onSuccess')
            const { file } = yield select((store: RootState) => store.users.getPreSignedUpload.data)
            yield put(fileUpload(payload.avatar.fileList[0]))
            yield take('user/upload-image/onSuccess')
            payload.data.avatar = file
        } else if (typeof payload.avatar !== 'string') {
            payload.data.avatar = null
        }
        const response = yield axios.post(`/users`, {
            avatar: payload.data.avatar,
            confirm_password: payload.data.confirm_password,
            email: payload.data.email,
            firstname: payload.data.firstname,
            lastname: payload.data.lastname,
            mobile: payload.data.mobile,
            password: payload.data.password,
            role: payload.data.role
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(toggleUserModal({}))
            if (typeof payload.sagaCB.onSuccess === 'function') {
                payload.sagaCB.onSuccess(response.result)
            }
        }
    } catch (error) {
        if (error.response) {
            yield put(onFailure(error.response))
        }
    }
}

function* createUserSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createUserSagaTask)
}

export default createUserSaga
