import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { initialState } from '@utils/constants/requestConstants'

const getMeetingCost = createSlice({
    name: 'meeting/get-meeting-cost',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            if (action.payload?.noSaveInStore !== true) {
                state.data = action.payload
            }
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getMeetingCostActions = getMeetingCost.actions
export default getMeetingCost.reducer
