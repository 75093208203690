import { combineReducers } from 'redux'
import anonymousSupportReducer, { anonymousSupportActions } from '@app-store/slices/support/anonymousSupport'
import loggedinUserSupportReducer, { loggedinUserSupportActions } from '@app-store/slices/support/loggedInUserSupport'

export const supportReducer = combineReducers({
    anonymousSupport: anonymousSupportReducer,
    loggedinUserSupport: loggedinUserSupportReducer
})

export const supportActions = {
    anonymousSupport: anonymousSupportActions,
    loggedinUserSupport: loggedinUserSupportActions
}
