import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IDeleteSpeakerPayload extends ISagaCb {
    speakerId: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const deleteSpeaker = createSlice({
    name: 'speakers/delete-speaker',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IDeleteSpeakerPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const deleteSpeakerActions = deleteSpeaker.actions
export default deleteSpeaker.reducer
