import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { IGetUsersPayload } from '@app-store/slices/chat/getUsers'
import { getParams } from '@utils/request'

interface TaskAction extends Action {
    payload: IGetUsersPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.getUsers

function* getUsersSagaTask({ payload }: TaskAction) {
    try {
        const params = yield getParams(payload)
        const response = yield axios.get(`/users.list`, {
            params
        })

        if (response.status === 200) {
            yield put(onSuccess(response.data))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getUsersSaga(): any {
    yield takeLatest<TaskAction>(onRequest, getUsersSagaTask)
}

export default getUsersSaga
