import listSaga from './listSaga'
import devicesSaga from './devicesSaga'
import exhibitorDevicesSaga from './exhibitorDevicesSaga'
import exhibitorOrdersSaga from './exhibitorOrdersSaga'
import exhibitorPotentialValuesSaga from './exhibitorPotentialValuesSaga'
import exhibitorQutationRFQStatsSaga from './exhibitorQutationRFQStatsSaga'
import matchListGroupStatsSaga from './matchListGroupStatsSaga'
import matchListStatsSaga from './matchListStatsSaga'
import ordersSaga from './ordersSaga'
import potentialValuesSaga from './potentialValuesSaga'
import qutationRFQStatsSaga from './qutationRFQStatsSaga'
import visitorPotentialValuesSaga from './visitorPotentialValuesSaga'
import allMatchListsStatsSaga from './allMatchListsStateSaga'
import contactsStateStatsSaga from './contactsStateSaga'

const companyStatisticsSagas = [
    listSaga(),
    devicesSaga(),
    exhibitorDevicesSaga(),
    exhibitorOrdersSaga(),
    exhibitorPotentialValuesSaga(),
    exhibitorQutationRFQStatsSaga(),
    matchListGroupStatsSaga(),
    matchListStatsSaga(),
    ordersSaga(),
    potentialValuesSaga(),
    qutationRFQStatsSaga(),
    visitorPotentialValuesSaga(),
    allMatchListsStatsSaga(),
    contactsStateStatsSaga()
]

export default companyStatisticsSagas
