import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.countries.list

function* listSagaTask({ payload }: ListTaskAction) {
    const pagination = {
        limit: 1000,
        ...payload.pagination
    }
    try {
        const params = {
            ...pagination,
            query: payload.query
        }

        const response = yield axios.get(`/companies/countries`, {
            params,
            transformRequest: (data, headers) => {
                delete headers['event-id']
                return data
            }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result.data)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listSagaTask)
}

export default listSaga
