import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IOneCRMPayload {
    crmId: string
}

export interface IOneCRM {
    status: string
    data: any
    errors: any
}

const initialState: IOneCRM = {
    status: STATUS.IDLE,
    data: {},
    errors: null
}

const oneCRM = createSlice({
    name: 'crm/one',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IOneCRMPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneCRMActions = oneCRM.actions
export default oneCRM.reducer
