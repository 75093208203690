import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IResendConfirmationCodePayload extends ISagaCb {
    username: string
}

export interface IResendConfirmationCodeState {
    status: string
    data: {
        token: string
    }
    errors: any
}

const initialState: IResendConfirmationCodeState = {
    status: STATUS.IDLE,
    data: {
        token: ''
    },
    errors: null
}

const resendConfirmationCodeSlice = createSlice({
    name: 'auth/resend-confirmation-cpde',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IResendConfirmationCodePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const resendConfirmationCodeActions = resendConfirmationCodeSlice.actions
export default resendConfirmationCodeSlice.reducer
