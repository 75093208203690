import { createSlice } from '@reduxjs/toolkit'

export interface ISubscriptionPaymentDataItems {
    addCardModalVisible?: boolean
}

export interface ISubscriptionPaymentUIState {
    data: ISubscriptionPaymentDataItems
}

const initialState: ISubscriptionPaymentUIState = {
    data: {
        addCardModalVisible: false
    }
}
const subscriptionPaymentSlice = createSlice({
    name: 'ui/subscription',
    initialState,
    reducers: {
        toggleModalVisible(state) {
            state.data.addCardModalVisible = !state.data.addCardModalVisible
        }
    }
})

export const subscriptionPaymentActions = subscriptionPaymentSlice.actions
export default subscriptionPaymentSlice.reducer
