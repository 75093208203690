import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IContactVerifyPayload } from '@app-store/slices/contacts/contactVerify'

interface TaskAction extends Action {
    payload: IContactVerifyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.contacts.contactVerify
const { onChangeDetails } = rootActions.contacts.list
const { onChangeDetailsOneContact } = rootActions.contacts.oneContact
const { onChangeEmployeesEditorDetails } = rootActions.ui.companyProfile
function* contactVerifySagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/contacts/${payload.id}/contact-detail/verify`, {
            ...payload.data
        })
        if (response.success) {
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
            yield put(onSuccess(response.result))
            yield put(onChangeDetails({ contactId: payload.id, ...response.result }))
            yield put(onChangeDetailsOneContact({ ...response.result }))
            yield put(onChangeEmployeesEditorDetails({ ...response.result }))
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload.sagaCB?.onError) {
            yield payload.sagaCB?.onError()
        }
    }
}

function* contactVerifySaga(): any {
    yield takeLatest<TaskAction>(onRequest, contactVerifySagaTask)
}

export default contactVerifySaga
