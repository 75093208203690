import { combineReducers } from 'redux'
import listIntegrationServiceSlice, { listIntegrationServiceActions } from './list'

export const integrationServicesReducer = combineReducers({
    list: listIntegrationServiceSlice
})

export const integrationServicesActions = {
    list: listIntegrationServiceActions
}
