import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IContactsStatePayloadState } from '@app-store/slices/companyStatistics/contactsState'

interface TaskAction extends Action {
    payload: IContactsStatePayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.companyStatistics.contactsState

function* contactsStateSagaTask({ payload }: TaskAction) {
    const params: any = {}
    if (payload.query) {
        params.query = payload.query
    }
    try {
        const response = yield axios.get(`/companies/stats/contacts`, { params })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload.sagaCB?.onError === 'function') {
            payload.sagaCB?.onError()
        }
    }
}

function* contactsStateSaga(): any {
    yield takeLatest<TaskAction>(onRequest, contactsStateSagaTask)
}

export default contactsStateSaga
