import { IFilters } from '@features/General'
import { SubscriptionOrderStatus } from '../index'

export const listFilters = (i18n: any): IFilters[] => [
    {
        key: 'companies',
        title: i18n?.General?.Company,
        type: 'select',
        labelField: 'name',
        projection: ['_id', 'name', 'logo'],
        searchableFields: ['name'],
        logoField: 'logo',
        multiple: true
    },
    {
        key: 'status',
        title: i18n?.General?.Status,
        type: 'select',
        values: SubscriptionOrderStatus
    },
    {
        key: 'approvedAt',
        title: i18n?.General?.Select_date || 'Select date',
        type: 'dateTime',
        rangeKey: true
    }
]
