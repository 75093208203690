import { createSlice } from '@reduxjs/toolkit'

export interface IEventPlannerViewState {
    newEventModal: boolean
    editEventModal: boolean
    eventTopicId: string
    successEventPlannerModal: boolean
    newConferenceModal: boolean
    editConferenceModal: boolean
    conferenceId: string
    newSessionModal: boolean
    editSessionModal: boolean
    sessionId: string
}

const initialState: IEventPlannerViewState = {
    newEventModal: false,
    editEventModal: false,
    eventTopicId: '',
    successEventPlannerModal: false,
    newConferenceModal: false,
    editConferenceModal: false,
    conferenceId: '',
    newSessionModal: false,
    editSessionModal: false,
    sessionId: ''
}

const eventPlannerViewSlice = createSlice({
    name: 'operation/evnet_planner/view',
    initialState,
    reducers: {
        setNewEventModal(state, action) {
            state.newEventModal = action.payload
        },
        setEditEventModal(state, action) {
            state.editEventModal = action.payload.visible
            state.eventTopicId = action.payload.eventTopicId
        },
        setSuccessEventPlannerModal(state, action) {
            state.successEventPlannerModal = action.payload
        },
        setNewConferenceModal(state, action) {
            state.newConferenceModal = action.payload.visible
            state.eventTopicId = action.payload.eventTopicId
        },
        setNewSessionModal(state, action) {
            state.newSessionModal = action.payload.visible
            state.eventTopicId = action.payload.eventTopicId
            state.conferenceId = action.payload.conferenceId
        },
        setEditConferenceModal(state, action) {
            state.editConferenceModal = action.payload.visible
            state.conferenceId = action.payload.conferenceId
            state.eventTopicId = action.payload.eventTopicId
        },
        setEditSessionModal(state, action) {
            state.editSessionModal = action.payload.visible
            state.sessionId = action.payload.sessionId
            state.conferenceId = action.payload.conferenceId
            state.eventTopicId = action.payload.eventTopicId
        }
    }
})

export const eventPlannerViewActions = eventPlannerViewSlice.actions
export default eventPlannerViewSlice.reducer
