import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    data: {
        [key: string]: string[]
    }
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: {
        connectionDegree: [],
        jobDepartment: [],
        jobTitle: [],
        seniorityLevel: [],
        yearsOfExperience: [],
        status: []
    },
    errors: null
}

const contactStaticEnumsSlice = createSlice({
    name: 'hub/companies/contact-static-enums',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const contactStaticEnumsActions = contactStaticEnumsSlice.actions
export default contactStaticEnumsSlice.reducer
