import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type CorrectionURLRequestType = string

export interface IOneImportState {
    status: string
    data: any
    errors: any
}

const initialState: IOneImportState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneImportSlice = createSlice({
    name: 'hub/data-import/one-import',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<CorrectionURLRequestType>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneImportActions = oneImportSlice.actions
export default oneImportSlice.reducer
