import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import { getEventPublishabilityErrorLink } from '@features/Dashboard/handlers/index'
import { ISagaCb } from '@typings/requestPayloads'

export interface ICheckEventPublishabilityPayload extends ISagaCb {}

export interface ICheckEventPublishability {
    status: string
    success: boolean
    data: any
    errors: any
}

const initialState: ICheckEventPublishability = {
    status: STATUS.IDLE,
    success: true,
    data: {},
    errors: null
}

const checkEventPublishabilitySlice = createSlice({
    name: 'hub/events/check-event-publishability',
    initialState,
    reducers: {
        onRequest(state, action?: PayloadAction<ICheckEventPublishabilityPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            const data = action.payload
            const description = []
            for (const key in data) {
                const statistics = data[key]?.statistics
                if (statistics && !isEmpty(statistics)) {
                    description.push({
                        title: startCase(key) + ' is not completed',
                        link: getEventPublishabilityErrorLink(key),
                        details: Object.keys(statistics).map(key => statistics[key])
                    })
                }
            }
            state.data = { ...data, description }
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const checkEventPublishabilityActions = checkEventPublishabilitySlice.actions
export default checkEventPublishabilitySlice.reducer
