import { createSlice } from '@reduxjs/toolkit'

export interface ICommonState {
    selectedRows: any[]
    meetingCostModal: {
        isOpen: boolean
    }
}

const initialState: ICommonState = {
    selectedRows: [],
    meetingCostModal: {
        isOpen: false
    }
}

const analyticsSlice = createSlice({
    name: 'ui/analytics',
    initialState,
    reducers: {
        changeMeetingCostModal(state, action) {
            state.meetingCostModal.isOpen = action.payload
        }
    }
})

export const analyticsActions = analyticsSlice.actions
export default analyticsSlice.reducer
