import { createSlice } from '@reduxjs/toolkit'

export interface ICommonState {
    sectionModal: {
        isOpen: boolean
        sectionName?: string
    }
}

const initialState: ICommonState = {
    sectionModal: {
        isOpen: false,
        sectionName: ''
    }
}

const groupSlice = createSlice({
    name: 'ui/group-settings',
    initialState,
    reducers: {
        setSectionModalInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.sectionModal[item] = action.payload[item]
            }
        }
    }
})

export const groupActions = groupSlice.actions
export default groupSlice.reducer
