import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.matchList

function* matchListSagaTask({ payload }) {
    const allPayload = {
        ...payload,
        query: { matchList: payload.matchList, ...(payload.query || {}) },
        sort: { status: -1, createdAt: 1 },
        hasTotal: true,
        needPopulate: true,
        populates: [
            { path: 'sharedWithContact', select: 'firstname lastname' },
            { path: 'sharedWithCompany', select: 'name logo' }
        ]
    }
    const params = yield getParams(allPayload)

    try {
        const response = yield axios.get(`/companies/shared-lists`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onFailure) payload.sagaCB.onError(error)
    }
}

function* matchListSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, matchListSagaTask)
}

export default matchListSaga
