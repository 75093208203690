import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IGenerateMeetingLinkTokenPayload extends ISagaCb {
    meetingId: string
    contactId: string
}

export interface IGenerateMeetingLinkTokenState {
    status: string
    data: any
    errors: any
}

const initialState: IGenerateMeetingLinkTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const generateMeetingLinkToken = createSlice({
    name: 'meeting/generate-meeting-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGenerateMeetingLinkTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const generateMeetingLinkTokenActions = generateMeetingLinkToken.actions
export default generateMeetingLinkToken.reducer
