import { combineReducers } from 'redux'
import exhibitorOnboardingViewReducer, { exhibitorOnboardingViewActions } from './view.slice'

export const onboardingReducer = combineReducers({
    view: exhibitorOnboardingViewReducer
})

export const onboardingActions = {
    view: exhibitorOnboardingViewActions
}
