import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const outgoingQuotationsSlice = createSlice({
    name: 'ui/outgoing-quotations',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const outgoingQuotationsActions = outgoingQuotationsSlice.actions
export default outgoingQuotationsSlice.reducer
