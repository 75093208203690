import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const scanSlice = createSlice({
    name: 'ui/scans',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const scansActions = scanSlice.actions
export default scanSlice.reducer
