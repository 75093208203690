import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IAnalyticsState {
    status: string
    data: number
    errors: any
}
const initialState: IAnalyticsState = {
    status: STATUS.IDLE,
    data: 0,
    errors: null
}

const analyticsSlice = createSlice({
    name: 'scans/analytics',
    initialState,
    reducers: {
        onRequest(state) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const analyticsActions = analyticsSlice.actions
export default analyticsSlice.reducer
