import QueryString from 'qs'
import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import isEmpty from 'lodash/isEmpty'
import { ICreateWishCampaignPayload } from '@app-store/slices/campaigns/createWishCampaign'
import routes from '@utils/constants/routes'
import { history } from '@components/App'

interface TaskAction extends Action {
    payload: ICreateWishCampaignPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.createWishCampaign

function* createWishCampaignSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/notifications/campaigns/wish-campaigns`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
            if (!isEmpty(payload.fromLocation)) {
                if (payload.fromLocation.includes(routes.organizer.events.email_campaign._)) {
                    yield put(rootActions.organizer.eventDetails.emailCampaign.setNewCampaignModalVisible(false))
                    yield put(rootActions.organizer.eventDetails.emailCampaign.setNewStepsModalVisible(true))
                    yield history.push({
                        search: QueryString.stringify({ campaignId: response.result._id })
                    })
                }
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createWishCampaignSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createWishCampaignSagaTask)
}

export default createWishCampaignSaga
