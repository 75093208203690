import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateListPayload } from '@app-store/slices/quotations/create'

interface TaskAction extends Action {
    payload: ICreateListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.quotations.create

function* createSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/quotations`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createSagaTask)
}

export default createSaga
