import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import { IChangePasswordPayload } from '@app-store/slices/auth/changePassword.slice'
import { newPasswordCognitoUser } from '@utils/authenticateService'
import { successLogin } from '@features/Auth'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: IChangePasswordPayload
}

const { onFailure, onRequest } = rootActions.auth.changePassword

function* fetchChangePasswordSaga({ payload }: TaskAction) {
    try {
        const changePasswordValues = {
            password: payload.password,
            cognitoUser: payload.cognitoUser
        }
        const response = yield newPasswordCognitoUser(changePasswordValues)
        yield successLogin(response, payload?.sagaCB)
    } catch (e) {
        yield put(onFailure('Email or password is incorrect'))
    }
}

function* changePasswordSaga(): any {
    yield takeLatest<TaskAction>(onRequest, fetchChangePasswordSaga)
}

export default changePasswordSaga
