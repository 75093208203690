import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateWishListsPayload } from '@app-store/slices/wishLists/create'
// import useUserRole from 'hooks/processor/useUserRole'
import notification from 'antd/lib/notification'
interface TaskAction extends Action {
    payload: ICreateWishListsPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.wishLists.create
const { onAddToList } = rootActions.wishLists.list

function* createWishListsSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/wish-lists`, { ...payload.data })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(onAddToList(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.Wishlist_added_successfully || 'Wishlist added successfully.'
            })
            if (payload?.sagaCB) {
                payload?.sagaCB?.onSuccess(response.result)
            }
        } else {
            yield put(onFailure(''))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createWishListsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createWishListsSagaTask)
}

export default createWishListsSaga
