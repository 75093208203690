import listSaga from './listSaga'
import oneOrderSaga from './oneSaga'
import createOrderSaga from './createSaga'
import approveOrderSaga from './approve'
import rejectOrderSaga from './reject'
import editOrderSagaTask from './edit'
import cancelOrderSaga from './cancel'
import listSearchSaga from './listSearchSaga'

const ordersSagas = [
    listSaga(),
    oneOrderSaga(),
    createOrderSaga(),
    approveOrderSaga(),
    rejectOrderSaga(),
    editOrderSagaTask(),
    cancelOrderSaga(),
    listSearchSaga()
]

export default ordersSagas
