import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IInitiateImportState {
    status: string
    data: {
        excelColumnNames?: any[]
        excelSampleData?: any[]
        supportedNames?: any
        importId?: undefined | string
    }
    errors: any
}

const initialState: IInitiateImportState = {
    status: STATUS.IDLE,
    data: {
        excelColumnNames: [],
        excelSampleData: [],
        supportedNames: {},
        importId: undefined
    },
    errors: null
}

const initiateImportSlice = createSlice({
    name: 'hub/data-import/intiat-import',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const initiateImportActions = initiateImportSlice.actions
export default initiateImportSlice.reducer
