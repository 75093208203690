import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IListQueryPaginationPayload {
    query?: any
}

const initialState: { data: any[]; errors: null; status: string } = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const listUpdateSlice = createSlice({
    name: 'hub/cities/list-update',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = [...state.data, ...action.payload.data]
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const listUpdateActions = listUpdateSlice.actions
export default listUpdateSlice.reducer
