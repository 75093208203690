import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ICompanyPublishedListSearchQueryPayload } from '@app-store/slices/companies/publishedListSearch'

interface TaskAction extends Action {
    payload: ICompanyPublishedListSearchQueryPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.publishedListSearch

// This saga is for getting specific advanced list companies
function* publishedListSearchSagaTask({ payload }) {
    try {
        const params = yield getParams(payload)
        const response = yield axios.get(`/companies/published-list`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            yield put(onFailure(''))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* publishedListSearchSaga(): any {
    yield takeLatest<TaskAction>(onRequest, publishedListSearchSagaTask)
}

export default publishedListSearchSaga
