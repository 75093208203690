import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IUrlPropertyPayload extends ISagaCb {
    data: {
        originalUrl: string
    }
}

export interface ICreatePropertyState {
    status: string
    data: any
    errors: any
}

const initialState: ICreatePropertyState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const urlProperty = createSlice({
    name: 'property/url',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IUrlPropertyPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const urlPropertyActions = urlProperty.actions
export default urlProperty.reducer
