import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
import { IDataState } from '@typings/requestPayloads'
export interface ICompanySubscriptionUIState extends IDataState {
    subscriptionModal: {
        isOpen?: boolean
        id?: string
    }
}
const CompanySubscriptionInitialState: ICompanySubscriptionUIState = {
    ...listUIInitialState,
    populates: ['company', 'subscription', 'users'],
    subscriptionModal: {
        isOpen: false,
        id: ''
    }
}
const companySubscriptionsSlice = createSlice({
    name: 'ui/company-subscriptions',
    initialState: CompanySubscriptionInitialState,
    reducers: {
        ...listUIReducers,
        setSubscriptionModalInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.subscriptionModal[item] = action.payload[item]
            }
        }
    }
})

export const companySubscriptionsActions = companySubscriptionsSlice.actions
export default companySubscriptionsSlice.reducer
