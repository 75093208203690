import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IOneSurvayByTokenPayload {
    token: string
    surId?: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneSurvayByToken = createSlice({
    name: 'survey/one-survey-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneSurvayByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneSurvayByTokenActions = oneSurvayByToken.actions
export default oneSurvayByToken.reducer
