import { combineReducers } from 'redux'
import userListReducer, { userListActions } from '@app-store/slices/users/list'
import userListSearchReducer, { userListSearchActions } from '@app-store/slices/users/listSearch'
import userCreateReducer, { userCreateActions } from '@app-store/slices/users/create'
import userEditReducer, { userEditActions } from '@app-store/slices/users/edit'
import userEdiStatusReducer, { userEditStatusActions } from '@app-store/slices/users/editStatus'
import userReinviteReducer, { userReinviteActions } from '@app-store/slices/users/reinvite'
import UploadImageReducer, { UploadImageActions } from '@app-store/slices/users/uploadImage'
import userDeleteReducer, { userDeleteActions } from '@app-store/slices/users/delete'
import userProfileReducer, { userProfieActions } from '@app-store/slices/users/profile'
import getPreSignedUploadReducer, { getPreSignedUploadActions } from '@app-store/slices/users/getPreSignedUploadURL'
import userChangeStatusReducer, { userChangeStatusActions } from '@app-store/slices/users/changeStatus'
import userViewReducer, { userViewActions } from './view.slice'
import existingUserCreateReducer, { existingUserCreateActions } from '@app-store/slices/users/createExistingUser'
import addUserCreateReducer, { addUserCreateActions } from '@app-store/slices/users/addUser'
import resetPasswordReducer, { resetPasswordActions } from '@app-store/slices/users/resetPassword'
import activationReducer, { activationActions } from '@app-store/slices/users/activation'
import sendInvitationReducer, { sendInvitationActions } from '@app-store/slices/users/sendInvitation'
import oneByEmailReducer, { oneByEmailActions } from '@app-store/slices/users/oneByEmail'
import findUserReducer, { findUserActions } from '@app-store/slices/users/findUser'
import confirmUserReducer, { confirmUserActions } from '@app-store/slices/users/confirmUser'

export const userReducer = combineReducers({
    list: userListReducer,
    profile: userProfileReducer,
    create: userCreateReducer,
    edit: userEditReducer,
    editStatus: userEdiStatusReducer,
    reinvite: userReinviteReducer,
    uploadImage: UploadImageReducer,
    delete: userDeleteReducer,
    getPreSignedUpload: getPreSignedUploadReducer,
    changeStatus: userChangeStatusReducer,
    view: userViewReducer,
    existingUserCreate: existingUserCreateReducer,
    addUserCreate: addUserCreateReducer,
    resetPassword: resetPasswordReducer,
    listSearch: userListSearchReducer,
    activation: activationReducer,
    sendInvitation: sendInvitationReducer,
    oneByEmail: oneByEmailReducer,
    findUser: findUserReducer,
    confirmUser: confirmUserReducer
})

export const userActions = {
    list: userListActions,
    profile: userProfieActions,
    create: userCreateActions,
    edit: userEditActions,
    editStatus: userEditStatusActions,
    reinvite: userReinviteActions,
    uploadImage: UploadImageActions,
    delete: userDeleteActions,
    getPreSignedUpload: getPreSignedUploadActions,
    changeStatus: userChangeStatusActions,
    view: userViewActions,
    existingUserCreate: existingUserCreateActions,
    addUserCreate: addUserCreateActions,
    resetPassword: resetPasswordActions,
    listSearch: userListSearchActions,
    activation: activationActions,
    sendInvitation: sendInvitationActions,
    oneByEmail: oneByEmailActions,
    findUser: findUserActions,
    confirmUser: confirmUserActions
}
