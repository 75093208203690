import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface ICreateCategoryPayload extends ISagaCb {
    data: {
        title: string
        categoryCode?: string
        type?: string
        standard?: string
        parentCategory?: string
    }
}

export interface ICreateCategoryState {
    status: string
    data: any
    errors: any
    createCategoryModal: {
        isOpen: boolean
        action: string
        category?: any
        parents: string[]
    }
}

const initialState: ICreateCategoryState = {
    status: STATUS.IDLE,
    data: null,
    errors: null,
    createCategoryModal: {
        isOpen: false,
        action: '',
        category: null,
        parents: []
    }
}

const createCategory = createSlice({
    name: 'hub/categories/create',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateCategoryPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onChangeCreateCategoryModal(state, action) {
            state.createCategoryModal.isOpen = action.payload.isOpen
            state.createCategoryModal.action = action.payload.action
            state.createCategoryModal.category = action.payload.category
            state.createCategoryModal.parents = action.payload.parents
        }
    }
})

export const createCategoryActions = createCategory.actions
export default createCategory.reducer
