import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IOneTemplatePayload } from '@app-store/slices/templates/oneTemplate'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IOneTemplatePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.templates.one

function* oneTemplateSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/notifications/templates/one/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneTemplateSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneTemplateSagaTask)
}

export default oneTemplateSaga
