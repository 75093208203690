import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { IIgnoreUserPayload } from '@app-store/slices/chat/ignoreUser'

interface TaskAction extends Action {
    payload: IIgnoreUserPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.ignoreUser

function* ignoreUserSagaTask({ payload }: TaskAction) {
    try {
        const params: any = {
            rid: payload.rid,
            userId: payload.userId
        }

        if (payload.ignore !== undefined) {
            params.ignore = payload.ignore
        }

        const response = yield axios.get(`/chat.ignoreUser`, {
            params
        })

        if (response.status === 200) {
            yield put(onSuccess(response.data))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* ignoreUserSaga(): any {
    yield takeLatest<TaskAction>(onRequest, ignoreUserSagaTask)
}

export default ignoreUserSaga
