import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { ISurveyReportPayload } from '@app-store/slices/reporting/surveyReport'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: ISurveyReportPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.reporting.surveyReport

function* surveyReportSagaTask({ payload }) {
    try {
        const sagaCB = payload.sagaCB
        delete payload.sagaCB
        const params: any = { ...payload }
        const response = yield axios.get(`/reporting/agendas/survey-report`, {
            responseType: 'blob',
            params
        })
        if (response) {
            yield put(onSuccess(response))
            yield put(sagaCB.onSuccess(response))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* surveyReportSaga(): any {
    yield takeLatest<TaskAction>(onRequest, surveyReportSagaTask)
}

export default surveyReportSaga
