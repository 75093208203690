import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IEditPropertyPayload extends ISagaCb {
    propertyId: string
    data: {
        content?: string
        visibility?: string
    }
}

export interface IEditPropertyState {
    status: string
    data: any
    errors: any
}

const initialState: IEditPropertyState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editProperty = createSlice({
    name: 'property/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditPropertyPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editPropertyActions = editProperty.actions
export default editProperty.reducer
