import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IUnavailableTimeSlotsByTokenPayload extends ISagaCb {
    data: {
        token: string
        userStartDate?: string
        userEndDate?: string
    }
}

export interface IUnavailableTimeSlotsByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: IUnavailableTimeSlotsByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const unavailableTimeSlotsByToken = createSlice({
    name: 'meeting-requests/unavailable-timeslots-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IUnavailableTimeSlotsByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const unavailableTimeSlotsByTokenActions = unavailableTimeSlotsByToken.actions
export default unavailableTimeSlotsByToken.reducer
