import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/cities/list'
import listUpdateReducer, { listUpdateActions } from '@app-store/slices/cities/listUpdate'
import oneCityReducer, { oneCityActions } from '@app-store/slices/cities/oneCity'

export const citiesReducer = combineReducers({
    list: listReducer,
    listUpdate: listUpdateReducer,
    one: oneCityReducer
})

export const citiesActions = {
    list: listActions,
    listUpdate: listUpdateActions,
    one: oneCityActions
}
