import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteVisibilityPayload } from '@app-store/slices/eventSettings/deleteVisibility'
import notification from 'antd/lib/notification'
import { history } from '@components/App'
import { matchPath } from 'react-router-dom'
import routes from '@utils/constants/routes'

interface TaskAction extends Action {
    payload: IDeleteVisibilityPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.deleteVisibility

function callbackByRoutes() {
    const profile = matchPath(location.pathname, { path: routes.settings.event.visibility.profile._ })
    if (profile) {
        history.push(routes.settings.event.visibility._)
    }
}

function* deleteVisibilitySagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete(
            `/event/settings/${payload.settingId}/group-visibility/${payload.visibilityId}`
        )
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Visibility_deleted_successfully || 'Visibility deleted successfully.'
            })
            callbackByRoutes()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteVisibilitySaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteVisibilitySagaTask)
}

export default deleteVisibilitySaga
