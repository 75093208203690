import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const outgoingRfqInviteSuppliersSlice = createSlice({
    name: 'ui/outgoing-rfq-invite-suppliers',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const outgoingRfqInviteSuppliersActions = outgoingRfqInviteSuppliersSlice.actions
export default outgoingRfqInviteSuppliersSlice.reducer
