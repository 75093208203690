import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListState, ISagaCb } from '@typings/requestPayloads'
export interface IRecordingQueryPaginationPayload extends ISagaCb {
    id?: string
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const recordingSlice = createSlice({
    name: 'call-recording/list',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IRecordingQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
            state.pagination = {
                ...state.pagination,
                skip: action.payload.skip,
                total: action.payload.total
            }
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const recordingActions = recordingSlice.actions
export default recordingSlice.reducer
