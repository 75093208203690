import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const statusStatisticsSlice = createSlice({
    name: 'ui/status-statistics',
    initialState: {
        ...listUIInitialState
    },
    reducers: {
        ...listUIReducers
    }
})

export const statusStatisticsActions = statusStatisticsSlice.actions
export default statusStatisticsSlice.reducer
