import { createSlice } from '@reduxjs/toolkit'
import { listUIReducers } from '../reducers'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { directoryReducers } from '../reducers'

const assignedContactsListSlice = createSlice({
    name: 'ui/assigned-contacts-list',
    initialState: {
        ...listUIInitialState,
        openSideFilter: false,
        expandedContactKeys: [],
        expandedContactTreeKeys: [],
        expandedRowKeys: [],
        editListId: '',
        populates: [{ path: 'company', select: '_id name logo sectors demandingSectors' }]
    },
    reducers: {
        ...listUIReducers,
        changeSideFilterVisibility(state, action) {
            state.openSideFilter = action.payload
        },
        onChangeEditListId(state, action) {
            state.editListId = action.payload
        },
        ...directoryReducers
    }
})

export const assignedContactsListActions = assignedContactsListSlice.actions
export default assignedContactsListSlice.reducer
