import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const listStaticEnums = createSlice({
    name: 'meeting/list-static-enums',
    initialState,
    reducers: {
        onRequest(state) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const listStaticEnumsActions = listStaticEnums.actions
export default listStaticEnums.reducer
