import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onSuccess, onRequest, onFailure } = rootActions.categories.createCategory

function* createCategorySagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/categories`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createCategorySaga(): any {
    yield takeLatest<TaskAction>(onRequest, createCategorySagaTask)
}

export default createCategorySaga
