import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationType, ISagaCb } from '@typings/requestPayloads'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers, manipulateListReducers } from '../reducers'

export interface IContactAdvancedListQueryPayload extends ISagaCb {
    pagination?: PaginationType
    userType?: string
    query?: any
}

const advancedListSlice = createSlice({
    name: 'hub/contacts/advanced-list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<IContactAdvancedListQueryPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        ...manipulateListReducers
    }
})

export const advancedListActions = advancedListSlice.actions
export default advancedListSlice.reducer
