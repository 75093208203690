import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface DetailAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.rfqs.outgoingDetail
function* outgoingSaga({ payload }) {
    try {
        const params = {
            hasTotal: true,
            needPopulate: true,
            query: { rfq: payload.id },
            populates: [
                { path: 'products.product' },
                { path: 'company', select: 'name logo country' },
                { path: 'contact', select: 'firstname lastname avatar jobTitle' }
            ]
        }
        const response = yield axios.get(`/companies/quotations`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ data: response.result }))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* outgoingDetail(): any {
    yield takeLatest<DetailAction>(onRequest, outgoingSaga)
}

export default outgoingDetail
