import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOneSettingPayload } from '@app-store/slices/eventSettings/oneSetting'

interface TaskAction extends Action {
    payload: IOneSettingPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.oneSetting

function* getOneSettingTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/event/settings/one/${payload.settingId || 'default'}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getOneSetting(): any {
    yield takeLatest<TaskAction>(onRequest, getOneSettingTask)
}

export default getOneSetting
