import { createSlice } from '@reduxjs/toolkit'
import { ICreateQuotationDataPayload } from '@store/slices/quotations/create'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const initialData: ICreateQuotationDataPayload = {
    _id: '',
    title: '',
    condition: '',
    deliveryTerms: 'EXW',
    deliveryDate: '',
    expireDate: '',
    discount: null,
    fee: null,
    tax: null,
    description: '',
    purchaseTerm: '',
    products: [],
    services: [],
    rfq: '',
    costs: [],
    totalAmount: 0,
    finalAmount: 0,
    favouriteContacts: []
}

const sendQuoteSlice = createSlice({
    name: 'ui/send-quote',
    initialState: { ...listUIInitialState, data: initialData },
    reducers: {
        addItems(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.data[item] = action.payload[item]
            }
        },
        addProduct(state, action) {
            state.data.products = [action.payload]
        },
        removeProduct(state, action) {
            const newArr = state.data.products.filter(i => i.product !== action.payload._id)
            state.data.products = newArr
        },
        editProduct(state, action) {
            const newArr = state.data.products
            const { index, key, value } = action.payload
            newArr[index][key] = value
            state.data.products = newArr
        },
        addService(state, action) {
            state.data.services = [action.payload]
        },
        removeService(state, action) {
            const newArr = state.data.services.filter(i => i.service !== action.payload._id)
            state.data.services = newArr
        },
        editService(state, action) {
            const newArr = state.data.services
            const { index, key, value } = action.payload
            newArr[index][key] = value
            state.data.services = newArr
        },
        resetItems(state) {
            state.data = initialData
        },
        ...listUIReducers
    }
})

export const sendQuoteActions = sendQuoteSlice.actions
export default sendQuoteSlice.reducer
