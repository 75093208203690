import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const companySubscriptionsUsersSlice = createSlice({
    name: 'ui/company-subscriptions',
    initialState: { ...listUIInitialState, populates: ['company', 'subscription', 'users'] },
    reducers: listUIReducers
})

export const companySubscriptionsUsersActions = companySubscriptionsUsersSlice.actions
export default companySubscriptionsUsersSlice.reducer
