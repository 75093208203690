import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAvailableTablesPayload } from '@app-store/slices/table/availableTables'

interface TaskAction extends Action {
    payload: IAvailableTablesPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.table.availableTables

function* availableTablesTableTask({ payload }) {
    try {
        const response = yield axios.get(`/meeting/table/get-available-tables`, { params: payload.query || payload })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            if (payload?.sagaCB) {
                yield payload.sagaCB?.onError()
            }
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* availableTablesTable(): any {
    yield takeLatest<TaskAction>(onRequest, availableTablesTableTask)
}

export default availableTablesTable
