import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: {
        id: string
    }
}

const { onRequest, onSuccess, onFailure } = rootActions.events.oneEvent
const { setCompanyProfileRole } = rootActions.ui.companyProfile
function* oneEventSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/event/one/${payload.id}`)
        yield put(setCompanyProfileRole({ eventRecord: response.result }))
        if (response.success) {
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneEventSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneEventSagaTask)
}

export default oneEventSaga
