import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditProductPayload } from '@app-store/slices/products/editProduct'
import store from '@app-store/store'

interface TaskAction extends Action {
    payload: IEditProductPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.products.edit

function* editProductSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/products/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            store.dispatch(
                rootActions.products.list.editProduct({
                    id: payload.id,
                    data: { ...response.result, productImages: payload.productImages }
                })
            )
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editProductSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editProductSagaTask)
}

export default editProductSaga
