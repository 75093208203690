import routes from '@utils/constants/routes'

export const getEventPublishabilityErrorLink = key => {
    switch (key) {
        case 'branding':
            return routes.settings.event.branding._
        case 'categories':
            return routes.settings.event.categories._
        case 'companies':
            return routes.directory.published.companies
        case 'contacts':
            return routes.directory.published.contacts
        case 'event':
            return routes.settings.event.event_info._
        case 'groups':
            return routes.settings.groups._
        case 'halls':
            return routes.settings.event.tables._
        case 'tables':
            return routes.settings.event.tables._
    }
}
