import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const eventProfileViewSlice = createSlice({
    name: 'ui/manage-eventProfile',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const eventProfileActions = eventProfileViewSlice.actions
export default eventProfileViewSlice.reducer
