import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { ICreateDirectMessagePayload } from '@app-store/slices/chat/createDirectMessage'

interface TaskAction extends Action {
    payload: ICreateDirectMessagePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.createDirectMessage

function* createDirectMessageSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/im.create`, payload.data)
        if (response.status === 200) {
            yield put(onSuccess(response.data))
            yield payload?.sagaCB?.onSuccess(response.data.room)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createDirectMessageSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createDirectMessageSagaTask)
}

export default createDirectMessageSaga
