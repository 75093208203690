import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IUserIdByContactPayload extends ISagaCb {
    contactId: string
}

const initialState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const getUserIdByContactSlice = createSlice({
    name: 'chat/get-user-id-by-contact',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IUserIdByContactPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.ims
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onDeleteDirectMessage(state, action) {
            state.data = state.data.filter(item => item._id !== action.payload)
        }
    }
})

export const getUserIdByContactActions = getUserIdByContactSlice.actions
export default getUserIdByContactSlice.reducer
