import { createSlice } from '@reduxjs/toolkit'
import { addMessage } from '@utils/helpers/chatHelpers'

interface IChatState {
    selectedRoom: {
        id: string
        title: string
        avatar: string
        position?: string
        userId: string
        currentUserId?: string
    }
    loading: boolean
    messages: any[]
    unreads: {
        roomId: string
        count: number
    }[]
    error: any
    typing: any
    connected: boolean
    anchorMessage: any
}

const initialState: IChatState = {
    selectedRoom: {
        id: '',
        title: '',
        avatar: '',
        position: '',
        userId: '',
        currentUserId: ''
    },
    loading: false,
    messages: [],
    unreads: [],
    error: {},
    typing: {},
    connected: false,
    anchorMessage: null
}

const chatSlice = createSlice({
    name: 'chat/actions',
    initialState,
    reducers: {
        setSelectedRoom: (state, { payload }) => {
            state.selectedRoom = {
                id: payload.id,
                title: payload.title,
                avatar: payload.avatar,
                position: payload.position || '',
                userId: payload.userId,
                currentUserId: payload.currentUserId
            }
        },
        setProgress: (state, { payload }) => {
            state.loading = payload
        },
        setMessages: (state, { payload }) => {
            state.messages = payload
        },
        addMessage: (state, { payload }) => {
            if (payload && state.selectedRoom.id === (payload.id || payload.rid)) {
                state.messages = addMessage(state.messages, payload)
            }
        },
        setUnreads: (state, { payload }) => {
            state.unreads = payload
        },
        addUnread: (state, { payload }) => {
            const index = state.unreads.findIndex(item => item.roomId === payload.roomId)
            state.unreads[index].count += payload.count
        },
        setRead: (state, { payload }) => {
            const index = state.unreads.findIndex(item => item.roomId === payload.roomId)
            if (index >= 0) state.unreads[index].count = 0
        },
        setError: state => {
            state.loading = false
        },
        setTyping: (state, { payload }) => {
            state.typing = payload
        },
        setConnected: (state, { payload }) => {
            state.connected = payload
        },
        setAnchorMessage: (state, { payload }) => {
            state.anchorMessage = payload
        }
    }
})

export const chatActions = chatSlice.actions
export default chatSlice.reducer
