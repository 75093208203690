import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IGetContactsAvailabilityPayload } from '@app-store/slices/meetingRequests/getContactsAvailability'

interface TaskAction extends Action {
    payload: IGetContactsAvailabilityPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingRequests.getContactsAvailability

function* getContactsAvailabilityTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/get-my-available-slots`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            yield put(onFailure(response.result))
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload?.sagaCB?.onError(error)
    }
}

function* getContactsAvailability(): any {
    yield takeLatest<TaskAction>(onRequest, getContactsAvailabilityTask)
}

export default getContactsAvailability
