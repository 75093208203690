import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateNewFeaturePayload } from '@app-store/slices/newFeature/create'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICreateNewFeaturePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.newFeature.create

function* createNewFeatureSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/reporting/modules/new-features`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Settings?.New_feature_created_successfully || 'New feature created successfully.'
            })
        } else {
            yield payload.sagaCB?.onError()
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createNewFeatureSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createNewFeatureSagaTask)
}

export default createNewFeatureSaga
