import listSaga from './listSaga'
import listSearchSaga from './listSearchSaga'
import editSaga from './editSaga'
import editItpSaga from './editItpSaga'
import assignedListSaga from './assignedListSaga'
import oneCompanySaga from './oneCompanySaga'
import publishCompanySaga from './publishCompanySaga'
import prePublishCompany from './prePublishCompanySaga'
import advancedListSaga from './advancedListSaga'
import publishedListSaga from './publishedListSaga'
import publishedListSearchSaga from './publishedListSearchSaga'
import statisticsSaga from './statisticsSaga'
import contactCompanySaga from './contactCompanySaga'
import editOneSaga from './editOneSaga'
import contactEditorCompanySaga from './contactEditorCompanySaga'
import contactStaticEnumsSaga from './contactStaticEnumsSaga'
import staticEnumsSaga from './staticEnumsSaga'
import contactProfileSaga from './contactProfileSaga'
import itpStaticEnumsSaga from './itpStaticEnumsSaga'
import checkStatusEventSaga from './checkStatusEventSaga'
import onboardingExhibitorSaga from './onboardingExhibitorSaga'
import itpListSaga from './itpListSaga'
import industryListSaga from './industryListSaga'
import createItpSaga from './createItpSaga'
import deleteItpSaga from './deleteItpSaga'
import getPreSignedUploadURLSaga from './getPreSignedUploadURLSaga'
import uploadImageSaga from './uploadImageSaga'
import createCompanySaga from './createCompanySaga'
import deleteCompanySaga from './deleteSaga'
import shareItpSaga from './shareItp'
import deactiveCompanySaga from './deactiveCompanySaga'
import itpByTokenSaga from './itpByTokenSaga'
import editItpByTokenSaga from './editItpByTokenSaga'
import statusStatisticsSaga from './statusStatisticsSaga'
import internalPublishCompanySaga from './internalPublishCompanySaga'
import searchSaga from './searchSaga'
import internalPublishedListSearchSaga from './internalPublishedListSearchSaga'
import duplicateListSaga from './duplicateListSaga'
import mergeDuplicateListSaga from './mergeDuplicateListSaga'
import companyVerifySaga from './companyVerifySaga'
const companiesSagas = [
    listSaga(),
    itpListSaga(),
    listSearchSaga(),
    assignedListSaga(),
    oneCompanySaga(),
    editSaga(),
    editItpSaga(),
    publishCompanySaga(),
    prePublishCompany(),
    statisticsSaga(),
    contactCompanySaga(),
    editOneSaga(),
    advancedListSaga(),
    publishedListSaga(),
    publishedListSearchSaga(),
    contactEditorCompanySaga(),
    contactStaticEnumsSaga(),
    staticEnumsSaga(),
    contactProfileSaga(),
    itpStaticEnumsSaga(),
    checkStatusEventSaga(),
    onboardingExhibitorSaga(),
    createItpSaga(),
    deleteItpSaga(),
    getPreSignedUploadURLSaga(),
    uploadImageSaga(),
    createCompanySaga(),
    deleteCompanySaga(),
    shareItpSaga(),
    industryListSaga(),
    deactiveCompanySaga(),
    itpByTokenSaga(),
    editItpByTokenSaga(),
    statusStatisticsSaga(),
    internalPublishCompanySaga(),
    searchSaga(),
    internalPublishedListSearchSaga(),
    duplicateListSaga(),
    mergeDuplicateListSaga(),
    companyVerifySaga()
]

export default companiesSagas
