import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'
import { ISagaCb } from '@typings/requestPayloads'
export interface LoggedinUserSupportPayload extends ISagaCb {
    email: string
    phone: string
    event: string
    description: string
    priority?: string
    meetingsCount?: number
    membersCount?: number
}

export interface IState {
    success: boolean
    status: string
    result: any
    errors: any
}

const initialState: IState = {
    success: true,
    result: {},
    errors: null,
    status: STATUS.IDLE
}

const loggedinUserSupport = createSlice({
    name: 'companies/support/ticket',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<LoggedinUserSupportPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const loggedinUserSupportActions = loggedinUserSupport.actions
export default loggedinUserSupport.reducer
