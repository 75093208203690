import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type UserModalStatusTypes = 'create' | 'edit' | ''
export interface UserModalPayload {
    isOpen?: boolean
    status?: UserModalStatusTypes
    editData?: any
}
export interface ISettingsUIState {
    visibility: {
        editBatchModalVisibility: boolean
    }
    userModal: UserModalPayload
    existingUserModal: boolean
    addQuestionModal: boolean
    editQuestionModal: boolean
    surId: string
}

const initialState: ISettingsUIState = {
    visibility: {
        editBatchModalVisibility: false
    },
    userModal: {
        isOpen: false,
        status: 'create',
        editData: null
    },
    existingUserModal: false,
    addQuestionModal: false,
    editQuestionModal: false,
    surId: ''
}

const settingsSlice = createSlice({
    name: 'ui/settings',
    initialState,
    reducers: {
        toggleEditBatchModalVisibility(state) {
            state.visibility.editBatchModalVisibility = !state.visibility.editBatchModalVisibility
        },
        toggleUserModal(state, action: PayloadAction<UserModalPayload>) {
            state.userModal.status = action.payload.status as UserModalStatusTypes
            state.userModal.isOpen = action.payload?.isOpen || (!state.userModal.isOpen as boolean)
            state.userModal.editData = (action.payload?.editData as any) || null
            if (!state.userModal.isOpen) {
                state.userModal.editData = null
            }
        },
        setExistingUserModal(state, action) {
            state.existingUserModal = action.payload
        },
        setAddQuestionModal(state, action) {
            state.addQuestionModal = action.payload
        },
        setEditQuestionModal(state, action) {
            state.editQuestionModal = action.payload.visible
            state.surId = action.payload.surId
        }
    }
})

export const settingsActions = settingsSlice.actions
export default settingsSlice.reducer
