import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'

export interface ICreateExistingUserPayload {
    eventId: string
    userId: string
    role: string
}

export interface IState {
    status: string
    success: boolean
    result: boolean
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    success: true,
    result: false,
    errors: null
}

const existingUserCreateSlice = createSlice({
    name: 'hub/users/add-existing-users-to-event',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateExistingUserPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const existingUserCreateActions = existingUserCreateSlice.actions
export default existingUserCreateSlice.reducer
