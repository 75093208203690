import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateMatchListGroupPayload } from '@app-store/slices/matchListGroups/create'

interface TaskAction extends Action {
    payload: ICreateMatchListGroupPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchListGroups.create

function* createMatchListGroupSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/companies/match-list-groups`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.Matching?.Match_list_group_created_successfully || 'Match list group created successfully.'
            })
            yield put(rootActions.matchListGroups.list.onAddToList(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createMatchListGroupSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createMatchListGroupSagaTask)
}

export default createMatchListGroupSaga
