import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IMeetingInfoByTokenPayload extends ISagaCb {
    token?: string
}

export interface IMeetingInfoByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: IMeetingInfoByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const meetingInfoByToken = createSlice({
    name: 'meetingAgenda/meeting-info-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IMeetingInfoByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onChangeFields(state, action) {
            const data = action.payload
            for (const key in data) {
                state.data[key] = data[key]
            }
        }
    }
})

export const meetingInfoByTokenActions = meetingInfoByToken.actions
export default meetingInfoByToken.reducer
