import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateMatchListPayload } from '@app-store/slices/matchLists/create'

interface TaskAction extends Action {
    payload: ICreateMatchListPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchLists.create

function* createMatchListSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/companies/match-lists`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload.sagaCB?.onError === 'function') {
            payload.sagaCB?.onError(error)
        }
    }
}

function* createMatchListSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createMatchListSagaTask)
}

export default createMatchListSaga
