import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ISelectCompaniesByTokenPayload } from '@app-store/slices/sharedLists/selectCompaniesByToken'
interface TaskAction extends Action {
    payload: ISelectCompaniesByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.selectCompaniesByToken

function* selectCompaniesByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/companies/shared-lists/select?token=${payload.token}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* selectCompaniesByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, selectCompaniesByTokenSagaTask)
}

export default selectCompaniesByTokenSaga
