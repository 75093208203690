import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const companyIncomingRequestsSlice = createSlice({
    name: 'ui/company-incoming-requests',
    initialState: { ...listUIInitialState, populates: ['location', 'hostId', 'contactIds'] },
    reducers: listUIReducers
})

export const companyIncomingRequestsActions = companyIncomingRequestsSlice.actions
export default companyIncomingRequestsSlice.reducer
