import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure, onRequestList, onSuccessPageList } = rootActions.properties.listSearch

function* listSearchPropertySagaTask({ payload }) {
    const params = yield getParams({ ...payload, addPresignedURL: true })
    try {
        const response = yield axios.get(`/attachments/properties`, {
            params
        })
        if (response.success) {
            yield payload.sagaCB?.onSuccess(response.result)
            yield put(onSuccess({ keepOldData: payload.keepOldData || false, ...response.result }))
            yield put(onSuccessPageList(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSearchPropertySaga(): any {
    yield takeEvery<ListTaskAction>(onRequestList, listSearchPropertySagaTask)
    yield takeLatest<ListTaskAction>(onRequest, listSearchPropertySagaTask)
}

export default listSearchPropertySaga
