import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IGetAvailableSlotsPayload extends ISagaCb {
    guestIds?: string[]
    hostIds?: string[]
}

export interface IGetAvailableSlotsState {
    status: string
    data: {
        startDate: string
        endDate: string
        available: {
            startDate: string
            endDate: string
        }[]
        unavailable: {
            startDate: string
            endDate: string
        }[]
    }
    errors: any
}

const initialState: IGetAvailableSlotsState = {
    status: STATUS.IDLE,
    data: {
        startDate: '',
        endDate: '',
        available: [],
        unavailable: []
    },
    errors: null
}

const getAvailableSlots = createSlice({
    name: 'meeting/get-available-slots',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetAvailableSlotsPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        reset(state) {
            state = initialState
        }
    }
})

export const getAvailableSlotsActions = getAvailableSlots.actions
export default getAvailableSlots.reducer
