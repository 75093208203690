import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface ICreateModulePayload {
    data: {
        title: string
        status: boolean
    }
}

export interface ICreateModuleState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateModuleState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createModule = createSlice({
    name: 'module/create',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateModulePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onReset(state) {
            state.status = STATUS.ERROR
            state.data = null
            state.errors = null
        }
    }
})

export const createModuleActions = createModule.actions
export default createModule.reducer
