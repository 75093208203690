import createEventCampaignSaga from './createEventCampaignSaga'
import createWishCampaignSaga from './createWishCampaignSaga'
import createEventStep from './createEventStepSaga'
import createWishStep from './createWishStepSaga'
import editCampaignSaga from './editCampaignSaga'
import editStepSaga from './editStepSaga'
import eventCampaignListSaga from './eventCampaignListSaga'
import wishCampaignListSaga from './wishCampaignListSaga'
import oneCampaignSaga from './oneCampaignSaga'
import runCampaignSaga from './runCampaignSaga'
import resumeCampaignSaga from './resumeCampaignSaga'
import pauseCampaignSaga from './pauseCampaignSaga'
import runCampaignStepSaga from './runCampaignStepSaga'
import deleteCampaignSaga from './deleteCampaignSaga'
import deleteCampaignStepSaga from './deleteCampaignStepSaga'
import getStepStatisticsSaga from './getStepStatistics'
import emailStatusSaga from './emailStatusSaga'
import getStepMeetingStatisticsSaga from './getStepMeetingStatisticsSaga'

const campaignsSagas = [
    createEventCampaignSaga(),
    createWishCampaignSaga(),
    createEventStep(),
    createWishStep(),
    editCampaignSaga(),
    editStepSaga(),
    eventCampaignListSaga(),
    wishCampaignListSaga(),
    oneCampaignSaga(),
    runCampaignSaga(),
    runCampaignStepSaga(),
    resumeCampaignSaga(),
    pauseCampaignSaga(),
    deleteCampaignSaga(),
    deleteCampaignStepSaga(),
    getStepStatisticsSaga(),
    emailStatusSaga(),
    getStepMeetingStatisticsSaga()
]

export default campaignsSagas
