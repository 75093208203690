import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListState } from '@typings/requestPayloads'
export interface IListQueryPaginationPayload {
    query?: any
    needPopulate?: boolean
    necessaryProjectionArray?: string[]
    populates?: any[]
    hasTotal?: boolean
    limit?: number
    skip?: number
    sort?: string
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const listSlice = createSlice({
    name: 'service/list',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        addService(state, action) {
            const newData = [...state.data]
            if (!newData.map(item => item._id).includes(action.payload._id)) {
                newData.push({ ...action.payload, images: action.payload.serviceImages })
            }
            if (newData.length > 8) {
                newData.splice(0, 1)
            }
            state.data = newData
        },
        editService(state, action) {
            const newData = [...state.data]
            const service = newData.find(item => (item._id || item.value) === action.payload.id)
            if (service) {
                service.name = action.payload.data.name || service.name
                service.price = action.payload.data.price || service.price
                service.category = action.payload.data.category || service.category
                service.currency = action.payload.data.currency || service.currency
                service.description = action.payload.data.description || service.description
                service.images = action.payload.data.serviceImages

                state.data = newData
            }
        }
    }
})

export const listActions = listSlice.actions
export default listSlice.reducer
