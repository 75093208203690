import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IOneBadgePayload } from '@app-store/slices/badges/oneBadge'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IOneBadgePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.badges.one

function* oneBadgeSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/event/settings/badge/one/${payload.badgeId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneBadgeSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneBadgeSagaTask)
}

export default oneBadgeSaga
