import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { getTimeStampsAsOffset } from '@features/General/dayjs/hanlers'

dayjs.extend(utc)

export const getTimeStamps = (timestampsValue, oldOffsetTime) => {
    const timestamps = getTimeStampsAsOffset(timestampsValue, 0, oldOffsetTime)
    return timestamps.map(item => {
        const endTime = dayjs(item.endTime, 'YYYY-MM-DD hh:mm AZ').utc().format('HH:mm')
        return {
            startTime: item ? dayjs(item.startTime, 'YYYY-MM-DD hh:mm AZ').utc().format('HH:mm') : '',
            endTime: item ? (endTime === '00:00' ? '24:00' : endTime) : '',
            date: item?.date?.format
                ? dayjs(
                      item.date.format('YYYY-MM-DD') +
                          ' ' +
                          dayjs(item.startTime, 'YYYY-MM-DD hh:mm AZ').format('HH:mm'),
                      'YYYY-MM-DD hh:mm AZ'
                  )
                      .utc()
                      .format('YYYY-MM-DD[T]00:00:00')
                : ''
        }
    })
}
