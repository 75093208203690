import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteFeaturePayload } from '@app-store/slices/feature/delete'

interface TaskAction extends Action {
    payload: IDeleteFeaturePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.feature.delete

function* deleteFeatureSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/subscriptions/features/${payload.featureId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.feature_deleted_successfully || 'Feature deleted successfully.'
            })
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess()
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteFeatureSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteFeatureSagaTask)
}

export default deleteFeatureSaga
