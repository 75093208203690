import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteCampaignStepPayload } from '@app-store/slices/campaigns/deleteCampaignStep'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IDeleteCampaignStepPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.deleteCampaignStep

function* deleteCampaignStepSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/notifications/campaigns/${payload.campaignId}/step/${payload.stepId}`)
        if (response.success) {
            yield payload?.sagaCB.onSuccess()
            yield put(onSuccess(response.result))
            yield put(rootActions.campaigns.oneCampaign.onRequest({ campaignId: payload.campaignId }))
            yield put(rootActions.ui.emailCampaign.setSelectedStep(0))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Step_removed_successfully || 'Step removed successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteCampaignStepSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteCampaignStepSagaTask)
}

export default deleteCampaignStepSaga
