import { put, takeLatest, all } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure, onRequestClear, onClear } = rootActions.rfqs.edit
function* editSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/rfqs/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                payload.sagaCB?.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}
function* clearSagaTask({ payload }) {
    yield put(onClear(''))
}

function* editSaga(): any {
    yield all([takeLatest<TaskAction>(onRequest, editSagaTask), takeLatest(onRequestClear, clearSagaTask)])
}

export default editSaga
