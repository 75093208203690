import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const {
    onRequestCategory: onRequest,
    onSuccessCategory: onSuccess,
    onFailureCategory: onFailure
} = rootActions.companies.onboarding
function* categorySagaTask({ payload }) {
    try {
        const payloadData: any = {}
        if (payload?.categories) payloadData.categories = payload?.categories
        if (payload?.newCategories) payloadData.newCategories = payload?.newCategories
        if (payload?.deletedCategories) payloadData.deletedCategories = payload?.deletedCategories
        if (payload?.deletedCustomCategories) payloadData.deletedCustomCategories = payload?.deletedCustomCategories
        const response = yield axios.post(`/companies/categories/onboard-category`, payloadData)

        if (response.success) {
            yield put(onSuccess())
            yield payload.sagaCB.onSuccess()
        }
    } catch (error) {
        yield put(onFailure())
    }
}

function* categorySaga(): any {
    yield takeLatest<TaskAction>(onRequest, categorySagaTask)
}

export default categorySaga
