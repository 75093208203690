import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IOneSpeakerPayload } from '@app-store/slices/speakers/oneSpeaker'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IOneSpeakerPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.speakers.oneSpeaker

function* oneSpeakerSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/event/speaker/one/${payload.speakerId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneSpeakerSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneSpeakerSagaTask)
}

export default oneSpeakerSaga
