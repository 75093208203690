import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IQutationRFQStatsPayloadState } from '@app-store/slices/companyStatistics/qutationRFQStats'

interface TaskAction extends Action {
    payload: IQutationRFQStatsPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.companyStatistics.qutationRFQStats

function* qutationRFQStatsSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/companies/stats/quotation-rfq`)
        if (response.success) {
            yield put(onSuccess(response.result.data))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* qutationRFQStatsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, qutationRFQStatsSagaTask)
}

export default qutationRFQStatsSaga
