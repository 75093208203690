import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
import { initialState } from '@utils/constants/requestConstants'

export interface IAddFeaturePayload extends ISagaCb {
    subscriptionId: string
    newFeatures: any
}

const addFeatureSlice = createSlice({
    name: 'subscription/event-subscription-add-feature',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IAddFeaturePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const addFeatureActions = addFeatureSlice.actions
export default addFeatureSlice.reducer
