import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditVisibilityPayload } from '@app-store/slices/eventSettings/editVisibility'

interface TaskAction extends Action {
    payload: IEditVisibilityPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.editVisibility

function* editVisibilitySagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/event/settings/${payload.settingId}/visibility`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editVisibilitySaga(): any {
    yield takeLatest<TaskAction>(onRequest, editVisibilitySagaTask)
}

export default editVisibilitySaga
