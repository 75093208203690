import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICancelMeetingByTokenPayload } from '@app-store/slices/meetings/cancelByToken'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICancelMeetingByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.cancelMeetingByToken

function* cancelMeetingByTokenSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/meeting/cancel-by-token`, { token: payload.token })
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Meeting?.Meeting_updated_successfully
            })
            if (payload.sagaCB) {
                yield payload.sagaCB.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* cancelMeetingByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, cancelMeetingByTokenSagaTask)
}

export default cancelMeetingByTokenSaga
