import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IUpdateMeetingCostPayload } from '@app-store/slices/meetings/updateMeetingCost'

interface TaskAction extends Action {
    payload: IUpdateMeetingCostPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.updateMeetingCost

function* updateMeetingCostSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/meeting/costs/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (response?.result?._id) {
                if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* updateMeetingCostSaga(): any {
    yield takeLatest<TaskAction>(onRequest, updateMeetingCostSagaTask)
}

export default updateMeetingCostSaga
