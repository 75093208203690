import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditAvailabilityPayload, IEditAvailabilityByTokenPayload } from '@app-store/slices/calendar/typings'

interface TaskAction extends Action {
    payload: IEditAvailabilityPayload
}
interface TaskActionByToken extends Action {
    payload: IEditAvailabilityByTokenPayload
}
const { onRequest, onSuccess, onFailure } = rootActions.calendar.editAvailability
const {
    onRequest: onRequestByToken,
    onSuccess: onSuccessByToken,
    onFailure: onFailureByToken
} = rootActions.calendar.editAvailabilityByToken

function* editAvailabilitySagaTask({ payload }) {
    try {
        const response = yield axios.patch(`/meeting/user-availabilities/calendar/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editAvailabilityByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.patch(
            `/meeting/user-availabilities/calendar/${payload.id}/by-token/${payload.token}`,
            payload.data
        )
        if (response.success) {
            yield put(onSuccessByToken(response.result))
            yield payload?.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailureByToken(error))
    }
}

function* editAvailabilitySaga(): any {
    yield takeLatest<TaskAction>(onRequest, editAvailabilitySagaTask)
}
function* editAvailabilityByToken(): any {
    yield takeLatest<TaskActionByToken>(onRequestByToken, editAvailabilityByTokenSagaTask)
}
export const editAvailabilityByTokenSaga = editAvailabilityByToken
export default editAvailabilitySaga
