import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditSurveyPayload } from '@app-store/slices/survey/editSurvey'

interface TaskAction extends Action {
    payload: IEditSurveyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.survey.editSurvey
const { setEditQuestionModal } = rootActions.ui.settings

function* editSurveySagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.patch(`/meeting/survey/${payload.surId}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(setEditQuestionModal({ visible: false, surId: '' }))
            if (payload.sagaCB) {
                payload.sagaCB?.onSuccess()
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editSurveySaga(): any {
    yield takeLatest<TaskAction>(onRequest, editSurveySagaTask)
}

export default editSurveySaga
