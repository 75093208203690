import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.subscriptions.list

function* listSagaTask({ payload }: ListTaskAction) {
    try {
        const params = yield getParams(payload)
        const response = yield axios.get(`/subscriptions`, {
            params
        })

        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listSagaTask)
}

export default listSaga
