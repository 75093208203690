import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAddUserPayload } from '@app-store/slices/companySubscriptions/addUser'

interface TaskAction extends Action {
    payload: IAddUserPayload
}

const { onSuccess, onRequest, onFailure } = rootActions.companySubscriptions.addUser

function* addUserSagaTask({ payload }) {
    try {
        const response = yield axios.post(
            `/subscriptions/company-subscriptions/user/${payload.subscriptionId}`,
            payload.data
        )
        if (response.success) {
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* addUserSaga(): any {
    yield takeLatest<TaskAction>(onRequest, addUserSagaTask)
}

export default addUserSaga
