import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAddToAgendaByTokenPayload } from '@app-store/slices/meetings/addToAgendaByToken'
interface TaskAction extends Action {
    payload: IAddToAgendaByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.addToAgendaByToken

function* addToAgendaByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/meeting/unavailable/by-token?token=${payload.token}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* addToAgendaByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, addToAgendaByTokenSagaTask)
}

export default addToAgendaByTokenSaga
