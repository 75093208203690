import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateFeaturePayload } from '@app-store/slices/feature/create'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICreateFeaturePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.feature.create
const { onAddToList } = rootActions.feature.list

function* createFeatureSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/subscriptions/features`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(onAddToList(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.feature_created_successfully || 'Feature created successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createFeatureSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createFeatureSagaTask)
}

export default createFeatureSaga
