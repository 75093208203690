import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'
import { RfqTypes, DeliveryTerms, CostTypes } from '@features/Rfq/Market'

export interface ICreateRfqDataPayload {
    _id?: string
    title: string
    type: keyof typeof RfqTypes
    deliveryTerms: keyof typeof DeliveryTerms
    deliveryDate?: string
    expireDate: string
    condition?: string
    description?: string
    products?: {
        quantity: number
        unit: string
        currency?: string
        condition?: string
        targetPrice?: number
        name: string
        description: string
        product: string
        images?: string[]
        categories: [string]
    }[]
    services?: {
        currency?: string
        targetPrice?: number
        service: string
        name: string
        description: string
        images?: string[]
        categories: [string]
    }[]
    eventId?: string
    company: string
    invitedCompanies: string[]
    link?: string
    currency?: string
    targetPrice?: number
    attachments?: any
    costs?: {
        costType: keyof typeof CostTypes
        amount: number
    }[]
}
export interface ICreateListPayload {
    name: string
}

export interface ICreateState {
    status: string
    data: any[]
    errors: any
    id?: any
}

const initialState: ICreateState = {
    status: STATUS.IDLE,
    data: null,
    errors: null,
    id: null
}

const createRfq = createSlice({
    name: 'hub/rfqs/createRfq',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
            state.id = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createRfqActions = createRfq.actions
export default createRfq.reducer
