import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/callLog/list'
import recordingReducer, { recordingActions } from '@app-store/slices/callLog/recording'
import callStatisticReducer, { callStatisticActions } from '@app-store/slices/callLog/callStatistic'
import agentStatisticReducer, { agentStatisticActions } from '@app-store/slices/callLog/agentStatistic'

export const callLogReducers = combineReducers({
    list: listReducer,
    recording: recordingReducer,
    callStatistic: callStatisticReducer,
    agentStatistic: agentStatisticReducer
})

export const callLogActions = {
    list: listActions,
    recording: recordingActions,
    callStatistic: callStatisticActions,
    agentStatistic: agentStatisticActions
}
