import acceptByIdSaga from './acceptByIdSaga'
import acceptByTokenSaga from './acceptByTokenSaga'
import cancelSaga from './cancelSaga'
import createSaga from './createSaga'
import editSaga from './editSaga'
import infoByTokenSaga from './infoByTokenSaga'
import listSaga from './listSaga'
import oneSaga from './oneSaga'
import rejectById from './rejectByIdSaga'
import rejectByTokenSaga from './rejectByTokenSaga'
import suggestByIdSaga from './suggestByIdSaga'
import suggestByTokenSaga from './suggestByTokenSaga'
import getContactsAvailabilitySaga from './getContactsAvailabilitySaga'
import uavailableTimeSlotsByTokenSaga from './uavailableTimeSlotsByToken'
import listHallByTokenSaga from './listHallByTokenSaga'
import listTableByTokenSaga from './listTableByTokenSaga'
import infoByLinkSaga from './infoByLinkSaga'
import directMeetingByLinkSaga from './directMeetingByLinkSaga'

const meetingRequestsSaga = [
    acceptByIdSaga(),
    acceptByTokenSaga(),
    cancelSaga(),
    createSaga(),
    editSaga(),
    infoByTokenSaga(),
    listSaga(),
    oneSaga(),
    rejectById(),
    rejectByTokenSaga(),
    suggestByIdSaga(),
    suggestByTokenSaga(),
    getContactsAvailabilitySaga(),
    uavailableTimeSlotsByTokenSaga(),
    listHallByTokenSaga(),
    listTableByTokenSaga(),
    infoByLinkSaga(),
    directMeetingByLinkSaga()
]

export default meetingRequestsSaga
