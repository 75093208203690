import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOneUserByEmailPayload } from '@app-store/slices/users/oneByEmail'
import { Action } from 'redux'

interface TaskAction extends Action {
    payload: IOneUserByEmailPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.oneByEmail

function* oneByEmailSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/users/by-email/${payload.email}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) {
                payload?.sagaCB?.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneByEmailSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneByEmailSagaTask)
}

export default oneByEmailSaga
