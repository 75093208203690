import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICheckInOutPayload } from '@app-store/slices/contacts/checkInOut'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICheckInOutPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.contacts.checkInOut

function* checkInOutSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.patch(`/companies/contacts/check-in-out/${payload.id}`, {
            activityType: payload.activityType
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.UserActivity_updated_successfully || 'User Activity updated successfully.'
            })
            yield put(rootActions.meetings.list.onUserActivityChange(response.result))
            yield put(
                rootActions.contacts.advancedList.onEditRecordInList({
                    id: response.result._id,
                    data: { activityLogs: response.result.activityLogs }
                })
            )
            if (payload.sagaCB) {
                yield payload.sagaCB.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* checkInOutSaga(): any {
    yield takeLatest<TaskAction>(onRequest, checkInOutSagaTask)
}

export default checkInOutSaga
