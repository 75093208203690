import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IGenerateBpaMatchPayload } from '@app-store/slices/matchLists/generateBpaMatch'

interface TaskAction extends Action {
    payload: IGenerateBpaMatchPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchLists.generateBpaMatch

function* generateBpaMatchSagaTask({ payload }) {
    const params = {
        company: payload.company,
        businessPotential: payload.businessPotential
    }
    try {
        const response = yield axios.get(`/companies/match-lists/bpa-match`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result.data)
        } else {
            yield payload.sagaCB?.onError(response.error)
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* generateBpaMatchSaga(): any {
    yield takeLatest<TaskAction>(onRequest, generateBpaMatchSagaTask)
}

export default generateBpaMatchSaga
