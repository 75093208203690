import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { initialState } from '@utils/constants/requestConstants'

const historyImportAttendeesSlice = createSlice({
    name: 'onbordingOrganizer/historyImportAttendees',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onDeleteRow(state, action) {
            state.data = action.payload.data
        }
    }
})

export const historyImportAttendeesActions = historyImportAttendeesSlice.actions
export default historyImportAttendeesSlice.reducer
