import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateBrandingPayload } from '@app-store/slices/onbordingOrganizer/createBranding'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICreateBrandingPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.onbordingOrganizer.createBranding

function* createBrandingSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post('/event/branding', payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Branding_updated_successfully || 'Branding updated successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createBrandingSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createBrandingSagaTask)
}

export default createBrandingSaga
