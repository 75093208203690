import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ICreateRatingPayload extends ISagaCb {
    data: {
        stars: number
        meetingId: string
        participantCompanyId: string
    }
}

export interface ICreateRatingState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateRatingState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createRating = createSlice({
    name: 'momentum/create-rating',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateRatingPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createRatingActions = createRating.actions
export default createRating.reducer
