import './index.scss'

import React from 'react'
import ReactDOM from 'react-dom'

// import registerServiceWorker from './sw'
import App from '@components/App'
import catchUnhandledRejection from './errorHandler'
import { initialize } from './core/initialisation'

// registerServiceWorker()
catchUnhandledRejection()

const render = (Component: React.ComponentType) => {
    ReactDOM.render(<Component />, document.getElementById('app'))
}

initialize().then(() => render(App))
