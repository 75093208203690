import { combineReducers } from 'redux'
import emailCampaignReducer, { emailCampaignActions } from './emailCampaign.slice'
import eventDetailsViewReducer, { eventDetailsViewActions } from './view.slice'

export const eventDetailsReducer = combineReducers({
    emailCampaign: emailCampaignReducer,
    view: eventDetailsViewReducer
})

export const eventDetailsActions = {
    emailCampaign: emailCampaignActions,
    view: eventDetailsViewActions
}
