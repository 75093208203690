import { put, takeLatest } from 'redux-saga/effects'
import isEmpty from 'lodash/isEmpty'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.wishCampaignList

function* wishCampaignListSagaTask({ payload }) {
    try {
        const query: any = payload?.query || {}
        const pagination = payload?.pagination || undefined
        const params = {}

        if (!isEmpty(query)) Object.assign(params, { query })
        if (pagination) Object.assign(params, { ...pagination })

        const response = yield axios.get(`/notifications/campaigns/wishes`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* wishCampaignListSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, wishCampaignListSagaTask)
}

export default wishCampaignListSaga
