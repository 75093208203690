import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISelectMatchListDataItems {
    selectedCompanies: string[]
}

export interface ISelectMatchListFlowMode {
    sharedListId?: string | null
}

export interface ISelectMatchListUIState extends ISelectMatchListFlowMode {
    data: ISelectMatchListDataItems
    sharedListId: null
}

const initialState: ISelectMatchListUIState = {
    data: {
        selectedCompanies: []
    },
    sharedListId: null
}
const selectMatchListSlice = createSlice({
    name: 'ui/select-match-list',
    initialState,
    reducers: {
        addItems(state, action: PayloadAction<ISelectMatchListDataItems>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.data[item] = action.payload[item]
            }
        },
        setMode(state, action: PayloadAction<ISelectMatchListFlowMode>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state[item] = action.payload[item]
            }
        }
    }
})

export const selectMatchListActions = selectMatchListSlice.actions
export default selectMatchListSlice.reducer
