import { combineReducers } from 'redux'
import createReducer, { createSharedListActions } from '@app-store/slices/sharedLists/create'
import bulkMatchListReducer, { bulkMatchListActions } from '@app-store/slices/sharedLists/bulkMatchList'
import deleteReducer, { deleteSharedListActions } from '@app-store/slices/sharedLists/delete'
import selectCompaniesReducer, { selectCompaniesActions } from '@app-store/slices/sharedLists/selectCompanies'
import rejectCompaniesReducer, { rejectCompaniesActions } from '@app-store/slices/sharedLists/rejectCompanies'
import selectCompaniesByTokenReducer, {
    selectCompaniesByTokenActions
} from '@app-store/slices/sharedLists/selectCompaniesByToken'
import editReducer, { editSharedListActions } from '@app-store/slices/sharedLists/edit'
import editCompaniesReducer, { editCompaniesSharedListActions } from '@app-store/slices/sharedLists/editCompanies'
import listReducer, { listSharedListActions } from '@app-store/slices/sharedLists/list'
import listSearchReducer, { listSearchSharedListActions } from '@app-store/slices/sharedLists/listSearch'
import oneSharedListReducer, { oneSharedListActions } from '@app-store/slices/sharedLists/oneSharedList'
import infoByTokenReducer, { infoByTokenActions } from '@app-store/slices/sharedLists/infoByToken'
import listWithCompanyReducer, { listWithCompanyActions } from '@app-store/slices/sharedLists/listWithCompany'
import changeStatusReducer, { changeStatusActions } from '@app-store/slices/sharedLists/changeStatus'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/sharedLists/staticEnums'
import companyMatchListsReducer, { companyMatchListsActions } from '@app-store/slices/sharedLists/companyMatchLists'
import reShareSharedListReducer, { reShareSharedListActions } from '@app-store/slices/sharedLists/reShare'
import matchListReducer, { matchListActions } from '@app-store/slices/sharedLists/matchList'

export const sharedListsReducer = combineReducers({
    create: createReducer,
    bulkMatchList: bulkMatchListReducer,
    delete: deleteReducer,
    selectCompanies: selectCompaniesReducer,
    rejectCompanies: rejectCompaniesReducer,
    selectCompaniesByToken: selectCompaniesByTokenReducer,
    edit: editReducer,
    editCompanies: editCompaniesReducer,
    list: listReducer,
    listSearch: listSearchReducer,
    oneSharedList: oneSharedListReducer,
    infoByToken: infoByTokenReducer,
    listWithCompany: listWithCompanyReducer,
    changeStatus: changeStatusReducer,
    staticEnums: staticEnumsReducer,
    companyMatchLists: companyMatchListsReducer,
    reShareSharedList: reShareSharedListReducer,
    matchList: matchListReducer
})

export const sharedListsActions = {
    create: createSharedListActions,
    bulkMatchList: bulkMatchListActions,
    delete: deleteSharedListActions,
    selectCompanies: selectCompaniesActions,
    rejectCompanies: rejectCompaniesActions,
    selectCompaniesByToken: selectCompaniesByTokenActions,
    edit: editSharedListActions,
    editCompanies: editCompaniesSharedListActions,
    list: listSharedListActions,
    listSearch: listSearchSharedListActions,
    oneSharedList: oneSharedListActions,
    infoByToken: infoByTokenActions,
    listWithCompany: listWithCompanyActions,
    changeStatus: changeStatusActions,
    staticEnums: staticEnumsActions,
    companyMatchLists: companyMatchListsActions,
    reShareSharedList: reShareSharedListActions,
    matchList: matchListActions
}
