import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateOrderDataPayload {
    user?: string
    company?: string
    subscription: string
    planType?: 'Yearly' | 'Monthly'
    userOption?: string
    setterOption?: string
}

export interface ICreateOrderPayload extends ISagaCb {
    data: ICreateOrderDataPayload
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createSubscriptionOrder = createSlice({
    name: 'subscription/create-subscription-order',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateOrderPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createOrderActions = createSubscriptionOrder.actions
export default createSubscriptionOrder.reducer
