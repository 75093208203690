import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const myAgendaSlice = createSlice({
    name: 'ui/my-agenda',
    initialState: listUIInitialState,
    reducers: {
        ...listUIReducers,
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        }
    }
})

export const myAgendaActions = myAgendaSlice.actions
export default myAgendaSlice.reducer
