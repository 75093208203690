import listSaga from './listSaga'
import oneSubscriptionSaga from './oneSubscriptionSaga'
import staticEnumsSaga from './staticEnumsSaga'
import createSaga from './createSaga'
import editSaga from './editSaga'
import deleteSaga from './deleteSaga'
import createCustomSaga from './createCustomSaga'

const subscriptionsSagas = [
    listSaga(),
    oneSubscriptionSaga(),
    staticEnumsSaga(),
    createSaga(),
    editSaga(),
    deleteSaga(),
    createCustomSaga()
]

export default subscriptionsSagas
