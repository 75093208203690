import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRunCampaignPayload } from '@app-store/slices/campaigns/runCampaign'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IRunCampaignPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.runCampaign

function* runCampaignSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/notifications/campaigns/run-campaign/${payload.campaignId}`)
        const { data: i18n } = yield select((store: RootState) => store.localization.locals)

        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess()
            notification.success({
                message: i18n?.Event?.Campaign_published_successfully || 'Campaign published successfully.'
            })
        } else if (response.status === 503) {
            notification.success({
                message:
                    i18n?.Event?.Campaign_being_prepared_take_some_time ||
                    "The Campaign is being prepared, this could take some time. You will receive an email once it's ready with all details."
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload?.sagaCB?.onError === 'function') {
            payload?.sagaCB?.onError()
        }
    }
}

function* runCampaignSaga(): any {
    yield takeLatest<TaskAction>(onRequest, runCampaignSagaTask)
}

export default runCampaignSaga
