import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { IGetActivePayload } from '@app-store/slices/companySubscriptions/getActive'

interface TaskAction extends Action {
    payload: IGetActivePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companySubscriptions.getActive

function* getActiveSagaTask({ payload }: TaskAction) {
    try {
        const params = yield getParams(payload)
        const response = yield axios.get('subscriptions/company-subscriptions/active', {
            params
        })

        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* getActiveSaga(): any {
    yield takeLatest<TaskAction>(onRequest, getActiveSagaTask)
}

export default getActiveSaga
