import { rootActions } from '@store/slices'
import { IUnSubscribeInfoByTokenPayload } from '@store/slices/unSubscribe/unSubscribeInfoByToken'
import axios from '@utils/request'
import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'

interface TaskAction extends Action {
    payload: IUnSubscribeInfoByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.unSubscribe.unSubscribeInfoByToken

function* unSubscribeInfoByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/notifications/emails/token-info?token=${payload.token}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) payload.sagaCB.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* unSubscribeInfoByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, unSubscribeInfoByTokenSagaTask)
}

export default unSubscribeInfoByTokenSaga
