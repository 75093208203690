import listSaga from './listSaga'
import createSaga from './createSaga'
import deleteSaga from './deleteSaga'
import editSaga from './editSaga'
import editRangeTableSaga from './editRangeTable'
import oneTableSaga from './oneTableSaga'
import availableTablesSaga from './availableTablesSaga'
import validateAvailabilitySaga from './validateAvailabilitySaga'
import renameSectionSaga from './renameSectionSaga'

const hallSaga = [
    listSaga(),
    createSaga(),
    deleteSaga(),
    editSaga(),
    editRangeTableSaga(),
    oneTableSaga(),
    availableTablesSaga(),
    validateAvailabilitySaga(),
    renameSectionSaga()
]

export default hallSaga
