import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import { rootActions } from '@app-store/slices'
import store from '@app-store/store'

dayjs.extend(utc)
dayjs.extend(isoWeek)

export const callAvailableSlotsEndpoint = (
    hostCompanyId,
    guestCompanyId,
    guestIds,
    hostIds,
    isMeetingHubEvent,
    week
) => {
    if (guestIds && hostIds) {
        const payload: any = {
            hostCompanyId,
            hostIds,
            guestIds,
            guestCompanyId
        }
        if (isMeetingHubEvent) {
            const startDate =
                week === 0 ? dayjs().utc().add(1, 'days') : dayjs().utc().startOf('isoWeek').add(week, 'weeks')
            const endDate = dayjs().utc().endOf('isoWeek').add(week, 'weeks')
            payload.userStartDate = startDate.format('YYYY-MM-DD[T]00:00:00')
            if (endDate.diff(startDate, 'seconds', true) < 0) {
                payload.userEndDate = startDate.format('YYYY-MM-DD[T]23:59:59')
                payload.userStartDate = endDate.format('YYYY-MM-DD[T]23:59:59')
            } else {
                payload.userEndDate = endDate.format('YYYY-MM-DD[T]23:59:59')
            }
        }
        store.dispatch(rootActions.meetings.getAvailableSlots.onRequest(payload))
    }
}
