import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IResumeCampaignPayload } from '@app-store/slices/campaigns/resumeCampaign'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IResumeCampaignPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.resumeCampaign
const { onEditRecordInList } = rootActions.campaigns.eventCampaignList

function* resumeCampaignSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/notifications/campaigns/resume/${payload.campaignId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSucccess()
            yield put(onEditRecordInList({ id: payload.campaignId, data: { status: 'Paused' } }))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Campaign_published_successfully || 'Campaign published successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* resumeCampaignSaga(): any {
    yield takeLatest<TaskAction>(onRequest, resumeCampaignSagaTask)
}

export default resumeCampaignSaga
