import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.templates.list

function* listSagaTask({ payload }: ListTaskAction) {
    try {
        const params = yield getParams(payload)

        const response = yield axios.get(`/notifications/templates`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
        } else {
            if (payload.sagaCB) {
                yield payload.sagaCB?.onError(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload.sagaCB) {
            yield payload.sagaCB?.onError()
        }
    }
}

function* listSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listSagaTask)
}

export default listSaga
