import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/subscriptions/list'
import oneSubscriptionReducer, { oneSubscriptionActions } from '@app-store/slices/subscriptions/oneSubscription'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/subscriptions/staticEnums'
import createSubscriptionReducer, { createSubscriptionActions } from '@app-store/slices/subscriptions/create'
import editSubscriptionReducer, { editSubscriptionActions } from '@app-store/slices/subscriptions/edit'
import deleteSubscriptionReducer, { deleteSubscriptionActions } from '@app-store/slices/subscriptions/delete'
import createCustomSubscriptionReducer, {
    createCustomSubscriptionActions
} from '@app-store/slices/subscriptions/createCustom'

export const subscriptionReducers = combineReducers({
    list: listReducer,
    oneSubscription: oneSubscriptionReducer,
    staticEnums: staticEnumsReducer,
    create: createSubscriptionReducer,
    edit: editSubscriptionReducer,
    delete: deleteSubscriptionReducer,
    createCustom: createCustomSubscriptionReducer
})

export const subscriptionActions = {
    list: listActions,
    oneSubscription: oneSubscriptionActions,
    staticEnums: staticEnumsActions,
    create: createSubscriptionActions,
    edit: editSubscriptionActions,
    delete: deleteSubscriptionActions,
    createCustom: createCustomSubscriptionActions
}
