import { combineReducers } from 'redux'
import matchEngineViewReducer, { matchEngineViewActions } from './view.slice'

export const matchEngineReducer = combineReducers({
    view: matchEngineViewReducer
})

export const matchEngineActions = {
    view: matchEngineViewActions
}
