import { Action } from 'redux'
// import notification from 'antd/lib/notification'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import { IResendConfirmationCodePayload } from '@app-store/slices/auth/resendActivationCode'
import { resendConfirmationCodeCognitoUser } from '@utils/authenticateService'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: IResendConfirmationCodePayload
}

const { onFailure, onSuccess, onRequest } = rootActions.auth.resendConfirmationCode

function* resendConfirmationCodeSaga({ payload }: TaskAction) {
    const { data: i18n } = yield select((store: RootState) => store.localization.locals)
    try {
        const response = yield resendConfirmationCodeCognitoUser({ username: payload.username })
        // notification.success({
        //     message:
        //         i18n?.General?.Confirmation_code_sent_your_email || 'Confirmation code has been sent to your email.'
        // })
        yield put(onSuccess(response))
        yield payload.sagaCB?.onSuccess()
    } catch (e) {
        yield put(onFailure(i18n?.General?.Email_is_incorrect || 'Email is incorrect'))
    }
}

function* resendConfirmationCodeCodeSaga(): any {
    yield takeLatest<TaskAction>(onRequest, resendConfirmationCodeSaga)
}

export default resendConfirmationCodeCodeSaga
