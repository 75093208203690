import { ISagaCb } from '@typings/requestPayloads'
import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IGetContactsAvailabilityPayload extends ISagaCb {
    data: {
        guestIds: any[]
        userStartDate?: string
        userEndDate?: string
    }
}

export interface IGetContactsAvailabilityState {
    status: string
    data: any
    errors: any
}

const initialState: IGetContactsAvailabilityState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const getContactsAvailability = createSlice({
    name: 'meeting-requests/get-contacts-availability',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetContactsAvailabilityPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
            state.data = {}
        }
    }
})

export const getContactsAvailabilityActions = getContactsAvailability.actions
export default getContactsAvailability.reducer
