import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateEventCampaignStepPayload } from '@app-store/slices/campaigns/createEventStep'

interface TaskAction extends Action {
    payload: ICreateEventCampaignStepPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.createEventStep

function* createEventStepSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/notifications/campaigns/${payload.campaignId}/event-step`, payload.data)
        if (response.success) {
            yield payload?.sagaCB.onSuccess(response.result)
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (typeof payload?.sagaCB?.onError === 'function') {
            payload?.sagaCB?.onError()
        }
    }
}

function* createEventStepSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createEventStepSagaTask)
}

export default createEventStepSaga
