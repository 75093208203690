import detailsSaga from './detailsSaga'
import brandSaga from './brandSaga'
import importAttendeesSaga from './importAttendeesSaga'
import historyImportAttendeesSaga from './historyImportAttendeesSaga'
import deleteHistoryImportAttendeesSaga from './deleteHistoryImportAttendeesSaga'
import categorySaga from './categorySaga'
import oneBrandSaga from './oneBrandSaga'
import oneBrandByEventSaga from './oneBrandByEventSaga'
import editBrandingSaga from './editBrandingSaga'
import createBrandingSaga from './createBrandingSaga'

const onboardingOrganizerSagas = [
    detailsSaga(),
    brandSaga(),
    oneBrandSaga(),
    oneBrandByEventSaga(),
    importAttendeesSaga(),
    historyImportAttendeesSaga(),
    deleteHistoryImportAttendeesSaga(),
    categorySaga(),
    editBrandingSaga(),
    createBrandingSaga()
]

export default onboardingOrganizerSagas
