import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreatePropertyPayload } from '@app-store/slices/properties/create'

interface TaskAction extends Action {
    payload: ICreatePropertyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.properties.create
const { onSaveAddedData } = rootActions.properties.listSearch
function* createPropertySagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/attachments/properties`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess()
            if (payload.data.relatedRecordType === 'SharedList') {
                yield put(onSaveAddedData(response.result))
            }
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            yield notification.success({
                message: `${payload.data.type} ${i18n?.General?.created_successfully || 'created successfully.'}`,
                duration: 1
            })
        } else {
            yield payload.sagaCB?.onError()
            throw new Error('')
        }
    } catch (error) {
        yield payload.sagaCB?.onError()
        yield put(onFailure(error))
    }
}

function* createPropertySaga(): any {
    yield takeLatest<TaskAction>(onRequest, createPropertySagaTask)
}

export default createPropertySaga
