import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'
import store from '@app-store/store'

const { onRequest, onSuccess, onFailure } = rootActions.feature.list

function* listFeaturetSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/subscriptions/features`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result.data)
            store.dispatch(rootActions.ui.feature.onChangeTotal(response.result.total || 0))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listFeatureSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listFeaturetSagaTask)
}

export default listFeatureSaga
