import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const subscriptionTeamSlice = createSlice({
    name: 'ui/subscription-team',
    initialState: {
        ...listUIInitialState,
        creditModalInfo: {
            isOpen: false,
            userId: ''
        }
    },
    reducers: {
        ...listUIReducers,
        setCreditLimitModalInfo(state, action) {
            Object.keys(action.payload).map(key => {
                state.creditModalInfo[key] = action.payload[key]
            })
        }
    }
})

export const subscriptionTeamActions = subscriptionTeamSlice.actions
export default subscriptionTeamSlice.reducer
