import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateHallPayload } from '@app-store/slices/hall/create'

interface TaskAction extends Action {
    payload: ICreateHallPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.hall.create

function* createHallSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/hall`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createHallSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createHallSagaTask)
}

export default createHallSaga
