import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.dataImport.list

function* listSagaTask({ payload }: TaskAction) {
    try {
        const params = yield getParams(payload)
        const response = yield axios.get(`/companies/imports`, {
            params
        })
        if (response.success) {
            yield payload?.sagaCB?.onSuccess(response.result)
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<TaskAction>(onRequest, listSagaTask)
}

export default listSaga
