import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IGetUnavailableSlotsByTokenPayload extends ISagaCb {
    guestIds?: string[]
    hostIds?: string[]
    query?: any
    sort?: string
    sharedListId?: string
    token: string
    limit?: number
    hasTotal?: boolean
}

export interface IGetUnavailableSlotsByTokenState {
    status: string
    data: any
    errors: any
}

const initialState: IGetUnavailableSlotsByTokenState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const getUnavailableSlotsByToken = createSlice({
    name: 'meeting/get-unavailable-slots-by-token',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IGetUnavailableSlotsByTokenPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getUnavailableSlotsByTokenActions = getUnavailableSlotsByToken.actions
export default getUnavailableSlotsByToken.reducer
