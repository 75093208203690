import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IReinviteUserPayload {
    data: any
}

interface IReinviteState {
    status: string
    data: any
    errors: any
}

const initialState: IReinviteState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const userReinviteSlice = createSlice({
    name: 'hub/user/reinvite',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IReinviteUserPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const userReinviteActions = userReinviteSlice.actions
export default userReinviteSlice.reducer
