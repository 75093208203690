import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IAddContactToWishListPayload extends ISagaCb {
    id: string
    data: {
        contactIds?: string[]
    }
}

interface IAddContactToWishListState {
    status: string
    data: any
    errors: any
}

const initialState: IAddContactToWishListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const wishListsAddContactSlice = createSlice({
    name: 'hub/wish-lists/add-contact',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IAddContactToWishListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const wishListsAddContactActions = wishListsAddContactSlice.actions
export default wishListsAddContactSlice.reducer
