import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IListQueryPaginationPayload } from '@app-store/slices/callLog/list'

interface TaskAction extends Action {
    payload: IListQueryPaginationPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.callLog.list

function* listSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/integrations/maqsam/find-agent-calls?phone=${payload.phone}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result.data)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<TaskAction>(onRequest, listSagaTask)
}

export default listSaga
