import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ICreateEventSettingPayload {
    data: null
}

export interface ICreateEventSettingState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateEventSettingState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createEventSetting = createSlice({
    name: 'event-setting/create',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateEventSettingPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createEventSettingActions = createEventSetting.actions
export default createEventSetting.reducer
