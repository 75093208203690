import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IMeetingRequestByStatusPayloadState } from '@app-store/slices/meetingStatistics/meetingRequestByStatus'

interface TaskAction extends Action {
    payload: IMeetingRequestByStatusPayloadState
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingStatistics.meetingRequestByStatus

function* meetingRequestByStatusSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/meeting/stats/meeting-request/by-status`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB && payload.sagaCB?.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload?.sagaCB && payload.sagaCB?.onError()
    }
}

function* meetingRequestByStatusSaga(): any {
    yield takeLatest<TaskAction>(onRequest, meetingRequestByStatusSagaTask)
}

export default meetingRequestByStatusSaga
