import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListState, IListQueryPaginationPayload } from '@typings/requestPayloads'

const initialState: IListState = {
    status: STATUS.IDLE,
    data: [],
    pagination: {
        skip: 0,
        hasTotal: true,
        total: 0,
        limit: 10
    },
    errors: null
}

const companyMatchLists = createSlice({
    name: 'sharedList/company-matchlists',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            if (action.payload?.noSaveInStore !== true) {
                state.data = action.payload.data
                state.pagination = {
                    ...state.pagination,
                    skip: action.payload.skip,
                    total: action.payload.total
                }
            }
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const companyMatchListsActions = companyMatchLists.actions
export default companyMatchLists.reducer
