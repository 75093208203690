import { combineReducers } from 'redux'
import createListReducer, { createActions } from '@app-store/slices/editList/create'
import listReducer, { listActions } from '@app-store/slices/editList/list'
import oneReducer, { oneActions } from '@app-store/slices/editList/one'
import editReducer, { editActions } from '@app-store/slices/editList/edit'
import publishListReducer, { publishListActions } from '@app-store/slices/editList/publishList'
import internalPublishListReducer, { internalPublishListActions } from '@app-store/slices/editList/internalPublishList'
import prePublishListReducer, { prePublishListActions } from '@app-store/slices/editList/prePublishList'
import deleteEditListReducer, { deleteEditListActions } from '@app-store/slices/editList/delete'
import publishContactListReducer, { publishContactListActions } from '@app-store/slices/editList/publishContactList'
import internalPublishContactListReducer, {
    internalPublishContactListActions
} from '@app-store/slices/editList/internalPublishContactList'

export const editListReducers = combineReducers({
    create: createListReducer,
    list: listReducer,
    edit: editReducer,
    one: oneReducer,
    publishList: publishListReducer,
    internalPublishList: internalPublishListReducer,
    prePublishList: prePublishListReducer,
    delete: deleteEditListReducer,
    publishContactList: publishContactListReducer,
    internalPublishContactList: internalPublishContactListReducer
})

export const editListActions = {
    create: createActions,
    list: listActions,
    edit: editActions,
    one: oneActions,
    publishList: publishListActions,
    internalPublishList: internalPublishListActions,
    prePublishList: prePublishListActions,
    delete: deleteEditListActions,
    publishContactList: publishContactListActions,
    internalPublishContactList: internalPublishContactListActions
}
