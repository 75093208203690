import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListSurveyState {
    status: string
    result: any
    errors: any
}

const initialState: IListSurveyState = {
    status: STATUS.IDLE,
    result: {},
    errors: null
}

const listSurveySlice = createSlice({
    name: '/meeting/survey',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const listSurveyActions = listSurveySlice.actions
export default listSurveySlice.reducer
