import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

const { onRequest, onSuccess, onFailure } = rootActions.eventSetting.eventProfiles

function* eventProfilesTask() {
    try {
        const response = yield axios.get(`/event/event-profiles`)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            yield put(onFailure(response.result))
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* eventProfiles(): any {
    yield takeLatest(onRequest, eventProfilesTask)
}

export default eventProfiles
