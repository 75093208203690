import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IGenerateQRPayload } from '@app-store/slices/qr/generateQR'

interface TaskAction extends Action {
    payload: IGenerateQRPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.qr.generateQR

function* generateQRSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/attachments/qr?query={"returnUrl":true}`, payload.data)
        if (response.data) {
            yield put(onSuccess(response.data))
            yield payload.sagaCB?.onSuccess(response.data)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* generateQRSaga(): any {
    yield takeLatest<TaskAction>(onRequest, generateQRSagaTask)
}

export default generateQRSaga
