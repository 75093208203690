import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const matchListResponsesSlice = createSlice({
    name: 'ui/match-list-responses',
    initialState: {
        ...listUIInitialState,
        populates: [
            { path: 'sharedWithContact', select: 'firstname lastname email' },
            {
                path: 'sharedWithCompany',
                select: '_id userType website img name updatedAt logo matchingPercentage matchingSuggestions'
            }
        ]
    },
    reducers: listUIReducers
})

export const matchListResponsesActions = matchListResponsesSlice.actions
export default matchListResponsesSlice.reducer
