import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/rocketChatRequest'
import { IPostMessagePayload } from '@app-store/slices/chat/postMessage'

interface TaskAction extends Action {
    payload: IPostMessagePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chat.postMessage

function* postMessageSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/chat.postMessage`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* postMessageSaga(): any {
    yield takeLatest<TaskAction>(onRequest, postMessageSagaTask)
}

export default postMessageSaga
