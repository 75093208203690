import { put, takeLatest, all } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onFilteredDataRequest, onSuccess, onFailure, onListData, onFilteredData } = rootActions.rfqs.list

const populates = [
    { path: 'products.product' },
    { path: 'company', select: 'name _id logo' },
    { path: 'createdByContact', select: 'firstname lastname _id avatar jobTitle' }
]

function* listSagaTask({ payload }: ListTaskAction) {
    try {
        const params = yield getParams(payload)
        params.needPopulate = true
        params.populates = populates

        const response = yield axios.get(`/companies/rfqs`, {
            params
        })
        if (response.success) {
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
            yield put(onSuccess({ ...response.result, keepOldData: payload.keepOldData || false }))
            yield put(onListData(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}
function* listFilterSagaTask({ payload }: ListTaskAction) {
    try {
        const params = yield getParams(payload)
        params.needPopulate = true
        params.populates = populates

        const response = yield axios.get(`/companies/rfqs`, {
            params
        })
        if (response.success) {
            yield put(onFilteredData(response.result))
            yield payload.sagaCB?.onSuccess()
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield all([
        takeLatest<ListTaskAction>(onRequest, listSagaTask),
        takeLatest<ListTaskAction>(onFilteredDataRequest, listFilterSagaTask)
    ])
}

export default listSaga
