import { createSlice } from '@reduxjs/toolkit'

export interface ICommonState {
    qrData: []
    color?: string
    badgesPerPage?: number
    pageSize?: string
    selectedTheme: number
}

const initialState: ICommonState = {
    qrData: [],
    color: '',
    badgesPerPage: 0,
    pageSize: 'A4',
    selectedTheme: 1
}

const qrSlice = createSlice({
    name: 'ui/qrData',
    initialState,
    reducers: {
        setQRData(state, action) {
            state.qrData = action.payload.qrData
            state.badgesPerPage = action.payload.badgesPerPage
            state.color = action.payload.color
            state.pageSize = action.payload.pageSize
        },
        setSelectedTheme: (state, { payload }) => {
            state.selectedTheme = payload
        }
    }
})

export const qrActions = qrSlice.actions
export default qrSlice.reducer
