import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.hall.listByCompanyGroups

function* listByCompanyGroupsSagaTask({ payload }) {
    const params = yield getParams(payload)

    try {
        const response = yield axios.get(`/meeting/hall/by-company-groups`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result.data)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listByCompanyGroupsSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, listByCompanyGroupsSagaTask)
}

export default listByCompanyGroupsSaga
