import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ModalType = 'RATING' | 'FEEDBACK_DONE'

export interface SurveyModalType {
    type?: ModalType
    isOpen?: boolean
    surveyId?: string | undefined
}

const initialState: SurveyModalType = {
    type: 'RATING',
    isOpen: false,
    surveyId: undefined
}

const surveySlice = createSlice({
    name: 'ui/survey',
    initialState,
    reducers: {
        openModal(state, action: PayloadAction<SurveyModalType>) {
            state.type = action.payload.type
            state.surveyId = action.payload.surveyId
            state.isOpen = true
        },
        closeModal(state) {
            state.type = 'RATING'
            state.isOpen = false
            state.surveyId = undefined
        },
        changeModalType(state, action: PayloadAction<SurveyModalType>) {
            state.type = action.payload.type
        }
    }
})

export const surveyActions = surveySlice.actions
export default surveySlice.reducer
