import { combineReducers } from 'redux'
import demoImportReducer, { demoImportActions } from '@app-store/slices/demos/import'
import templatesReducer, { templatesActions } from '@app-store/slices/demos/templates'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/demos/staticEnums'

export const demoReducers = combineReducers({
    import: demoImportReducer,
    templates: templatesReducer,
    staticEnums: staticEnumsReducer
})

export const demoActions = {
    import: demoImportActions,
    templates: templatesActions,
    staticEnums: staticEnumsActions
}
