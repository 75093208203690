import { createSlice } from '@reduxjs/toolkit'

interface IBadgeState {
    selectedTheme: number
    organizerLogo: any
    sponsorLogo: any
    iosQrFile: any
    androidQrFile: any
    step1Icon: any
    step2Icon: any
    step3Icon: any
    floorOverviewImage: any
}

const initialState: IBadgeState = {
    selectedTheme: 1,
    organizerLogo: [],
    sponsorLogo: [],
    iosQrFile: [],
    androidQrFile: [],
    step1Icon: [],
    step2Icon: [],
    step3Icon: [],
    floorOverviewImage: []
}

const bagdeSlice = createSlice({
    name: 'badge/actions',
    initialState,
    reducers: {
        setSelectedTheme: (state, { payload }) => {
            state.selectedTheme = payload
        },
        setImage: (state, { payload }) => {
            state[payload.key] = payload.value
        },
        setImages: (state, { payload }) => {
            const objectkeys = Object.keys(payload)
            for (const key of objectkeys) {
                state[key] = payload[key]
            }
        }
    }
})

export const badgeActions = bagdeSlice.actions
export default bagdeSlice.reducer
