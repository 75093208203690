import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

const outgoingRfqsSlice = createSlice({
    name: 'ui/outgoing-rfqs',
    initialState: listUIInitialState,
    reducers: listUIReducers
})

export const outgoingRfqsActions = outgoingRfqsSlice.actions
export default outgoingRfqsSlice.reducer
