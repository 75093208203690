import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IOnePropertyPayload extends ISagaCb {
    propertyId: string
}

export interface IOnePropertyState {
    status: string
    data: null
    errors: any
}

const initialState: IOnePropertyState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneProperty = createSlice({
    name: 'property/one-property',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOnePropertyPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const onePropertyActions = oneProperty.actions
export default oneProperty.reducer
