import { createSlice } from '@reduxjs/toolkit'

interface IEventEmailCampaignStep {
    subject: string
    sendAt: string
    sendDate: string
    content: string
    hasChanged: boolean
}

export interface IEventEmailCampaignUIState {
    campaign: {
        name: string
        steps: any[]
    }
    selectedStepIndex: number
    changedStepIndexes: any[]
    downloadStatisticsModal: {
        isOpen: boolean
        stepId: string
        campaignId: string
    }
}

export const initialEmailCampaignStepData: IEventEmailCampaignStep = {
    subject: '',
    sendAt: '',
    sendDate: '',
    content: '',
    hasChanged: false
}

const initialState: IEventEmailCampaignUIState = {
    campaign: {
        name: '',
        steps: []
    },
    selectedStepIndex: 0,
    changedStepIndexes: [],
    downloadStatisticsModal: {
        isOpen: false,
        stepId: '',
        campaignId: ''
    }
}
const eventEmailCampaignSlice = createSlice({
    name: 'ui/event-email-campaign',
    initialState,
    reducers: {
        setCampaign(state, action) {
            state.campaign = {
                ...action.payload,
                steps: action.payload?.steps?.map(item => ({ ...item, sendDate: item.sendAt }))
            }
        },
        pushStep(state, action) {
            state.campaign.steps.push(action.payload || initialEmailCampaignStepData)
            state.selectedStepIndex = state.campaign.steps.length - 1
        },
        popStep(state, action) {
            state.campaign.steps.splice(action.payload, 1)
            if (state.selectedStepIndex === action.payload) {
                if (state.campaign.steps.length) {
                    state.selectedStepIndex = 0
                }
            }
        },
        setSelectedStep(state, action) {
            state.selectedStepIndex = action.payload || 0
        },
        onChangeData(state, action) {
            const acceptableKeywords = [
                'subject',
                'sendAt',
                'sendDate',
                'content',
                'hasChanged',
                'place',
                'duration',
                'stepType',
                'templateId',
                'reason',
                'payloadType'
            ]
            const actionKeys = Object.keys(action.payload)
            const isValidObject = actionKeys.every(item => acceptableKeywords.includes(item))
            const activeIndex = state.selectedStepIndex
            const indexes = state.changedStepIndexes

            if (isValidObject) {
                const set = new Set([...indexes, activeIndex])
                state.campaign.steps[activeIndex] = {
                    ...state.campaign.steps[activeIndex],
                    ...action.payload
                }
                state.changedStepIndexes = [...set]
            }
        },
        onChangeStep(state, action) {
            state.campaign.steps[action.payload.index] = {
                ...state.campaign.steps[action.payload.index],
                ...action.payload.data
            }
        },
        reset(state) {
            state = initialState
        },
        changeDownloadStatisticsModalData(state, action) {
            Object.keys(action.payload).forEach(key => {
                state.downloadStatisticsModal[key] = action.payload[key]
            })
        }
    }
})

export const emailCampaignActions = eventEmailCampaignSlice.actions
export default eventEmailCampaignSlice.reducer
