import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import notification from 'antd/lib/notification'
import { IDeleteSurveyPayload } from '@app-store/slices/survey/deleteSurvey'

interface TaskAction extends Action {
    payload: IDeleteSurveyPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.survey.deleteSurvey

function* deleteSurveySagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete(`/meeting/survey/${payload.surId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Survey_deleted_successfully || 'Survey deleted successfully.'
            })
            yield payload.sagaCB?.onSuccess()
        } else {
            yield payload.sagaCB?.onError()
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        yield payload.sagaCB?.onError()
    }
}

function* deleteSurveySaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteSurveySagaTask)
}

export default deleteSurveySaga
