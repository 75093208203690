import listSaga from './listSaga'
import staticEnumsSagas from './staticEnumsSaga'
import createRfq from './createRfq'
import oneRfqSaga from './oneRfqSaga'
import editSaga from './editSaga'
import inviteSuppliers from './inviteSuppliers'
import decline from './declineSaga'
import close from './closeSaga'
import outgoingDetail from './outgoingDetail'
import orderSaga from './orderSaga'
import createOrderSaga from './createOrder'
import contactCompanyProfileSaga from './contactCompanyProfileSaga'
import getStatistics from './getStatistics'
import getListStatistics from './getListStatistics'

const eventsSagas = [
    listSaga(),
    staticEnumsSagas(),
    oneRfqSaga(),
    createRfq(),
    inviteSuppliers(),
    decline(),
    close(),
    outgoingDetail(),
    editSaga(),
    orderSaga(),
    createOrderSaga(),
    contactCompanyProfileSaga(),
    getStatistics(),
    getListStatistics()
]

export default eventsSagas
