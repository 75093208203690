import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListState } from '@typings/requestPayloads'
export interface IListQueryPaginationPayload {
    query?: any
    needPopulate?: boolean
    necessaryProjectionArray?: string[]
    populates?: any[]
    hasTotal?: boolean
    limit?: number
    skip?: number
    sort?: string
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: [],
    errors: null
}

const listSlice = createSlice({
    name: 'product/list',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        addProduct(state, action) {
            const newData = [...state.data]
            if (!newData.map(item => item._id).includes(action.payload._id)) {
                newData.push({ ...action.payload, images: action.payload.productImages })
            }
            if (newData.length > 8) {
                newData.splice(0, 1)
            }
            state.data = newData
        },
        editProduct(state, action) {
            const newData = [...state.data]
            const product = newData.find(item => (item._id || item.value) === action.payload.id)
            if (product) {
                product.name = action.payload.data.name || product.name
                product.brand = action.payload.data.brand || product.brand
                product.price = action.payload.data.price || product.price
                product.category = action.payload.data.category || product.category
                product.currency = action.payload.data.currency || product.currency
                product.images = action.payload.data.productImages
                product.productCode = action.payload.data.productCode || product.productCode
                product.quantity = action.payload.data.quantity || product.quantity
                state.data = newData
            }
        }
    }
})

export const listActions = listSlice.actions
export default listSlice.reducer
