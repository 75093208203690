import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import notification from 'antd/lib/notification'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IUserIdByContactPayload } from '@app-store/slices/chatDetails/getUserIdByContact'

interface TaskAction extends Action {
    payload: IUserIdByContactPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.chatDetails.getUserIdByContact

function* getUserIdByContactSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/users/chat-details/contact/${payload.contactId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB.onSuccess(response.result)
        } else {
            payload.sagaCB.onError()
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.error({
                message: i18n?.TradeHub?.contact_doesnt_have_chat_account || "This contact doesn't have chat account",
                duration: 3
            })
        }
    } catch (error) {
        payload.sagaCB.onError()
        yield put(onFailure(error))
    }
}

function* getUserIdByContactSaga(): any {
    yield takeLatest<TaskAction>(onRequest, getUserIdByContactSagaTask)
}

export default getUserIdByContactSaga
