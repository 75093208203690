import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IDownloadAgendasPayload } from '@app-store/slices/reporting/downloadAgendas'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IDownloadAgendasPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.reporting.downloadAgendas

function* downloadAgendasSagaTask({ payload }) {
    try {
        const params: any = {}
        if (payload.companies) {
            params.companies = payload.companies
        }
        if (payload.status) {
            params.status = payload.status
        }
        if (payload.participants) {
            params.participants = payload.participants
        }
        if (payload.meetingDate) {
            params.meetingDate = payload.meetingDate
        }
        if (payload.meetingType) {
            params.meetingType = payload.meetingType
        }
        if (payload.timeShift) {
            params.timeShift = payload.timeShift
        }
        const response = yield axios.get(`/reporting/agendas/download`, { responseType: 'blob', params })
        if (response) {
            yield put(onSuccess(response))
            yield payload?.sagaCB?.onSuccess(response)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* downloadAgendasSaga(): any {
    yield takeLatest<TaskAction>(onRequest, downloadAgendasSagaTask)
}

export default downloadAgendasSaga
