import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from '@utils/constants/requestConstants'
import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { listSideEffectReducers } from '../reducers'

const listSearchSlice = createSlice({
    name: 'hub/companies/list-search',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers
    }
})

export const listSearchActions = listSearchSlice.actions
export default listSearchSlice.reducer
