import { rootActions } from '@store/slices'
import { ICreateExistingUserPayload } from '@app-store/slices/users/createExistingUser'
import axios from '@utils/request'
import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'

interface TaskAction extends Action {
    payload: ICreateExistingUserPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.existingUserCreate
const { setExistingUserModal } = rootActions.ui.settings

function* existingUserCreateSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/users/add-existing-users-to-event`, [...payload])

        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(setExistingUserModal(false))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* existingUserCreateSaga(): any {
    yield takeLatest<TaskAction>(onRequest, existingUserCreateSagaTask)
}

export default existingUserCreateSaga
