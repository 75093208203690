import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { IDataState } from '@typings/requestPayloads'
import { listUIReducers } from '../reducers'
export interface IMatchListViewDataState extends IDataState {
    addCompaniesModalVisible: boolean
}

const matchListViewInitialState: IMatchListViewDataState = {
    ...listUIInitialState,
    addCompaniesModalVisible: false
}

const matchListViewSlice = createSlice({
    name: 'ui/match-list-view',
    initialState: matchListViewInitialState,
    reducers: {
        ...listUIReducers,
        onChangeAddCompaniesModalVisibility(state, action) {
            state.addCompaniesModalVisible = action.payload
        }
    }
})

export const matchListViewActions = matchListViewSlice.actions
export default matchListViewSlice.reducer
