import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IListQueryPaginationPayload } from '@typings/requestPayloads'
import { MeetingNoteType } from '@features/Meeting/MeetingAgenda/constants/note'

export interface IUploadFileModalState {
    visible: boolean
    type: keyof typeof MeetingNoteType
    relatedRecordId: string
    subRecordId: string
    relatedRecordType: string
}
const initialUploadFileModalState: IUploadFileModalState = {
    visible: false,
    type: 'Image',
    relatedRecordId: '',
    subRecordId: '',
    relatedRecordType: ''
}
const initialState = {
    status: STATUS.IDLE,
    data: [],
    pageList: [],
    pagination: {
        skip: 0,
        hasTotal: true,
        total: 0,
        limit: 10
    },
    errors: null,
    UploadFileModal: initialUploadFileModalState
}

const listProperty = createSlice({
    name: 'property/list',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onRequestList(state, action: PayloadAction<IListQueryPaginationPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            if (action.payload.keepOldData) {
                state.data = [...state.data, ...action.payload.data]
            } else {
                state.data = action.payload.data
            }

            state.pagination = {
                ...state.pagination,
                skip: action.payload.skip,
                total: action.payload.total
            }
        },
        onSuccessPageList(state, action) {
            state.status = STATUS.READY
            const oldIds = []
            state.pageList.map(item => {
                oldIds.push(item._id)
            })
            action.payload.data.map(item => {
                if (oldIds.indexOf(item._id) == -1) {
                    state.pageList = [...state.pageList, item]
                }
            })
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        setViewUploadFileModal(state, action) {
            state.UploadFileModal.visible = action.payload.visible
            state.UploadFileModal.type = action.payload.type
            state.UploadFileModal.relatedRecordId = action.payload.relatedRecordId
            state.UploadFileModal.subRecordId = action.payload.subRecordId
            state.UploadFileModal.relatedRecordType = action.payload.relatedRecordType
        }
    }
})

export const listPropertyActions = listProperty.actions
export default listProperty.reducer
