import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers } from '../reducers'

const listSlice = createSlice({
    name: 'hub/data-import/list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<any | undefined>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers
    }
})

export const listActions = listSlice.actions
export default listSlice.reducer
