import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/events/list'
import oneEventReducer, { oneEventActions } from '@app-store/slices/events/oneEvent'
import editEventReducer, { editEventActions } from '@app-store/slices/events/edit'
import deleteEventReducer, { deleteEventActions } from '@app-store/slices/events/delete'
import searchEventsReducer, { searchEventsActions } from '@app-store/slices/events/searchEvents'
import checkDomainReducer, { checkDomainActions } from '@app-store/slices/events/checkDomain'
import checkEventPublishabilityReducer, {
    checkEventPublishabilityActions
} from '@app-store/slices/events/checkEventPublishability'
import oneEventByDomainReducer, { oneEventByDomainActions } from '@app-store/slices/events/oneEventByDomain'

export const eventsReducer = combineReducers({
    list: listReducer,
    oneEvent: oneEventReducer,
    edit: editEventReducer,
    delete: deleteEventReducer,
    searchEvents: searchEventsReducer,
    checkDomain: checkDomainReducer,
    checkEventPublishability: checkEventPublishabilityReducer,
    oneEventByDomain: oneEventByDomainReducer
})

export const eventsActions = {
    list: listActions,
    oneEvent: oneEventActions,
    edit: editEventActions,
    delete: deleteEventActions,
    searchEvents: searchEventsActions,
    checkDomain: checkDomainActions,
    checkEventPublishability: checkEventPublishabilityActions,
    oneEventByDomain: oneEventByDomainActions
}
