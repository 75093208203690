import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IRemoveCompanyFromWishListPayload extends ISagaCb {
    id: any
    data: {
        companyIds?: string[]
        contactIds?: string[]
    }
}

interface IRemoveCompanyToWishListState {
    status: string
    data: any
    errors: any
}

const initialState: IRemoveCompanyToWishListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const wishListsRemoveCompanySlice = createSlice({
    name: 'hub/wish-lists/remove-company',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IRemoveCompanyFromWishListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const wishListsRemoveCompanyActions = wishListsRemoveCompanySlice.actions
export default wishListsRemoveCompanySlice.reducer
