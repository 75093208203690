import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import { IInfoByLinkPayload } from '@store/slices/meetingRequests/infoByLink'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: IInfoByLinkPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetingRequests.infoByLink

function* infoByLinkTask({ payload }: TaskAction) {
    try {
        const link = payload.data.link
        delete payload.data.link
        const response = yield axios.get(`/meeting/user-availabilities/calendar/link/one/${link}`, {
            params: payload.data
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload?.sagaCB?.onError(error)
    }
}

function* infoByLink(): any {
    yield takeLatest<TaskAction>(onRequest, infoByLinkTask)
}

export default infoByLink
