import { combineReducers } from 'redux'
import createSpeakerReducer, { createSpeakerActions } from '@app-store/slices/speakers/createSpeaker'
import editSpeakerReducer, { editSpeakerActions } from '@app-store/slices/speakers/editSpeaker'
import deleteSpeakerReducer, { deleteSpeakerActions } from '@app-store/slices/speakers/deleteSpeaker'
import oneSpeakerReducer, { oneSpeakerActions } from '@app-store/slices/speakers/oneSpeaker'
import listReducer, { listActions } from '@app-store/slices/speakers/list'

export const speakersReducer = combineReducers({
    list: listReducer,
    create: createSpeakerReducer,
    edit: editSpeakerReducer,
    oneSpeaker: oneSpeakerReducer,
    delete: deleteSpeakerReducer
})

export const speakersActions = {
    list: listActions,
    create: createSpeakerActions,
    edit: editSpeakerActions,
    oneSpeaker: oneSpeakerActions,
    delete: deleteSpeakerActions
}
