import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationType } from '@typings/requestPayloads'
import { listInitialState } from '@utils/constants/requestConstants'
import { listSideEffectReducers, manipulateListReducers } from '../reducers'

export interface ICompanyPublishedListQueryPayload {
    pagination?: PaginationType
    userType?: string
}

const publishedListSlice = createSlice({
    name: 'hub/companies/published-list',
    initialState: listInitialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICompanyPublishedListQueryPayload>) {
            state.status = STATUS.RUNNING
        },
        ...listSideEffectReducers,
        ...manipulateListReducers
    }
})

export const publishedListActions = publishedListSlice.actions
export default publishedListSlice.reducer
