import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IOneUrlPayload extends ISagaCb {
    urlId: string
}

export interface IOneUrlState {
    status: string
    data: null
    errors: any
}

const initialState: IOneUrlState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneUrl = createSlice({
    name: 'property/one-url',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneUrlPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneUrlActions = oneUrl.actions
export default oneUrl.reducer
