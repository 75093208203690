import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IListByLevelPayload } from '@app-store/slices/categories/listByLevel'

interface TaskAction extends Action {
    payload: IListByLevelPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.categories.listByLevel

function* listByLevelSagaTask({ payload }: TaskAction) {
    try {
        const params: any = {}
        if (payload.query) {
            params.query = payload.query
        }
        if (payload.necessaryProjectionArray) {
            params.necessaryProjectionArray = payload.necessaryProjectionArray
        }
        const response = yield axios.get(
            `/companies/categories/${payload.parentCategory}/sub-categories/${payload.level}`,
            {
                params
            }
        )
        if (response.success) {
            yield put(onSuccess({ ...response.result }))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listByLevelSaga(): any {
    yield takeLatest<TaskAction>(onRequest, listByLevelSagaTask)
}

export default listByLevelSaga
