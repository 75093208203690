import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IPauseCampaignPayload } from '@app-store/slices/campaigns/pauseCampaign'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IPauseCampaignPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.pauseCampaign
const { onEditRecordInList } = rootActions.campaigns.eventCampaignList

function* pauseCampaignSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/notifications/campaigns/pause/${payload.campaignId}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSucccess()
            yield put(onEditRecordInList({ id: payload.campaignId, data: { status: 'Ongoig' } }))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Campaign_published_successfully || 'Campaign published successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* pauseCampaignSaga(): any {
    yield takeLatest<TaskAction>(onRequest, pauseCampaignSagaTask)
}

export default pauseCampaignSaga
