import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: {
        attendeeExhibitorTemplate: '',
        attendeeVisitorTemplate: '',
        categoryTemplate: '',
        itpTemplate: ''
    },
    errors: null
}

const onboardingTemplates = createSlice({
    name: 'onboarding/templates',
    initialState,
    reducers: {
        onRequest(state) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const onboardingTemplatesActions = onboardingTemplates.actions
export default onboardingTemplates.reducer
