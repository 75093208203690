import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IFeedbackListPayload {
    surveyId: string
}

export interface IFeedbackListState {
    status: string
    result: any
    errors: any
}

const initialState: IFeedbackListState = {
    status: STATUS.IDLE,
    result: {},
    errors: null
}

const feedbackListSlice = createSlice({
    name: '/meeting/survey-feedback',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IFeedbackListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const feedbackListActions = feedbackListSlice.actions
export default feedbackListSlice.reducer
