import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface IEditStepPayload extends ISagaCb {
    campaignId: string
    stepId: string
    data: any
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editStep = createSlice({
    name: 'campaign/edit-step',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IEditStepPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editStepActions = editStep.actions
export default editStep.reducer
