import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IChangeStatusUserPayload } from '@app-store/slices/users/changeStatus'

interface TaskAction extends Action {
    payload: IChangeStatusUserPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.changeStatus
const { onRequest: getUserList } = rootActions.users.list

function* changeStatusSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/users/status/${payload.id}`, {
            status: payload.status
        })
        if (response.success) {
            yield put(onSuccess(''))
            const pagination = { hasTotal: true, skip: 0, limit: 10 }
            yield put(getUserList({ pagination }))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* changeStatusSaga(): any {
    yield takeLatest<TaskAction>(onRequest, changeStatusSagaTask)
}

export default changeStatusSaga
