import { put, select, take, takeLatest, all } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { RootState } from '@app-store/store'

interface TaskAction extends Action {
    payload: {
        id: string
    }
}

const { onRequest, onSuccess, onCompanyData, onFailure, onUploadImage } = rootActions.companies.oneCompany

function* oneCompanySagaTask({ payload }) {
    const config = {
        params: payload?.params,
        headers: undefined
    }
    if (payload.eventId) {
        config.headers = {
            ['event-id']: payload.eventId
        }
    }
    try {
        const response = yield axios.get(`/companies/one/${payload.companyId}`, config)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
            yield put(onCompanyData(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}
const { onRequest: getPreSignedUploadURL } = rootActions.companies.getPreSignedUploadURL
const { onRequest: fileUpload } = rootActions.companies.fileUpload

function* uploadImage({ payload }) {
    try {
        yield put(getPreSignedUploadURL())
        yield take('hub/companies/get-presigned-upload-URL/onSuccess')
        const { file } = yield select((store: RootState) => store.companies.getPreSignedUploadURL.data)
        yield put(fileUpload(payload.image))
        yield take('hub/companies/file-upload/onSuccess')
        yield put(
            rootActions.companies.oneCompany.onEditRequest({
                id: payload.id,
                data: { [payload.type]: file },
                sagaCB: payload.sagaCB
            })
        )
        yield take('hub/companies/one-company/onSuccess')
    } catch (e) {
        throw new Error(e)
    }
}

function* oneCompanySaga(): any {
    yield all([
        takeLatest<TaskAction>(onRequest, oneCompanySagaTask),
        takeLatest<TaskAction>(onUploadImage, uploadImage)
    ])
}

export default oneCompanySaga
