import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IQueueStatisticsPayload extends ISagaCb {
    queueName?: string
    action?: string
    matchListGroupId: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const queueStatisticsSlice = createSlice({
    name: 'reporting/queue-statistics',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IQueueStatisticsPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const queueStatisticsActions = queueStatisticsSlice.actions
export default queueStatisticsSlice.reducer
