import { STATUS } from '@features/General'
import { MeetingType } from '@features/Meeting/MeetingAgenda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IEditMeetingPayload extends ISagaCb {
    meetingId: string
    id?: string // only for labelTag
    data: {
        meetingType?: keyof typeof MeetingType
        hostIds?: string[]
        guestIds?: string[]
        hostCompanyId?: string
        guestCompanyId?: string
        description?: string
        guests?: string[]
        table?: string
        hall?: string
        floorPlanLink?: string
        meetingDuration?: number
        reminderDuration?: number
        startTime?: string
        endTime?: string
        reminderType?: string[]
        status?: string
    }
}

export interface IEditMeetingState {
    status: string
    data: any
    errors: any
}

const initialState: IEditMeetingState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editMeeting = createSlice({
    name: 'meeting/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditMeetingPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editMeetingActions = editMeeting.actions
export default editMeeting.reducer
