import { createSlice } from '@reduxjs/toolkit'
import store from 'store'
import { STATUS, LOCALSTORAGE_KEYS } from '@features/General'
const onboarding = store.get(LOCALSTORAGE_KEYS.ONBOARDING_ORGANIZER)
const initialState = {
    status: STATUS.IDLE,
    details: {
        title: 'Test Title',
        description: null,
        condition: null,
        deliveryDate: '2022-06-18T00:00:00',
        rfq: null,
        products: []
    },
    products: [],
    services: [],
    categories: []
}

const createSendQuotationsSlice = createSlice({
    name: 'hub/quotations/createSend',
    initialState: onboarding || initialState,
    reducers: {
        setDetails(state, action) {
            state['details'][action.payload.type] = action.payload.value
        },
        editProducts(state, action) {
            state['products'][action.payload.indis][action.payload.key] = action.payload.value
        },
        removeProducts(state, action) {
            const newArr = state.products.filter(i => i.product !== action.payload._id)
            state['products'] = newArr
        },
        setProducts(state, action) {
            const arr = []
            arr.push(action.payload)
            // state['products'] = action.payload
            state.products = [...state.products, action.payload]
        },
        editServices(state, action) {
            state['services'][action.payload.indis][action.payload.key] = action.payload.value
        },
        removeServices(state, action) {
            const newArr = state.services.filter(i => i._id !== action.payload._id)
            state['services'] = newArr
        },
        setServices(state, action) {
            const arr = []
            arr.push(action.payload)
            // state['services'] = action.payload
            state.services = [...state.services, action.payload]
        }
    }
})

export const createSendActions = createSendQuotationsSlice.actions
export default createSendQuotationsSlice.reducer
