import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/event-type/list'

export const eventTypeReducer = combineReducers({
    list: listReducer
})

export const eventTypeActions = {
    list: listActions
}
