import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditMatchListGroupPayload } from '@app-store/slices/matchListGroups/edit'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IEditMatchListGroupPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.matchListGroups.edit

function* editMatchListGroupSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.put(`/companies/match-list-groups/${payload.matchListGroupId}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message:
                    i18n?.Matching?.Match_list_group_updated_successfully || 'Match list group updated successfully.'
            })
            yield put(
                rootActions.matchListGroups.list.onEditRecordInList({
                    id: payload.matchListGroupId,
                    data: response.result
                })
            )
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editMatchListGroupSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editMatchListGroupSagaTask)
}

export default editMatchListGroupSaga
