import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IOneMeetingAgendaPayload extends ISagaCb {
    meetingId: string
}

export interface IOneMeetingState {
    status: string
    data: any
    errors: any
}

const initialState: IOneMeetingState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneMeetingAgenda = createSlice({
    name: 'meeting-agenda/one-meeting',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneMeetingAgendaPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onChangeFields(state, action) {
            const data = action.payload
            for (const key in data) {
                state.data[key] = data[key]
            }
        }
    }
})

export const oneMeetingAgendaActions = oneMeetingAgenda.actions
export default oneMeetingAgenda.reducer
