import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IModuleByIDPayload {
    id: string
}

export interface IModuleByIDState {
    status: string
    data: any
    errors: any
}

const initialState: IModuleByIDState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const moduleByID = createSlice({
    name: 'module-by-id',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IModuleByIDPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const moduleByIDActions = moduleByID.actions
export default moduleByID.reducer
