import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IFindUserPayload } from '@app-store/slices/users/findUser'
import { Action } from 'redux'

interface TaskAction extends Action {
    payload: IFindUserPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.findUser

function* findUserSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/users/find-user`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) {
                payload?.sagaCB?.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* findUserSaga(): any {
    yield takeLatest<TaskAction>(onRequest, findUserSagaTask)
}

export default findUserSaga
