import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'

export interface ICreateEventCampaignStepPayload extends ISagaCb {
    campaignId: string
    data: {
        subject: string
        content: string
        sendAt: Date
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createEventStep = createSlice({
    name: 'campaign/create-event-step',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateEventCampaignStepPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createEventStepActions = createEventStep.actions
export default createEventStep.reducer
