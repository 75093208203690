import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IOneSessionPayload {
    sessionId: string
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneSession = createSlice({
    name: 'sessions/one-session',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IOneSessionPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const oneSessionActions = oneSession.actions
export default oneSession.reducer
