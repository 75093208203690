import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRenameSectionPayload } from '@app-store/slices/table/renameSection'

interface TaskAction extends Action {
    payload: IRenameSectionPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.table.renameSection

function* renameSectionSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.patch(`/meeting/table/by-section/hall/${payload.hallId}`, payload.data)
        if (response.success) {
            yield put(onSuccess({ response: response.result, tableId: payload.hallId }))
            if (payload?.sagaCB) {
                payload.sagaCB.onSuccess()
            }
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Event?.Section_renamed_successfully || 'Section renamed successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure({ error, tableId: payload.hallId }))
    }
}

function* renameSectionSaga(): any {
    yield takeLatest<TaskAction>(onRequest, renameSectionSagaTask)
}

export default renameSectionSaga
