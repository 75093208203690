import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditTourPayload } from '@app-store/slices/tours/edit'

interface TaskAction extends Action {
    payload: IEditTourPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.tours.edit

function* editTourSagaTask({ payload }) {
    try {
        const response = yield axios.patch(`/event/tour/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.Settings?.Tour_updated_successfully || 'Tour updated successfully.'
            })
        } else {
            yield payload.sagaCB?.onError()
            throw new Error('')
        }
    } catch (error) {
        yield payload.sagaCB?.onError()
        yield put(onFailure(error))
    }
}

function* editTourSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editTourSagaTask)
}

export default editTourSaga
