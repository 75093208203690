import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { ISyncAuthenticateUrlByTokenPayload } from '@app-store/slices/calendar/typings'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: ISyncAuthenticateUrlByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.calendar.syncAuthenticateUrlByToken

function* syncAuthenticateUrlByTokenSagaTask({ payload }) {
    try {
        const response = yield axios.get(
            `/meeting/sync/calendar/auth-url/by-token/${payload.token}?sourceType=${payload.sourceType}`
        )
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* syncAuthenticateUrlByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, syncAuthenticateUrlByTokenSagaTask)
}

export default syncAuthenticateUrlByTokenSaga
