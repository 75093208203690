import listSaga from './listSaga'
import createSaga from './createSaga'
import createByAdminSaga from './createByAdminSaga'
import editSaga from './editSaga'
import deleteSaga from './deleteSaga'
import listByTokenSaga from './listByTokenSaga'
import createByTokenSaga from './createByTokenSaga'
import editByAdminSaga from './editByAdminSaga'
import deleteByAdminSaga from './deleteByAdminSaga'
import listByAdminSaga from './listByAdminSaga'
const tagsSagas = [
    listSaga(),
    createSaga(),
    createByAdminSaga(),
    editSaga(),
    deleteSaga(),
    listByTokenSaga(),
    createByTokenSaga(),
    listByAdminSaga(),
    deleteByAdminSaga(),
    editByAdminSaga()
]

export default tagsSagas
