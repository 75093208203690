import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IEditTagPayload } from '@app-store/slices/tags/edit'

interface TaskAction extends Action {
    payload: IEditTagPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.tags.edit

function* editTagSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/tags/${payload.id}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editTagSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editTagSagaTask)
}

export default editTagSaga
