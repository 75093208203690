import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { IGetConnectedCalendarPayload } from '@app-store/slices/calendar/typings'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: IGetConnectedCalendarPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.calendar.getConnectedCalendar
const {
    onRequest: onRequestByToken,
    onSuccess: onSuccessByToken,
    onFailure: onFailureByToken
} = rootActions.calendar.getConnectedCalendarByToken

function* getConnectedCalendarSagaTask({ payload }) {
    const params: any = {
        necessaryProjectionArray: ['status', 'sourceType']
    }
    if (payload.query) {
        params.query = payload.query
    }
    try {
        const response = yield axios.get(`/meeting/sync/calendar`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result?.data || []))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getConnectedCalendarByTokenSagaTask({ payload }) {
    const params: any = {
        necessaryProjectionArray: ['status', 'sourceType']
    }
    if (payload.query) {
        params.query = payload.query
    }
    try {
        const response = yield axios.get(`/meeting/sync/calendar/by-token/${payload.token}`, {
            params
        })
        if (response.success) {
            yield put(onSuccessByToken(response.result?.data || []))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailureByToken(error))
    }
}

function* getConnectedCalendarSaga(): any {
    yield takeLatest<TaskAction>(onRequest, getConnectedCalendarSagaTask)
}
function* getConnectedCalendarByToken(): any {
    yield takeLatest<TaskAction>(onRequestByToken, getConnectedCalendarByTokenSagaTask)
}

export const getConnectedCalendarByTokenSaga = getConnectedCalendarByToken
export default getConnectedCalendarSaga
