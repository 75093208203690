import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS } from '@features/General'

export interface IresetPasswordPayload {
    email: string
    password: string
}

export interface IState {
    status: string
    success: boolean
    result: boolean
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    success: true,
    result: false,
    errors: null
}

const resetPasswordSlice = createSlice({
    name: 'hub/users/reset-password',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IresetPasswordPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.result = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const resetPasswordActions = resetPasswordSlice.actions
export default resetPasswordSlice.reducer
