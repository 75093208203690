import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface IEditModulePayload {
    id: string
    data: {
        title: string
        status: boolean
    }
}

export interface IEditModuleState {
    status: string
    data: any
    errors: any
}

const initialState: IEditModuleState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editModule = createSlice({
    name: 'module/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditModulePayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        onReset(state) {
            state.status = STATUS.ERROR
            state.data = null
            state.errors = null
        }
    }
})

export const editModuleActions = editModule.actions
export default editModule.reducer
