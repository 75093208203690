import { STATUS } from '@features/General'
import { MeetingType } from '@features/Meeting/MeetingAgenda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateMeetingPayload extends ISagaCb {
    data: {
        meetingType: keyof typeof MeetingType
        hostIds: string[]
        guestIds: string[]
        hostCompanyId: string
        guestCompanyId: string
        description: string
        guests: string[]
        table: string
        hall: string
        floorPlanLink: string
        meetingDuration: number
        reminderDuration: number
        startTime: string
        endTime: string
        reminderType: string[]
    }
}

export interface ICreateMeetingState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateMeetingState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createMeeting = createSlice({
    name: 'meeting/create',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateMeetingPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createMeetingActions = createMeeting.actions
export default createMeeting.reducer
