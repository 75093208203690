import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateSubscriptionPayload } from '@app-store/slices/subscriptions/create'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: ICreateSubscriptionPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptions.create
const { onAddSubscription } = rootActions.subscriptions.list

function* createSubscriptionSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/subscriptions`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield put(onAddSubscription(response.result))
            yield payload.sagaCB?.onSuccess(response.result)
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.subscription_created_successfully || 'Subscription created successfully.'
            })
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* createSubscriptionSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createSubscriptionSagaTask)
}

export default createSubscriptionSaga
