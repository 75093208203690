import { createSlice } from '@reduxjs/toolkit'

export interface IMatchEngineViewState {
    saveMatchListGroupModal: boolean
    macthListGroupName: string
    matchListGroupId: string
    successMatchListGroupModal: boolean
    matchLists: string[]
}

const initialState: IMatchEngineViewState = {
    saveMatchListGroupModal: false,
    macthListGroupName: '',
    matchListGroupId: '',
    successMatchListGroupModal: false,
    matchLists: []
}

const matchListGroupViewSlice = createSlice({
    name: 'match_list_groups/view',
    initialState,
    reducers: {
        setSaveMatchListGroupModal(state, action) {
            state.saveMatchListGroupModal = action.payload.visible
            state.macthListGroupName = action.payload.macthListGroupName
            state.matchListGroupId = action.payload.matchListGroupId
            state.matchLists = action.payload.matchLists
        },
        setSuccessMatchListGroupModal(state, action) {
            state.successMatchListGroupModal = action.payload
        }
    }
})

export const matchListGroupViewActions = matchListGroupViewSlice.actions
export default matchListGroupViewSlice.reducer
