import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { getStartOrEndTimeOfDate } from '@features/General/dayjs/hanlers'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'

dayjs.extend(timezone)
const meetingsState = {
    filters: {
        status: ['Scheduled'],
        startTime: { $gte: getStartOrEndTimeOfDate() }
    },
    pureFilters: {
        status: ['Scheduled'],
        meetingDate: [dayjs(), null],
        timeShift: ''
    },
    preLoading: true,
    sort: { startTime: 1, _id: -1 }
}

const companyMeetingsSlice = createSlice({
    name: 'ui/company-meetings',
    initialState: { ...listUIInitialState, ...meetingsState },
    reducers: {
        ...listUIReducers,
        onChangePreLoading(state, action) {
            state.preLoading = action.payload
        }
    }
})

export const companyMeetingsActions = companyMeetingsSlice.actions
export default companyMeetingsSlice.reducer
