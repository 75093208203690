import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IActivationPayload } from '@app-store/slices/users/activation'
import { Action } from 'redux'

interface TaskAction extends Action {
    payload: IActivationPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.activation

function* activationSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/users/join?token=${payload.token}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) {
                payload?.sagaCB?.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* activationSaga(): any {
    yield takeLatest<TaskAction>(onRequest, activationSagaTask)
}

export default activationSaga
