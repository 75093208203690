import { combineReducers } from 'redux'
import createOrderReducer, { createOrderActions } from '@app-store/slices/subscriptionOrder/create'
import oneOrderReducer, { oneOrderActions } from '@app-store/slices/subscriptionOrder/oneOrder'
import listReducer, { listActions } from '@app-store/slices/subscriptionOrder/list'
import approveOrderReducer, { approveOrderActions } from '@app-store/slices/subscriptionOrder/approve'
import rejectOrderReducer, { rejectOrderActions } from '@app-store/slices/subscriptionOrder/reject'
import editSubscriptionOrderReducer, { editSubscriptionOrderActions } from '@app-store/slices/subscriptionOrder/edit'
import cancelOrderReducer, { cancelOrderActions } from '@app-store/slices/subscriptionOrder/cancel'
import listSearchReducer, { listSearchActions } from '@app-store/slices/subscriptionOrder/listSearch'

export const subscriptionOrderReducers = combineReducers({
    list: listReducer,
    create: createOrderReducer,
    oneOrder: oneOrderReducer,
    approve: approveOrderReducer,
    reject: rejectOrderReducer,
    edit: editSubscriptionOrderReducer,
    cancel: cancelOrderReducer,
    listSearch: listSearchReducer
})

export const subscriptionOrderActions = {
    list: listActions,
    create: createOrderActions,
    oneOrder: oneOrderActions,
    approve: approveOrderActions,
    reject: rejectOrderActions,
    edit: editSubscriptionOrderActions,
    cancel: cancelOrderActions,
    listSearch: listSearchActions
}
