import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteRatingPayload } from '@app-store/slices/momentum/deleteRating'

interface TaskAction extends Action {
    payload: IDeleteRatingPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.momentum.delete

function* momentumDeleteRatingSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.delete(`/meeting/rating/${payload.rateId}`)
        if (response.success) {
            yield put(onSuccess(response.result.data))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess()
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
        if (payload?.sagaCB?.onError) payload?.sagaCB?.onError()
    }
}

function* momentumDeleteRatingSaga(): any {
    yield takeLatest<TaskAction>(onRequest, momentumDeleteRatingSagaTask)
}

export default momentumDeleteRatingSaga
