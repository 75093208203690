import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IChangeStatusPayload } from '@app-store/slices/sharedLists/changeStatus'
interface TaskAction extends Action {
    payload: IChangeStatusPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.sharedLists.changeStatus

function* changeStatusSagaTask({ payload }) {
    try {
        const response = yield axios.put(`/companies/shared-lists/status/${payload.sharedListId}`, payload.data)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* changeStatusSaga(): any {
    yield takeLatest<TaskAction>(onRequest, changeStatusSagaTask)
}

export default changeStatusSaga
