import { combineReducers } from 'redux'
import createRatingReducer, { createRatingActions } from '@app-store/slices/momentum/createRating'
import deleteRatingReducer, { deleteRatingActions } from '@app-store/slices/momentum/deleteRating'
import editRatingReducer, { editRatingActions } from '@app-store/slices/momentum/editRating'
import listRatingReducer, { listRatingActions } from '@app-store/slices/momentum/listRating'
import oneRatingReducer, { oneRatingActions } from '@app-store/slices/momentum/oneRating'
import notHappenedReducer, { notHappenedActions } from '@app-store/slices/momentum/notHappened'
import createRatingByTokenReducer, { createRatingByTokenActions } from '@app-store/slices/momentum/createRatingByToken'
import editRatingByTokenReducer, { editRatingByTokenActions } from '@app-store/slices/momentum/editRatingByToken'
import notHappenedByTokenReducer, { notHappenedByTokenActions } from '@app-store/slices/momentum/notHappenedByToken'
export const momentumReducer = combineReducers({
    create: createRatingReducer,
    delete: deleteRatingReducer,
    edit: editRatingReducer,
    list: listRatingReducer,
    one: oneRatingReducer,
    notHappened: notHappenedReducer,
    createByToken: createRatingByTokenReducer,
    editByToken: editRatingByTokenReducer,
    notHappenedByToken: notHappenedByTokenReducer
})

export const momentumActions = {
    create: createRatingActions,
    delete: deleteRatingActions,
    edit: editRatingActions,
    list: listRatingActions,
    one: oneRatingActions,
    notHappened: notHappenedActions,
    createByToken: createRatingByTokenActions,
    editByToken: editRatingByTokenActions,
    notHappenedByToken: notHappenedByTokenActions
}
