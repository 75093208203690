import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IOneBrandByEventPayload } from '@app-store/slices/onbordingOrganizer/oneBrandByEvent'

interface TaskAction extends Action {
    payload?: IOneBrandByEventPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.onbordingOrganizer.oneBrandByEvent

function* getOneBrandByEventTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/event/branding/by-event`)

        if (response.success) {
            yield put(onSuccess(response))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* oneBrandByEventSaga(): any {
    yield takeLatest<TaskAction>(onRequest, getOneBrandByEventTask)
}

export default oneBrandByEventSaga
