import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IEditSharedListPayload extends ISagaCb {
    sharedListId: string
    id?: string // we add payload.id only for adding badges[labelTag]
    data: {
        selectedComapnies: any
        badges?: any
    }
}

export interface IEditSharedListState {
    status: string
    data: any
    errors: any
}

const initialState: IEditSharedListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const editSharedList = createSlice({
    name: 'sharedList/edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditSharedListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editSharedListActions = editSharedList.actions
export default editSharedList.reducer
