import { createSlice } from '@reduxjs/toolkit'

export interface GroupsUIState {
    searchValue: string | null
    selectedGroup?: string
    VisibileNewGroupForm?: boolean
    loading?: boolean
    filters?: any
    sort?: string
    projection?: any
    needPopulate: boolean
    populates?: any
}

const initialState: GroupsUIState = {
    searchValue: null,
    selectedGroup: '',
    VisibileNewGroupForm: false,
    loading: false,
    filters: {},
    sort: '-createdAt',
    projection: [],
    needPopulate: true,
    populates: ['location', 'hostId', 'contactIds']
}

const GroupsSlice = createSlice({
    name: 'ui/groups',
    initialState,
    reducers: {
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeItems(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state[item] = action.payload[item]
            }
        }
    }
})

export const groupsActions = GroupsSlice.actions
export default GroupsSlice.reducer
