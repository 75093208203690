import listSaga from './listSaga'
import staticEnumsSaga from './staticEnumsSaga'
import createSaga from './createSaga'
import editSaga from './editSaga'
import oneTemplateSaga from './oneTemplateSaga'
import evaluatePlaceholderSaga from './evaluatePlaceholder'
import deleteSaga from './deleteSaga'

const templatesSagas = [
    listSaga(),
    staticEnumsSaga(),
    createSaga(),
    editSaga(),
    oneTemplateSaga(),
    evaluatePlaceholderSaga(),
    deleteSaga()
]

export default templatesSagas
